/* ------------------------------------------------------------ *\
	List Links Secondary
\* ------------------------------------------------------------ */

.list-links-secondary {
	display: flex;
	padding: 0;
	margin: 0;
	font-size: 14px;
	letter-spacing: -0.03em;
	color: #fff;
	list-style-type: none;
	li {
		+ li {
			margin-left: 11px;
		}
	}
	a {
/*
		display: block;
		height: 48px;
		padding: 0 19px;
		border-radius: 2px;
		line-height: 53px;
*/
		
		
	}
}

@media(max-width: $width-sm-max){
	.list-links-secondary {
		font-size: 12px;
		a {
			padding: 5px 15px;
				display: block;
			
		}
	}
}

@media(max-width: $width-xs-max){
	.list-links-secondary {
		flex-flow: row wrap;
		font-size: 12px;
		li {
			+ li {
				margin: 13px 0 0;
			}
		}
		a {
/*
			height: 40px;
			line-height: 38px;
*/
		}
	}
}