#intro-section.intro-popup {
    /*background-image: url(/resources/images/home-image_henley.jpg);*/
    background-image: none;
    background-color: transparent;
}

#intro-section{

/*
	.hero_block{
		&__title{
			font-size: 42px;
			line-height: 42px;
			letter-spacing: 0.5px;
			@media (max-width: $width-xs-max) {
				font-size: 27px;
				line-height: 27px;
			}
		}

	}
*/
	.hero_block__title{
		@media (max-width: $width-sm-max) {
			br {
				display: none;
			}
		}
	}
}



#special-offers-section{

	.offers-info-column.last .offers-info-column__body{
		padding-right: 0;
	}

}
.explore-section{
	.explore-banner .main-text{
		width: auto;
		max-width: 45%;
		@media (max-width: $width-md-max) {
			font-size: 38px;
		}
		@media (max-width: $width-xs-max) {
			font-size: 22px;
			width: 80%;
			max-width: 80%;
			/*margin-top: 37px;*/
			line-height: 1.15em;
		}
	}

	.explore-banner .main-text a{
		margin-top: 6px;

		@media (max-width: $width-xs-max) {
			margin-top: 0;
		}
	}

	.offers-info-column--heightsm {
		min-height: 180px;
		margin-bottom: 90px;
		@media (max-width: $width-sm-max) {
			min-height: 0;
		}
		@media (max-width: $width-xxs-max) {
				margin-bottom: 30px;
			&.last{
				margin-bottom: 30px;
			}
		}
		@media (max-width: $width-xs-max) {
			min-height: 0;
		}
	}

}

.guarantees-head{
	margin-top: 44px;
	margin-bottom: 46px;
	padding-top: 20px;
	border-top:1px solid $gold-dark;
	overflow: hidden;

	span.arrow{
	    background: url(../images/icon-arrow-red-big.svg) 99% center no-repeat;
	    display: inline-block;
	    background-size: 30px auto;
	    padding-right: 40px;
	    font-style: italic;
	    -webkit-transition: all 300ms ease;
	    transition: all 300ms ease;
	    text-decoration: none;
	    height: 22px;
	    background-size: contain;
	    margin-top: 8px;
	}

	@media (max-width: $width-xs-max) {
		padding-top: 10px;
	}
	&__title{
		font-size: 32px;
		float: left;
	}
	&__link{
		color: $main-brown;
		text-decoration: none;
		font-size: 14px;
		padding-top: 9px;
		float: right;
		position: relative;
		font-weight: 500;
		&:hover, &:focus{
			color: $main-brown;
		}
	}
	.btn-mobile {
		font-size: 25px;
		color: $main-brown;
		margin: 0 auto;
		font-family: $fenice;
		text-align: center;
	}
}

#guarantees-section-home {
	/*background: url("../images/guarantee-img.jpg")no-repeat center center;
	background-size: cover;*/
	min-height: 350px;
	padding-bottom: 130px;
	@media (max-width: $width-sm-max) {
		/*height: 280px;*/
		padding-bottom: 30px;
	}
	h3{
		font-size: 14px;
		font-family: $helvetica;
		text-transform: uppercase;
		letter-spacing: 0.3em;
		padding-top: 15px;
		margin-top: 90px;

		/*padding-top: 60px;*/
		/*color:#fff;*/
		padding-bottom: 30px;
		@media (max-width: $width-md-max) {
			padding-top: 18px;
			margin-top: 18px;
		}
		@media (max-width: $width-sm-max) {
			font-size: 25px;
			padding-bottom: 12px;
		}
		img{
			margin-left: 7px;
		}
	}
	p{
		font-size: 32px;
		font-family: $graphik;
		line-height: 1.1em;
		margin-bottom: 30px;
		@media (max-width: $width-md-max) {
			br {
				display: none;
			}
		}
		@media (max-width: $width-sm-max) {
			font-size: 16px;
			br {
				display: none;
			}
		}

	}
	@media (max-width: $width-md-max) {
		.button-gold {
			display: inline-block;
		}
	}
	@media (max-width: $width-sm-max) {
		.row > div:first-child {
			margin-bottom: 40px;
		}
	}
	@media (max-width: $width-xs-max) {
		.button-gold {
			/*
			line-height: 1.8;
			height: 30px;
			padding-left: 20px;
			padding-right: 20px;
			*/
			width: 50%;
			text-align: center;
			padding: 8px;
			height: 33px;
			font-size: 12px;
			line-height: inherit;
		}
	}
	ul{
		margin-bottom: 35px;
		text-align: center;
		padding-left: 0;
		@media (max-width: $width-sm-max) {
			margin-left: 0;
			padding-left: 0;
		}
		@media (max-width: $width-xs-max) {
			text-align: center;
		}
		li{
			display: inline-block;
			list-style: none;
			/*margin-right: 65px;*/
			margin-right: 0px;
			float: left;
			margin-top: 20px;
			width: 20%;
			@media (max-width: 1024px) {
				margin-right: 0px;
			}
			/*
			@media (max-width: $width-sm-max) {
				margin-right: 45px;
			}
			*/
			@media (max-width: $width-xs-max) {
				margin-right: 0;
			}
			&:last-child {
				margin-right: 0;
			}
			img{
				width: 140px;
				/*height: 115px;*/
				@media (max-width: $width-xs-max) {
					width: 17vw;
					height: auto;
				}
			}
		}
	}
}