/* ------------------------------------------------------------ *\
ARTICLE NEWS
\* ------------------------------------------------------------ */

/*  Extends Article Class  */

.article--news {
	padding: 24px 0;
	line-height: 1.6;
	@media (max-width: $width-xs-max) {
		line-height: 1.43;
		font-size: 12px;
		letter-spacing: -0.02em;
	}
	&__head h1 {
		font-size: 60px;
		border: 0;
		line-height: 0.9;
		margin-bottom: 46px;
		.news-post &{
			padding-top: 0px;
			border-top: none;
			margin-bottom: 45px;
			text-transform: uppercase;
		}
		@media (max-width: $width-xs-max) {
			font-size: 35px;
			padding-top: 2px;
			line-height: 1;
			margin-bottom: 32px;
		}
	}
	&__meta {
		border-top: 2px solid  #c1c1c1;
		display: flex;
		justify-content: space-between;
		padding-top: 14px;

		@media (max-width: $width-xs-max) {
			padding-top: 11px;
			font-size: 12px;
			line-height: 1.3;
			.news-post & {
				display: block;
			}
			span { display: block; }
		}

		&-author {
			flex-grow: 1;
			min-width: 150px;
		}
	}
	&__tags {
		flex-grow: 2;
		text-align: right;

		@media (max-width: $width-xs-max) {
			flex-grow: 1;
			text-align: left;
		}

		a {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}

			@media (max-width: $width-xs-max) {
				line-height: 32px;
				white-space: nowrap;
				margin-right: 10px;
			}
		}
	}
	&__meta-category {
		border: 1px solid  #c9c8c8;
		border-radius: 4px;
		font-size: 14px;
		font-family: $graphik;
		color: $sage;
		letter-spacing: -0.043em;
		height: 36px;
		padding: 6px 22px;
		@media (max-width: $width-xs-max) {
			font-size: 12px;
			// padding: 8px 38px;
			font-weight: 600;
			height: 32px;
		}
		&:hover {
			background: #c9c8c8;
			color: #fff;
			text-decoration: none;
		}
	}
	&__body {
		padding-top: 20px;

		@media (max-width: $width-xs-max) {
			padding-top: 23px;
		}
	}
	.video {
		margin-bottom: 49px;

		@media (max-width: $width-xs-max) {
			margin-bottom: 36px;
		}
	}
	h4 {
		margin-bottom: 21px;
		line-height: 1.2;
		@media (max-width: $width-xs-max) {
			font-size: 19px;
			line-height: 1.12;
			margin-bottom: 15px;
		}
	}
	figure {
		margin: 0 0 44px;

		@media (max-width: $width-xs-max) {
			margin: 0 -20px 31px;
		}
	}
	blockquote  {
		padding: 31px 0;
		margin: 40px 0;
		font-size: 24px;
		letter-spacing: 0.014em;
		@media (max-width: $width-xs-max) {
			margin: 30px 0 37px;
			padding: 28px 0 22px;
			font-size: 20px;
		}
	}
	img {
		max-width: 100%;
		height: auto;
	}

}