/* ==========================================================================
	WHAT WE VALUE SECTION
   ========================================================================== */
#what-we-value-section {
	background: url('../images/henley_awards_bg1.jpg') center center no-repeat;
	background-size: cover;
	padding-top: 100px;
	padding-bottom: 100px;
	@media (max-width: $width-sm-max) {
// 		background-image: url('../images/henley_awards_bg_sm.jpg');
		padding-top: 127px;
		padding-bottom: 127px;
	}
	@media (max-width: $width-xs-max) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	@media (-webkit-min-device-pixel-ratio: 1.25) {
		background-image: url('../images/henley_awards_bg1.jpg');
	}
	.heading-text {
		font-size:14px;
		/*margin-top:30px;*/
		color:white;
		border-top:1px solid white;
		position: relative;
		font-weight: 500;
		padding-top: 15px;
		margin-bottom: 30px;
		@media (max-width: $width-xs-max) {
			font-size: 12px;
			margin-top: 15px;
			padding-top: 10px;
		}
	}
	img {
		display: block;
		margin-bottom: 15px;
		height: auto;
		max-height: 70px;
		width: auto;
		@media (max-width: $width-xs-max) {
			max-width: 100%;
		}
	}
	h4 {
		font-family: $fenice;
		font-size: 32px;
		line-height: 1;
		color: #fff;
		margin-bottom: 6px;
		padding-top: 14px;
		@media (max-width: $width-xs-max) {
			font-size: 20px;
			padding-top: 0;
			margin-bottom: 20px;
		}
	}
	span.arrow{
		font-size: 32px;
		position: relative;
		line-height: 0.9em;
		font-family: $fenice;
		background: url(../images/icon-arrow-red-big.svg) 99% center no-repeat;
		display: inline;
		background-size: 20px auto;
		padding-right: 30px;
		font-style: italic;
		transition: all 300ms ease;
		text-decoration: none;
		width:20px; 
		height:20px;
		
		@media (max-width: $width-sm-max) {
			font-size: 38px;
		}
		@media (max-width: $width-xs-max) {
			font-size: 20px;
			line-height: 1.15em;
			background-size: 12px;
			padding-right: 20px;
		}
		&:hover {
			background-position: right center;
		}
	}
	a {
		font-size: 32px;
		position: relative;
		line-height: 0.9em;
		font-family: $fenice;
		background: url(../images/icon-arrow-red-big.svg) 99% center no-repeat;
		display: inline-block;
		background-size: 20px auto;
		padding-right: 30px;
		font-style: italic;
		transition: all 300ms ease;
		text-decoration: none;
		@media (max-width: $width-sm-max) {
			font-size: 38px;
		}
		@media (max-width: $width-xs-max) {
			font-size: 20px;
			line-height: 1.15em;
			background-size: 12px;
			padding-right: 20px;
		}
		&:hover {
			background-position: right center;
		}
	}
	a.norhpadding{
		padding-right:0;
	}
}