/* ==========================================================================
	Signup Section
   ========================================================================== */
#section-signup{
	height: 100vh;
	@media (max-width: $width-xs-max) {
		height: 100%;
		background-attachment: fixed !important;
	}
	.signup_block{
		width: 75vw;
		text-align: left;
		transform: translate(-50%, -50%);
		margin: 0;
		top:60%;
		@media (max-width: $width-xs-max) {
			width: 100%;
			position: relative;
			top:0;
			left:0;
			transform: translate(0);
			padding: 80px 20px;
		}
		@media (min-width: $width-sm-max) {
			width: 760px;
		}
	}

	.signup-group{
		/*
		max-width: 760px;
		width: 100%;
		*/
		@media (max-width: $width-xs-max) {
			width: 100%;
			padding: 0 40px;
		}

		&__text{
			padding-top: 15px;
			border-top: 1px solid #fff;
		}
		h2{
			font-size: 32px;
			padding-top: 75px;
			@media (max-width: $width-xs-max) {
				padding-top: 35px;
			}
			span{
				font-style: italic;
			}
		}
		ul{
			padding: 0;
			margin:0;
			margin-top: 20px;
			@media (max-width: $width-xs-max) {
				margin-top: 15px;
			}
			li{
				display: block;
				margin-bottom: 8px;
				font-size: 14px;
				font-weight: 500;
				img{
					margin-right: 10px;
					@media (max-width: $width-xs-max) {
						display: inline-block;
						/*float: left;*/
						vertical-align: middle;
						width: 47px;
						height: 47px;
					}
				}
				@media (max-width: $width-xs-max) {
					/*clear: both;*/
					min-height: 47px;
					margin-bottom: 5px;
					span {
						display: inline-block;
						vertical-align: middle;
						width: calc(100% - 60px);
						line-height: 1.2;
					}
				}
			}
		}
		.btn{
			&--facebook{
				background-color: #425698;
				color:#fff;
				display: block;
				box-shadow: 0 3px 3px  #3B4B88;
				@media (max-width: $width-xs-max) {
					margin-top: 45px;
				}
				&.hvr-darken:hover {
					background-color: #2C3966 !important;
					background: #2C3966;
					box-shadow: 0 3px 3px #242d54;
				}
			}
		}
		.form{
			&--signup{
				label{
					display: block;
					font-size: 14px;
					font-weight: 500;
					padding-top: 5px;
				}
				input{
					width: 100%;
					display: block;
					margin-bottom: 16px;
					font-size: 14px;
					color:#C0C0C0;
					height: 35px;
					padding-left: 15px;
					border-radius: 4px;
					border:none;
					&[type="submit"]{
						padding: 0;
						background-color:#FF5153;
						color:#fff;
						margin: 25px 0;
						box-shadow: 0 3px 3px $main-brown;
					}
					@media (max-width: $width-xs-max) {
						margin-bottom: 5px;
					}
				}
			}
		}
		.login-here{
			font-weight: 500;
			display: block;
			color:#fff;
			font-size: 14px;
			br {
				display: none;
				@media (max-width: $width-xs-max) {
					display: block;
				}
			}
		}
		.title-or{
			position: relative;
			margin: 20px 0;
			font-family: $graphik;
			&:before{
				width: 155px;
				height:1px;
				background: #fff;
				content: "";
				display: inline-block;
				margin-right: 14px;
				vertical-align: middle;
			}
			&:after{
				width: 155px;
				height:1px;
				background: #fff;
				content: "";
				display: inline-block;
				margin-left: 14px;
				vertical-align: middle;
			}
			@media (max-width: $width-xs-max) {
				&:before, &:after {
					width: 40%;
					max-width: 155px;
				}
			}
		}
	}
}