/* ------------------------------------------------------------ *\
	Slider Images Secondary
\* ------------------------------------------------------------ */


.slider-images-secondary {
	position: relative;
	margin-bottom: 125px;
	letter-spacing: -0.03em;
	color: #918f8f;
	outline: none;
	p {
		margin-bottom: 0;
		.general &{
			margin-top: 10px;
			&:empty {
				margin-top: 0;
			}
		}
		display: none;
	}
	p.float-lr{
		text-align:right;
		padding-right: 50px;
		span{
			text-transform: uppercase;
			float: left;
			padding-left: 50px;
			font-family: $helvetica;
			letter-spacing: .3em;
			text-transform: uppercase;
		}
	}
	.slick-active, .inclusions &{
		p{
			display:block;
		}
	}
	img {
		outline: none;
		max-height: 100%;
	}
	.slick-arrow {
		position: absolute;
		width: 30px;
		height: 30px;
		top: calc(50% - 10px);
		transform: translateY(-50%);
		margin-top: -15px;
		transition: opacity .3s;
		z-index: 10;
		font-size: 0;
		line-height: 0;
		text-indent: -4004px;
		&:hover {
			opacity: .7;
		}
		@media (max-width: $width-sm-max) {
			top: calc(50% - 16px);
		}
	}
	.slick-prev {
		left: 10px;
		background: url(../images/arrow-left.png) no-repeat 0 0;
		background-size: 100% 100%;
	}
	.slick-next {
		right: 10px;
		background: url(../images/arrow-right.png) no-repeat 0 0;
		background-size: 100% 100%;
	}
	&__slide {
		&-content {
			text-align: center;


		}
		&-image {
			margin-bottom: 12px;
			outline: none;
		}
	}
}

@media(max-width: 1200px){
	.slider-images-secondary {
		.slick-prev {
			left: 20px;
			margin: 0;
		}
		.slick-next {
			left: auto;
			right: 20px;
			margin: 0;
		}
	}
}

@media(max-width: $width-sm-max){
	.slider-images-secondary {
		margin-bottom: 60px;
	}
}

@media(max-width: $width-xs-max){
	.slider-images-secondary {
		margin-bottom: 30px;
		.slick-prev {
			left: 10px;
		}
		.slick-next {
			right: 10px;
		}
		.img-container{
			height: auto!important;
		}

		img {
			width: auto!important;
			height: 223px!important;
			outline: none;
			min-height: 223px;
		}

		p {
		    margin-top: 11px;
		    font-size: 15px;
		    max-width: 100vw !important;

		    &.float-lr {
		    	margin: 0 auto;
		    	padding-right: 20px;
		    	padding-left: 0;
		    	padding-top: 10px;

		    	span {
		    		padding-left: 20px;
		    	}
		    }
		}

		.slick-slide:not(.slick-current) .slider_bg_overlay{ max-height: 223px;}
	}
}
