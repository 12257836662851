/* ------------------------------------------------------------ *\
Table Properties
\* ------------------------------------------------------------ */

.table-properties {
	padding-top: 28px;
	table {
		width: 100%;
		table-layout: fixed;
	}
	thead {
		border-bottom: 2px solid #d0353a;
	}
	tbody {
		tr {
			&:first-child {
				td {
					padding: 12px 0 4px;
				}
			}
		}
		td {
			height: 42px;
		}
	}
	th {
		font-weight: 500;
		&:nth-child(1) {
			width: 8.68%;
		}
		&:nth-child(2) {
			width: 17.02%;
		}
		&:nth-child(3) {
			width: 34.21%;
		}
		&:nth-child(4) {
			width: 14.04%;
		}
		&:nth-child(5) {
			width: 17.02%;
		}
		&:nth-child(6) {
			width: 9.03%;
		}
	}

	&__btn {
		display: block;
		height: 32px;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 500;
		line-height: 32px;
		color: #231f20;
		text-align: center;
		background: #fff;

		&:hover {
			text-decoration: none;
		}
	}
	th:not(:last-child), td:not(:last-child) {
		padding-right: 10px;
	}
	th {
		height: 49px;
		vertical-align: middle;
	}
}

@media(max-width: $width-sm-max){
	.table-properties {
		th {
			&:nth-child(1) {
				width: 12.68%;
			}
			&:nth-child(2) {
				width: 19.02%;
			}
			&:nth-child(3) {
				width: 28.21%;
			}
			&:nth-child(4) {
				width: 14.04%;
			}
			&:nth-child(5) {
				width: 17.02%;
			}
			&:nth-child(6) {
				width: 9.03%;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.table-properties {
		thead {
			display: none;
		}

		tr:first-child [data-title], [data-title] {
			position: relative;
			padding: 0 0 0 85px;
			&:before {
				content: attr(data-title);
				position: absolute;
				top: 0;
				left: 0;
				font-weight: 500;
			}
		}
		td {
			+ td {
				margin-top: 20px;
			}
		}
		tbody {
			td {
				height: auto;
			}
		}
		tr {
			border-top: 1px solid #df383c;
			padding-top: 28px;
			+ tr {
				margin-top: 28px;
			}
		}

		.table-properties__btn {
			max-width: 244px;
		}

		table, tbody, tr, td {
			display: block;
			width: 100%;
		}
	}
}