/* ------------------------------------------------------------ *\
Slider Images
\* ------------------------------------------------------------ */

.slider-images {
	position: relative;
	margin: 18px 0 90px;
	letter-spacing: -0.02em;
	color: #918f90;
	.display-centre-single &, .estate &{
		margin: 0px;
	}

	strong {
		font-weight: 500;

		&.visible-xs-inline {
			@media (max-width: $width-xs-max) {
				display: block !important;
				float: none !important;
			}
		}
	}
	img {
		display: block;
		width: 100%;
		height: auto;
		margin-bottom: 12px;
	}
	p {
		margin-bottom: 0;
	}

	.slides {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.slide {

		&:focus {
			outline: 0;
		}
	}
	&__counter {
		position: absolute;
		bottom: 0px;
		right: 0;
// 		background: #f5f5f5;
	}
	.slick-prev {
		left: 20px;
		background-image: url(../images/arrow-left.png);

		#facadesSelection & {
			left: 40px;
		}
	}
	.slick-next {
		right: 20px;
		background-image: url(../images/arrow-right.png);

		#facadesSelection & {
			right: 40px;
		}
	}

	.slick-prev, .slick-next {
		content: '';
		position: absolute;
		width: 30px;
		height: 30px;
		margin-top: -7px;
		top: calc(50% - 32px);
		transform: translate(0, 0);
		background-size: 100% 100%;
		transition: opacity .3s;
		z-index: 8;

		&:before {
			display: none;
		}
		&:hover {
			opacity: .7;
		}

		#facadesSelection & {
			top: 50%;
			margin-top: -15px;
		}
	}

	@media (max-width: $width-xs-max) {
		#facadesSelection & {
			.slick-prev {
				left: 20px;
			}
			.slick-next {
				right: 20px;
			}
		}
	}

	.pinterest_button{
		    font-size: 35px;
		    height: 35px;
		    width: 30px;
		    position: absolute;
		    opacity: 0;
			transition: opacity .3s;
		    margin-left: 20px;
		    top: 15px;
		    cursor: pointer;
		    color: $sage;
		    i{
			    top: 0px;
				position: absolute;
		    }
		    @media(max-width: $width-xs-max){
			   opacity: 1;
		    }
	}
	&:hover{
		.pinterest_button{
			opacity: 1;
		}
	}
}

@media(max-width: $width-sm-max){
	.slider-images {
		margin-bottom: 50px;
	}
}

@media(max-width: $width-xs-max){
	.slider-images {
		/*margin-bottom: 34px;*/
		margin-bottom: 42px;
		img {
			margin-bottom: 6px;
		}
		.slick-prev {
			left: 7px;
		}
		.slick-next {
			right: 7px;
		}
		.slick-prev, .slick-next {
			width: 26px;
			height: 26px;
			margin-top: 5px;
			top: calc(50% - 40px);
		}
	}
}


/* ------------------------------------------------------------ *\
	SLIDER IMAGES SEECONDARY SMALL
\* ------------------------------------------------------------ */

/*  Exendex slider-images-secondary  */

.slider-images-secondary {
	&--small {
		padding-top: 17px;
		margin-bottom: 92px;
		& .slick-next {
			margin: 12px 0 0;
			left: auto;
			right: 21px;
		}
		& .slick-prev {
			margin: 12px 0 0;
			right: auto;
			left: 21px;
		}
		& .slick-slide {
			overflow: hidden;
			outline: none;
		}
		.slider-images-secondary__slide-content {
			text-align: left;
			@media (max-width: $width-xs-max) {
				text-align: center;
				padding: 0 20px;
			}
		}
		@media (max-width: $width-sm-max) {
			margin-bottom: 20px;
		}
		@media (max-width: $width-xs-max) {
			margin-left: -20px;
			margin-right: -20px;
			margin-bottom: 20px;
			& .slick-next {
				right: 30px;
				margin-top: 0;
				width: 26px;
				height: 26px;
			}
			& .slick-prev {
				left: 30px;
				margin-top: 0;
				width: 26px;
				height: 26px;
			}
		}
	}
}