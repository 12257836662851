/* ------------------------------------------------------------ *\
	Infobox
\* ------------------------------------------------------------ */

.infoBox {
	position: relative;
	width: 130px !important;
	margin-left: -65px;
	z-index: 2;
	&.infobox-expanded {
		z-index: 5;
		.gmap:not(.no-recenter-onclick) &{
			margin-left: 0px;
		}
		
		.infobox-tag {
			box-shadow: none;
			&:before,
			&:after {
				display: none;
			}
		}
		.infobox-cluster {
			background-image: url(../images/pin-black.png);
		}
		.infobox-content {
			background-color: $map-popup-grey;
			&:not(.infobox-content-secondary) {
				display: block;
			}
		}
	}
	img {
		display: none;
	}
	.infobox-tag {
		position: relative;
		
		padding: 7px 9px;
		border-radius: 3px;
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;
		color: #fff;
		//white-space: nowrap;
		background: #000;
		margin-bottom: 34px;
		text-align: center;
		//left: -50%;
		
		
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 100%;
			left: 50%;
			margin-left: -12px;
			border-style: solid;
			border-width: 12px 12px 0 12px;
			border-color: #000 transparent transparent transparent;
			z-index: 3;
		}
		&:after {
			content: '';
			position: absolute;
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: calc(100% + 2px);
			left: 50%;
			margin-left: -12px;
			border-style: solid;
			border-width: 12px 12px 0 12px;
			border-color: rgba(0, 0, 0, .3) transparent transparent transparent;
			z-index: 2;
		}
		&-white {
			color: #000;
			background: #fff;

			&:before {
				border-color: #fff transparent transparent transparent;
			}
		}
		&-darkgrey {
			color: #fff;
			background: #3a3637;
		
			&:before {
				border-color: #3a3637 transparent transparent transparent;
			}
		}
		i {
			position: relative;
			top: -1px;
			margin-right: 8px;
		}
	}
	.infobox-cluster {
		width: 29px;
		height: 40px;
		padding: 0;
		box-shadow: none;
		text-align: center;
		background-image: url(../images/pin.png);
		background-color: transparent;
		background-size: 100% 100%;
		position: relative;
		&:before, &:after {
			display: none;
		}
		strong {
			font-size: 14px;
			font-weight: 400;
			&.infobox-counter {
			   
			    top: 5px;
			    position: absolute;
			    left: 50%;
			    transform: translateX(-50%);
			}
		}
	}
	.infobox-content {
		display: none;
		position: absolute;
		bottom: 0;
		//left: 50%;
		margin-bottom: 34px;
		width: 275px;
		padding-bottom: 19px;
		margin-left: -137px;
		border-radius: 3px;
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
		font-size: 13px;
		color: #231f20 ;
		text-align: center;
		background:  $map-popup-grey;
		z-index: 10;
		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: 100%;
			left: 50%;
			margin-left: -12px;
			border-style: solid;
			border-width: 12px 12px 0 12px;
			border-color:  $map-popup-grey transparent transparent transparent;
			z-index: 3;
		}
		&:after {
			content: '';
			position: absolute;
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			top: calc(100% + 2px);
			left: 50%;
			margin-left: -12px;
			border-style: solid;
			border-width: 12px 12px 0 12px;
			border-color: rgba(0, 0, 0, .3) transparent transparent transparent;
			z-index: 2;
		}
		strong {
			display: block;
			margin-bottom: 8px;
			font-weight: 500;
			
		}
		p {
			margin-bottom: 0;
		}
		a {
			&[href^="tel"] {
				font-weight: 500;
				color: #231f20;
			}
		}
	}
	.infobox-image {
		height: 145px;
		margin-bottom: 17px;
		border-radius: 3px 3px 0 0;
		background-size: cover;
		background-position: center center;
	}
	.infobox-btn {
		display: inline-block;
		min-width: 145px;
		height: 26px;
		padding: 0 10px;
		margin-top: 10px;
		border-radius: 2px;
		font-size: 13px;
		letter-spacing: -0.02em;
		line-height: 26px;
		color: #fff;
		background: $sage;
		&:hover{
			background: #fff;
			color: $sage;
		}
	}
}

@media(max-width: $width-xs-max){
	.infoBox {
		&.infobox-expanded {
			.infobox-tag {
				box-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
				&:after, &:before {
					display: block;
				}
			}
			.infobox-content {
				&:not(.infobox-content-secondary) {
					display: none;
				}
			}
		}
	}
}

