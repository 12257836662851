/* ------------------------------------------------------------ *\
	WIDGET
\* ------------------------------------------------------------ */

.widget {
	&--cta {
		border: 1px solid #c0c0c0;
		text-align: center;
		padding: 60px 23px;
		margin-bottom: 0;

		@media (max-width: $width-xs-max) {
			padding: 32px 23px 27px;
		}

		h2 {
			font-size: 32px;
			color: #231f20;
			font-family: $fenice;
			line-height: 1.1em;
			margin-bottom: 33px;
			@media (max-width: $width-xs-max) {
				font-size: 25px;
				margin-bottom: 7px;
			}
		}

		p {
			margin-bottom: 38px;
			@media (max-width: $width-sm-max) {
				max-width: 164px;
				margin: 0 auto 27px;
			}
		}

		.button-gold {
			padding: 12px 25px;
			border-radius: 2px;

			@media (max-width: $width-xs-max) {
				padding: 5px 7px;
				border-radius: 2px;
			}
		}
	}
}