/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

/*  Custom Radio  */

.list-radios {
	display: flex;
	flex-flow: row wrap;
	padding: 0;
	margin: 0;
	list-style-type: none;
	li {
		flex: 0 0 50%;
		margin-bottom: 16px;
	}
	&--secondary {
		li {
			flex: 0 1 auto;
			+ li {
				margin-left: 40px;
			}
		}
	}
}
.houses-filter__map-controls .list-radios {
	text-align: right;
	display: block;
	flex: none;
	li {
		display: inline-block;
		flex: none;
		margin-bottom: 0;
		margin-top: 7px;
	}
}

.custom--radio, .custom-checkbox {
	margin: 0;
	input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		&:checked {
			~ .radio__label, ~ .checkbox__label {
				span {
					&:before {
						display: block;
					}
				}
			}
		}
	}

	.radio__label.checked{
		span {
			&:before {
				display: block;
			}
		}
	}

	.radio__label, .checkbox__label {
		position: relative;
		display: block;
		padding: 0 0 0 30px;
		margin: 0;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.03em;
		color: $charcoal;
		span {
			position: absolute;
			width: 18px;
			height: 18px;
			top: 0;
			left: 0;
			border-radius: 50%;
			background: #fff;
			#contactLayout &{
				border: 1px solid $charcoal;
			}
			&:before {
				content: '';
				position: absolute;
				display: none;
				width: 10px;
				height: 10px;
				top: 50%;
				left: 50%;
				margin: -5px 0 0 -5px;
				border-radius: 50%;
				background: $charcoal;
			}
		}
		&--secondary {
			font-weight: 400;
		}
	}


	.checkbox__label {
		span {
			border-radius: 0;
			&:before {
				content: '\00D7';
				background: transparent;
				color: $charcoal;
				font-size: 22px;
				margin-top: -14px;
				margin-left: -5px;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.custom--radio {
		.radio__label {
			font-size: 12px;
			span {
				top: -1px;
			}
		}
	}
}

/* Custom Radio Secondary */

.list-radios-secondary {
	display: flex;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.custom--radio-secondary {
	padding: 0;
	margin: 0;
	input {
		display: none;
		&:checked {
			~ .radio__label {
				border-color: #fff;
			}
		}
	}

	.radio__label {
		display: block;
		min-width: 61px;
		height: 34px;
		padding: 0 5px;
		border: 1px solid transparent;
		border-radius: 2px;
		font-size: 14px;
		line-height: 32px;
		text-align: center;
	}
}

/*  Modifier: Custom Radio Tertiary */

.custom--radio-tertiary, .custom--checkbox-tertiary {
	margin: 0;
	input {
		display: none;
		&:checked {
			~ .radio__label, ~ .checkbox__label {
				span {
					&:after {
						display: block;
					}
				}
			}
		}
	}

	.radio__label, .checkbox__label {
		position: relative;
		display: block;
		padding: 0 28px 0 0;
		span {
			position: absolute;
			display: inline-block;
			width: 20px;
			height: 20px;
			top: 50%;
			right: 0;
			margin-top: -10px;
			border-radius: 50%;
			vertical-align: middle;
			background: #fff;

			&:after {
				content: '';
				position: absolute;
				display: none;
				width: 12px;
				height: 12px;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				border-radius: 50%;
				background: $charcoal;
			}
		}
	}
	.checkbox__label {
		
		&.formatted{
			position: relative;
			display: block;
			padding: 0 28px 0 30px!important;
			font-style: normal;
			line-height: 20px;
			font-family: auto;
			font-weight: 400;
			span {
				position: absolute;
				display: inline-block;
				width: 20px;
				height: 20px;
				top: 0;
				left: 0;
				margin-top: 0;
				border-radius: 50%;
				vertical-align: middle;
				background: #fff;
	
				&:after {
					content: '';
					position: absolute;
					display: none;
					width: 12px;
					height: 12px;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					border-radius: 50%;
					background: $charcoal;
				}
			}
		

		}	
	}
	
	
	
	
	.checkbox__label {
		span {
			border-radius: 0;

		}
	}
}

@media(max-width: $width-xs-max){
	/* Custom Radio Secondary */
	.list-radios-secondary {
		li {
			flex: 0 0 25%;
		}
	}

	.list-radios--secondary {
		display: block;
		li {
			margin: 0 0 15px;
			+ li {
				margin-left: 0;
			}
		}
	}

	.custom--radio-secondary {
		.radio__label {
			font-size: 11px;
		}
	}
}

/* Custom Select */

.custom--select {
	position: relative;
	min-width: 166px;

	& + & {
		margin-left: 12px;
	}

	&:before {
		content: '';
		position: absolute;
		width: 11px;
		height: 9px;
		top: 50%;
		right: 10px;
		margin-top: -5px;
		pointer-events: none;
		background-image: url(../images/select-arrow-down.png);
		background-size: 100% 100%;
	}

	select {
		display: block;
		width: 100%;
		height: 34px;
		padding: 0 32px 0 10px;
		border: 1px solid #fff;
		border-radius: 2px;
		font-size: 14px;
		background: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		option {
			color: #000;
		}

		&::ms-expand {
			display: none;
		}
	}
}

/*  Custom Select Secondary  */

.custom--select-secondary {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 12px;
		height: 10px;
		right: 9px;
		top: 50%;
		margin-top: -5px;
		background: url(../images/select-arrow-down-secondary.png) no-repeat 0 0;
		background-size: 100% 100%;
		pointer-events: none;
	}

	select {
		display: block;
		width: 100%;
		height: 31px;
		padding: 0 30px 0 8px;
		border: 1px solid #fe4e50;
		border-radius: 2px;
		font-size: 14px;
		letter-spacing: -0.02em;
		color: #231f20;
		background: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		&::ms-expand {
			display: none;
		}
	}
}