/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links {
	display: flex;
	padding: 0;
	margin: 0 0 41px;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.02em;
	list-style-type: none;

	li {
		position: relative;
		+ li {
			margin-left: 31px;
			&:before {
				content: '';
				position: absolute;
				width: 2px;
				height: 14px;
				top: 2px;
				left: -17px;
				background: #bdbcbc;
			}
		}
	}
}

@media(max-width: $width-md-max){
	.list-links {
		margin-bottom: 18px;
	}
}

@media(max-width: $width-sm-max){
	.list-links {
		margin-bottom: 38px;
		font-size: 12px;
		li {
			+ li {
				margin-left: 27px;
				&:before {
					left: -15px;
				}
			}
		}
	}
}