/* ------------------------------------------------------------ *\
	Feature
\* ------------------------------------------------------------ */

.features {
	margin-bottom: 80px;
	@media(max-width: $width-xs-max){
		&.row{
			margin-left: 0;
			margin-right: 0;
		}

		> div {
		    padding: 0;
		    .general &{
			    padding-left: 15px;
				padding-right: 15px;
		    }
		    .where-we-build &{
			    padding-left: 0px;
				padding-right: 0px;
		    }
		}
	}
	.feature {
		margin-bottom: 26px;
		overflow: hidden;
	}
	@media(max-width: $width-xs-max) and (orientation:portrait){
		.col-xs-6 {
	        width: 100%;
	    }

	}
}

.feature {
	position: relative;
	&:not(.no_hover){
		span {
			&:first-child {
					display: none;
			}
		}

	}
	a.link_cover_box{
		    opacity: 1;
		    visibility: visible;
		    /* left: 15px; */
		    margin-left: 0;
		    transform: translateX(-50%);
		    width: 100%;
		    height: 100%;
		}

	&.no_hover{
		&:after {
			display: none;
		}
	}

	&.no_link{
		&:after {
			display: none;
		}
	}

	&:hover:not(.no_hover) {
		h2 {
			color: #231f20;
		}
		span {
			color: #231f20;
			&:first-child {
				opacity: 0;
				visibility: hidden;

			}
			&:last-child {
				opacity: 1;
				visibility: visible;
			}
		}
		a {

			opacity: 1;
			visibility: visible;
		}

		.feature__overlay {
			min-height: 100%;
			background: rgba(244,244,244,.85);


		}
		&:after {
			background-image: url(/resources/images/icon-circle-right-arrow.svg) !important;

			.where-we-build &{
			    transform: none;
		    }
		}

	}

	&__overlay {
		position: absolute;
		width: 100%;
		height: auto;
		min-height: 63px;
		bottom: 0;
		left: 0;
		padding: 26px 20px 11px;
		font-size: 14px;
		color: #fff;
		text-align: left;
		transition: min-height .4s, background .4s;
		h2{
			.where-we-build &{
				padding-bottom: 0px;
			}
		}
		a {
			opacity: 1 !important;
			visibility: visible !important;
			background-image: url(/resources/images/icon-circle-right-arrow.svg);
			background-position: center center;
			background-size: contain;
			text-indent: -99999px;
		}

		&:hover{

			a{
				background-image: url(/resources/images/icon-circle-right-arrow.svg) !important;
			}

		}


	}

	&:after {
	    content: " ";
	    background-image: url(/resources/images/icon-circle-right-arrow.svg);

	    height: 24px;
	    width: 24px;
	    position: absolute;
	    right: 20px;
	    bottom: 12px;
	    background-size: 100%;
	    .where-we-build &{
		    transform: rotate(90deg);
	    }

	}

	.where-we-build &{
		&.no_hover{
			&:hover {
				.img_tint{
					opacity: .1;
				}

			}
		}
	}


	h2 {
		font-family: $graphik;
		font-weight: 200;
		font-size: 24px;

		transition: color .3s;
		 max-width: 80%;
		 margin: 0px;
	}
	p {
		position: relative;
		margin-bottom: 0px;
	}
	span {
		display: block;
		overflow: hidden;
		transition: opacity .3s, visibility .3s, color .3s;
		&:last-child {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			opacity: 0;
			visibility: hidden;
			@media(max-width: $width-xs-max){
					position: relative;
				}
		}
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
	a {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: bottom .3s, opacity .3s, visibility .3s;
		    height: 24px;
	    left: auto;
	    width: 24px;
	    display: inline-block;
	    right: 20px;
	    bottom: 12px;
	        z-index: 8;
		&:hover {
			opacity: .7;
		}
	}
}

@media(max-width: 1199px){
	.feature {
		&:hover {
			a {
				bottom: 20px;
			}
		}
		&__overlay {
			font-size: 10px;
		}
	}
}

@media(max-width: $width-sm-max){
	.feature {
		&:hover {
			a {
				bottom: 20px;
			}
		}

		&__overlay {
			font-size: 14px;
		}
	}
}

@media(max-width: $width-xs-max){
	.features {
		margin-bottom: 20px;
	}
	.feature {
		position: relative;
	    background: $map-popup-grey;

		h4 {
			font-size: 22px;
		}

		&__overlay {
		    position: relative;
		    a {
		    	background-image: url(/resources/images/icon-circle-right-arrow-gold.svg);
		    }
		}

		a {
			display: inline-block;
		}


		h2 {
			color: #231f20;
			position: relative;
		    width: 100%;
		    max-width: 100%;
		    text-align: center;
		    margin-bottom: 12px;
		}
		p {
			width: 100%;
		    position: relative;
		    margin-bottom: 12px;
		}
		span {
			color: #231f20;
			&:first-child {
				opacity: 0;
				visibility: hidden;
				@media(max-width: $width-xs-max){
					display: none;
				}
			}
			&:last-child {
				opacity: 1;
				visibility: visible;
			    overflow: scroll;
				max-height: 90%;
			}
		}
		.img_tint{
			display: none;
		}
		img {
			max-width: 50%;

			.general &, body.inclusions & {
				width: 100%;
				max-width: 100%;
			}
		}

		&:after {
			/*
			right: auto;
			left: 50%;
			transform: translateX(20px);
			background-image: url(/resources/images/icon-circle-right-arrow-gold.svg);
			*/
			display: none;
			.where-we-build &{
				bottom: 5px;
			    right: 25px;
			    left: auto;
			    transform: translateX(20px) rotate(0deg) !important;
				background-image: url(/resources/images/icon-circle-right-arrow-gold.svg);
				display: inline-block;
			}
		}

		&.no_hover{
			img{
				max-width: 100%;
			}
			.feature__overlay{
				height: auto;
				h2{
					position: relative;
					max-width: none;
					bottom: auto;
				}
				p{
					background: transparent;
				    position: relative;
				    top: auto;
				    width: 100%;
				    display: block;
				    height: auto;
				    left: auto;
				    padding: 0;
				    span{
					    &:first-child{
						     display: block;
						     opacity: 1;
						     visibility: visible;
					    }
					    color: #fff;
				    }
				}
			}
		}
		&:hover {
			a {
				bottom: 12px;
			}
		}
	}
}