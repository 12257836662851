/* ------------------------------------------------------------ *\
	Section Filters
\* ------------------------------------------------------------ */

.section-filters {
	padding: 75px 0 70px;
	color: #fff;
	background: $gold-dark;

	h3 {
		font-family: $graphik;
		font-size: 32px;
		letter-spacing: -0.04em;
	}

	&__head {
		margin-bottom: 22px;
	}
}

@media(max-width: $width-xs-max){
	.section-filters {
		padding: 30px 0 78px;

		h3 {
			font-size: 27px;
			margin-bottom: 13px;
		}
	}
}