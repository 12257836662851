/* ==========================================================================
Custom form elements
========================================================================== */

.jstyling-select { color: #464646; width:100%; float: left; margin-top: 5px; }
.jstyling-select-s { width: 100%; font-size: 14px; color: $main-brown;  line-height: 35px;  background: url(../images/arrow-red-down.svg);
	background-size: 14px 11px; font-family: $graphik;  background-repeat:  no-repeat; background-position:  95% center; padding-left: 10px;
	margin-bottom: 20px; border-radius: 4px; border: 1px solid #C7C8CA;
	@media only screen and (max-width:  $width-sm-max) {
	}
	@media only screen and (max-width:  $width-xs-max) {
		line-height: 30px;
	}
}
.jstyling-select-l { top: 35px;   padding-top: 20px; padding-bottom: 10px; color:$main-brown; font-size: 14px;  border: 1px solid $main-brown;
	border-radius: 4px;}
.jstyling-select-l div {  text-align: left; background-color: #fff ;  z-index: 200; padding: 15px 0;}
.jstyling-select-l div:hover { color: #000; background: #F0F0F0 ; }

.jstyling-select {margin: 0; padding: 0; position:relative; z-index: 0;}
.jstyling-select-t {white-space: nowrap; overflow: hidden;    text-align: left;}
.jstyling-select-l {margin: 0; padding: 0; width: 100%; display: none; position: absolute; overflow-y: auto; z-index: 1000;}
.jstyling-select-l div {list-style: none; display: block; white-space: nowrap; overflow: hidden;}
.jstyling-select-l div:hover {}
.jstyling-select-l div.disabled {}
.jstyling-select-l div.disabled:hover {}
.jstyling-select.active {z-index: 700; }
.jstyling-select.active .jstyling-select-l {display: block;}
.jstyling-checkbox, .jstyling-radio {margin: 0; padding: 0; display: inline-block; z-index: 1;}
.jstyling-checkbox input[type=checkbox], .jstyling-radio input[type=radio] {opacity: 0; filter: alpha(opacity = 0); z-index: 0;}

/* Select wrapper */
.jstyling-select {width: 100%; cursor: pointer; font-size: 14px;
	@media only screen and (max-width:  $width-xs-max) {
		font-size: 12px;
	}
}
/* Select current option */

/* Select options list*/
.jstyling-select-l {
	max-height: 350px;
	border-top: 0;
	top: 40px;
	left: 0;
	@media only screen and (max-width:  $width-sm-max) {
	}
	@media only screen and (max-width:  $width-xs-max) {
		top: 30px;
	}
}
.jstyling-select-l div {
	line-height: 35px;
	padding: 0px 20px;
	@media only screen and (max-width:  $width-sm-max) {
		line-height: 45px;
		font-size: 12px;
	}
	@media only screen and (max-width:  $width-xs-max) {
		line-height: 40px;
		font-size: 12px;
	}
}
.jstyling-select-l div:hover {}
.jstyling-select-l div.disabled { background-color: #EBEBEB; }
.jstyling-select-l div.disabled:hover {color: #000000;}
.jstyling-select-l div:first-child {border-top: 0}
.jstyling-select.active {background-position: 100% -28px;}
.jstyling-select.disabled { background-color: #EBEBEB; }

/*-- checkbox --*/
.custom-checkbox .group-checkbox{display: block;width: 100%; margin-bottom: 5px;}
.custom-checkbox .jstyling-checkbox{border: 1px solid #b2b1b1; height: 20px;width: 20px;position: relative;}
.custom-checkbox .jstyling-checkbox.active:after{content: " "; height: 10px; width: 10px; background-color: #333; position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);}
.custom-checkbox label, .houses-filter__sidebar .filters form fieldset .custom-checkbox label{margin-left: 15px; color: $body-text-color;   font-weight: 300;}

@media(max-width: $width-xxs-max){
	.custom-checkbox label, .houses-filter__sidebar .filters form fieldset .custom-checkbox label{
		margin-left: 8px;
	    position: relative;
	    top: -4px;
	}
}



