/* ------------------------------------------------------------ *\
	List Info Secondary
\* ------------------------------------------------------------ */

.list-info-secondary {
	display: flex;
	padding: 0;
	margin: 0 0 37px;
	font-family: $graphik;
	font-size: 32px;
	color: #fff;
	list-style-type: none;

	body.readybuilt &, body.new-homes_series &, body.house-and-land & {
		color: $charcoal;
	}

	li {
		+ li {
			margin-left: 35px;
		}
	}
}

@media(max-width: $width-xs-max){
	.list-info-secondary {
		display: block;
		padding-top: 13px;
		margin-bottom: 19px;
		font-size: 25px;
		padding-right: 70px;
		li {

			    display: inline-block;
			margin: 0 20px 11px 0px;
			+ li {

				margin-left: 0px;
			}
		}
	}
}