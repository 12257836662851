.intrinsic_gallery{
	.slide_bg{
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: #000;
		
	}
	
	.slide:not(:first-child){
		.startup_loading &{
			position: absolute;
			opacity: 0;
		}
	}
		
	
		
	.slide_bg--p{
		background-size: contain;
	}
}