/* ------------------------------------------------------------ *\
	Modal
\* ------------------------------------------------------------ */

.modal {
	padding: 20px !important;
	&-open {
		padding: 0 !important;
	}
	&-dialog {
		width: 100%;
		margin: 0;
	}
	&-backdrop {
		//display: none;
		transition: all .4s;
		&.in{
			opacity: .85;
			@media(max-width: $width-xs-max){
				opacity: .9;
			}
		}
	}
	&-content {
		border: 0;
		border-radius: 0;
		box-shadow: none;
		background: none;
	}
}
#modal-testimonial-video{
	.modal-content{
		 max-width: 150vh;
   		margin: auto;
	}
}

.contact-modal .custom--checkbox-tertiary {
	.checkbox__label {
		display: inline-block;
		padding-right: 38px;
	}
}

#modal-enquiry-form-widget {
	max-width: 1400px;
	margin: 0 auto;
	.form-contact__aside {
		.text {
			h2 {
				color: $body-text-color;
				font-family: $graphik;
				font-size: 40px;
				margin-bottom: 20px;
			}
		}
	}
	&.small-modal{
		max-width: 800px!important;
	}
	
}

#modal-call-now {
	max-width: 560px;
	width: 100%;
	left: 50%;
	top: 50%;
	height: 100%;
	transform: translate(-50%, -50%);

	.form-enquiry-secondary {
		padding: 45px 65px;
	}

	.salesperson-image {
		width: 100%;
		margin-bottom: 1rem;
	}

	p {
		font-size: 24px;
		@media (max-width: $width-xs-max) {
			text-align: center;
		}
	}
}