/* ------------------------------------------------------------ *\
	Grid Elements
\* ------------------------------------------------------------ */
.general main > .section--grid-elements {
	padding-top: 50px; 
	padding-bottom: 50px; 
	margin-bottom: 0; 

	h2 {
		margin-bottom: 20px; 
	}
}
.grid-elements {
	display: flex; 
	flex-wrap: wrap; 
	justify-content: space-between; 
	margin-top: 50px; 
}
.grid-element {
	flex: 1 0 50%; 
	margin-bottom: 30px; 
	padding-right: 25px; 
	display: flex; 
	flex-wrap: no-wrap; 
	justify-content: space-between; 
	&:nth-child(2n) {
		padding-left: 25px; 
		padding-right: 0; 
	}
	&__left {
		padding-right: 15px; 
		flex: 0 1 40%; 
	}
	&__right {
		padding-left: 15px; 
		flex: 0 1 60%; 
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
	}
	&__image {
		img {
			width: 100%; 
			display: block; 
		}
	}
	&__text {
		h3 {
			margin-bottom: 20px; 
		}
	}
	&__buttons {
		a {
			display: block; 
			width: 100%; 
			margin-bottom: 10px; 
			&:last-child {
				margin-bottom: 0; 
			}
			
			&.button-black-bordered {
			    background: transparent;
			    color: $charcoal;
			    border: 2px solid $charcoal;
			    -webkit-border-radius: 0;
			    border-radius: 0;
			    
			    &:hover{
				   color: $sage;
				   border: 2px solid $sage;
			    }
			}
			
		}
		
		
		
	}
	@media (max-width: $width-sm-max) {
		flex: 0 0 100%; 
		padding-right: 0; 
		&:nth-child(2n) {
			padding-left: 0; 
		}
	}
	@media (max-width: $width-xs-max) {
		flex-direction: column; 
		&__left {
			padding-right: 0; 
			flex: 1 1 100%; 
			margin-bottom: 15px; 
			img {
				width: auto; 
				max-height: 50vh; 
				margin-left: auto; 
				margin-right: auto; 
			}
		}
		&__right {
			padding-left: 0; 
			flex: 1 1 100%; 
		}
	}
}