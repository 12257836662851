/* ------------------------------------------------------------ *\
	Form Filters
\* ------------------------------------------------------------ */

.form-filters {
	padding: 13px 14px;
	border: 1px solid #d03539;
	form {
		display: flex;
	}

	&__inner {
		display: flex;
		align-items: center;
		strong {
			margin-right: 18px;
		}
		& + & {
			margin-left: 45px;
		}
	}
}

@media(max-width: $width-sm-max){
	.form-filters {
		form {
			width: 100%;
			flex-flow: row wrap;
			justify-content: center;

			> * {
				flex: 0 0 auto;
			}
		}
		&__inner {
			& + & {
				margin: 15px 0 0;
			}

			&:last-child {
				display: flex;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.form-filters {
		padding: 0;
		border: 0;
		&__inner {
			strong {
				display: block;
				margin-bottom: 6px;
			}
		}
		form, &__inner {
			display: block;
		}
	}
}