/* ------------------------------------------------------------ *\
	List Categories
\* ------------------------------------------------------------ */

.list-categories {
	display: flex;
	flex-flow: row wrap;
	padding: 0;
	margin: 0 -4px;
	list-style-type: none;
	li {
		margin: 0 4px 9px;
		&:only-child {
			margin-bottom: 0;
		}
	}
	a {
		color: $sage;
		display: block;
		padding: 8px 21px 6px;
		border: 1px solid #c0bfbf;
		border-radius: 3px;
		letter-spacing: -0.04em;
		.news &{
			padding: 6px 10px 4px;
		}
		&:hover {
			text-decoration: none;
			background: #c0bfbf;
			color: #fff;
		}
	}
}

@media(max-width: $width-xs-max){
	.list-categories {
		a {
			padding: 7px 25px 6px;
		}
	}
}