/* ==========================================================================
	EXPLORE SECTION
   ========================================================================== */
.explore-section{
	background-color: #fff;
	position: relative;
	.explore-banner {
		position: relative;
		height: 356px;
		margin-bottom: 100px;
		color: $charcoal!important;
		@media (max-width: $width-xs-max) {
			
			margin-bottom: 25px;
		}
		@media (max-width: $width-sm-max) {
			margin-bottom: 25px;
		}
		&__left {
			    border-left: 5px solid $sage;
			background-color: #f8f8f8;
			position: absolute; 
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			@media (max-width: $width-xs-max) {
				width: 100%;
				height: 50%;
				position: relative;
				display: block;
			}
		}
		&__right {
			background: url(../images/explore.jpg) center center;
			background-size: cover;
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			@media (max-width: $width-xs-max) {
				width: 100%;
				height: 50%;
				position: relative;
				display: block;
			}
		}
		@media (max-width: $width-xs-max) {
			> .container{
				    position: absolute;
				    top: 0;
				    width: 100%;
			}
		}
		
		.heading-text{
			font-size:14px;
			text-transform: uppercase;
			color:$sage;
			font-family: $graphik;
			text-transform: uppercase;
			letter-spacing: 0.3em;
			position: relative;
		
			font-weight: 500;
			    margin-top: 130px;
			@media (max-width: $width-xs-max) {
				font-size: 12px;
				margin-top: 15px;
				padding-top: 10px;
				span {
					max-width: 120px;
					display: block;
				}
			}
		}
		.main-text{
			color: white;
			font-size: 50px;
			position:relative;
			width:40%;
			line-height: 0.9em;
			font-family: $graphik;
			font-weight: 100;
			
			@media (max-width: $width-sm-max) {
				font-size: 38px;
			}
			@media (max-width: $width-xs-max) {
				font-size: 19px;
				width: 50%;
				margin-top: 37px;
				line-height: 1.15em;
			}
			a {
				background: url(../images/icon-circle-right-arrow-gold.svg) 99% center no-repeat;
				display: inline-block;
				background-size: 35px 35px;
				padding-right: 60px;
				
				transition: all 300ms ease;
				text-decoration: none;
				@media (max-width: $width-xs-max) {
					background-size: 12px;
					padding-right: 20px;
				}
				&:hover {
					background-position: right center;
				}
			}
		}
	}
}

.subtitle-top {
	font-family: $graphik;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	font-size: 14px;
	line-height: 1em;
	font-weight: 500;
	padding: 12px 0 35px;
}

.latest-news-head{
	margin-top: 44px;
	margin-bottom: 20px;
	
	
	overflow: hidden;
	@media (max-width: $width-xs-max) {
		padding-top: 10px;
	}
	&__title{
		font-size: 14px;
		float: left;
		font-family: $graphik;
		text-transform: uppercase;
		letter-spacing: 0.3em;
		
	}
	&__link{
		
		text-decoration: none;
		font-size: 14px;
		padding-top: 9px;
		float: right;
		position: relative;
		font-weight: 500;
		
	}
	.btn-mobile {
		font-size: 25px;
		color: $charcoal;
		margin: 0 auto;
		font-family: $graphik;
		text-align: center;
	}
}

.latest-news-item{
	margin-bottom: 93px;
	@media (max-width: $width-xs-max) {
		margin-bottom: 30px;
	}
	&__title {
		font-size: 24px;
		font-family: $graphik;
		color: $charcoal;
		margin: 18px 0;
		.estate &{
			font-weight: 200;
		}
	}
	&__text{
		font-size: 14px;
		letter-spacing: -0.3px;
		margin-bottom: 15px;
		a { font-weight: 500; }
	}
	&__date{
		font-size: 14px;
		font-weight: 400;
		color: #b2b1b1;
	}
}