/* ------------------------------------------------------------ *\
List Dimensions
\* ------------------------------------------------------------ */

.list-dimensions {
	padding: 0;
	margin: 0;
	letter-spacing: -0.03em;
	color: #fff;
	list-style-type: none;

	body.readybuilt & {
		color: $main-brown;
	}

	strong {
		display: inline-block;
		width: 140px;
		font-weight: 500;
		color: #fff;

		body.readybuilt & {
			color: $main-brown;
		}
	}

	.zoom-in-modal &{
		color: inherit;
		strong {
			color: inherit;
		}
	}
}
