#hotspot_image{
	.litetooltip-hotspot-wrapper{
		max-width: 100% !important;
		.litetooltip-hotspot-container{
			.hotspot{
				background: transparent !important;
				border: none !important;
			}
			img{
				width: 100%;
			}
		}
	}
	margin-bottom: 10px;
}
.litetooltip-wrapper{
	opacity: 1 !important;
	@media(max-width: $width-xs-max){
		display: none !important;
	}
	.tooltip-arrow{
		border-top-color: #fff !important;
		opacity: 1 !important;
	} .tooltip-content{
		
		h4, h2{
			margin-bottom: 5px;
			font-family: $graphik;
			font-weight: 500;
			font-size: 16px;

		}
		font-family: $graphik;
		text-align:left !important;
		padding: 15px !important;
		background-color: #fff !important;
		color: #333 !important; 
		opacity: 1 !important;
		box-shadow: none !important;
		font-size: 15px !important;
		color:  #333;
	}
}