/* ------------------------------------------------------------ *\
	List Links Quaternary
\* ------------------------------------------------------------ */

.list-links-quaternary {
	padding: 0;
	margin: 0;
	list-style-type: none;
	li {
		+ li {
			margin-top: 9px;
		}
		a{
			.display-centres &{
				color: $sage;
			}
		}
	}
	.list-links-quaternary__active {
		font-weight: 500;
	}
}