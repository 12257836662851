/* ------------------------------------------------------------ *\
	List Info
\* ------------------------------------------------------------ */

.list-info {
	display: flex;
	padding: 0;
	margin: 0 0 -8px;
	font-family: $graphik;
	font-size: 32px;
	color: #fff;
	list-style-type: none;

	body.readybuilt & {
		color: $main-brown
	}
	span {
		display: block;
		padding-bottom: 4px;
		font-family: $graphik;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.02em;
	}
	li {
		.house-and-land &, .readybuilt & , .display-for-sale_detail &{
			> div {
				margin-bottom: 15px;
				    padding: 0px;
			}
		}
		+ li {
			margin-left: 43px;
		}
	}
}

@media(max-width: $width-sm-max){
	.list-info {
		font-size: 24px;
	}
}

@media(max-width: $width-xs-max){
	.list-info {
		flex-flow: row wrap;
		padding-bottom: 12px;
		margin: 0;
		font-size: 25px;
		li {
			flex: 0 1 50%;
			&:first-child {
				flex: 0 0 100%;
				margin-bottom: 12px;
			}
			+ li {
				margin: 0;
			}
		}
		span {
			padding-bottom: 2px;
			font-size: 12px;
			font-weight: 400;
		}
	}
}