/* ------------------------------------------------------------ *\
	Section Secondary
\* ------------------------------------------------------------ */

.section-secondary {
	.where-we-build &{
		h2{
			font-family: $graphik;
			border-top: none;
			text-align: left;
			padding-bottom: 40px;
		}
	}

	&--darkgrey {
		position: relative;
		margin: -66px 0 -30px;
		background: #efefef;
		.section-secondary__map-container {
			padding-bottom: 70px;
			margin-bottom: 0;
			border-bottom: 1px solid #d5d6d7;
		}
	}
	&__media {
		margin-bottom: 135px;
		img {
			width: 100%;
			height: auto;
		}
	}
	&__group {
		padding-top: 31px;
		margin-bottom: 94px;
		border-top: 1px solid #706e6f;
		&-head {
			display: flex;
			justify-content: space-between;
			margin-bottom: 24px;
			strong {
				display: inline-block;
				margin-right: 18px;
				font-size: 14px;
				letter-spacing: -0.02em;
			}
			a {
				font-weight: 500;
			}
			.form-sort {
				max-width: 88px;
				margin-top: -7px;
			}
		}
	}
	&__group-secondary {
		display: flex;
		justify-content: space-between;
		padding: 66px 0 53px;
		color: #231f20;
		.display-centres &, .display-centres-rb  &{
			padding: 100px 0 90px;
		}
		.display &, .display-centres-rb & {
			justify-content: flex-start;
			@media (max-width: $width-xs-min) {
				padding-bottom: 0;
			}
		}
		h3 {
			font-family: $graphik;
			font-size: 24px;
			line-height: 1.18;
			letter-spacing: -0.02em;
			margin-bottom: 23px;
			.display-centres &, .where-we-build &, .display-centres-rb &{
				margin-bottom: 10px;
				font-family: $graphik;
			}
			.where-we-build &{
				font-size: 32px;
			}
		}
	}
	&__infobox-container {
		display: none;
	}
	&__aside-secondary {
		flex: 0 0 350px;
		margin-right: 40px;
		.display-centres-rb & {
			@media (max-width: $width-xs-min) {
				margin-right: 0;
				flex: 1 1 auto;
				padding-right: 0;
			}
		}
	}
	&__content-secondary {
		display: flex;
		padding-right: 22px;
		strong {
			display: block;
			margin-bottom: 19px;
			font-size: 14px;
			font-weight: 400;
			letter-spacing: -0.03em;
			color: #a7a5a6;
			.display-centres &, .display-centres-rb &{
				font-size: 24px;
				line-height: 1.2em;
				@media(max-width: $width-sm-max){
					font-size: 14px;
				}
			}
			.display-centres-rb & {
				@media (max-width: $width-sm-max) {
					font-size: 24px;
				}
			}
		}
	}
	&__map-container {
		margin-bottom: 100px;
		.estate &, .display-centre-single &{
			margin-bottom: 74px;
		}
		.list-radios {
			justify-content: flex-end;
		}
	}
	&__inner-secondary {
		html.ie10{
			display: inline-block;
		}

		&:last-child {
			/*padding-left: 13px;*/
		}
		& + & {
			margin-left: 50px;
		}
		.display-centres-rb & {
			border-left: 1px solid #646464;
			display: flex;
			width: 100%;
			flex-direction: column;
			align-content: flex-start;
			margin-left: 19px;
			padding-left: 19px;

			@media (max-width: $width-sm-min) {
				border-left: none;
				margin-left: 0;
				padding-left: 0;
				flex: 1 0 auto;
			}
		}
	}
	&__map {
		position: relative;
		padding-top: 52.63%;
		.where-we-build &{
			margin-bottom: 30px;
		}

		&.square{
			padding-top: 70%;
		}
		> div {
			top: 0;
			left: 0;
		}
		@media (max-width: $width-xs-max) {
			padding-top: 88.63%;
		}
	}
}

.section-tertiary {


}

@media(max-width: $width-sm-max){
	.section-secondary {
		&__media {
			margin-bottom: 60px;
		}
		&__group {
			margin-bottom: 30px;
		}
		&__map-container {
			margin-bottom: 30px;
		}
		&__group-secondary {
			display: block;
			padding: 40px 0;

			h3 {
				margin-bottom: 15px;
			}
		}
		&__aside-secondary {
			margin-bottom: 30px;
		}
	}
}

@media(max-width: $width-xs-max){
	.section-secondary {
		&--darkgrey {
			padding-top: 19px;
			margin-bottom: -15px;

			.section-secondary__map-container {
				padding-bottom: 30px;
			}
		}
		.container {
			padding: 0 20px;
		}
		&__media {
			margin-bottom: 30px;
		}
		&__group {
			padding-top: 25px;
		}
		&__group-head {
			a {
				display: block;
			}
			.form-sort {
				margin: 0;
			}
		}
		&__infobox-container {
			position: relative;
			display: block;
			box-shadow: 0 2px 0 rgba(0, 0, 0, 0.4);
			z-index: 2;
			.infobox-content {
				position: relative;
				padding-left: 32.8%;
				overflow: hidden;
				font-size: 9px;
				letter-spacing: -0.02em;
				color: #231f20;
				background: #fff;
				z-index: 3;
				strong {
					display: block;
					margin-bottom: 4px;
					color: $gold-dark;
				}
				p {
					margin-bottom: 4px;
					line-height: 1.12;
				}
				a {
					&[href^="tel"] {
						font-weight: 500;
						color: #231f20;
					}
				}
			}
			.infobox-btn {
				display: inline-block;
				height: 21px;
				line-height: 21px;
				padding: 0 6px;
				margin-top: 4px;
				border-radius: 2px;
				color: #fff;
				background: $gold-dark;
			}
			.infobox-image {
				position: absolute;
				width: 32.8%;
				height: 100%;
				top: 0;
				left: 0;
				background-size: cover;
				background-position: center center;
			}
			.infobox-inner {
				position: relative;
				padding: 10px 13px 11px;
				background: #fff;
				z-index: 3;
			}
		}
		&__group-secondary {
			padding: 24px 0 17px;
			h3 {
				font-size: 19px;
				margin-bottom: 18px;

				.ico-triangle-right {
					width: 14px;
					height: 15px;
				}
			}
			p {
				font-size: 12px;
			}
		}
		&__aside-secondary {
			padding-right: 50px;
		}
		&__content-secondary {
			flex-flow: row wrap;
			strong {
				font-size: 12px;
			}
		}
		&__inner-secondary {
			flex: 0 0 50%;
			margin-bottom: 26px;

			& + & {
				margin-left: 0;
			}
		}
	}
}