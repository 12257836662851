/* ------------------------------------------------------------ *\
Form Enquiry Secondary
\* ------------------------------------------------------------ */

.form-enquiry-secondary {
	position: relative;
	/*padding: 123px 131px 89px;*/
	padding: 90px 130px;
	background: rgba(231, 229, 229, 0.95);
	h3 {
		font-size: 32px;

		margin-bottom: 20px;
		em{
			font-style: normal;
		}
	}
	
	.error {
		.form-enquiry-secondary__field,
		.form-enquiry-secondary__label{
			
			color: $charcoal;
			border-bottom: 1px solid $red;
		}
	}
	
	.ico-envelope-gold{
	 	@media(max-width: 768px){
		    display: none;
		}  
	}
	
	
	.modal-close {
		position: absolute;
		top: 20px;
		right: 20px;
		border: 0;
		background: none;
		transition: opacity .3s;
		&:hover {
			opacity: .7;
		}
		
		@media (max-width: $width-xs-max) {
			top: 10px;
			right: 10px;
		}	
		
	}
	&__success {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0px 0;
		text-align: center!important;
		&.inactive {
			display: none;
		}
		h2 {
			font-family: $graphik;
			font-size: 32px;
			margin-bottom: 20px;
		}
		h4 {
			font-size: 32px;

			font-family: $graphik;
			font-weight: 200;
			margin-bottom: 43px;
		}

		.btn-red {
			min-width: 124px;
			height: 35px;
			padding: 0;
			border-radius: 2px;
			font-size: 14px;
			line-height: 35px;
			font-weight: 500;
			letter-spacing: -0.02em;
			color: #fff;
			background: $gold-dark;
			&:hover {
				background: #e02022;
			}
		}
	}
	&__body {
		position: relative;
		/*display: flex;
		justify-content: center;*/
		display: block;
		/*padding-bottom: 92px;*/
		padding-bottom: 0;
		/*border-bottom: 1px solid #565354;*/
		.custom--select-tertiary {
			select {
				background: #fff;
				padding-right: 24px;
				color: #a7a5a6;
				font-size: 14px;
				min-width: 250px;
				height: 36px;
			}
		}
		.toggleable-maintenance .custom--select-tertiary select {/* min-width: 170px; */ min-width: 130px; }
	}
	&__aside {
		position: absolute;
		width: 388px;
		top: 0;
		left: 0;
	}
	&__content {
		flex: 0 1 361px;
		.ios.tablet &{
			flex: none;
			width: 100%;
		}
		.toggleDisable {
			margin-top: 18px;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
		& + & {
			margin-top: 18px;
		}
	}
	&__foot {
		/*display: flex;
		justify-content: space-between;*/
		display: block;
		margin-top: 60px;
		padding-top: 60px;
		border-top: 1px solid #565354;
		.contact &{
			margin-top: 0px;
			padding-top: 0px;
			border-top: none;
		}
		h3 {
			color: #333333;
		}
		@media (max-width: $width-xs-max) {
			br {
				display: none;
			}
		}
		.container {
			width: auto;
			padding-left: 0;
			padding-right: 0;
		}
		#quarantees-section {
			h3 {
				padding-top: 0;
				border-top: none;
				margin-top: 0;
			}
		}
	}
	&__entry {
		flex: 0 0 262px;
		font-size: 14px;
		h3 {
			font-family: $graphik;
			font-size: 24px;
			letter-spacing: -0.04em;
			color: #000;
			margin-bottom: 25px;
		}
	}
	&__label {
		display: block;
		font-size: 14px;
		font-weight: 300;
		letter-spacing: -0.02em;
		color: #4f4c4d;
		span.light {
			color: #808080;
			font-weight: 400;
		}
		&.error{
			color:$charcoal;
			font-weight: 500;
		}
	}
	&__field-container {
		flex: 0 1 165px;
		&:only-child {
			flex: 1 0 100%;
		}
	}
	&__radios, &__checkboxes {
		/*display: flex;*/
		padding: 0px 0 0;
		margin: 0;
		list-style-type: none;
		li {
			display: inline-block;
			+ li {
				margin-left: 17px;
			}
		}
		.custom--radio-tertiary {
			margin-bottom: 24px;
			display: inline-block;
			.radio__label {
				padding-right: 0;
				padding-left: 28px;
				span {
					right: auto;
					left: 0;
					&:after {
						background: $charcoal;
					}
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__notifications {
		padding-top: 8px;
		.custom--checkbox-tertiary {
			/*margin-bottom: 24px;*/
			margin-left: 0 !important;
			display: inline-block;
			.radio__label {
				padding-right: 0;
				padding-left: 28px;
				&:first { padding-left: 0; }
			}
		}
	}
	&__radios-zero, &__checkboxes-sero {
		.custom--radio-tertiary {
			padding-left: 0;
		}
	}
	&__row.toggleable-requestType{
		.custom--radio-tertiary {
			display: block;
		}
	}
	&__row.toggleable {
		&-general-maintenance-blurb, &-urgent-maintenance-blurb {
			max-width: 432px;
			p {
				font-weight: 500;
				&:last-child {
					margin-bottom: 0;
				}
			}
			@media (max-width: $width-md-max) {
				max-width: 100%;
			}
		}
	}
	&__actions {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.03em;
		color: #999;
		p {
			margin-top: -2px;
		}
	}
	&__btn {
		display: inline-block;
		min-width: 124px;
		height: 36px;
		border: 0;
		border-radius: 2px;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: -0.03em;
		color: #fff;
		background: $sage;
		&:hover {
			background: $light-brown;
		}
		&:disabled {
			opacity: 0.5;
			background: $light-brown;
		}
	}
	&__field {
		display: block;
		width: 100%;
		height: 36px;
		padding: 0 14px;
		border: 0;
		border-radius: 2px;
		font-size: 14px;
		letter-spacing: -0.03em;
		color: #000;
		background: #fff;
		&::-webkit-input-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&::-moz-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&:-moz-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&:-ms-input-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&--textarea {
			height: 80px;
			padding-top: 10px;
			padding-bottom: 10px;
			resize: none;
		}
		&:disabled {
			opacity: 0.5;
			cursor: default;
		}
	}
	.error {
		.form-enquiry-secondary__field,
		.form-enquiry-secondary__label {
			color: $charcoal;
		}
		input, select, textarea{
			border: 1px solid $red;
		}
	}
	&__input-file {
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
		+ label {
			cursor: pointer;
			-webkit-appearance: none;
			appearance: none;
			border: 2px solid $charcoal;
			border-radius: 50%;
			background: transparent;
			text-indent: -9999px;
			display: inline-block;
			width: 30px;
			height: 30px;
			position: relative;
			&:after {
				content: '+';
				display: block;
				position: absolute;
				height: 100%;
				width: 100%;
				color: $charcoal;
				font-weight: 400;
				top: 0;
				left: 0;
				text-indent: 0;
				font-size: 30px;
				line-height: 27px;
				text-align: center;
			}
		}
		&:disabled + label {
			opacity: 0.3;
		}
	}
	&__input-file-label {
		display: none;
		margin-left: 10px;
		color: $charcoal;
		vertical-align: -20%;
		font-size: 14px;
	}
}

.compeition_radio_label{
	display: block;
    font-family: 'Akkurat',sans-serif;
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 300;
    margin-left: 15px;
}
#request-type-Yes{
	margin-right: 10px;
}
.competition_radios{
	margin-left: 12px;
	font-size: 15px;
	font-family: 'Akkurat',sans-serif;
}

.competition_radios .custom--radio-tertiary{
	margin-bottom: 10px!important;
}

label[for=request-type-Yes]{
	margin-right: 10px!important;
}
		
		
#modal-enquiry-form .form-enquiry-secondary__aside {
	display: none !important;
	&.active {
		display: inline-block !important;
	}
}

.contact-modal .form-enquiry-secondary {
	&__label {
		display: none;
		font-weight: bold;
	}
	div.checkbox {
		display: inline-block;
		margin-left: 0px;
		.checkbox__label {
			padding-right: 38px;
			span {
				border-radius: 50%;
			}
		}
	}
}

.woh-booking {
	.form-contact__field-container {
		/*
		padding-left: 0;
		padding-right: 0;
		*/
	}
	.form-enquiry-secondary__row {
		.col-lg-6.form-contact__field-container:first-child {
			flex: 1;
			padding-left: 15px;
		}
		.col-lg-6.form-contact__field-container:last-child {
			flex: 1;
			padding-right: 15px;
		}
	}
	.form-enquiry-secondary__field {
		.error {
			color: $red
		}
	}
	.loader {
		top: 50% !important;
		bottom: initial !important;
	}
	aside.form-contact__aside {
		position: relative !important;
		display: block !important;
		border: none !important;

		@media (max-width: $width-sm-max) {
			clear: both;
			width: auto;
			text-align: left;
			margin-left: -15px;
		}

		p {
			position: relative !important;
			bottom: 0 !important;
		}
	}
	.mobile-required {
		margin-top: 10px;
		color: #999;
	}

	.col-lg-6 {
		@media (max-width: $width-ipad-max) {
			margin-top: 18px;
		}
	}
}

@media(max-width: 1280px){
	.form-enquiry-secondary {
		padding: 40px 20px;
		h3 {
			font-size: 40px;
		}
		&__aside {
			width: 150px;
		}
		&__success {
			padding: 100px 0;
		}
	}
}

@media(max-width: $width-sm-max){
	.form-enquiry-secondary {
		&__entry {
			flex: 0 0 240px;
		}
		&__success {
			padding: 40px 0px;
			h2 {
				font-size: 22px;
			}
			h4 {
				font-size: 25px;
			}
			h3 {
				font-size: 22px;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.form-enquiry-secondary {
		padding: 30px 20px 20px;
		h3 {
			margin-bottom: 10px;
			font-size: 22px!important;
		}
		&__body {
			display: block;
			padding-bottom: 40px;
		}
		&__success {
			padding: 40px 0px;
			h2 {
				font-size: 22px;
				margin-bottom: 12px;
			}
			h4 {
				font-size: 18px;
				margin-bottom: 15px;
			}
		}
		&__aside {
			position: static;
			width: 100%;
			margin-bottom: 20px;
			text-align: center;
			br {
				display: none;
			}
		}
		&__foot {
			display: block;
		}
		&__row {
			display: block;
		}
		&__field-container {
			& + & {
				margin-top: 18px;
			}
		}
		&__entry {
			margin-bottom: 30px;
		}
	}
}