.section-text-image{
	position: relative;
	.text-container{
		h2{
			font-size: 14px;
			margin-bottom: 10px;
			font-size: 14px;
			font-family: $helvetica;
			text-transform: uppercase;
			letter-spacing: 0.3em;

		}
		font-family: $graphik;
		font-size:32px;
		background-color: $charcoal;
		color: #fff;
		padding: 270px 0px;
		text-align: center;

		@media (max-width: $width-xs-max) {
			padding: 100px 0px;
		}
		.wrapper{
			display: inline-block;
			text-align: left;
			max-width: 80%;
		}





	}
	.image-container{
		height: 100%;
	    position: absolute;
	    right: 0;
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: top center;
	    @media (max-width: $width-sm-max) {
		     position: relative;
		     padding-bottom: 50%;
	    }
	    @media (max-width: $width-xs-max) {
		    padding-bottom: 80%;
	    }
	}


	.home &{
		.text-container{
			text-align: right;
			background-color: $grey-background-light;
			color: $body-text-color;
			padding: 120px 0;
			font-family: $graphik;
			    line-height: 1em;
			.row-text{
				margin-bottom: 50px;
				&:last-of-type{
					margin-bottom: 0px;
				}

			}
			p.heading-text{
				font-size: 14px;
				font-family: $helvetica;
				text-transform: uppercase;
				letter-spacing: 0.3em;

			}
			@media (max-width: $width-sm-max) {
				.wrapper{
					max-width: 100%;
					width: 100%;
				}
			}
			@media (max-width: $width-xs-max) {
				padding-bottom: 30px;
				padding-top: 30px;
				.wrapper{
					.row-text{
						/*margin-bottom: 0px;*/
					}
					p{
						font-size: 22px;
					}
				}
			}
		}
		.image-container{
			@media (max-width: $width-xs-max) {
			    padding-bottom: 100%;
		    }
		}
	}
}