/* ==========================================================================
	Postcode search on Where We Build page
   ========================================================================== */

.postcode {
	&__search {
		position: relative;
		input {
			border-radius: 0;
			&[type=submit] {
				width: 22px;
				background: url('../images/search-icon.svg');
				height: 22px;
				position: absolute;
				top: 7px;
				right: 10px;
				border: 0;
				-webkit-appearance: none;
				text-indent: -9999999px;
				overflow: hidden;
				-webkit-transition: all .3s ease;
				transition: all .3s ease;
				font-size: 1px;
			}
			&[type=text] {
				color: #646464;
				background-color: #fff;
				font-size: 15px;
				border: none;
				display: block;
				width: 100%;
				outline: none;
				height: 36px;
				padding: 0 35px 0 10px;
				&:focus + input[type=submit] {
					-webkit-transform: rotate(-90deg);
					-ms-transform: rotate(-90deg);
					transform: rotate(-90deg);
				}
			}
		}
		&.build {
			input[type=text] {
				padding-left: 26px;
			}
			&:before {
				content: '';
				display: block;
				width: 12px;
				height: 12px;
				background: url('../images/checked_circle.svg') center center no-repeat;
				background-size: contain;
				position: absolute;
				top: 12px;
				left: 10px;
			}
		}
		&-results {
			position: absolute;
			z-index: 100;
			list-style: none;
			width: 100%;
			background: $grey-background-dark;
			display: none;
			padding: 0;
			&.active {
				display: block;
			}
			li {
				display: block;
				a {
					padding: 10px;
					text-transform: uppercase;
					display: block;
					vertical-align: middle;
					line-height: 1;
					box-sizing: content-box;
					text-decoration: none;
					color: #231F20;
					font-weight: 400;
					&:hover {
						background: shade($grey-dark-hover, 10);
					}
					@media (max-width: $width-xs-max) {
						font-size: 15px;
					}
				}
				&.build {
					a:before {
						content: '';
						display: inline-block;
						width: 12px;
						height: 12px;
						background: url('../images/checked_circle.svg') center center no-repeat;
						background-size: contain;
						margin-right: 5px;
						vertical-align: middle;
					}
				}
				&:last-child {
					a {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&__notice {
		margin-top: 16px;
	}
}
body.where-we-build {
	section.section-tertiary aside.section-secondary__aside-secondary {
		position: relative;
		flex: 0 0 50%;
		margin-right: 0;
		padding-right: 40px;
		@media (max-width: $width-xs-max) {
			padding-right: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
	.section-secondary__map-container {
		margin-bottom: 16px;
	}
}