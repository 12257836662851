/* ------------------------------------------------------------ *\
	List Anchors
\* ------------------------------------------------------------ */

.list-anchors {
	display: flex;
	padding: 15px 0 0;
	margin: 0;
	justify-content: center;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.03em;
	color: #fff;
	list-style-type: none;
	@media (max-width: $width-xxs-max) {
		display: block;
	}
	html.ie10 &{
		display: block;
		li{
			display: inline-block;
		}
	}
	a {
		color: inherit;
		text-shadow: 0 1px 3px rgba(0, 0, 0, .6);
	}
	li {
		position: relative;

		+ li {
			margin-left: 26px;
			@media (max-width: $width-xxs-max) {
				margin-left: 0;
			}
			&:after {
				content: '';
				position: absolute;
				width: 1px;
				height: 13px;
				top: 3px;
				left: -12px;
				opacity: .5;
				background: #fff;
				@media (max-width: $width-xxs-max) {
					display: none;
				}
			}
		}
	}
}