/* ==========================================================================
	SEO SECTION
   ========================================================================== */

.seo-section{
	.home &{
		background-color: #fff;
	}
	background-color: $grey-background-light;
	padding: 56px 0;
	padding-bottom: 43px;
	@media (max-width: $width-sm-max) {
		text-align: center;
		padding: 40px 0;
	}
	&__title{
		font-size: 32px;
		font-family: $graphik;
		margin-bottom: 10px;
		@media (max-width: $width-xs-max) {
			font-size: 25px;
		}
	}

	&__text{
		font-size: 14px;
		letter-spacing: -0.3px;
		a { font-weight: 500;     display: block;}

		@media (max-width: $width-sm-max) {
			text-align: left;
		}
		@media (max-width: $width-xs-max) {
			font-size: 12px;
			text-align: left;
		}
		&-expandable {
			display: none;
		}
	}
	
	img{
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}

.additional-seo-content{
	padding-top: 0px;
	padding-bottom: 43px;
	@media (max-width: $width-sm-max) {
		text-align: center;
		padding: 20px 0;
	}
	
	h3{
		margin: 15px 0px;
	}
	
	img{
		width: 100%;
		max-width: 100%;
		height: auto;
	}
	
}