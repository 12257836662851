/* ------------------------------------------------------------ *\
Intro Primary
\* ------------------------------------------------------------ */

.intro-primary {
	position: relative;
	display: flex;
	/*height: 720px;*/
	height: $intro-vh-height;
	min-height: $intro-min-height;
	max-height: $intro-max-height;
	flex-flow: row wrap;
	align-items: center;
	background-size: cover;
	background-position: center center;

	&.small{
		height: $intro-sm-height;
	}

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(35, 31, 32, 0.3);
	}
	
	&.remove-tint{
		
		&:before{
			background: transparent;
		}
	}


	h1 {
		font-size: 60px;
		line-height: 1;
		color: #fff;
		margin-bottom: 36px;
		text-transform: uppercase;
		.main-inner.sinhalese &{
			font-family: "sinhalese";
		}
	}
	h4 {
		font-family: $graphik;
		font-size: 24px;
		font-weight: 200;
		letter-spacing: -0.03em;
		color: #fff;
	}
	> * {
		flex: 0 0 100%;

	}

	&__title {
		padding-top: 47px;
	}
	&__content {
		position: relative;
		text-align: center;
		z-index: 2;

		html.ie &{
			position: absolute !important;
			top:50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}

		&--secondary {
			/*padding-top: 30px;*/
		}
		
		&.dark-text{
			h1{
				color: #3B3B3C!important;
			}	
		}
		
	}
	&__inner {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		padding-bottom: 22px;
		text-align: center;
		z-index: 2;
	}
	&--alt {
		/*
		height: 470px;
		**/
		&:before {
			display: none;
		}
	}
	&--big {
		/*height: 515px;*/
		background-size: cover;
		background-position: center center;
	}
	/*
	&--medium {
		height: 500px;
	}
	*/

	&__no-banner {
		/*
		background-image: none;
		background-color: transparent;
		*/
		background: inherit;
		h1, h4 {
			color: #333;
		}
		.list-anchors li a {
			color: $main-brown;
		}
		&:before {
			display: none;
		}
	}
}
/*
@media(max-width: $width-sm-max){
	.intro-primary {
		height: 450px;
	}
}
*/

@media(max-width: $width-xs-min){
	.intro-primary, .intro-bg {
		height: $intro-mobile-height;
		min-height: $xs-intro-min-height;
		max-height: 200px;
	}
}

@media(max-width: $width-xs-max){
	.intro-primary {

		height: $intro-mobile-height;
		min-height: $xs-intro-min-height;
		max-height: 200px;
		h1 {
			font-size: 30px;
			margin-bottom: 20px;
		}
		h4 {
			font-size: 20px;
		}

		&__content {
			padding: 0 15px;
		}

		&--alt {
			/*height: 240px;*/
			padding: 0;
		}
		.intro-primary__title {
			padding: 0;
			margin: 0;
		}
	}
}