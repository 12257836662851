/* ------------------------------------------------------------ *\
	List Views
\* ------------------------------------------------------------ */

.list-views {
	display: flex;
	padding: 0;
	margin: 0;
	list-style-type: none;
	a {
		display: block;
		height: 35px;
		border-radius: 3px;
		line-height: 35px;
		color: #231f20;
		i {
			position: relative;
			margin-left: 5px;
			top: -1px;
		}
	}
	li {
		+ li {
			margin-left: 15px;
		}
		&.current {
			a {
				padding: 0 16px;
				color: #fff;
				background: $gold-dark;
			}
		}
	}
}