/* ------------------------------------------------------------ *\
List Images
\* ------------------------------------------------------------ */

.list-images {
	display: flex;
	flex-flow: row wrap;
	padding: 0 0 83px;
	margin: 0 -5px;
	list-style-type: none;

	body.new-homes_series #inclusionsSection & {
		padding-bottom: 0;
	}

	li {
		max-width: 33.3%;
		flex: 0 0 33.3%;
		padding: 0 5px 10px;
	}
	a {
		position: relative;
		display: block;
		background: #000;
		&:hover {
			img {
				opacity: 1;
			}
		}
	}
	figure {
		display: block;
		margin: 0;
		&:after{
			content: " ";
			background-image: url(/resources/images/icon-circle-right-arrow.svg);
			height: 24px;
			width: 24px;
			position: absolute;
			right: 20px;
			bottom: 20px;
			background-size: 100%;
		}
	}
	figcaption {
		position: absolute;
		width: 100%;
		bottom: 20px;
		left: 0;
		padding-left: 20px;
		font-family: $graphik;
		font-weight: 100;
		font-size: 24px;
		line-height: 1;
		letter-spacing: 0.01em;
		color: #fff;
		text-align: left;
		cursor: pointer;

		max-width: 80%;
		@media(max-width: $width-xs-max){
			max-width: 70%;
		}

	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
		opacity: .7;
		transition: opacity .3s;
		transform: translate3d(0,0,0);
	}
}

@media(max-width: $width-sm-max){
	.list-images {
		padding-bottom: 40px;
	}
}

@media(max-width: $width-xs-max){
	.list-images {
		margin: 0 -7px;
		li {
			flex: 0 0 50%;
			max-width: 50%;
			padding: 0 7px 14px;
		}
		figcaption {
			font-size: 16px;
		}
	}
}