/* ------------------------------------------------------------ *\
	Readybuilt Listing
\* ------------------------------------------------------------ */

.readybuilt__no-results {
	padding-left: 0px;
	margin-top: 40px;
	@media (max-width: $width-sm-max) {
		padding-left: 15px;
	}
	a {
		cursor: pointer;
	}

	form {
		margin-top: 18px;
		padding: 18px;
		background: rgba(245, 245, 245, 0.953);
		.form-enquiry-secondary__field-container {
			flex: none;
			button {
				margin-top: 24px;
			}
		}
		.form-enquiry-secondary__row+.form-enquiry-secondary__row {
			margin-top: 0;
		}

		.form-group.error label {
			color: $red;
		}

		.form-booking {
			padding: 0;
		}
	}
}
.section-readybuilt {
	background: $main-brown;
	padding: 75px 0;
	@media(max-width: $width-xs-max) {
		padding-bottom: 0;
	}
	h3 {
		font-size: 32px;
		font-family: $graphik;
		color: #fff;
		margin-bottom: 30px;
		font-weight: 200;
	}
	.readybuilt-filter {

		padding: 0;
		height: 60px;
		margin-bottom: 45px;
		@media(max-width: $width-xs-max) {
			height: auto;
			padding-bottom: 15px;
		}
		&__region {
			display: inline-block;
			height: 60px;
			line-height: 60px;
			color: #fff;
			@media(max-width: $width-xs-max) {
				display: block;
				height: auto;
			}
			p {
				display: inline-block;
				margin-right: 10px;
				font-weight: 500;
				@media(max-width: $width-xs-max) {
					display: block;
					height: auto;
					line-height: 1;
					margin-top: 15px;
				}
			}
		}
		&__regions {
			display: inline-block;
			input {
				display: none;
			}
			label {
				margin-right: 10px;
				font-weight: normal;
				border: 1px solid transparent;
				height: auto;
				display: inline;
				padding: 10px;
				border-radius: 2px;
				cursor: pointer;
				line-height: 36px;
				@media(max-width: $width-xs-max) {
					margin-right: 0;
				}
			}
			input:checked + label {
				border-color: #fff;
			}
		}
		&__select {
			display: inline-block;
			/*margin-left: 50px;*/
			@media(max-width: $width-sm-max) {
				margin-left: 0;
			}
			select {
				margin-right: 12px;
				width: 165px;
				border: 1px solid #fff;
				border-radius: 2px;
				font-size: 16px;
				color: #fff;
				height: 34px;
				-webkit-appearance: none;
				appearance: none;
				padding-left: 10px;
				background: transparent url('../images/select-arrow-down.png') 95% center no-repeat;
				background-size: 11px 9px;
				&:active, &:focus {
					background: transparent url('../images/select-arrow-down.png') 95% center no-repeat !important;
					background-size: 11px 9px !important;
				}
				&::last-child {
					margin-right: 0;
				}
				&::-ms-expand {
					display: none;
				}
				@media(max-width: $width-sm-max) {
					width: 120px;
				}
				@media(max-width: $width-xs-max) {
					width: 39vw;
					&:last-child {
						margin-right: 0;
					}
				}
				option {
					color: #000;
				}
			}
		}
	}
	.readybuilt-table {
		width: 100%;
		color: #fff;
		&__header {
			border-bottom: 1px solid $header-grey;
			th {
				height: 33px;
				vertical-align: top;
				&:last-child {
					width: 120px;
				}
			}
			+ tr > td {
				padding-top: 12px;
				height: 48px !important;
			}
		}
		tr {
			td {
				height: 36px;
				vertical-align: middle;
				&:last-child {
					text-align: right;
					width: 120px;
				}

				@media (max-width: 768px) {
					padding-left: 3px;
					padding-right: 3px;
				}
			}
		}
		&__enquire {
			padding: 5px 15px;
			height: auto;
			width: 100px;
			text-align: center;

			border-radius: 2px;
			font-weight: 500;


		}
	}
	.readybuilt-listing {
		&__element {
			border-top: 1px solid $header-grey;
			padding: 30px 0;
			color: #fff;
			font-size: 14px;
			div.row {
				margin-bottom: 16px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__enquire {

			height: 32px;
			width: 100%;
			text-align: center;
			line-height: 22px;
			border-radius: 2px;


		}
	}
}