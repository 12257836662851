/* ------------------------------------------------------------ *\
	Widget Categories
\* ------------------------------------------------------------ */

.widget-categories {
	
	h4 {
		font-family: $helvetica;
		text-transform: uppercase;
		
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .1em;
		margin-bottom: 25px;
	}
}
#news-rollLayout .widget-categories {
	margin-bottom: 125px;
}
@media(max-width: $width-xs-max){
	.widget-categories {
		margin-bottom: 18px;
		h4 {
			font-size: 12px;
		}
		&__list {
			margin: 0 -9px;
			li {
				margin: 0 9px 10px;
			}
			a {
				padding: 7px 13px 6px;
			}
		}
	}
}