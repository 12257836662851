/* ==========================================================================
	RECENTLY VIEWED SECTION
   ========================================================================== */
#recently-viewed-section{
	background-color: $grey-background-dark;
	padding: 70px 0;
	padding-bottom: 41px;
	position: relative;
	@media (max-width: $width-sm-max) {
		padding: 40px 0;
		padding-bottom: 25px;
	}

}
.recently_viewed_header{
	text-align: center;
	padding-bottom: 47px;
	&__title{
		font-size: 24px;
		padding-top: 15px;
		font-family: $graphik;
		letter-spacing: -0.7px;
	}
	&__search-icon{
		width: 33px;
	}
}

.property-item{
	height: auto;
	padding: 0;
	background-color: $grey-background-light;
	transition: all 300ms ease;
	margin-bottom: 30px;
	@media (max-width: $width-sm-max) {
		margin-bottom: 40px;
		margin: 0 auto 40px;
	}
	@media (max-width: $width-xs-max) {
		min-height: 0;
		margin: 0 0 20px 0;
		width: 100%;
		position: relative;
	}

	&__panel {
		height: 100%;
		&:hover{
			background-color: $grey-dark-hover;
			.action-buttons{
				opacity: 1;
			}
		}
	}

	&__body{
		.nh-property &{
			@media (max-width: $width-xs-max) {
				min-height: 0;
			}
		}
		text-align: center;
		padding: 25px 25px;
		min-height: 170px;

		padding-bottom: 0;
		padding-top: 32px;
		@media (max-width: $width-xs-max) {
			min-height: 0;
			text-align: left;
			padding: 20px 15px !important;
			position: relative;
			background-color: $grey-background-light;
			z-index: 2;
			max-height: 100%;
		}
		h3{
			font-size: 18px;
			margin-bottom: 16px;
			letter-spacing: -0.5px;

			color:$body-text-color;
			i {
				color: #A7A5A6;

			}
			@media (max-width: $width-xs-max) {
				font-size: 22px;
				margin-bottom: 10px;
			}
		}
		p {
			font-size: 13px;
			margin-bottom: 11px;
			letter-spacing: -0.1px;
			@media (max-width: $width-xs-max) {
				font-size: 12px;
				margin-bottom: 10px;
				padding-right: 20px;
				display: inline;
			}
			&.property-item__facade-name {
				margin-bottom: 12px;
			}
			&.no-mb{
				margin-bottom: 0px !important;
			}

			&.strike {
				position: relative;
				display: inline-block;
				padding-right: 0;
				margin-bottom: 0 !important;
				svg {
					position: absolute;
					top: 4px;
					left: 0;
					width: 100%;
					height: 60%;
					z-index: 10;
					line {
						stroke: $strikethrough-color;
						stroke-width: 2px;
					}
				}

				@media (max-width: $width-xs-max) {
					margin-right: 5px;
					display: inline;
				}

				+ p.no-mb {
					font-weight: bold;
					font-size: 120%;
				}
			}
		}
		> a.property-item__body-clickable{
			display: block;

		}
		&-clickable {
			/*
			text-decoration: inherit;
			color: inherit;
			*/
			text-decoration: none;
			color: #333;
			display: block;
			&:hover, &:focus {
				text-decoration: inherit;
				color: inherit;
			}
			@media (max-width: $width-xs-max) {

				    display: inline-block;
				/*
				p:last-child{
					position: absolute;
					right: 30px;
					bottom: 19px;
				}
				*/
			}
		}
		.tag {

			padding: 5px;
			color: #fff;
			font-weight: 300;
			&.gold{
				background-color: $gold-dark;
			}
			&.grey{
				background-color: $grey-dark;
			}
			&.brown{
				background-color:$main-brown;
			}
		}
	}
	&__icons {
		margin-bottom: 14px;
		@media (max-width: $width-xs-max) {
			margin-bottom: 14px;
		}
		span {
			padding: 0 5px;
			font-size: 13px;
			@media (max-width: $width-xs-max) {
				font-size: 12px;
			}
			body.readybuilt-landing & {
				color: $sage;
				font-family: $graphik;
				font-size: 18px;
			}
		}
		img {
			vertical-align: top;
			margin-right: 1px;
			position: relative;
			top: 2px;
			max-height: 16px;
			fill:$charcoal;

			html.edge &{
				height: 12px;
			}
			@media (max-width: $width-xs-max) {
				width: 20px;
				top: 4px;

				body.new-homes & {
					width: 25px;
				}
			}
			body.readybuilt-landing & {
				vertical-align: middle;
			}
		}
		span img {
			display: inline;
		}
	}
	&__tail{
		padding: 10px;
		border-top: 2px solid #f7f7f7;

		body.new-homes & {
			padding-bottom: 25px;
		}

		@media (max-width: $width-xs-max) {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			border: 0 none;
			background: #f4f4f4;
		}
		&--threesixty-view {
			font-weight: 800;
			color: $sage;
			cursor: pointer;
			font-size: 12px;
			padding-left: 20px;
			height: 16px;
			display: inline-block;
			background: url('/resources/images/360view_teal.svg') left center no-repeat;
			background-size: contain;

			@media (max-width: $width-xs-max) {
				position: absolute;
				z-index: 100;
				body.readybuilt-landing &, body.new-homes & {
					position: relative;
				}
			}
		}
		.tag {
			font-size: 12px;
			padding: 5px;
			color: #fff;
			font-weight: 300;
			&.gold{
				background-color: $sage;
			}
			&.grey{
				background-color: $sage;
			}
			&.brown{
				background-color: $main-brown;
			}
			.readybuit &, .readybuilt-landing &, &.rb {
				color: $body-text-color;
				border: 1px solid $body-text-color;
				font-weight: 300;
			}
			img {
				margin-right: 5px;
				html.edge &{
					height: 16px;
				}

				@media (max-width: $width-xs-max) {
					width: 12px;
				}
			}
			@media (max-width: $width-xs-max) {
				font-size: 9px;
			}
		}
		&___preview-btn{
			font-weight: 800;
			float:right;
			color: $sage!important;
			cursor: pointer;
			text-decoration: none;

			font-size: 12px;
		}
	}
	&__photo{
		width: 100%;
		@media (max-width: $width-xs-max) {
			position: relative;
			width: 100%;
		}
	}
	&__mobile-suburb {
		@media (max-width: $width-xs-max) {
			padding-right: 0 !important;
		}
	}
	&__mobile-pricing {
		p {
			padding-right: 0;
			font-size: 14px;
		}
	}
	&__mobile-360 {
		body.new-homes & {
			padding-right: 0;
		}
	}
	.divider {
		display: none;
		/*
		max-width: 70px;
		height: 2px;
		background: #fff;
		margin: 20px auto;
		@media (max-width: $width-sm-max) {
			background: $grey-dark;
			height: 1px;
		}
		@media (max-width: $width-xs-max) {
			max-width: 100%;
			margin: 17px 0;
		}
		*/
	}
	.tooltip {
		.tooltip-inner {
			background: #fff;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
			font-size: 9px;
			color: #a7a5a6;
			border-radius: 2px;
		}
		.tooltip-arrow {
			border-top-color: #fff;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		}
	}
	.action-buttons{
		position: relative;
		top:-55px;
		height: 0;
		opacity: 0;
		transition: all 300ms ease;
		@media (max-width: $width-xs-max) {
			display: none;
		}
		img{
			width:43px;
			margin:2px;
			box-shadow: 0 4px 0 rgba(0,0,0,0.2);
			border-radius: 100%;
			cursor: pointer;
			background-color: $button-shadow-color;
			&:hover{
				filter: brightness(0.7);
			}
		}
	}
	.button__favorite {
		position: absolute;
		top: 8px;
		right: 15px;
		width: 22px;
		@media (max-width: $width-xs-max) {
			width: 34px;
		}
	}
	.button__preview {
		position: absolute;
		bottom: 8px;
		right: 15px;
		width: 22px;
		@media (max-width: $width-xs-max) {
			width: 26px;
			bottom: 15px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.button__see-terms {
		font-size: 12px;
		color: $sage;
		font-weight: 500;
		cursor: pointer;
		text-decoration: underline;
		font-family: $graphik, sans-serif;
		letter-spacing: -0.02em;
		position: relative;
		.property-q-collection & {
			color: $q-collection-color;
		}
	}
}
html.tablet .property-item .action-buttons { opacity: 1; }

body.display-centre-single{
	.property-item {
		&__body {
			.nh-property & {
				@media (max-width: $width-xs-max) {
					min-height: 0;
				}
			}
			text-align: center;
			padding: 25px 25px;
			min-height: 170px;

			&.rbp {
				min-height: 220px;
			}

			padding-bottom: 0;
			padding-top: 32px;
			@media (max-width: $width-xs-max) {
				min-height: 0;
				padding: 20px 15px !important;
				position: relative;
				background-color: $grey-background-light;
				z-index: 2;
				max-height: 100%;
				padding-bottom: 35px!important;
			}
		}

		.property-item__slide.rbp {
			padding-top: 40px;
		}
	}

	@media (max-width: $width-xs-max) {
		.property-item__panel {
			height: auto;
		}
	}
}