/* ------------------------------------------------------------ *\
SECTION STORIES
\* ------------------------------------------------------------ */

.section-stories {
	background: #efefef;
	@media (max-width: $width-xs-max) {
		.container {
			padding: 0 22px;
		}
	}
}
.section-stories-secondary {
	.container{
		border-top: 1px solid $grey-background-dark;
	}
	
	padding-top: 10px;
	.news-post &{
		background: transparent;
	}
}