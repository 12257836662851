/* ------------------------------------------------------------ *\
	Container Secondary
\* ------------------------------------------------------------ */

.container-secondary {
	max-width: 850px;
	transform: translateZ(0);

	body.new-homes_series &, body.house-and-land &, body.readybuilt & {
		max-width: 100%;
	}
}
