#productLayout{
	.container-secondary {
		/*
		@media (min-width: 992px) and (max-width: 1199px){
			max-width: 660px;
		}
		*/
		@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
			max-width: 100%;
		}
	}

	.slider-images img {
		@media  (max-width: 1199px){
			width: 100%!important;
			height: auto!important;
		}
	}

	.section-home__aside {
		@media (min-width: 768px) and (max-width: 991px){
			flex: 0 0 0;
		}
	}

	.design__select-design{
		@media (max-width: 767px){
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	#gallerySection .section__head{
		@media (max-width: 767px){
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	#gallerySection .section__body p {
		@media (max-width: 767px){
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.list-links-secondary li {
		@media (max-width: 767px){
			max-width: none;
			min-width: 1px;
			width: 100%;
			display: block;
		}
	}

	#quarantees-section ul li {
		@media (min-width: 992px) and (max-width: 1199px){
			margin-right: 0px;
		}
	}

	.new-homes-widget__mobile{
		a{
			display: inline-block;
			width: 30px;
			height: 30px;
			background-color: $main-brown;
			border-radius: 50%;
			padding: 0;
			margin-bottom: 0;
			border: none;
			line-height: 1;
			transition: none;
			i {
				top: 50%;
				right: auto;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}

		.widget-enquiry__inner--date span{
			color: #231f20;
		}

		.series_home_price{
			margin-top: 7px;
		}
	}
}

.display-for-sale_detail{
	.cta{
		margin-top: 50px;
		.container{
			width: 100%;
			margin: 0px;
			padding: 0px 40px;
			.section-contact__head{
				text-align: left;
			}
		}
	}
}