/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}
html.no-scroll{
	overflow: hidden;
}

.touch{
	-webkit-overflow-scrolling: touch;
}
@media (max-width: 991px) and (min-width: 768px){
	.visible-sm--inline{
		display: inline-block !important;
	}
}

/* ==========================================================================
Fonts
========================================================================== */
@font-face {
    font-family: 'Akkurat';
    src: url('#{$fontRoot}/akkurat-regular.eot');
    src: url('#{$fontRoot}/akkurat-regular.eot') format('embedded-opentype'),
         url('#{$fontRoot}/akkurat-regular.woff2') format('woff2'),
         url('#{$fontRoot}/akkurat-regular.woff') format('woff'),
         url('#{$fontRoot}/akkurat-regular.ttf') format('truetype'),
         url('#{$fontRoot}/akkurat-regular.svg#AkkuratRegular') format('svg');
    font-weight:300;
    font-style:normal;
}

@font-face {
    font-family: 'Akkurat';
    src: url('#{$fontRoot}/akkurat-bold.eot');
    src: url('#{$fontRoot}/akkurat-bold.eot') format('embedded-opentype'),
         url('#{$fontRoot}/akkurat-bold.woff2') format('woff2'),
         url('#{$fontRoot}/akkurat-bold.woff') format('woff'),
         url('#{$fontRoot}/akkurat-bold.ttf') format('truetype'),
         url('#{$fontRoot}/akkurat-bold.svg#AkkuratBold') format('svg');
    font-weight:500;
    font-style:normal;
}
@font-face {
    font-family: 'Akkurat';
    src: url('#{$fontRoot}/akkurat-bolditalic.eot');
    src: url('#{$fontRoot}/akkurat-bolditalic.eot') format('embedded-opentype'),
         url('#{$fontRoot}/akkurat-bolditalic.woff2') format('woff2'),
         url('#{$fontRoot}/akkurat-bolditalic.woff') format('woff'),
         url('#{$fontRoot}/akkurat-bolditalic.ttf') format('truetype'),
         url('#{$fontRoot}/akkurat-bolditalic.svg#akkurat-italic') format('svg');
    font-weight:500;
    font-style:italic;
}

@font-face {
    font-family: 'Akkurat';
    src: url('#{$fontRoot}/akkurat-light.eot');
    src: url('#{$fontRoot}/akkurat-light.eot') format('embedded-opentype'),
         url('#{$fontRoot}/akkurat-light.woff2') format('woff2'),
         url('#{$fontRoot}/akkurat-light.woff') format('woff'),
         url('#{$fontRoot}/akkurat-light.ttf') format('truetype'),
         url('#{$fontRoot}/akkurat-light.svg#AkkuratLight') format('svg');
    font-weight:200;
    font-style:normal;
}


@font-face {
    font-family: 'Akkurat';
    src: url('#{$fontRoot}/akkurat-italic.eot');
    src: url('#{$fontRoot}/akkurat-italic.eot') format('embedded-opentype'),
         url('#{$fontRoot}/akkurat-italic.woff2') format('woff2'),
         url('#{$fontRoot}/akkurat-italic.woff') format('woff'),
         url('#{$fontRoot}/akkurat-italic.ttf') format('truetype'),
         url('#{$fontRoot}/akkurat-italic.svg#akkurat-italic') format('svg');
    font-weight:300;
    font-style:italic;
}

@font-face {
    font-family: 'helveticaNeueFonts';
    src: url('#{$fontRoot}/helveticaNeueFonts.eot');
    src: url('#{$fontRoot}/helveticaNeueFonts.eot') format('embedded-opentype'),
         url('#{$fontRoot}/helveticaNeueFonts.woff2') format('woff2'),
         url('#{$fontRoot}/helveticaNeueFonts.woff') format('woff'),
         url('#{$fontRoot}/helveticaNeueFonts.ttf') format('truetype');

    font-weight:400;
    font-style:normal;
}

@font-face {
    font-family: 'sinhalese';
    src: url('#{$fontRoot}/sinhalese_normal-webfont.eot');
    src: url('#{$fontRoot}/sinhalese_normal.eot') format('embedded-opentype'),
         url('#{$fontRoot}/sinhalese_normal.woff2') format('woff2'),
         url('#{$fontRoot}/sinhalese_normal.woff') format('woff'),
         url('#{$fontRoot}/sinhalese_normal.ttf') format('truetype'),
         url('#{$fontRoot}/sinhalese_normal.svg#sinhalese') format('svg');
    font-weight:400;
    font-style:italic;
}

@font-face {
    font-family: 'Graphik-Regular';
    src: url('#{$fontRoot}/Graphik-Regular-Web.woff2') format('woff2'),
         url('#{$fontRoot}/Graphik-Regular-Web.woff') format('woff');
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family: 'Graphik-Bold';
    src: url('#{$fontRoot}/Graphik-Bold-Web.woff2') format('woff2'),
         url('#{$fontRoot}/Graphik-Bold-Web.woff') format('woff');
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family: 'Graphik-Medium';
    src: url('#{$fontRoot}/Graphik-Medium-Web.woff2') format('woff2'),
         url('#{$fontRoot}/Graphik-Medium-Web.woff') format('woff');
    font-weight:400;
    font-style:normal;
}
@font-face {
    font-family: 'Graphik-Semibold';
    src: url('#{$fontRoot}/Graphik-Semibold-Web.woff2') format('woff2'),
         url('#{$fontRoot}/Graphik-Semibold-Web.woff') format('woff');
    font-weight:400;
    font-style:normal;
}



/* ==========================================================================
    ANIMATIONS AND HOVER EFFECTS
========================================================================== */

// pulse animation
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation: pulse 0.5s linear;
  animation:  pulse 0.5s linear;
}


// bounce animation
@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0,-4px,0);
    transform: translate3d(0,-4px,0);
  }
}

.bounce {
  -webkit-animation: bounce 0.5s linear;;
  animation: bounce 0.5s linear;;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

/* Sweep To Right */
.hvr-sweep-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-sweep-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $button-shadow-color;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-sweep-to-right:hover, .hvr-sweep-to-right:focus, .hvr-sweep-to-right:active {
  color: white;
}
.hvr-sweep-to-right:hover:before, .hvr-sweep-to-right:focus:before, .hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

/* normal / vanilla hover state */
.hvr-darken { -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.hvr-darken:hover { background-color: shade($sage, 20%) !important; background: shade($sage, 20%); color: #fff; text-decoration: none; }

/* ==========================================================================
Helper classes
========================================================================== */
.clearfix:before,
.clearfix:after { content: " "; display: table; }

.clearfix:after { clear: both; }

.bg-grey { background-color: $grey-background-light; }

.row-0{
    margin: 0;
	>div{
		padding: 0;
	}
}

.row{
	&.row__padding-sm{
	    margin-left: -5px;
		margin-right: -5px;
		[class*='col-'], [class*=' col-']{
			padding-left: 5px;
			padding-right: 5px;
		}
	}

}


.red{
	color:$main-brown;
	&:hover, &:focus{
		color: $main-brown;
	}
}
input[type='date'], input[type='time'] {
	-webkit-appearance: none;
}
.line{
	border: 0;
    border-top: 1px solid $body-text-color;
    width: 200px;
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    &.small{
	     width: 70px;
    }
    &.gold{
	    border-color: $sage;
    }
    &.white{
	    border-color: #fff;
    }
}
.button-icons{

	.envelope{
	    display: inline-block;
	    height: 14px;
	    width: 23px;
	    margin-left: 5px;
	    background-image: url(/resources/images/icon-envelope-white.svg);
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: 100% 100%;
	    vertical-align: middle;
	    &.gold{
		    background-image: url(/resources/images/icon-envelope.svg);
	    }
    }
    .arrow-circle{
	    display: inline-block;
	    height: 14px;
	    width: 14px;
	    margin-left: 5px;
	    background-image: url(/resources/images/icon-circle-right-arrow.svg);
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: 100%;
	    vertical-align: middle;
	    &.gold{
			    background-image: url(/resources/images/icon-circle-right-arrow-gold.svg);
			    fill: $sage;
		    }
    }
    .download{
	    display: inline-block;
	    height: 15px;
	    width: 11px;
	    margin-left: 5px;
	    background-image: url(/resources/images/icon-download.svg);
	    background-position: center center;
	    background-repeat: no-repeat;
	    background-size: 100%;
	    vertical-align: middle;
	    &.gold{
			    background-image: url(/resources/images/icon-download-teal.svg);
		    }
    }
    &:hover {

        .envelope{
	        background-image: url(/resources/images/icon-envelope.svg);
	         &.gold{
			    background-image: url(/resources/images/icon-envelope-white.svg);
			     
		    }
	    }
	    .arrow-circle{
		     background-image: url(/resources/images/icon-circle-right-arrow-teal.svg);
		     &.gold{
			   background-image: url(/resources/images/icon-circle-right-arrow-teal.svg);
		    }
	    }
	    .download{
			background-image: url(/resources/images/icon-download-teal.svg);
		    &.gold{
				     background-image: url(/resources/images/icon-download-teal.svg);
			}
	    }



    }

}

.button-gold{
	background: $sage;
	color: white;
	padding: 10px 15px;
	outline: 0;
    border: 0 none;
    font-size: 16px;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
    transition: all .1s ease-in;

    &:hover, &:focus {
        text-decoration: none;
        background: #fff;
        color: $sage;
        border: 1px solid $charcoal;
    }

    @media (max-width: $width-xs-max) {
        font-size: 16px;
        padding: 5px 10px;
    }
    &-border {
    	background: transparent;
    	color: inherit;
    	border: 1px solid $main-brown;
    	border-radius: 3px;
    }
    &-transparent {
    	display: inline-block;
    	border: 1px solid  #c9c8c8;
    	border-radius: 4px;
    	font-size: 14px;
    	font-family: $graphik;
    	color: $sage;
    	letter-spacing: -0.043em;
    	padding: 9px 22px;
    }
    &-transparent-border{
	    background: transparent;
		color: $sage;
			padding: 10px 15px;
		border: 1px solid $sage;
		transition: all .1s ease-in;
		&:hover {
	        color: #fff;
			background: $sage;
	        & a{
		        text-decoration: none;
	        }

	    }
    }
}
.button-teal{
	background: $q-collection-color;
	color: white;
	padding: 10px 15px;
	outline: 0;
	border: 0 none;
	font-size: 16px;
	border-radius: 2px;
	overflow: hidden;
	cursor: pointer;
	transition: all .1s ease-in;

	&:hover, &:focus {
		text-decoration: none;
		background: #fff;
		color: $q-collection-color;
	}

	@media (max-width: $width-xs-max) {
		font-size: 16px;
		padding: 5px 10px;
	}

	&-border {
		background: transparent;
		color: inherit;
		border: 1px solid $main-brown;
		border-radius: 3px;
	}
	&-transparent {
		display: inline-block;
		border: 1px solid  #c9c8c8;
		border-radius: 4px;
		font-size: 14px;
		font-family: $graphik;
		color: $q-collection-color;
		letter-spacing: -0.043em;
		padding: 9px 22px;
	}
	&-transparent-border{
		background: transparent;
		color: $q-collection-color;
		padding: 10px 15px;
		border: 1px solid $q-collection-color;
		transition: all .1s ease-in;
		&:hover {
			color: #fff;
			background: $q-collection-color;
			& a {
				text-decoration: none;
			}
		}
	}
}
.button-white{
	background-color: transparent;
	border: 1px solid $charcoal;
	color: black;
	padding: 9px 15px;
    border-radius: 2px;
    overflow: hidden;
    &:hover {
        text-decoration: none;
    }
    @media (max-width: $width-xs-max) {
        font-size: 12px;
        padding: 4px 10px;
    }
}
.button-grey {
	background: $grey-dark;
	color: white;
	padding: 10px 15px;
	outline: 0;
	border: 0 none;
	font-size: 16px;
	border-radius: 2px;
	overflow: hidden;
	cursor: pointer;
	transition: all .1s ease-in;

	&:hover, &:focus {
		text-decoration: none;
		background: #fff;
		color: shade($grey-dark, 25);
	}

	@media (max-width: $width-xs-max) {
		font-size: 16px;
		padding: 5px 10px;
	}
	&.widget-enquiry__btn {
		background-color: $grey-dark;
		color: white;
	}

	&-transparent-border-hover {
		border: 1px solid transparent;
		&:hover {
			border: 1px solid $grey-dark;
			color: shade($grey-dark, 25) !important;
		}
	}
}
.bordered-top{
	border-top:1px solid $footer-grey-divider;
}
.zeroHeight{
	height: 0;
}

h1, h2, h3, h4, h5, h6 { margin: 0; padding: 0; font-weight: normal;  }
h1, h2 { letter-spacing: -0.5px; }
h1{
	font-family: $graphik;
}
h2{
	font-family: $graphik;
}
h3, h4{
	font-family: $graphik;
	font-weight: 200;
}

h5, h6{
	font-family: $graphik
}

p.terms{
	font-size: 75%!important;
	line-height: 1.2em!important;
}


.table{ display: table; width: 100%; height: 100%; position: relative; }
.table-cell{ display: table-cell; vertical-align: middle; width: 100%; }

strong { font-family: 'Graphik-Bold'; font-weight: 500; }

button, input, optgroup, select, textarea {
	font-family: $graphik;
	&:focus {
		outline: none;
	}
}
html.firefox{
	select{
		-moz-appearance: none;
	}
}

html.ipad {
	.hidden-ipad {
		display: none !important;
	}
}

a {
    color: $sage;
    &:hover, &:focus { color: $sage; }
    &:focus {
    	outline: none;
    	text-decoration: none;
    }
    &:hover {
    	text-decoration: underline;
    }
}

.img_tint{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	background-color: rgba(35,31,32,0.4);
}
.no-padding-lr{
	padding-left: 0px;
	padding-right: 0px;
}
.half-container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;


    @media(min-width:768px) {
      width: calc(750px/2);
    }
    @media (min-width: 992px){
	   width: calc(970px/2);
	}

    @media(min-width:1200px) {
      width: calc(1170px/2);
    }

}


select::-ms-expand {
    display: none;
}
@media (max-width: $width-sm-max){
	.visible-inline-xs {
    	display: inline-block;
	}
}
.visible-inline-xs {
    display: none;
}
@media (max-width: $width-xs-max) and (orientation:landscape){
	.col-landscape-xs-6{
		width: 50%;
		float: left;
	}
}
@media screen and (max-width: $width-xs-max) and (-webkit-min-device-pixel-ratio:0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
    background: #eee !important;
  }
}
@media screen and (max-width: $width-xs-max) and (-webkit-min-device-pixel-ratio:0) {
  select,
  textarea,
  input {
    font-size: 16px ;
  }
}


/* ==========================================================================
   Main styles
   ========================================================================== */
html,body { /*overflow-x: hidden;*/ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
body{
    font-family: $graphik;
     font-weight:300;
    font-style:normal;
    font-size: 12px;
//     background: #f5f5f5;
   -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; text-size-adjust: 100%; -moz-text-size-adjust: 100%;
}

.vimeo-iframe-container {
    background-color:#000;
    position: relative;
    padding-top:56.25%;
    width:100%;
    iframe{
      position:absolute;
      width:100%;
      height:100%;
      top: 0;
      left: 0;
      border:none;
    }
}

/* ------------------------------------------------------------ *\
    Icons
\* ------------------------------------------------------------ */
.ico-triangle {
    @include icon(ico-triangle, 19px, 16px, true);
}
.ico-pointer {
    @include icon(ico-pointer, 19px, 22px, true);
}
.ico-triangle-small {
    @include icon(ico-triangle-small, 12px, 12px, true);
}
.ico-heart {
    @include icon(ico-heart, 30px, 25px, true);
}
.ico-envelope {
    @include icon(ico-envelope, 31px, 19px, true);
}
.ico-envelope-gold{
    @include icon(ico-envelope-gold, 31px, 19px, true);
}

.ico-envelope-white {
    @include icon(ico-envelope-white, 18px, 15px, true);
}
.ico-expand {
    /*@include icon(ico-expand, 35px, 28px, true);*/
    display: inline-block;
    width: 35px;
    height: 28px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-expand.svg);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;
}
.ico-rotate {
    /*@include icon(ico-rotate, 34px, 27px, true);*/
    display: inline-block;
    width: 34px;
    height: 27px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-rotate.svg);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    .q-collection & {
      background-image: url(/resources/images/ico-rotate-teal.svg);
    }
}
.ico-download {
    /*@include icon(ico-rotate, 34px, 27px, true);*/
    display: inline-block;
    width: 34px;
    height: 23px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-download-teal.png);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    .q-collection & {
      background-image: url(/resources/images/ico-download-teal.png);
    }
}
.ico-360 {
    /*@include icon(ico-rotate, 34px, 27px, true);*/
    display: inline-block;
    width: 34px;
    height: 23px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-360.svg);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    .q-collection & {
      background-image: url(/resources/images/ico-360-teal.svg);
    }
}
.ico-zoom-out {
    /* @include icon(ico-zoom-out, 34px, 34px, true); */
    display: inline-block;
    width: 34px;
    height: 34px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-zoom-out.svg);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    .q-collection & {
      background-image: url(/resources/images/ico-zoom-out-teal.svg);
    }
}
.ico-zoom-in {
    /*@include icon(ico-zoom-in, 34px, 34px, true);*/
    display: inline-block;
    width: 34px;
    height: 34px;
    vertical-align: middle;
    background-image: url(/resources/images/ico-zoom-in.svg);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    .q-collection & {
      background-image: url(/resources/images/ico-zoom-in-teal.svg);
    }
}
.ico-bed {
    @include icon(ico-bed, 39px, 27px, true);
}
.ico-bath {
    @include icon(ico-bath, 37px, 31px, true);
}
.ico-bath-secondary {
    @include icon(ico-bath-secondary, 41px, 25px, true);
}
.ico-car {
    @include icon(ico-car, 61px, 25px, true);
}
.ico-sofa {
    @include icon(ico-sofa, 43px, 23px, true);
}

.ico-bed-gold {
    @include icon(ico-bed-gold, 39px, 27px, true);
}
.ico-bath-gold {
    @include icon(ico-bath-gold, 37px, 31px, true);
}
.ico-bath-secondary-gold {
    @include icon(ico-bath-secondary-gold, 41px, 25px, true);
}
.ico-car-gold {
    @include icon(ico-car-gold, 61px, 25px, true);
}
.ico-sofa-gold {
    @include icon(ico-sofa-gold, 43px, 23px, true);
}
.ico-bed-teal {
    @include icon(ico-bed-teal, 39px, 27px, true);
}
.ico-bath-teal {
    @include icon(ico-bath-teal, 37px, 31px, true);
}
.ico-bath-secondary-teal {
    @include icon(ico-bath-secondary-teal, 41px, 25px, true);
}
.ico-car-teal {
    @include icon(ico-car-teal, 61px, 25px, true);
}
.ico-sofa-teal {
    @include icon(ico-sofa-teal, 43px, 23px, true);
}
.ico-envelope-big-gold {
    @include icon(ico-envelope-big-gold, 58px, 35px, true);
}
.ico-envelope-big {
    @include icon(ico-envelope-big, 58px, 35px, true);
}
.ico-envelope-small {
    @include icon(ico-envelope-small, 30px, 30px, true);
}
.ico-phone-small {
    @include icon(ico-phone-small, 30px, 30px, true);
}
.ico-heart-small {
    @include icon(ico-heart-small, 30px, 30px, true);
}
.ico-arrow-down {
    @include icon(ico-arrow-down, 28px, 28px, true);
}
.ico-arrow-down-small {
    @include icon(ico-arrow-down-small, 30px, 30px, true);
}
.ico-envelope-grey {
    @include icon(ico-envelope-grey, 39px, 31px, true);
}
.ico-cross {
    @include icon(ico-cross, 24px, 23px, true);
}
.ico-pointer-down-white {
    @include icon(ico-pointer-down-white, 16px, 19px, true);
}
.ico-pointer-down-red {
    @include icon(ico-pointer-down-red, 16px, 19px, true);
}
.ico-pointer-down-red-big {
    @include icon(ico-pointer-down-red-big, 47px, 54px, true);
}
.ico-arrow-down-big {
    @include icon(ico-arrow-down-big, 32px, 32px, true);
}
.ico-arrow-right {
    @include icon(ico-arrow-right, 46px, 46px, true);
}
.ico-triangle-right {
    @include icon(ico-triangle-right, 16px, 19px, true);
}
.ico-arrow-down-secondary {
    @include icon(ico-arrow-down-secondary, 11px, 9px, true);
}
.ico-sticks {
    @include icon(ico-sticks, 16px, 17px, true);
}
.ico-homes {
    @include icon(ico-homes, 70px, 70px, true);
}
.ico-list {
    @include icon(ico-list, 14px, 20px, true);
}
.ico-pointer-down-transparent {
    @include icon(ico-pointer-down-transparent, 17px, 21px, true);
}
.ico-triangle-right-white {
    @include icon(ico-triangle-right, 18px, 21px, true);
    background-image: url(/resources/images/triangle-white-right.svg);
}
.ico-heart-small-secondary {
    @include icon(ico-heart-small-secondary, 31px, 31px, true);
}
.ico-houses-small {
    @include icon(ico-houses-small, 31px, 31px, true);
}
.ico-arrow-right-small {
    @include icon(ico-arrow-right-small, 31px, 31px, true);
}
.ico-pin-map {
    @include icon(pin, 29px, 40px, true);
}
.ico-select {
	&-floorplan {
		@include icon(select-floorplan, 71px, 45px, false);
	}
	&-inclusions {
		@include icon(select-inclusions, 33px, 45px, false);
	}
	&-facade {
		@include icon(select-facade, 43px, 45px, false);
	}
}
@media(max-width: $width-xs-max){
    .ico-expand {
        width: 27px;
        height: 22px;
    }
    .ico-rotate {
        width: 26px;
        height: 21px;
    }
    .ico-bed {
        width: 26px;
        height: 14px;
    }
    .ico-bath {
        width: 23px;
        height: 15px;
    }
    .ico-bath-secondary {
        width: 26px;
        height: 16px;
    }
    .ico-car {
        width: 28px;
        height: 13px;
    }
    .ico-sofa {
        width: 28px;
        height: 14px;
    }
    .ico-triangle-small {
        @include icon(ico-triangle-small-mobile, 12px, 12px, true);
    }
    .ico-envelope-big {
        width: 40px;
        height: 31px;
    }

    .ico-zoom-in,
    .ico-zoom-out {
        width: 26px;
        height: 26px;
    }
}


/* ------------------------------------------------------------ *\
	Container XS
\* ------------------------------------------------------------ */

.container--xs {
	max-width: 750px;
}

/* ------------------------------------------------------------ *\
  Custome search selectors mobile
\* ------------------------------------------------------------ */

.suburb_select_custom, .estate_select_custom{
	display: block;
	width: 100%;
	padding: 5px 2px;
	border-radius: 0px;
	border: 1px solid #000;
}
.custom_rb_filters {
    position: relative;
    margin-bottom: 30px!important;
    margin-top: -10px;
}
.houses-filter.custom_rb_filters h3{
   font-size: 18px!important;
}

.filters-advanced-custom-reset{
	font-weight: 700;
}

.house-and-land-disclaimer{
	
	margin-top: 20px;
    border: 1px solid #c4c4c4;
    padding: 10px;
    font-size: 10px;
    line-height: 12px;
    background: #f0f0f0;
}  