/* ==========================================================================
	Compare Section
   ========================================================================== */
.row{
	&--seven{
		margin: 0 -7px;
		>div{
			padding: 0 7px;
		}
	}
}

#compare-section{
	.compare--up{
		padding: 40px 0;
		h1{
			font-size: 60px;
			padding-top: 30px;
			padding-bottom: 15px;
			@media (max-width: $width-sm-max) {
				font-size: 40px;
			}
			@media (max-width: $width-xs-max) {
				font-size: 30px;
			}
		}
		p{
			font-weight: bold;
		}
	}
	margin-bottom: 170px;
	@media (max-width: $width-xs-max) {
		margin-bottom: 0;
	}
	.compare--boxes {
		position: relative;
		transform: translateZ(0);
		.compare-col {
			&__close {
				text-decoration: none;
				text-indent: -9999px;
				display: inline-block;
				width: 43px;
				height: 43px;
				background: #fff url('../images/close-X.png') center center no-repeat;
				background-size: 50% 50%;
				border-radius: 100%;
				position: absolute;
				right: 0;
				top: -21px;
				display: none;
				&.on {
					display: block;
				}
				&:hover {
					background-color: #efefef;
				}
			}
		}
	}
}

.compare-box{
	background: #fff;
	padding-bottom: 15px;
	@media (max-width: $width-xs-max) {
		max-width: 300px;
		margin: 0 auto;
		margin-bottom: 40px;
	}
	h5{
		font-weight: 500;
		font-family: $graphik;
		padding-bottom: 4px;
		@media (max-width: $width-xs-max) {
			font-size: 12px;
		}

	}
	&__entry {
		padding-top: 20px;
		.property-item {
			&__photo-clickable {
				position: relative;
				display: block;
				max-height: 100%;
				.thumbnail_image {
					width: 100%;
					height: 100%;
					background-size: cover;
					background-position: center top;
					background-repeat: no-repeat;
					padding-bottom: 75%;
				}
			}
			&__photo {
				opacity: 0;
				visibility: hidden;
				display: none;
				width: 100%;
				max-width: 100%;
				height: auto;
			}
		}
	}
	&__up{
		padding: 0 15px;
		ul{
			margin:0;
			padding:30px 0;
			text-align: center;
			@media (max-width: $width-xs-max) {
				text-align: center;
			}
			li{
				list-style: none;
				display: inline-block;
				text-align: center;
				margin: 0 auto;
				@media (max-width: $width-xs-max) {
					display: block;
					width: 120px;
					margin-bottom: 20px;
				}
				a{
					color:#231F20;
					font-weight: 500;
					display: block;
					line-height: 28px;
					padding: 0 10px;
					&.active{
						background-color: $gold-dark;
						color:#fff;
						border-radius: 4px;
					}
				}
			}
		}
		select{
			font-size: 14px;
		}
	}
	&__tail{
		margin: 15px;
		margin-bottom: 0;
		padding-bottom: 15px;
		border-bottom: 1px solid #C7C8CA;
		img{
			margin-right: 10px;
		}
	}
	&__main{
		margin: 15px;
		border-bottom: 1px solid #C7C8CA;
		h5:first-child {
			padding-bottom: 15px;
		}
		.property-item__icons{
			span{
				vertical-align: middle;
				font-size: 32px;
				margin-right: 35px;
				font-weight: normal;
				font-family: $fenice;
				margin-bottom: 15px;
				display: inline-block;
				color: $sage;
				@media (max-width: $width-sm-max) {
					font-size: 20px;
				}
				@media (max-width: $width-xs-max) {
					font-size: 16px;
				}
				img{
					vertical-align: middle;
					top:0;
				}
			}
		}
		p{
			font-size: 32px;
			font-weight: normal;
			font-family: $fenice;
			margin-bottom: 22px;
			@media (max-width: $width-sm-max) {
				font-size: 20px;
			}
			@media (max-width: $width-xs-max) {
				font-size: 16px;
			}
			.text-italic{
				font-style: italic;
			}
			&.color-grey{
				color: rgba(35, 31, 32, 0.3);
			}
		}
		&-location {
			display: inline-block;
			min-height: 90px;
		}

	}

	&__features{
		margin: 15px;
		padding-bottom: 40px;
		border-bottom: 1px solid #C7C8CA;
		h5{
			padding-bottom: 15px;
		}
		a, span {
			border:2px solid  #C7C8CA;
			padding-left: 22px;
			padding-right: 15px;
			display: inline-block;
			line-height: 22px;
			color:#231F20;
			margin-bottom: 6px;
			margin-right: 3px;
			text-decoration: none;
			background: url("../images/tick-icon.svg") no-repeat;
			background-size: 10px 6.5px;
			background-position: 6px 50%;
			&.disabled{
				opacity:0.3;
			}
		}
	}
	&__dimensions{
		margin: 15px;
		border-bottom: 1px solid #C7C8CA;
		p{
			margin-bottom: 25px;
			line-height: 1.4em;
		}
	}
	&__bottom{
		margin: 0 15px;

		&-level-switch {
			margin: 0;
			margin-top: 30px;
			display: block;
			padding: 0;
			li{
				display: inline-block;
				width: 46%;
				margin-right: 15px;
				text-align: center;
				margin-bottom: 20px;
				&:last-child{
					margin-right: 0;
				}
				a {
					line-height: 35px;
					text-decoration: none;
					border: 1px solid $main-brown;
					color: $main-brown;
					display: block;
					border-radius: 4px;
					&.active, &:hover {
						background: $main-brown;
						color: #fff;
					}
					&.disabled, &.disabled:hover {
						color:#C7C8CA;
						border: 1px solid #C7C8CA;
						background: transparent;
						cusrsor: default;
					}
				}
			}
		}
		.compare-img-wrapper{
			position: relative;
			height: 500px;
			object {
				height: 500px;
				width: 100%;
			}
			.expand-icon{
				position: absolute;
				bottom:15px;
				left:15px;
				background: #fff;
			}
		}
		.btn{
			&--red{
				background: $gold-dark url('../images/triangle-white-right.svg') no-repeat;
				background-size: 18px 20px;
				background-position: 95%;
				display: block;
				line-height: 60px;
				color: #fff;
				padding:0;
				padding-left: 20px;
				font-size: 24px;
				text-align: left;
				margin: 15px 0;
				margin-bottom: 0;
				@media (max-width: $width-sm-max) {
					font-size: 16px;
					line-height: 45px;
				}
				@media (max-width: $width-xs-max) {
					line-height: 35px;
					font-size: 14px;
				}
			}
		}
	}
	.custom--select-tertiary {
		select {
			background: #fff;
			border-radius: 4px;
			border: 1px solid #b2b1b1;
			height: 37px;
			padding-right: 22px;
			color: #333;
		}
		&:after {
			background: url('../images/icon-arrow-down-dark.svg') 0 0 no-repeat;
			background-size: 14px 11px;
			width: 14px;
			height: 11px;
			pointer-events: none;
		}
		&-inactive {
			select {
				color: #333;
			}
			&:after {
				background: url('../images/icon-arrow-down-dark.svg') 0 0 no-repeat;
				pointer-events: none;
			}
		}
	}
}
.floorplan-data {
	display: none;
}
.background{
	&--red{
		background-color: $main-brown;
	}
}
#compare-section-info{
	color:#fff;
	padding-top: 45px;
	@media (max-width: $width-sm-max) {
		padding-bottom: 45px;
	}
	h2 {
		padding-top: 27px;
		padding-bottom: 35px;
		font-size: 32px;
		max-width: 370px
	}
	.btn{
		color:#fff;
		border: 1px solid #fff;
	}
	p{
		margin-bottom: 20px;
		padding-top: 30px;
	}
	&.productrange-compare {
		padding-bottom: 50px;
		h3 {
			padding-bottom: 0;
		}
		h4 {
			font-size: 32px;
			font-style: italic;
			color: $button-shadow-color;
		}
	}
}