/* ------------------------------------------------------------ *\
	SECTION INFO
\* ------------------------------------------------------------ */

.section-info {
	padding: 100px 0 73px;

	body.house-and-land & {
		padding-top: 60px;
		padding-bottom: 30px;
	}
	.display-centre-single &, .estate &{
		padding: 0px;

		.estate__no-results {
			margin: 0 0 40px 0;
			width: 100%;
		}

		.bg-grey{
			    background-color: $grey-background-light;
			    padding: 67px 0px;
		}
		&.homes-on-display{
			padding-top: 67px;
		}
		h2{
			font-family: $graphik;
		}
	}
	.estate &{
		padding: 30px;
		
	}	
	
	
	.new-homes_series &{
		padding: 73px 0 0;
		@media (max-width: $width-xs-max) {
			padding: 30px 15px 0 15px;
		}
	}

	#homes-on-display{
		h2{
			padding: 0px 30px;
			.display-centre-single &{
				padding: 0px;

			}
			.house-and-land &{
				padding: 0;
				font-family: $graphik;
				font-style: normal !important;
				font-size: 32px;
				letter-spacing: normal;
				text-transform: none;

				@media (max-width: $width-sm-max) {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}

		.slick-arrow {
			display: block;
			background: #fff;
			z-index: 10;
			width: 130px;
			height: 130px;

			&.slick-prev, &.slick-next {
				&:before {
					color: #000;
					opacity: 0.5;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
				box-shadow: 0 0 150px 1px rgba(0, 0, 0, 0.3);
			}

			&.slick-prev {
				border-top-right-radius: 100%;
				border-bottom-right-radius: 100%;
				left: -80px;
				&:before {
					right: 15px;
				}
			}
			&.slick-next {
				border-top-left-radius: 100%;
				border-bottom-left-radius: 100%;
				right: -80px;
				&:before {
					left: 15px;
				}
			}
		}

		/*
		there is an issue where slides that were previously hidden outside of the slider container
		would not have had their images lazyloaded, even when the user has ticked the slide over.
		*/
		.slick-cloned {
			.property-item {
				&__photo-clickable {
					.thumbnail_image:not(.lazy-loaded) {
						+ img.property-item__photo {
							visibility: visible;
							opacity: 1;
							position: absolute;
							top: 0;
							left: 0;
							object-fit: cover;
							object-position: center;
							height: 100%;
							display: block;
						}
					}
				}
			}
		}

	}

	@media (max-width: $width-sm-max) {
		padding: 60px 0;
	}
	@media (max-width: $width-xs-max) {
		padding: 40px 0;
	}

	h2 {
		font-size: 32px;
		margin-bottom: 23px;
		position: relative;
		a{
			font-size: 13px;
			position: absolute;
			right: 0;
			bottom: 0px;
			@media (max-width: $width-xs-max) {
			    position: relative;
				display: block;
				margin-top: 10px;
			}
		}
	}

	&__group-contacts {
		position: relative;
		display: flex;
		justify-content: space-between;

		@media (max-width: $width-sm-max) {
			display: block;
		}

		@media (max-width: $width-xs-max) {
			padding-bottom: 40px;
			margin-bottom: 40px;
			.display-centre-single &, .estate &{
				padding-bottom: 0px;
				margin-bottom: 0;
			}
		}

		&__body {
			width: 100%;
			max-width: 778px;
		}
		.button-gold {
			font-size: 16px;
			font-weight: 500;
			height: 53px;
			padding: 14px 30px;

			border-width: 2px;
			top: 25px;
			right: -50px;
			transform: translateY(-50%);
			position: absolute;
			@media (max-width: $width-sm-max) {
				margin-top: 10px;
			}
			.display-centre-single &, .estate &{
				height: auto;
				padding: 10px 15px;
				@media (max-width: $width-sm-max) {
					position: absolute;
					top: 10px;
					right:0px;
				}
			}
		}
		.display-centre-single &, .estate &{
			.contacts{
				@media (max-width: $width-sm-max) {
					position: relative;
				}
			}
		}
	}
	&__group-booking {

		padding-bottom: 38px;
		margin-bottom: 63px;
		position: relative;

		body.general & {
			border-top: none; 
			padding-top: 63px; 
			margin-bottom: 0; 
			margin-top: 0; 
		}

		.estate &, .display-centre-single &{
			margin-bottom: 0px;
			padding-top: 60px;
		}

		@media (max-width: $width-xs-max) {
			padding-bottom: 20px;
			margin-bottom: 29px;
		}
		.form-group.error {
			label {
				color: $sage;
			}
			input {
				color: $sage;
			}
		}
	}
	&__group-map {
		border-bottom: 2px solid  #cfd0d1;
		padding-bottom: 68px;
		margin-bottom: 68px;

		@media (max-width: $width-xs-max) {
			padding-bottom: 40px;
			margin-bottom: 40px;
		}
	}
	&__group-homes {
		.col-md-3:nth-child(4n+1) {
			clear: left;
				.ie &{clear: none;}
			 }

		@media (max-width: $width-sm-max) {
			.col-md-3:nth-child(odd) {
				clear: left;
				.ie &{clear: none;}
			}
		}
	}
	.row{
		.house-and-land &, .readybuilt &, .display-for-sale_detail &{
			@media (max-width: $width-sm-max) {
				margin: 0px;
			}
		}
	}
	&__group-form {
		&-select {
			margin-bottom: 30px; 
			.form-select {
				&__button-list {
					padding-left: 0; 
					li {
						display: inline-block; 
						list-style: none; 
						padding-left: 0; 
					}
					a.button {
						text-align: center; 
						&:hover {
							text-decoration: none; 
						}
						&.active {
							background: $sage !important;
							border-color: $sage;
							color: #fff !important; 
						}
					}
				}
			}
		}
		&-container {
			display: none; 
			position: relative; 
			&.active {
				display: block; 
			}
			body.display-centre-single & {
				.salesCentreRow {
					display: none; 
				}
				.loader-panel {
					background: rgba(242,242,242,.8);
				}
			}
		}
	}
	
	
	.homes-on-display.rb-estate-grid, .homes-on-display.hl-estate-grid {
		.property-item {
			margin-bottom: 50px;
		}
		.slick-arrow {
			display: block;
			background: #efefef;
			z-index: 10;
			width: 0px;
			height: 130px;
	
			body.general & {
				background: rgb(245, 245, 245);
			}
	
			&.slick-prev, &.slick-next {
				box-shadow: 0 0 150px 1px rgba(0, 0, 0, 0.3);
				&:before {
					color: #000;
					opacity: 0.5;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}
	
			&.slick-prev {
				border-top-right-radius: 100%;
				border-bottom-right-radius: 100%;
				left: 15px;
				&:before {
					right: 15px;
				}
			}
			&.slick-next {
				border-top-left-radius: 100%;
				border-bottom-left-radius: 100%;
				right: 15px;
				&:before {
					left: 15px;
				}
			}
	
			@media (max-width: $width-xs-max) {
				top: 25%;
			}
		}
		.slick-dots {
			bottom: 0px;
			li {
				position: relative;
				display: inline-block;
				height: 12px;
				width: 12px;
				border-radius: 100%;
				border: 1px solid $footer-grey;
				background: transparent;
	
				button {
					display: none;
				}
				&.slick-active {
					background: $footer-grey;
				}
			}
		}
	
	}
	
}