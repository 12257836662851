/* ------------------------------------------------------------ *\
Property
\* ------------------------------------------------------------ */

.property {
	display: flex;
	padding: 12px 0;
	/*border-bottom: 1px solid #e9e8e9;*/
	font-size: 13px;
	color: #231f20;
	clear: initial !important;

	@media (max-width: $width-xs-max) and (orientation:landscape){
		width: 50%;
		float: left;
	}

	@media (max-width: $width-xs-max) {
		/*
		&.grid_rb {
			height: 600px;
		}
		*/
	}

	html.ie10 &{
		display: block;
	}

	h3 {
		font-size: 18px;
		margin-bottom: 7px;
		font-family: $graphik;
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			margin: auto;
			font-style: normal;
		}
		em {
			color: #a7a5a6;
		}
	}
	p {
		margin: 0;

		.house-and-land-landing-page &, .house-and-land &, .new-homes_series &, .readybuilt-landing &, .readybuilt &, .display-for-sale &, .display-for-sale_detail & {
			margin-bottom: 10px;

			&.property-item__slide {
				&-estate, &-address {
					a {
						color: inherit;
						&:hover {
							text-decoration: none;
						}
					}
				}
			}

			@media (max-width: $width-xs-max){
				margin-bottom: 0px;
			}
		}
		.house-and-land-landing-page .gmap-content &, .readybuilt-landing .gmap-content &{
			margin-bottom: 0px;
		}
	}
	ul {
		display: flex;
		list-style-type: none;
		padding: 0;
		margin: 0;

		a {
			display: block;
			transition: opacity .3s;

			&:hover {
				opacity: .7;
			}
		}
	}
	li {
		+ li {
			margin-left: 5px;
		}
	}
	.property-item__body{
		p {
			margin-bottom: 10px;
		}

		.readybuilt-grid &, .display-centre-single & {
			.property-item__slide {
				&-estate, &-address {
					margin-bottom: 0;
					a {
						color: inherit;
						text-decoration: none;
						display: block;
					}
				}
				&-estate {
					text-transform: uppercase;
					font-weight: 500;
					
					&.boldtext{
						font-weight: 700!important;
					}
				}
				@media (max-width: $width-xs-max) {
					&-virtual-tour {
						padding-right: 0;
						text-align: center;
					}
				}
			}
		}
	}

	&__load-more {
		margin-top: 10px;
		a {
			display: block;
			margin-bottom: 10px;
			padding: 7px 10px;
			&:last-child {
				margin-bottom: 0;
				font-weight: 500;
			}
		}
	}

	.property-actions {
		display: flex;
		padding-top: 10px;
		justify-content: space-between;
	}
	.property-image {
		flex: 0 0 140px;
		margin-right: 15px;
		img {
			width: 100%;
			height: auto;
		}
	}
	.property-content {
		flex: 0 1 100%;
	}
	&.active {
		.property-item {
			background-color: #e9e9e9;
		}
	}
	&__view-more {
		width: calc(100% - 30px);
		display: block ;
		height: 775px;
		position: absolute;
		opacity: 0;
		z-index: -1;
		bottom: 30px;
		left: 15px;
		.top {
			height: 120px;
			margin-bottom: 5px;
			background-color: #e9e9e9;
			padding: 20px;
			.row {
				margin: 0px;
				height: 50%;
				position: relative;
				&:first-child {
					border-bottom: 2px solid $grey-dark;
					height: 50%;
					position: relative;
				}
				.close_view-more {
					height: 20px;
					width: 20px;
					position: absolute;
					right: 0px;
					top: -3px;
					cursor: pointer;
				}
				.view-home {
					position: absolute;
					width: 195px;
					right: 0px;
					bottom: -10px;
					background-image: url(../images/icon-circle-right-arrow.svg);
				    background-repeat: no-repeat;
				    background-position: -webkit-calc(100% - 15px) 50%;
				    background-position: calc(100% - 15px) 50%;
				    background-size: 16px;
					&:hover{
						background-image: url(../images/icon-circle-right-arrow-gold.svg);
					}


					html.ie10 & , html.ie &{
						background-position-x: 95%;
						background-size: 16px 16px;
					}
				}
			}
			.bold {
				font-weight: 500;
			}
			p {
				margin: 0px;
				font-size: 13px;
				span {
					margin-right: 20px;
					font-size: 15px;
				}
			}
			&:last-child {
				p {
					position: absolute;
					bottom: 0px;
				}
			}
		}
		.bottom {
			height: 650px;
			position: relative;
			background-color: #fff;
			.floorplan {
				background-color: #fff;
				padding: 20px;
				display: none;
				height: 650px;
				opacity: 0;
				html.ie & {
					opacity: 1;
				}
				&.active {
					display: block;
				}
				img {
					max-height: 650px;
				}
				.img-container {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
					max-width: 100%;
					max-height: 100%;
					.inner {
						max-width: 100%;
						max-height: 100%;
						display: flex;
						img {
							max-height: 650px;
						}
					}
				}
			}
			.slick-container {
				.full-bg {
					height: 650px;
					width: 100%;
					background-repeat: no-repeat;
					background-size: cover;
				}
				img.slick-arrow {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 5px;
					z-index: 1;
					&.prev {
						left: 5px;
						transform: rotate(180deg);
					}
				}
			}
		}
		&.active {
			z-index: 1;
		}
		@media (max-width: $width-xs-max) {
			display: none !important;
		}
	}
	&-item {
		&__sold, &_banner {
			position: absolute;
			display: block;
			padding: 0 10px;
			top: 0;
			left: 0;
			background-color: $sage;
			color: #fff;
			font-weight: 500;
			line-height: 34px;
			height: 34px;
			z-index: 100;
			&:after {
				content: '';
				position: absolute;
				border-style: solid;
				border-width: 17px 17px 17px 0px;
				border-color: $sage transparent;
				right: -17px;
				top: 0px;
			}

			&.teal{

				background-color: #93c0ae;
				&:after{
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 17px 17px 17px 0px;
					border-color: #93c0ae transparent;
					right: -17px;
					top: 0px;
				}



			}

			&.maroon{
				background-color: #4a0e2b;
				&:after{
					content: '';
					position: absolute;
					border-style: solid;
					border-width: 17px 17px 17px 0px;
					border-color: #4a0e2b transparent;
					right: -17px;
					top: 0px;
				}


			}



		}

		&__photo-clickable {
			&:hover {
				text-decoration: none;
			}
			p {
				padding: 3px 10px 0 10px;
				color: #666;
				font-size: 12px;
				text-align: center;
				margin-bottom: 0 !important;
			}
		}

		&__body-clickable {
			height: auto;

			body.new-homes & {
				height: initial;
			}

			@media (max-width: $width-xs-max) {
				body.new-homes & {
					width: 100%;
					margin-bottom: 10px;
				}

				p.disclaimer {
					padding-right: 0;
					margin-bottom: 14px;
				}
			}
		}

		&__slider {
			margin-left: -15px;
			margin-right: -15px;
			height: 184px;
			background: $property-slider-background;
			color: #333;

			body.new-homes & {
				background: transparent;

				@media (max-width: $width-sm-max) {
					margin-left: 0;
					margin-right: 0;
					height: auto;
				}
			}

			.slick-prev {
				left: 5px;
				z-index: 10;
				background: $property-slider-background url('/resources/images/arrow-dark-grey.svg') center center no-repeat;
				background-size: cover;
				&:before {
					content: '';
					display: none;
				}
			}
			.slick-next {
				right: 5px;
				z-index: 10;
				background: $property-slider-background url('/resources/images/arrow-dark-grey.svg') center center no-repeat;
				background-size: cover;
				transform: translateY(-50%) rotate(180deg);
				&:before {
					content: '';
					display: none;
				}
			}
		}
		&__slide {
			box-sizing: border-box;
			padding: 15px;
			
			&.rbp{
				margin-top:35px; 
				
			}

			&-price-line {
				p {
					display: block;
					font-size: 15px;
					font-weight: 400;
					padding-right: 0;
					text-align: center;
					&:last-child {
						margin-right: 0;
					}
				}
			}

			&-call-now {
				body.readybuilt-landing & {
					margin-top: 12px;
					margin-bottom: 0;
					text-align: center;

					@media (max-width: $width-sm-max) {
						padding-right: 0;
					}
				}
				.readybuilt-grid__enquire, .readybuilt-grid__call-now {
					background: $sage;
					height: 32px;
					width: 100px;
					text-align: center;
					color: #fff;
					border-radius: 2px;
					font-weight: 500;
					border: 1px solid $sage;
					&:hover {
						background: #fff;
						color: $sage;
					}
				}
			}
			&-estate, &-address, &-lot-size {
				@media (max-width: $width-xs-max) {
					padding-right: 0 !important;
					text-align: center;
				}
			}
			&-virtual-tour {
				margin-top: 10px !important;
				margin-bottom: 0 !important;
			}

			&-estate + .property-item__slide-price-line-bordered {
				width: 80%;
				margin: 38px auto 0 auto;
				border-top: 1px solid #333;
				/* border-bottom: 1px solid #333; */
				padding-top: 10px;
				font-family: $graphik;
				font-size: 16px;
				letter-spacing: normal;

				strong {
					font-weight: 700;
					letter-spacing: .3px;
				}

				@media (max-width: $width-xs-max) {
					padding-bottom: 10px;
					margin-top: 27px;

					strong {
						letter-spacing: normal;
					}
				}
			}
			&-address + .property-item__slide-price-line-bordered {
				width: 80%;
				margin: 10px auto 0 auto;
				border-top: 1px solid #333;
				padding-top: 10px;

				strong {
					font-family: $graphik;
					font-weight: 400;
					font-size: 20px;
					letter-spacing: normal;

					@media (max-width: $width-xs-max) {
						letter-spacing: normal;
					}
				}
			}
		}

		body.new-homes & {
			.slide {
				height: 184px;
				position: relative;
				@media (max-width: $width-xs-max) {
					text-align: center;
					height: auto;
				}
			}
		}
	}
	@media (max-width: $width-xs-max) {
		padding-bottom: 0px !important;
	}
	body.readybuilt-landing & {
		&-item__offers-above {
			display: inline-block;
			max-width: 55px;
			line-height: 0.8;
			text-align: right;
			vertical-align: text-bottom;
			margin-right: 5px;
		}
		&-item__price {
			font-family: $helvetica;
			color: #222;
			font-size: 26px;
			font-weight: 400;
			display: inline-block;
			vertical-align: middle;;
		}
	}

	&-q-collection {
		.property-item {
			&__sold, &_banner {
				background: $q-collection-banner-color;
				&:after {
					border-color: $q-collection-banner-color transparent;
				}
			}
			&__dots {
				li {
					a {
						color: $q-collection-color;
						border-color: $q-collection-color;
						&.active {
							color: #fff;
							background-color: $q-collection-color;
						}
					}
				}
			}
			&__tail {
				&--threesixty-view {
					color: $q-collection-color;
					background-image: url(../images/360view_teal.svg);
				}
				&___preview-btn {
					color: $q-collection-color;
				}
			}
		}
	}
}
#product-range-tab__feed, .add_property_padding {
	.property {
		padding: 0 15px;
		body.readybuilt-landing & {
			min-height: 486px;
		}
	}
}

.new-homes{
	.collections_featured{
		.newhomes{
			@media (max-width: $width-xs-max) {
				margin-left: 0px;
				margin-right: 0px;
				.row{
					margin-left: 0px;
					margin-right: 0px;
				}
			}
		}
		.property {
			padding: 0 15px;
		}
		@media (max-width: $width-sm-max) {
			//padding: 0px;
			.property {
				margin-bottom: 30px;
			}
		}
		@media (max-width: $width-xs-max) {
			.property {
				margin-bottom: 0px;
			    display: block;
 			    padding: 0px 0px;
			    .property-item {
				    margin: 0 0 20px 0;
				    &--newhomes {
					    margin: 0 0px 20px;
					    padding: 10px;
					}
					&__down {
						padding-left: 10px;
					}
			    }
			}
		}
	}
	.property.no_click {
		.property-item {
			min-height: 508px;
			@media (min-width: $width-sm-max) and (max-width: $width-md-max - 1) {
				min-height: 530px;
			}
			@media (max-width: $width-sm-max - 1) {
				min-height: 570px;
			}
			&__body {
				padding-top: 32px !important;
				&-clickable {
					height: auto;
				}
			}
		}
	}
}