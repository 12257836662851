/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features {
	display: flex;
	align-items: center;
	padding: 0;
	margin: 0;
	font-size: 14px;
	letter-spacing: -0.02em;
	font-weight: 500;
	color: #fff;
	list-style-type: none;
	flex-wrap: wrap;

	body.readybuilt & {
		color: $main-brown;
	}

	li {
		&:first-child {
			padding-right: 13px;
		}
		+ li {
			margin-left: 4px;
		}
	}

	span {
		position: relative;
		display: block;
		height: 25px;
		padding: 0 11px 0 24px;
		border: 2px solid #fff;
		border-radius: 2px;
		font-weight: 300;
		letter-spacing: -0.07em;
		line-height: 22px;
		white-space: nowrap;
		&:before {
			content: '';
			position: absolute;
			width: 9px;
			height: 7px;
			top: 50%;
			left: 7px;
			margin-top: -4px;
			background-image: url(../images/check-white.png);
			background-size: 100% 100%;
		}

		/*
		body.readybuilt & {
			border-color: $main-brown;
			&:before {
				background-image: url(../images/check-brown.png);
			}
		}
		*/
	}
}

@media(max-width: $width-sm-max){
	.list-features {
		flex-flow: row wrap;
		padding-bottom: 15px;
		font-size: 12px;
		font-weight: 400;
		li {
			margin-bottom: 7px;
			&:first-child {
				flex: 0 0 100%;
			}
			&:nth-child(2) {
				margin-left: 0;
			}
		}
	}
}