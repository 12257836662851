/* ------------------------------------------------------------ *\
	Widget Enquiry
\* ------------------------------------------------------------ */

.widget-enquiry {
	position: relative;
	border-radius: 5px;
	box-shadow: 0 2px 1px #c6c7c9;
	overflow: hidden;
	z-index: 4;

	h3 {
		font-family: $graphik;
		font-size: 24px;
		letter-spacing: -0.02em;
		font-weight: 200;
	}
	p {
		letter-spacing: -0.02em;
		margin: 0;
		color: #231f20;
		text-align: center;

		a {
			font-weight: 500;
			color: $sage;
		}
	}

	&.white-widget{
		background: #fff;
	}

	&__head {
		display: flex;
		justify-content: space-between;
		padding: 26px 19px 23px 23px;
		color: #fff;
		background: $sage;

		&--secondary {
			padding: 27px 19px 24px 23px;
		}

		.ico-heart {
			margin-top: -2px;
		}
		.ico-triangle-small {
			position: relative;
			top: 2px;
			left: -5px;
		}

		a {
			display: block;
			color: #fff;
			width: 100%;

			i {
				margin-left: 16px;
			}
		}
	}
	&__body {
		padding: 24px 18px 16px;

	}
	&__btn {
		position: relative;
		display: block;
		height: 50px;
		padding: 0 60px 0 15px;
		margin-bottom: 14px;
		line-height: 50px;
		transition: all .3s;
		background-color: $sage;
		color: #fff!important;
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			@media(max-width: $width-sm-max){
				height: auto;
				padding: 10px 15px;
				border: none;
				margin-bottom: 0px;
				line-height: normal;
			}
			@media(max-width: $width-xs-max){
				padding: 7px 10px;
				/*margin-top: 2px;*/
				margin-top: 0;
			}
		}

		&:hover {
			background-color: #fff;
			color: $sage!important;
			text-decoration: none;
			.ico-envelope{
				    background-image: url(../images/ico-envelope-teal.png);
			}
		}

		i {
			position: absolute;
			top: 50%;
			right: 23px;
			transform: translateY(-50%);
		}
	}
	.tooltip {
		.tooltip-inner {
			background: #fff;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
			font-size: 9px;
			color: #a7a5a6;
			border-radius: 2px;
		}
		.tooltip-arrow {
			border-top-color: #fff;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		}
	}
	&__favourite {
		&.selected {
			i {
				background-image: url('../images/ico-heart-white.png');
			}
		}
	}
}

@media(max-width: $width-sm-max){
	.widget-enquiry {
		position: fixed;
		display: flex;
		justify-content: center;
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 8px 20px;
		/*border-top: 1px solid #c4c4c4;*/
		box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.2);
		border-radius: 0;
		background: #efefef;
		z-index: 15;

		h3 {
			font-size: 16px;
			@media (max-width: $width-xs-max) {
				font-size: 150%;
				color: #333;
			}
			.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &, body.general &{
			    text-align: left;
			}
		}

		&__head {
			display: block;
			flex: 0 1 100%;
			padding: 0;
			color: #231f20;
			background: none;
			@media (max-width: $width-xs-max) {
				display: flex;
				flex: 0 1 100%;
				order: 1;
			}
			span {
				padding-bottom: 7px;
				font-size: 9px;
			}
		}
		&__aside {
			display: flex !important;
			flex: 0 0 170px;

			span {
				display: block;
				font-size: 9px;
				letter-spacing: -0.02em;
				color: #a7a5a6;
				&.gold{
					color: $gold-dark;
				}
			}

			&--secondary {
				display: flex;
				justify-content: center;
				text-align: center;
				.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &, body.general &{
					justify-content: space-around;
					flex: 0 0 100%;
					@media (max-width: $width-xs-max) {
						flex: 0 0 50%;
						order: 2;
					}
				}

				ul {
					display: block;
				}

				.widget-enquiry__inner {
					flex: 0 0 50%;
					@media (max-width: $width-xs-max) {
						flex: 0 0 100%;
					}
					.new-homes_series#productLayout, .house-and-land#productLayout &, .readybuilt#productLayout &, .display-for-sale_detail#productLayout &{
						flex: none;
					}
					+ .widget-enquiry__inner {
						margin-left: 0;
					}
				}
			}
		}
		&__inner  + &__inner {
			margin-left: 12px;
			@media (max-width: $width-xs-max) {
				align-self: center;
			}
		}
	}
}

@media (max-width: $width-sm-max) {
	.widget-enquiry {
		.widget-enquiry__inner:first-child {
			flex: none;
			display: none;

			body.new-homes_series &, body.readybuilt_lot & {
				flex: 0 0 100%;
				display: block;
			}
		}
		&__head {
			span {
				font-size: 22px;
			}
		}
	}
}

body.general{
	@media (max-width: $width-lg-max) and (min-width:$width-sm-min){
		.section-sticky-limit{
			.container__widget{
				margin-left: -190px;
			}

			article > .container:not(.container__widget), .transform-left-with-widget {
				transform: translateX(-135px);
			    padding-left: 15px;
				padding-right: 15px;
				z-index: 11;
			}

			.section-contact,  #quarantees-section, .modal {
				.container{
					transform: none;
				}

			}
		}
	}

	.container__widget{
		position: absolute;
		left: 50%;
		transform: translate(-50%);
		z-index: 1;
		.ie &{
			height: 1px;
			top: -1px;
		}
		&.sticky{
			top: 0;
		   // height: 100vh;
		    position: fixed;
	        z-index: 10;
		    .ie &{
// 				height: 100%;
				top: 0px;

			}
		    .widget-enquiry{
			     position: absolute;

			    top: 25px;
		    }
		}
		&.sticky-bottom{
			position: absolute;
		    bottom: 0;
		    height: 100%;
		    width: 100%;
			.widget-enquiry{
			    top: auto;
				bottom: 0;
			}
		}
		@media(max-width: $width-sm-max){
			height: 100px;
		    bottom: 0;
		    top: auto;
		    margin-bottom: 0 !important;
		    width: 100vw;
	        position: fixed;
	        left: 0px;
	        transform: none;
			max-width: none;
            padding: 8px 20px;
			height: 62px;

		}
		.widget-enquiry{
			width: 263px;
		    right: -313px;
		    position: absolute;
		    top: -147px;
		    margin-left: 50px;
		    margin-bottom: 0px;
		    @media(max-width: $width-sm-max){
			    width: 100%;
			    left: 0;
			    height: 100%;
			    top: 0;
			    margin-left: 0;
			   	padding: 2px 20px;


			}
			h3{
				 @media(max-width: $width-xs-max){
				 font-size: 12px;
			    }
			}

			&__btn {
				@media(max-width: $width-sm-max){
					height: auto;
					padding: 10px 15px;
					border: none;
					margin-bottom: 0px;
					line-height: normal;
			     margin-top: 10px;

				}
				@media(max-width: $width-xs-max){
					padding: 7px 10px;
					     margin-top: 12px;
				}
			}
		}
	}
	#modal-enquiry-form-widget{
		.form-contact__group{
			display: block;
			> .col-sm-6, .col-sm-4, .col-sm-8 {
				&:first-child{
					max-width: 388px;
					.form-contact__aside{
					    display: block;

						    .ico-envelope-big{
							    position: relative;
						        background-image: url(../images/ico-envelope-grey.png);
						        width: 39px;
						        height: 31px;
						        top: auto;
						    }
						p, a{
							display: none;
							font-size: 60px;
							 color: #a7a5a6;
							     margin-bottom: 0;
						}

						 border-top: none;
						 border-bottom: none;
					}

				}
			}

			.error label{
				color: $gold-dark;
			}

			.btn-white-transparent{
				display: inline-block;
			    min-width: 124px;
			    height: 36px;
			    border: 0;
			    -webkit-border-radius: 2px;
			    border-radius: 2px;
			    font-size: 14px;
			    font-weight: 400;
			    letter-spacing: -.03em;
			    color: #fff;
			    background: #ff3e40;
			}
			.form-contact__field-container, .form-contact__actions{
			    padding-left: 15px;
			    padding-right: 15px;
			}
			.form-contact__label {
			    font-size: 14px;
			    font-weight: 500;
			    letter-spacing: -.02em;
			    color: #4f4c4d;
			}
		}
	}

}