/* ------------------------------------------------------------ *\
Btns
\* ------------------------------------------------------------ */

/* Btn White */

.btn-white {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid #fff;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff;
	transition: color .3s, background .3s;

	&:hover {
		color: $sage;
		background: #fff;
	}
}

.btn-gold {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $sage;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff!important;
	background: $sage;
	transition: color .3s, background .3s;

	&:hover {
		color: $sage!important;
		background: #fff;
	}
}
.btn-gold-full {
	display: inline-block;
	width: 95%;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $sage;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff!important;
	background: $sage;
	transition: color .3s, background .3s;

	&:hover {
		color: $sage!important;
		background: #fff;
	}
}

.btn-teal {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $teal-dark;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff!important;
	background: $teal-dark;
	transition: color .3s, background .3s;

	&:hover {
		color: $teal-dark!important;
		background: #fff;
	}
}
.btn-teal-full {
	display: inline-block;
	width: 95%;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $teal-dark;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff!important;
	background: $teal-dark;
	transition: color .3s, background .3s;

	&:hover {
		color: $teal-dark!important;
		background: #fff;
	}
}

.btn-dark {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid #6e6761;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff;
	background: #6e6761;
	transition: color .3s, background .3s;

	&:hover {
		color: #6e6761;
		background: #fff;
	}
	
}

.btn-dark-full {
	display: inline-block;
	width: 95%;
	height: 36px;
	padding: 0 34px;
	border: 2px solid #6e6761;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff;
	background: #6e6761;
	transition: color .3s, background .3s;

	&:hover {
		color: #6e6761;
		background: #fff;
	}
	
}

.btn-promo-outline{
	display: inline-block;
	min-width: 180px;
	height: 45px;
	padding: 0 15px;
	border: 1px solid #000;
	background: transparent!important;
	border-radius: 2px;
	line-height: 44px;
	color: #000;

	&:hover {
		color: #fff;
		background: #cfcfcf!important;
	}
}

.btn-promo-gradient{
	display: inline-block;
	min-width: 180px;
	height: 45px;
	padding: 1px 16px;
	border: none;
	background: #000001;
	background: linear-gradient(to right, #000001, #000001);
	border-radius: 2px;
	line-height: 44px;
	color: #fff!important;

	&:hover {
		color: #fff;
		background: #cfcfcf!important;
	}
}


.btn-outline {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $sage;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: $sage!important;
	background: #fff;
	transition: color .3s, background .3s;

	&:hover {
		color: #fff!important;
		background: $sage;
	}
	
}
.btn-outline-full {
	display: inline-block;
	width: 95%;
	height: 36px;
	padding: 0 34px;
	border: 2px solid $sage;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: $sage!important;
	background: #fff;
	transition: color .3s, background .3s;

	&:hover {
		color: #fff!important;
		background: $gold-dark;
	}
	
}

.btn-grey {
	display: inline-block;
	height: 36px;
	padding: 0 34px;
	border: 2px solid #bcbbbc;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff;
	background: #bcbbbc;
	transition: color .3s, background .3s;

	&:hover {
		color: #bcbbbc;
		background: #fff;
	}
	
}

.btn-grey-full {
	display: inline-block;
	width: 95%;
	height: 36px;
	padding: 0 34px;
	border: 2px solid #bcbbbc;
	border-radius: 2px;
	font-family: $graphik;
	font-size: 14px;
	line-height: 32px;
	font-weight: 500;
	letter-spacing: -0.04em;
	color: #fff;
	background: #bcbbbc;
	transition: color .3s, background .3s;

	&:hover {
		color: #bcbbbc;
		background: #fff;
	}
	
}

.promo-tile-text{
	margin-top: 13px;
}

.rb_more_info{
	position: absolute;
	bottom: 15px;
	left: 50%;
	margin-left: -70px;
	background: #333;
	height: 32px;
	width: 140px;
	text-align: center;
	color: #fff;
	border-radius: 2px;
	font-weight: 500;
	border: 1px solid #333;
	&:hover {
		background: transparent;
		color: #333;
	}
	
}


@media(max-width: $width-xs-max){
	/* Btn White */
	.btn-white {
		height: 25px;
		padding: 0 25px;
		border-width: 1px;
		font-size: 10px;
		line-height: 23px;
	}
}



/* ------------------------------------------------------------ *\
	Buttons
\* ------------------------------------------------------------ */

.btn-white-transparent {
	display: inline-block;
	min-width: 152px;
	height: 37px;
	padding: 0;
	border: 2px solid #fff;
	border-radius: 2px;
	font-size: 14px;
	line-height: 33px;
	font-weight: 500;
	color: #fff;
	background: transparent;
	&:hover {
		background: #fff;
		color: $charcoal;
	}
}

.btn-red-transparent {
	display: inline-block;
	min-width: 180px;
	height: 45px;
	padding: 0 15px;
	border: 1px solid #ff5557;
	border-radius: 2px;
	line-height: 44px;

	&:hover {
		color: #fff;
		background: #ff5557;
	}
}

.rb_packages_anchor{
	display: block;
	padding: 10px 20px;
	background: #ad9554;
	color: #fff;
	margin: 0 auto;
	text-align: center;
	font-size: 20px;
	height: 50px;
	line-height: 30px;
	width: auto;
	max-width: 400px;
	
	&:hover {
		color: #fff;
		text-decoration: underline;
	}
	&:visited {
		color: #fff;
	}
	
}

@media(max-width: $width-sm-max){
	.rb_packages_anchor {
		font-size: 16px;
		height: 40px;
		line-height: 20px;
	}
}


/* ------------------------------------------------------------ *\
	Section Buttons
\* ------------------------------------------------------------ */

.section-buttons {
	padding: 47px 0;
}

/* ------------------------------------------------------------ *\
	List Buttons
\* ------------------------------------------------------------ */

.list-buttons {
	display: flex;
	justify-content: center;
	padding: 0;
	margin: 0;
	list-style-type: none;
	li {
		+ li {
			margin-left: 30px;
		}
	}
}


@media(max-width: $width-xs-max){
	.list-buttons {
		display: block;
		text-align: center;
		li {
			+ li {
				margin: 15px 0 0;
			}
		}
	}
}