/* ------------------------------------------------------------ *\
	Tabs
\* ------------------------------------------------------------ */
.tabs {
	padding: 34px;
	margin-bottom: 139px;
	
	background-color: $grey-background-light;
	&__head {
		margin-bottom: 45px;
	}
	&__nav {
		ul {
			display: flex;
			justify-content: center;
			padding: 0;
			margin: 0;
			list-style-type: none;
		}
		li {
			position: relative;
			padding: 3px 6px;
			border: 1px solid $sage;
			border-left: 0px;
			&:first-of-type{
				border-left: 1px solid $sage;
			}
			
			
		}
		a {
			display: block;
		}
		&-active {
			position: relative;
			background-color: $sage;
			
			
			font-weight: 500;
			a{
				color: #fff;
			}
			
		}
	}
	&__tab {
		
		display: none;
		h3{
			font-family: $graphik;
			font-weight: 200;
		}
		&-active {
			display: block;
		}
	}
	
}

@media(max-width: $width-sm-max){
	.tabs {
		margin-bottom: 50px;
	}
}

@media(max-width: $width-xs-max){
	.tabs {
		padding-bottom: 20px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 40px;
		&__nav {
			font-size: 10px;
			li {
				+ li {
					
					&:before {
						top: 0px;
						left: -8px;
					}
				}
			}
			&-active {
				top: 0;
			}
		}
		&__head {
			margin-bottom: 20px;
		}
	}
}


/* ------------------------------------------------------------ *\
	Tabs Tertiary
\* ------------------------------------------------------------ */

.tabs-tertiary {
	&__tab {
		display: none;
		&-active {
			display: block;
		}
	}
}

@media(max-width: $width-xs-max){
	.tabs-tertiary {
		&__tab {
			&:first-child {
				display: block;
			}
			&-active {
				display: none;
			}
		}
	}
}