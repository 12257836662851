/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article {
	padding: 74px 0 110px;
	.house-and-land-landing-page .main-part.about & {
		//padding: 50px 0px;
	}
	font-size: 14px;
	letter-spacing: -0.01em;
	color: #231f20;
	&:first-of-type{
		.container{
			border-top: 2px solid $sage;
			padding-top: 33px;
			
			&.remove-hr{
				border-top:none;
				padding-top: 0px;
			}
			
			&.no-pad{
				padding-left:0;
				padding-right:0;
			}
		}
	}
	.container{

		&.no-pad{
			padding-left:0;
			padding-right:0;
		}
	}
	
	h1 {
		font-size: 60px;
		line-height: 1;
// 		letter-spacing: 0.01em;

		margin-bottom: 21px;

	}
	h2 {
		font-size: 32px;
		line-height: 1.1;
// 		letter-spacing: 0.02em;
		margin-bottom: 21px;
// 		margin-bottom: 40px;
		font-family: $graphik;
	}
	h3 {

		font-size: 32px;
		line-height: 1;
		letter-spacing: -0.03em;
		margin-bottom: 20px;
		font-family: $graphik;
		font-weight: 200;
		//margin-bottom: 39px;
	}
	h4 {

		font-size: 24px;
		letter-spacing: -0.03em;
		margin-bottom: 19px;
		font-family: $graphik;
		font-weight: 200;
		//margin-bottom: 38px;
	}
	h5{
		font-family: $helvetica;
		letter-spacing: .3em;
		text-transform: uppercase;
		span, a, i{
			font-family: $graphik;
			letter-spacing: normal;
		}

	}
	h6{
		font-family: $graphik
	}
	p, ul {
		margin-bottom: 21px;
		line-height: 1.6;
		a {
			font-weight: 500;
		}
		&+h1{
			padding-top: 40px;
		}
		&+h2{
			padding-top: 42px;

		}
		&+h3{
			padding-top: 41px;
		}
		&+h4{
			padding-top: 40px;
		}
	}

	p.no-packages{
		padding:5px 15px;
		display:block;
		background:#30251d;
		color:#fff;
		font-weight:bold;
		line-height:26px;
	}

	div.container > ul, .article--news__body > ul, &-compact > ul, .accordion-secondary__section & > ul {
		padding-left: 0.5em;
		li {
			list-style:  none;
			&:before {
				content: '\2014';
				margin-right: 1em;
			}
		}
	}
	blockquote {
		display: block;
		padding: 24px 0 19px;
		margin: 34px 0 39px;
		border-top: 1px solid $sage;
		border-bottom: 1px solid $sage;
		border-left: 0;
		border-right: 0;
		font-family: $graphik;
		font-size: 24px;
		line-height: 1.11;
		letter-spacing: -0.04em;
		color: $sage;
		    word-spacing: 2px;

	}
	em{
		font-weight: 600;
	}
	hr{
		height: 1px;
		width: 100%;
		background-color: #231f20;
	}

	.section-secondary{
		.container{
			width: 100%;
			padding: 0px;
		}
	}
	img{
		max-width: 100%;
		height: auto;
	}

	.main-inner.sinhalese &{
		h1,h2,h3,h4,h5,h6,p,a,span{
			font-family: "sinhalese";
		}
	}
	.main-inner.japanese &{
		h1,h2,h3,h4,h5,h6,p,a,span{
			font-family: "MS Gothic", sans-serif;
		}
	}
}

@media(max-width: $width-sm-max){
	.article {
		padding-bottom: 40px;
	}
}

@media(max-width: $width-xs-max){
	.article {
		padding: 30px 0 10px;
		h1 {
			font-size: 34px;
			padding-top: 15px;
			margin-bottom: 15px;
		}
		h2 {
			font-size: 30px;
			margin-bottom: 17px;
		}
		h3 {
			font-size: 26px;
			line-height: 29px;
			margin-bottom: 21px;
		}
		h4 {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 19px;
		}
		p {
			margin-bottom: 15px;
		}
		table {
			width: 100% !important;
		}
		blockquote {
			margin: 25px 0;
		}

		.container {
			padding: 0 20px;
		}
	}
}

.general{
	main, .section-sticky-limit{
		> .article, > section , > .slider-images-secondary, > .container{
			padding: 0px;
			margin-top: 0px;
			margin-bottom: 70px;

			@media(max-width: $width-sm-max){
				margin-bottom: 40px;
			}
		}
	}


}


.article--three-columns {
	margin-bottom: 0 !important;
	padding-top: 70px !important;
	padding-bottom: 70px !important;

	&.white {
		background: #fff;
	}

	.article-compact {
		.container {
			border-top: none;
		}
	}
}

/* ------------------------------------------------------------ *\
	Article Compact
\* ------------------------------------------------------------ */

.article-compact {
	padding-bottom: 13px;
	font-size: 14px;
	letter-spacing: -0.02em;
	color: #231f20;

	h3 {
		font-family: $graphik;
		margin-bottom: 21px;

		@media(max-width: $width-xs-max){
			margin-bottom: 15px;
		}
	}
	p {
		&:last-child {
			margin: 0;
		}
	}
	a {
		font-weight: 500;
	}

	.three-columns {
		display: flex;
		&__column {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: stretch;
			flex: 0 0 33.33333%;

			&-image {
				display: block;
				margin-bottom: 20px;
				width: 100%;
				flex: 0 0 270px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;

				img {
					display: none;
				}
			}
			&-content {
				flex: 1 1 auto;
				margin-bottom: 20px;
				h4 {
					text-align: center;
				}
			}
			.button-row {
				flex: 0 0 45px;
			}
		}
		@media (max-width: $width-xs-max) {
			display: block;
			overflow: hidden;
			padding-bottom: 34px;
			&__column {
				display: block;
				&-image {
					background-image: none !important;
					img {
						display: block;
						width: 100%;
					}
				}
			}

			.slick-arrow {
				display: block;
				background: #efefef;
				z-index: 10;
				width: 130px;
				height: 130px;

				&.slick-prev, &.slick-next {
					box-shadow: 0 0 150px 1px rgba(0, 0, 0, 0.3);
					&:before {
						color: #000;
						opacity: 0.5;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				&.slick-prev {
					border-top-right-radius: 100%;
					border-bottom-right-radius: 100%;
					left: -80px;
					&:before {
						right: 15px;
					}
				}

				&.slick-next {
					border-top-left-radius: 100%;
					border-bottom-left-radius: 100%;
					right: -80px;
					&:before {
						left: 15px;
					}
				}
			}
			.slick-dots {
				height: 24px;
				bottom: -30px;
				li {
					position: relative;
					display: inline-block;
					height: 12px;
					width: 12px;
					border-radius: 100%;
					border: 1px solid $footer-grey;
					background: transparent;
					margin-bottom: 0;

					button {
						display: none;
					}
					&.slick-active {
						background: $footer-grey;
					}
				}
			}

			.article--three-columns.white & {
				.slick-arrow {
					background: #fff;
				}
			}
		}
	}
}