/* ------------------------------------------------------------ *\
	Form Contact
\* ------------------------------------------------------------ */

.form-contact {
	h2 {
		font-size: 14px;
		color: $sage;
	}

	&__btn {
		min-width: 151px;
		height: 36px;
		border: 1px solid #fff;
		border-radius: 2px;
		letter-spacing: -0.03em;

		background: none;
		&:hover {
			color: #231f20;
			background: #fff;
		}
	}
	&__content {
		margin-top: -3px;
	}
	&__actions {
		display: flex;
		justify-content: space-between;
		padding-top: 20px;
		p {
			font-weight: 500;
		}
	}
	&__row {
		display: flex;
		justify-content: space-between;
		& + & {
			margin-top: 18px;
		}

		&--custom-checkboxes{
			.form-enquiry-secondary__label{
				display: inline-block;
			}
			.custom--checkbox-tertiary{
				margin-left: 20px;
				display: inline-block;
				.checkbox__label {
					span{
						border-radius: 50%;
					}
				}
			}
		}
	}
	&__field-container {
		flex: 0 1 262px;

		html.ie10 &{
			width: 49%;
			display: inline-block;
			&:only-child {
				width: 100%;
			}
		}

		&:only-child {
			flex: 0 0 100%;
		}
	}
	&__label {
		display: none;
		padding-bottom: 7px;
		margin: 0;
		font-weight: 500;
	}
	&__aside {
		position: absolute;
		display: block;
		height: 100%;
		top: 0;
		left: 15px;
		right: 15px;
		align-items: center;

		html.ie10 &{
			height: 400px;
			h2{
				margin-top: 150px;
			}
		}
		i {

		}

		.line.gold{
			display: block;
			margin-top: 20px;
		}
		.text{
			margin-left: 5px;
		}

	}
	&__group {
		display: flex;
		.general .section-contact &{
			.form-contact__aside{
				h2{
					 max-width: 75%;
					 font-family: $fenice;
					 color: $body-text-color;
					 font-size: 40px;
					 @media(max-width: $width-xs-max){
						 max-width: none;
					 }
				}
			}


		}
	}
	&__field {
		display: block;
		width: 100%;
		height: 40px;
		padding: 0 16px;
		border: 0;
		font-size: 16px;
		border-radius: 2px;
		color: #231f20;
		line-height: 40px;

		&::-webkit-input-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&::-moz-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&:-moz-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&:-ms-input-placeholder { opacity: 1 !important; color: #a7a5a6 !important; }
		&--textarea {
			height: 100px;
			padding-top: 15px;
			padding-bottom: 15px;
			line-height: 1;
			resize: none;
		}
	}
	.col-sm-6 {
		max-width: 50%;
		flex: 0 0 50%;
	}
	&__checkboxes {
		padding-left: 0;
		li {
			list-style: none;
			label {
				display: inline-block;
				&.form-contact__label {
					margin-right: 20px;
				}
				&.checkbox__label {
					font-weight: 400;
					display: inline-block;
				}
				span {
					border-radius: 50% !important;
				}
			}
		}
	}
}

@media(max-width: 1199px){
	.form-contact {
		&__field-container {
			flex: 0 0 48%;
		}
	}
}

@media(max-width: $width-xs-max){
	.form-contact {
		h4 {
			font-size: 22px;
		}
		&__group {
			display: block;
		}
		.col-sm-6 {
			max-width: 100%;
		}
		&__aside {
			position: static;
			display: block;
			padding: 25px 0;
			margin-bottom: 25px;
			text-align: center;
			i {
				position: static;
				margin-bottom: 15px;
			}
		}
		&__row {
			display: block;
		}
		&__field-container {

			& + & {
				margin-top: 18px;
			}
		}
	}
}

.websiteFeedback-aside {
	display: block !important;
	@media (max-width: $width-xs-max) {
		padding: 0;
		margin: 0;
	}
}
.form-contact__aside-description {
	position: absolute;
	padding-top: 40px;
	top: 50%;
	transform: translateY(-50%);
	h2 {
		max-width: 100% !important;
		/*padding-top: 40px;*/
	}
	@media (max-width: $width-xs-max) {
		position: relative;
		padding-top: 0;
		transform: none;
	}
}

.houseAndLandEnquiry-thankyou {
	display:block;
	margin-top: 30px;
	
	
	&.hidden{
		display: none;
	}
}