/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {
	h3 {
		font-style: normal;
		color: #231f20;
		#productLayout &{
			font-family: $graphik;
			font-weight: 200;
			color: $accordion-grey;
		}
		.zoom-in-modal &{
			color: $body-text-color !important;
			font-size: 22px;
		}
	}

	&__head {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.02em;
		color: $sage;
		a {
			color: inherit;
			span {
				&:before {
					content: '+';
				}
			}
		}
	}
	&__body {
		transition: max-height .3s, padding .3s;
	}
	&__section {
		padding-bottom: 33px;

		&:first-child {
			padding-bottom: 53px;
		}
		&:last-child {
			padding-bottom: 0;
			.zoom-in-modal & {
				display: none;
			}
		}
		&:not(.accordion__section-expanded):not(.accordion__section--default) {
			.accordion__body {
				max-height: 0;
				padding: 0;
				overflow: hidden;
			}
		}
		&.accordion__section-expanded {
			&:last-child {
				padding-bottom: 55px;
			}
			.accordion__head {
				a {
					span {
						&:before {
							content: '-';
						}
					}
				}
			}
			.accordion__body {
				max-height: 1500px;
				padding-top: 28px;
			}
		}
	}
	&__group {
		display: flex;
		padding: 30px 0 26px;
		border-bottom: 1px solid #c4c4c4;
	}
	&__aside {
		flex: 0 1 100%;
	}
	&__content {
		flex: 0 0 268px;
	}
	&__inner {
		padding: 22px 0 15px;
		border-bottom: 1px solid #4d443c;

		&--secondary {
			padding: 0 0 55px;

			.house-and-land &, .readybuilt &, .display-for-sale_detail &{
				padding: 0px;
			}
		}
	}
}

/*
@media (min-width: $width-md-min) {
	.accordion__section.choices_and_add_ons {
		.accordion__head a span { display: none; }
		.accordion__body {
			display: block;
			height: 100% !important;
			max-height: 100% !important;
			padding-top: 28px !important;
		}
	}
}
*/

@media(max-width: $width-sm-max){
	.accordion {
		h3 {
			font-size: 20px;
			display: block;
			width: 100%;
			margin: 0;
		}
	}
}

@media(max-width: $width-xs-max){
	.accordion {
		&__section {
			padding-bottom: 0;

			&:first-child {
				padding-bottom: 0;
			}

			&.accordion__section-expanded {

				.accordion__body {
					padding-top: 0;
				}
			}

			&:first-child {

				.accordion__head {
					position: absolute;
					top: 19px;
					right: 20px;
				}
			}
			&:last-child {
				padding-top: 13px;
			}
		}
		&__inner {
			padding: 0;
		}
		&__head {
			font-size: 12px;
		}
		&__link {
			margin-right: 54px;
		}
		&__group {
			display: block;
		}
		&__aside {
			padding-bottom: 10px;
		}
	}
}




/* ------------------------------------------------------------ *\
	Accordion Secondary
\* ------------------------------------------------------------ */

.accordion-secondary {
	margin-bottom: 136px;
	padding-left: 15px;
				padding-right: 15px;
	h2{
		font-family: $graphik;
	}
	h3 {
		font-family: $graphik;
		font-size: 24px;
		font-weight: 200;
		letter-spacing: -0.05em;
		color: #231f20;
	}
	&__section {
		border-bottom: 1px solid #dedddd;
		/*padding-bottom: 30px;*/
		&:not(.accordion__section-expanded) {
			padding-bottom: 0;
			.accordion-secondary__head {
				&:before {
					display: block;
				}
			}
			.accordion-secondary__body {

			}
		}
	}
	&__body {

		overflow: hidden;
		display: none;


		@media(max-width: $width-xs-max){
			p{
				padding: 0 15px;
			}
			&.article h4 {
				padding: 0 20px;
			}
		}
		p:last-child {
			margin-bottom: 30px;
		}
	}

	&__head {
		position: relative;
		padding: 27px 28px 25px 0;


		&:after {
			content: '';
			position: absolute;
			width: 18px;
		    height: 14px;
			background-image: url(/resources/images/accordion-arrow-gold.svg);
			background-position: center center;
			background-size: 100%;
			background-repeat: no-repeat;
			top: 50%;
			right: 0;
			margin-top: -1px;


			@media(max-width: $width-xs-max){
				right: 15px;
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
	.accordion__section-expanded{
		.accordion-secondary__head{
			&:after {transform: rotate(180deg);}
		}
	}
}

@media(max-width: $width-sm-max){
	.accordion-secondary {
		margin-bottom: 50px;
	}
}

@media(max-width: $width-xs-max){
	.accordion-secondary {
		margin-bottom: 25px;
		h2 {
			padding-left: 20px;
			padding-right: 20px;
		}

		h3 {
			font-size: 18px;
		}
		&__head {
			padding: 20px 28px 20px 15px;
		}

		p{
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}

