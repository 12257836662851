.world-of-homes{
	.intro-primary{
		margin-bottom: 50px;
	}
	.tabs{
		margin-bottom: 60px;
		@media(max-width: $width-xs-max){
			padding: 20px 0;
		}
	}
	.bg-light-grey{
		background-color: $grey-background-light;
	}
	article.article{
		padding: 50px 0;
		.container{
			:last-child{
				margin-bottom: 0px;
			}
		}
	}
	.logo{
		img{
			max-width: 60%;
			display: block;
			margin: auto;
			@media(max-width: $width-sm-max){
				max-width: 100%;
			}
		}
	}
	.display-centre-single{
		padding-top: 50px;
	}
	.section-tertiary{
		padding-bottom: 50px;
	}
	.section-secondary__map-container{
		margin-bottom: 0px;
	}
	.form-enquiry-secondary{

	}
	.map-heading-links{
		float: right;
		width: calc(100% - 330px);
		    margin-top: 10px;
		a{
			font-size: 14px;
			width: 33.333333%;
			float: left;
			border-right:1px solid #c7c8ca;
			text-align: center;
			&:last-of-type{
				border-right: none;
			}
		}
		@media(max-width: $width-xs-max){
			width: 100%;
			    float: none;
		}
	}

	@media(max-width: $width-xs-max){
		.property-item__body-clickable{
			display: block;
			padding-right: 30px;
		}
/*
		.property-item__tail{
			position: relative;
			background-color: #fff;
		}
*/
	}
	&.general {
		.form-contact__field-container{
			flex: none;
		}
		article.article{
			padding: 0;

		}
		.section-tertiary{
			padding-bottom: 0px;
		}
		.container.display-centre-single{
			margin-bottom: 0px;
		}
		.form-enquiry-secondary{

			padding: 0;
			@media(max-width: $width-xs-max){
			    padding-top: 20px;
			}
		}
		.tabs{

			@media(max-width: $width-xs-max){
				margin-bottom: 0px;
				padding: 20px 15px;
			}
		}
		.contact-modal {
			position: relative;

			.article{
				padding: 0;
				padding-top: 70px;
				@media(max-width: $width-xs-max){
					padding-top: 40px;
				}
			}

			.form-enquiry-secondary__checkboxes {
				.form-enquiry-secondary__label {
					display: inline-block !important;
					margin-right: 20px;
				}
			}

			.custom--select-tertiary select {
				font-size: 14px;

				@media (max-width: $width-sm-max) {
					font-size: 16px;
				}
			}

			.form-booking__thank-you {
				background: #f5f5f5;
			}

			@media(max-width: $width-xs-max){

				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

.red_box_container{

	margin-bottom: 100px;
	position: relative;


	.red_box{
	    border: 8px solid #ff3e40;
	    width: 80%;
	    margin-top: 50px;
	    position: absolute;
	    bottom: 0;

		h3.title {
			position: absolute;
			top: -45px;
			color: $main-brown;

		}
		.content{
			h3{

			}
			width: 60%;
			padding: 40px 20px 40px 0;
			:last-child{
				margin-bottom: 0px;
			}
		}
	}
	&.video_embed{
		.img_container{
			width: calc(65% - 20px);
		}
		.red_box{
			.content{
				width: 40%;
			}
		}
	}
	.img_container{
		padding-bottom: 20px;
	    background: $grey-background-dark;
	    position: relative;
		width: calc(50% - 20px);
		display: inline-block;
		margin-bottom:30px;
		//position: absolute;
		//bottom: 40px;
		z-index: 1;
		img, .img_bg{
			width: 100%;
			background-position: center center;
		    background-repeat: no-repeat;
		    background-size: cover;

			&.play-button{
				    width: 110px;
				    height: 110px;
				    position: absolute;
				    top: 50%;
				    left: 50%;
					transform: translate(-50%,-50%);
					z-index: 1;
			}
		}

		iframe{
			display: none;
			position: absolute;
		    top: 0;
		    left: 0;
		    height: calc(100% - 20px);
		    width: calc(100% - 20px);
		    z-index: 2;
		}
	}
	&:nth-of-type(odd){
		.red_box{
			right: 15px;
			h3.title {
				left:40%;
			}

			.content{
				float: right;
			}
		}

		.img_container{
			padding-right: 20px;

		}

		&.video_embed{
			h3.title{
				left:60%;
			}
		}
	}
	&:nth-of-type(even){
		.red_box{
			left: 15px;
			.content{
				padding-left: 20px;
			}

		}
		.img_container{
			padding-left: 20px;
			float: right

		}
	}
	&:last-of-type{
		margin-bottom: 50px;
	}

	@media(max-width: $width-sm-max){
		padding-top: 0px !important;
		.img_container{
			width: 100% !important;
			padding: 0px !important;
			position: relative;
			right: 0 !important;
			left: 0 !important;
			float: none !important;
			margin-bottom: 0px;
			iframe{width: 100%;height: 100%;}
		}
		.red_box{
			width: 100%;
			margin-top: 0px;
			float: none !important;
			min-height: auto !important;
			right: 0 !important;
			left: 0 !important;
			position: relative;
			padding: 20px;
			border-top: none;
			    margin-top: -5px;
			h3.title, .content{
				width: 100% !important;
				position: relative;
				top: 0;
				right: 0 !important;
				left: 0 !important;

				float: none !important;
				br{display: none;}
			}
		}
	}
}