/* ------------------------------------------------------------ *\
	Book a Call Back landing page styles
\* ------------------------------------------------------------ */

// body class
.book-call-back-landing-page {
	.header {
		padding-bottom: 0;

		a.header__logo {
			margin-top: 0;
			padding: 8px 35px 15px 0;
		}
	}
	.pull-right {
		a.button-gold {
			margin-top: 6px;
		}
	}
	@media (max-width: $width-ipad-max) {
		.pull-right {
			.button-gold {
				margin-top: 3px;
				height: 36px;
				line-height: 36px;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	@media (max-width: $width-sm-max + 1) {
		.header {
			height: 48px!important;
			padding-top: 0;
			border-top: none;

			a.header__logo {
				padding: 0;
				display: block;
				margin-top: 9px;
				width: 122px;
			}
		}
		.pull-right {
			.button-gold {
				font-size: 16px;
				font-weight: 300;
				margin-top: 8px;
			}
		}
	}
}

.book_callback_thank_you{
	display: none;
	margin-top: 30%;
	min-height: 400px;
	text-align: center;

	p{
		margin-bottom: 20px;
		font-size: 20px;
	}

	.button-gold{
		width: 100%
	}
	.btn-white{
		width: 100%
	}
	.button-grey{
		width: 100%
	}

	@media (max-width: $width-xs-max + 1) {
		p{
			margin-bottom: 20px;
			font-size: 16px;
			padding: 0px 20px;
		}
	}
}

.book_callback_thank_you_error{
	display: none;
	margin-top: 10%;
	min-height: 400px;
	text-align: center;

	p{
		margin-bottom: 20px;
		font-size: 20px;
	}

	.button-gold{
		width: 100%
	}
	.btn-white{
		width: 100%
	}
	.button-grey{
		width: 100%
	}

	@media (max-width: $width-xs-max + 1) {
		p{
			margin-bottom: 20px;
			font-size: 16px;
			padding: 0px 20px;
		}
	}
}

.book-callback {
	&_intro {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 88px;
		min-height: 100vh;

		.d-flex {
			display: flex;
		}

		&-left {
			&.pad{
				padding: 0px 30px;
			}

			h4 {
				font-size: 32px;
				font-family: $graphik, serif;
				margin-bottom: 40px;
				color: #fff;
			}

			.rectangles {
				padding-left: 0;
				text-align: right;

				li {
					background: #fff;
					height: 110px;
					width: 100%;
					border-right: 5px solid $sage;
					display: inline-block;
					list-style: none;
					text-align: left;
					margin-bottom: 26px;

					&:nth-child(2) {
						max-width: 580px;
					}
					&:nth-child(3) {
						max-width: 490px;
					}

					img {
						width: 110px;
						height: 110px;
						display: inline-block;
						margin-right: 30px;
					}
					div.text {
						display: inline-block;
						vertical-align: middle;
						max-width: calc(100% - 170px);
						margin-right: 20px;

						p {
							font-family: $graphik;
							margin-bottom: 0;
							font-size: 20px;
						}
					}
				}
			}

			@media (max-width: $width-sm-max + 1) {
				padding-top: 48px;
				width: 100%;
			}
		}
		&-right {
			max-width: 504px;
			min-width: 360px;
			width: 100%;
			padding: 37px 32px;
			background-color: $map-popup-grey;
		}

		@media (max-width: $width-sm-max + 1) {
			padding-top: 48px;

			&-right {
				width: calc(100% - 20px);
				margin-left: 10px;
				margin-right: 10px;
				float: none;
				padding: 36px 30px;
				max-width: none;
			}
		}
	}
	&_form {
		&-intro {
			text-align: center;
			max-width: 350px;
			width: 100%;
			margin: 0 auto 32px auto;

			p {
				font-family: $graphik, serif;
				color: #000;
				font-size: 26px;
				line-height: 1.2;

				strong {
					color: $sage;
					font-weight: bold;
				}
			}
		}
		label {
			display: none;
		}

		.form {
			&-contact {
				&__row {
					input[type=checkbox] {
						width: 17px;
						height: 17px;
						appearance: none;
						border: 1px solid #000;
						position: relative;
						margin-top: 0;
						margin-right: 1em;

						&:checked {
							&::after {
								content: '×';
								display: block;
								position: absolute;
								color: #000;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								font-size: 16px;
								text-align: center;
							}
						}

						~ label {
							display: block;
							font-family: $graphik;
							color: #000;
							font-size: 16px;
							margin-bottom: 0;
							font-weight: 300;
						}
					}
					+ .form-contact__row {
						margin-top: 7px;
					}
					&.form-contact__checkboxes {
						margin-top: 20px;
						justify-content: flex-start;
						align-items: center;
						padding-left: 4px;
					}
				}
				&__field {
					height: 48px;
					&-container {
						border: 1px solid #fff;
						input::placeholder {
							color: #000 !important;
						}
						.jstyling-select {
							&-s {
								margin-bottom: 0;
								height: 48px;
								border: none;
								background: #fff;
								font-size: 16px;
								line-height: 48px;
								padding: 0 16px;
								border-radius: 2px;

								background-image: url(../images/arrow-down-header-black.svg);
								background-repeat: no-repeat;
								background-size: 19px 12px;
								background-position: calc(100% - 17px) center;
							}
							&-l {
								top: 48px;
								border-radius: 0;
								border: none;
								box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
								div {
									padding: 0 16px;
									font-size: 16px;
									&.item- {
										display: none;
									}
								}
							}
						}

						&.error{
							border: 1px solid #ad9554!important;
						}
					}
				}
				&__select{
					height: 48px;
					border-radius: 2px;
					font-size: 16px;
					color: #000;
					line-height: 48px;

				}
				&__submit-row {
					margin-bottom: 27px;

					input[type=submit] {
						width: 100%;
					}
				}
				p {
					font-style: italic;
					font-size: 16px;
					font-family: $graphik;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}

		@media (max-width: $width-sm-max + 1){
			.col-sm-12 {
				padding-left: 0;
				padding-right: 0;
			}

			&-intro {
				p {
					font-size: 18px;
				}
			}
			.form {
				&-contact {
					&__row {
						&.form-contact__checkboxes {
							display: flex;
							margin-top: 10px;
						}
					}
					&__field {
						height: 35px;
						padding: 0 11px;

						&-container {
							.jstyling-select {
								float: none;
								&-s {
									height: 36px;
									line-height: 36px;
									padding: 0 11px;
								}
								&-t {
									height: 36px;
								}
								&-l {
									top: 36px;
									div {
										padding: 0 11px;
									}
								}
							}
						}
					}
					&__select {
						height: 37px;
						line-height: 37px;
						padding: 0 9px;
					}
					&__submit-row {
						margin-top: 13px;
						margin-bottom: 13px;

						input[type=submit] {
							height: 37px;
						}
					}
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&_top-copy {
		margin-top: 55px;
		text-align: center;
		margin-bottom: 60px;

		h1 {
			color: #fff;
			font-size: 44px;
			font-family: $graphik, serif;
			margin-bottom: 32px;
		}
		p {
			color: #fff;
			font-size: 20px;
			font-family: $graphik;
		}

		.hia-logo {
			max-height: 25px;
			vertical-align: top;
			display: inline-block;
		}

		@media (max-width: $width-ipad-max) {
			margin-top: 0px;
			h1 {
				font-size: 28px;
				padding: 0 60px;
			}

			p {
				font-size: 16px;
				padding: 0 30px;
				
				
				img{ 
					width: 100%!important;
					height: auto!important;
				}
				
				
				
			}
			img{ 
				width: 100%!important;
				height: auto!important;
			}
			
		}
		@media (max-width: $width-xs-max + 1) {
			p {
				padding: 0 30px;
			}
		}
		@media (max-width: $width-xxs-max + 1) {
			p {
				padding: 0 10px;
			}
		}
	}
}

.book-call-back {
	&-steps {
		padding-top: 88px;
		padding-bottom: 75px;
		.container {
			border-top: 0 !important;
			padding-top: 0 !important;
		}
		&__intro {
			margin-bottom: 44px;

			h2 {
				font-size: 44px;
				margin-bottom: 22px;
			}
			p {
				font-size: 20px;
				line-height: 1.4;
			}
		}
		&__list {
			list-style: none;
			margin-bottom: 75px !important;

			li {
				display: flex;
				min-height: 322px;
				background: #f2f2f2;
				border-left: 6px solid $sage;
				align-items: stretch;
				margin-bottom: 40px;

				&::before {
					display: none;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
			&-image {
				flex: 0 0 442px;
				margin-right: 68px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;

				img {
					display: none;
				}
			}
			&-content {
				flex: 1 1 auto;
				align-self: center;
				padding: 32px 70px 32px 0;

				p {
					font-family: $graphik;
					font-size: 20px;
					line-height: 1.4;
					margin-bottom: 0;
				}
			}
			&-heading {
				color: $sage;
				font-size: 22px;
				font-family: $graphik !important;
				vertical-align: middle;
				text-transform: uppercase;
				margin-bottom: 30px;
			}
			&-number {
				display: inline-block;
				font-size: 72px;
				vertical-align: -17%;
			}
			&-subheading {
				font-family: $graphik, serif !important;
				font-size: 32px !important;
				margin-bottom: 30px !important;
			}
		}
		&__footer {
			width: 100%;
			max-width: 685px;
			margin: 0 auto;
			text-align: center;

			p {
				font-family: $graphik, serif;
				font-size: 32px;
				margin-bottom: 32px;
				line-height: 1.2;
			}
			a.button-gold {
				padding-left: 60px;
				padding-right: 60px;
				font-size: 20px;
			}
		}

		@media (max-width: $width-sm-max) {
			padding-top: 44px;
			&__intro {
				h2 {
					font-size: 26px;
					margin-bottom: 27px;
				}
				p {
					font-size: 16px;
				}
			}
			&__list {
				margin-bottom: 36px !important;
				li {
					flex-direction: column;
					margin-bottom: 23px;
					&:last-child {
						margin-bottom: 0;
					}
				}
				&-image {
					flex: 0 0 250px;
					width: 100%;
				}
				&-content {
					padding: 36px 40px 30px 23px;
					p {
						margin-top: 16px;
						margin-bottom: 0;
						font-size: 14px;
						line-height: 1.6;
					}
				}
				&-heading {
					display: inline-block;
					vertical-align: baseline;
					font-size: 13px;
					margin-bottom: 0 !important;
					margin-right: 5px;
				}
				&-number {
					font-size: 39px;
					vertical-align: -5% !important;
				}
				&-subheading {
					display: inline;
					vertical-align: baseline !important;
					font-size: 32px !important;
					line-height: 1;
					margin-bottom: 0 !important;
				}
			}
			&__footer {
				width: 75%;
				max-width: 75%;

				p {
					font-size: 18px;
					margin-bottom: 20px;
				}
				a.button-gold {
					font-size: 16px !important;
					padding-left: 24px !important;
					padding-right: 24px !important;
					font-weight: 300;
				}
			}
		}

		@media (max-width: $width-xs-max) {
			&__list {
				&-subheading {
					font-size: 20px !important;
				}
				&-number {
					vertical-align: -15% !important;
				}
			}
		}

		@media (max-width: $width-xxs-max) {
			&__list {
				&-image {
					flex: 0 0 100px;
				}
			}
		}
	}
	&-watch-video {
		background: $charcoal;
		padding-top: 77px;
		padding-bottom: 77px;
		.container {
			border-top: 0 !important;
			padding-top: 0 !important;
			display: flex;
		}
		&__left {
			flex: 0 0 539px;
			margin-right: 80px;
		}
		&__thumb {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			display: block;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 57px;
				height: 74px;
				background: url('../images/play-testimonial.svg') center center no-repeat;
				background-size: contain;
			}

			img {
				opacity: 0;
				visibility: hidden;
			}
		}
		&__right {
			align-self: center;
			flex: 1 1 auto;
			padding-right: 60px;

			h4 {
				color: #fff;
				font-size: 14px;
				font-family: $helvetica;
				text-transform: uppercase;
				letter-spacing: .3em;
			}
			p {
				color: #fff;
				font-family: $graphik, serif;
				font-size: 32px;
				line-height: 1.2;
			}
		}

		@media (max-width: $width-sm-max) {
			padding-top: 49px;
			padding-bottom: 49px;
			text-align: center;

			.container {
				flex-direction: column;
			}

			&__left {
				flex: 1 1 auto;
				margin-right: 0;
				margin-bottom: 20px;
			}
			&__right {
				padding-right: 0;

				h4 {
					span.line {
						display: none;
					}
				}

				p {
					width: 80%;
					margin: 0 auto;
					font-size: 18px;
				}
			}
		}
	}
	&-three-cols {
		padding-top: 96px;
		padding-bottom: 84px;
		&.beige {
			background-color: #f2f2f2;
		}
		h2 {
			font-size: 44px;
			margin-bottom: 92px;
		}
		.col {
			h3 {
				padding-right: 55px;
				font-family: $graphik, serif;
				margin-bottom: 45px;
				strong {
					color: $sage;
					font-weight: 600;
				}
			}
			p {
				padding-right: 55px;
				font-size: 20px;
				line-height: 1.4;
				margin-bottom: 0;
			}
		}
		@media (max-width: $width-sm-max) {
			padding-top: 45px;
			padding-bottom: 45px;
			h2 {
				font-size: 26px;
				margin-bottom: 38px;
			}
			.row {
				.col {
					margin-bottom: 50px;
					&:last-child {
						margin-bottom: 0;
					}
					h3 {
						padding-right: 0;
						font-size: 20px;
						margin-bottom: 18px;
					}
					p {
						padding-right: 0;
						font-size: 16px;
					}
				}
			}
		}
	}
	&-two-col {
		background: #f2f2f2;

		h2 {
			font-size: 44px;
			margin-bottom: 49px;
		}

		&__left {
			p {
				font-size: 20px;
				line-height: 1.4;
				margin-bottom: 36px;
			}
			ol {
				padding-left: 0px;
				color: $sage;
				font-size: 20px;
				counter-reset: ph-counter;

				li {
					margin-bottom: 16px;
					padding-left: 40px;
					counter-increment: ph-counter;
					position: relative;
					list-style: none;

					&:before {
						content: counter(ph-counter);
						color: $sage;
						font-size: 26px;
						font-family: $graphik;
						position: absolute;
						top: -7px;
						left: 0;
					}
				}
			}
		}
		&__right {
			.book-call-back-watch-video{
				&__thumb {
					margin-bottom: 50px;

					img {
						opacity: 0;
						visibility: hidden;
					}
				}
				&__none {
					&:before {
						display: none;
					}
				}
			}
			&-pullquote {
				h4 {
					color: #797979;
					font-size: 14px;
					font-family: $graphik;
					text-transform: uppercase;
					letter-spacing: .3em;
					margin-bottom: 38px;
					span.line {
						border-color: #797979;
						color: #797979;
					}
				}
				p {
					font-family: $graphik, serif;
					font-size: 32px;
					line-height: 1.2;
					color: #797979;
				}
			}
		}
		@media (max-width: $width-sm-max) {
			padding-top: 45px;
			padding-bottom: 45px;
			h2 {
				font-size: 26px;
				margin-bottom: 24px;
			}
			&__left {
				p {
					font-size: 16px;
					margin-bottom: 30px;
				}
				ol {
					margin-bottom: 40px;
					li {
						padding-left: 30px;
					}
				}
			}
			&__right {
				.book-call-back-watch-video__thumb {
					margin-bottom: 34px;
				}
				&-pullquote {
					h4 {
						color: #000;
						margin-bottom: 28px;
					}
					p {
						color: #000;
						font-size: 18px;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&-two-col-options {
		padding-top: 77px;
		padding-bottom: 68px;

		h2 {
			font-size: 44px;
			margin-bottom: 49px;
		}
		p {
			font-size: 20px;
			line-height: 1.4;
		}

		&__tiles {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
		}

		&__tile {
			flex: 0 1 calc(50% - 15px);
			background: #f2f2f2;

			&-image {
				a {
					display: block;
					height: 270px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;

					img {
						display: none;
					}
				}
			}
			&-content {
				padding-top: 48px;
				padding-bottom: 56px;
				text-align: center;

				h3 {
					font-size: 36px;
					font-family: $graphik;
				}

				p {
					font-size: 20px;
					margin: 0 auto 26px auto;
					max-width: 260px;
				}
			}
			&-price {
				text-transform: uppercase;
				font-size: 16px;
				font-family: $graphik;
				vertical-align: middle;
				line-height: 1;

				span {
					font-family: $fenice;
					font-size: 36px;
					vertical-align: -14%;
					line-height: 1;
				}
			}
			&-cta {
				font-size: 20px;
				font-family: $graphik;
				position: relative;
				padding-right: 28px;

				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 22px;
					height: 22px;
					background: url('../images/arrow-gold-right.svg') center center no-repeat;
					background-size: contain;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
				}
			}
		}

		&__footer {
			margin-top: 60px;
			text-align: center;

			a.button-gold {
				padding-left: 60px;
				padding-right: 60px;
			}
		}
		@media (max-width: $width-sm-max) {
			padding-top: 45px;
			padding-bottom: 34px;

			h2 {
				font-size: 26px;
				margin-bottom: 22px;
			}
			p {
				font-size: 16px;
				width: 87.4%;
			}

			&__tiles {
				flex-direction: column;
			}
			&__tile {
				flex: 0 1 100%;
				margin-bottom: 21px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			&-image {
				a {
					height: 53px;
				}
			}
			&-content {
				padding-top: 30px;
				padding-bottom: 30px;

				h3 {
					font-size: 26px;
					margin-bottom: 33px;
				}
			}
			&__footer {
				margin-top: 31px;

				a.button-gold {
					padding-left: 25px;
					padding-right: 25px;
					font-size: 16px !important;
					font-weight: 300;
				}
			}
		}
	}
	&-google-reviews {
		padding-top: 86px;
		h2 {
			font-size: 44px;
			margin-bottom: 80px;
		}
		&__image {
			margin-bottom: 20px;
			float: left;
			width: 180px;
			img {
				max-width: 140px;
			}
		}
		&__review {
			float: right;
			width: calc(100% - 190px);
			padding-right: 20px;
			p {
				font-size: 20px;
				line-height: 1.4;
				&.book-call-back-google-reviews__name {
					font-size: 16px;
					text-transform: uppercase;
					clear: both;
				}
			}
		}
		&__footer {
			width: 100%;
			max-width: 685px;
			margin: 30px auto 0 auto;
			text-align: center;
			a.button-gold {
				padding-left: 60px;
				padding-right: 60px;
				font-size: 20px;
			}
		}
		.slick-container {
			.slick-dots {
				margin-bottom: 0;
				li {
					margin: 0 10px;
					button {
						background: rgba(0, 0, 0, 0.25);
						width: 15px;
						height: 15px;
						border-radius: 50%;
						&:before {
							content: '';
						}
					}
					&.slick-active {
						button {
							background: rgba(0, 0, 0, 0.5);
						}
					}
				}
			}
		}

		@media (max-width: $width-xs-max) {
			padding-top: 42px;
			h2 {
				font-size: 26px;
				margin-bottom: 35px;
			}
			&__col {
				margin-bottom: 30px;
			}
			&__image {
				float: left;
				width: 150px;
				margin-bottom: 10px;
			}
			&__review {
				padding-right: 0;
				float: none;
				width: 100%;
				p {
					font-size: 16px;
				}
			}
			&__footer {
				width: 75%;
				max-width: 75%;
				margin-top: 0;
				margin-bottom: 30px;

				a.button-gold {
					font-size: 16px !important;
					padding-left: 24px !important;
					padding-right: 24px !important;
					font-weight: 300;
				}
			}
		}
		@media (max-width: $width-xxs-max) {
			&__image {
				width: 100px;
				margin-right: 10px;
				margin-bottom: 20px;
				img {
					width: 100%;
				}
			}
		}
	}
}

.book-callback-text {
	&_intro {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 88px;
		min-height: 100vh;

		.d-flex {
			display: flex;
		}

		&-left {
			&.pad{
				padding: 0px 30px;
			}
			h4 {
				font-size: 32px;
				font-family: $graphik, serif;
				margin-bottom: 40px;
				color: #fff;
			}

			.rectangles {
				padding-left: 0;
				text-align: right;

				li {
					background: #fff;
					height: 110px;
					width: 100%;
					border-right: 5px solid $sage;
					display: inline-block;
					list-style: none;
					text-align: left;
					margin-bottom: 26px;

					&:nth-child(2) {
						max-width: 580px;
					}
					&:nth-child(3) {
						max-width: 490px;
					}

					img {
						width: 110px;
						height: 110px;
						display: inline-block;
						margin-right: 30px;
					}
					div.text {
						display: inline-block;
						vertical-align: middle;
						max-width: calc(100% - 170px);
						margin-right: 20px;

						p {
							font-family: $graphik;
							margin-bottom: 0;
							font-size: 20px;
						}
					}
				}
			}

			@media (max-width: $width-sm-max + 1) {
				padding-top: 48px;
				width: 100%;
			}
		}
		&-right {
			max-width: 504px;
			width: 100%;
			padding: 37px 32px;
			background-color: $map-popup-grey;
		}

		.book-callback_intro-right {
			margin-bottom: 60px;
		}

		@media (max-width: $width-ipad-max) {
			.d-flex {
				flex-direction: column;
				align-items: center;
			}
		}

		@media (max-width: $width-sm-max + 1) {
			padding-top: 48px;

			&-right {
				width: calc(100% - 20px);
				margin-left: 10px;
				margin-right: 10px;
				float: none;
				padding: 36px 30px;
				max-width: none;
			}
		}
	}
	&_form {
		&-intro {
			text-align: center;
			max-width: 350px;
			width: 100%;
			margin: 0 auto 32px auto;

			p {
				font-family: $graphik, serif;
				color: #000;
				font-size: 26px;
				line-height: 1.2;

				strong {
					color: $sage;
					font-weight: bold;
				}
			}
		}
		label {
			display: none;
		}

		.form {
			&-contact {
				&__row {
					input[type=checkbox] {
						width: 17px;
						height: 17px;
						appearance: none;
						border: 1px solid #000;
						position: relative;
						margin-top: 0;
						margin-right: 1em;

						&:checked {
							&::after {
								content: '×';
								display: block;
								position: absolute;
								color: #000;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
								font-size: 16px;
								text-align: center;
							}
						}

						~ label {
							display: block;
							font-family: $graphik;
							color: #000;
							font-size: 16px;
							margin-bottom: 0;
							font-weight: 300;
						}
					}
					+ .form-contact__row {
						margin-top: 7px;
					}
					&.form-contact__checkboxes {
						margin-top: 20px;
						justify-content: flex-start;
						align-items: center;
						padding-left: 4px;
					}
				}
				&__field {
					height: 48px;
					&-container {
						border: 1px solid #fff;
						input::placeholder {
							color: #000 !important;
						}
						.jstyling-select {
							&-s {
								margin-bottom: 0;
								height: 48px;
								border: none;
								background: #fff;
								font-size: 16px;
								line-height: 48px;
								padding: 0 16px;
								border-radius: 2px;

								background-image: url(../images/arrow-down-header-black.svg);
								background-repeat: no-repeat;
								background-size: 19px 12px;
								background-position: calc(100% - 17px) center;
							}
							&-l {
								top: 48px;
								border-radius: 0;
								border: none;
								box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
								div {
									padding: 0 16px;
									font-size: 16px;
									&.item- {
										display: none;
									}
								}
							}
						}

						&.error{
							border: 1px solid #ad9554!important;
						}
					}
				}
				&__select{
					height: 48px;
					border-radius: 2px;
					font-size: 16px;
					color: #000;
					line-height: 48px;

				}
				&__submit-row {
					margin-bottom: 27px;

					input[type=submit] {
						width: 100%;
					}
				}
				p {
					font-style: italic;
					font-size: 16px;
					font-family: $graphik;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
		}

		@media (max-width: $width-sm-max + 1){
			.col-sm-12 {
				padding-left: 0;
				padding-right: 0;
			}

			&-intro {
				p {
					font-size: 18px;
				}
			}
			.form {
				&-contact {
					&__row {
						&.form-contact__checkboxes {
							display: flex;
							margin-top: 10px;
						}
					}
					&__field {
						height: 35px;
						padding: 0 11px;

						&-container {
							.jstyling-select {
								float: none;
								&-s {
									height: 36px;
									line-height: 36px;
									padding: 0 11px;
								}
								&-t {
									height: 36px;
								}
								&-l {
									top: 36px;
									div {
										padding: 0 11px;
									}
								}
							}
						}
					}
					&__select {
						height: 37px;
						line-height: 37px;
						padding: 0 9px;
					}
					&__submit-row {
						margin-top: 13px;
						margin-bottom: 13px;

						input[type=submit] {
							height: 37px;
						}
					}
					p {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	&_top-copy {
		margin-top: 55px;
		text-align: center;
		margin-bottom: 60px;

		h1 {
			color: #fff;
			font-size: 44px;
			font-family: $graphik, serif;
			margin-bottom: 32px;
		}
		p {
			color: #fff;
			font-size: 20px;
			font-family: $graphik;
		}

		.hia-logo {
			max-height: 25px;
			vertical-align: top;
			display: inline-block;
		}

		@media (max-width: $width-ipad-max) {
			margin-top: 35px;
			h1 {
				font-size: 26px;
				padding: 0 60px;
			}

			p {
				font-size: 16px;
				padding: 0 30px;
			}
		}
		@media (max-width: $width-xs-max + 1) {
			p {
				padding: 0 30px;
			}
		}
		@media (max-width: $width-xxs-max + 1) {
			p {
				padding: 0 10px;
			}
		}
	}
}

.book-floorplan {
	background: #e6e6e6;
	.container {
		border-top: 0 !important;
		padding-top: 0 !important;
	}
	.design {
		&__select {
			margin-top: 0;
			padding-left: 0;
			li a {
				background: transparent;
			}
		}
		&__inner {
			padding: 20px;
			&-sidebar {
				flex: 1 1 33.33%;
				@media (max-width: $width-ipad-max) {
					flex: 1 1 25%;
				}
				@media (max-width: $width-sm-max) {
					margin-bottom: 0;
				}
			}
			&-inner {
				flex: 1 1 66.67%;
				padding-left: 20px;
				@media (max-width: $width-ipad-max) {
					flex: 1 1 75%;
					padding-left: 0px;
				}
			}
			&-actions {
				display: flex;
				flex: 0 0 60px;
				flex-flow: row-reverse;
				align-content: flex-start;
				width: 100%;
				padding: 0;
				justify-content: center;
				ul {
					display: flex;
					flex: 0 1 120px;
					flex-direction: row;
					height: 100%;
					margin-bottom: 0;
					li {
						margin: 0 10px;
						flex: 0 1 6rem;
						display: flex;
						text-align: center;
						a {
							width: 75px;
							height: 55px;
							border: 1px solid $sage;
							position: relative;
							color: $sage;
							i {
								position: absolute;
								top: 33%;
								left: 50%;
								transform: translate(-50%, -50%);
								background-size: contain;
								background-repeat: no-repeat;
								background-position: center center;
								height: 26px;
							}
							span {
								position: absolute;
								bottom: 5px;
								width: 100%;
								font-size: 12px;
								font-weight: 400;
								text-align: center;
							}
							&.flipFloorplan {
								width: 95px;
								i.ico-rotate {
									width: 32px;
								}
							}
							&.downloadPDF {
								width: 115px;
								i.ico-download {
									height: 27px;
									background-size: contain;
									background-position: 50%;
									background-repeat: no-repeat;
								}
							}
						}
					}
				}
			}

			@media (max-width: $width-sm-max) {
				flex-direction: column;
			}
		}
		&__image {
			padding-right: 0;
			@media (max-width: $width-sm-max) {
				padding-top: 0;
			}
		}
	}
	.list-info-secondary {
		color: #231f20;
		display: flex;
		justify-content: space-between;
		border-top: 1px solid #c8c8c8;
		border-bottom: 1px solid #c8c8c8;
		padding: 10px 0;
		text-align: center;
		li {
			flex: 0 1 auto;
			font-size: 22px;
		}
		li + li {
			margin-left: 0;
		}

		@media (max-width: $width-ipad-max) {
			margin-left: -5px;
			margin-right: -5px;
			li {
				margin-left: 5px;
				margin-right: 5px;
			}
			li + li {
				margin-left: 5px;
			}
		}
		@media (max-width: $width-xs-max) {
			li {
				margin-bottom: 0;
			}
		}
	}
	.this-floorplan {
		.from-price {
			padding-bottom: 20px;
			border-bottom: 1px solid #c8c8c8;
			h3 {
				margin-bottom: 0;
			}
		}
		&__dimensions {
			padding-top: 20px;
			h4 {
				letter-spacing: 0;
			}
			h5 {
				font-family: $graphik;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: -.02em;
				line-height: 1.3em;
				text-transform: none;
				color: #231f20;
			}
			ul {
				list-style: none;
				display: flex;
				justify-content: flex-start;
				align-content: flex-start;
				padding-left: 0;
				padding-top: 4px;
				margin-bottom: 22px;
				li {
					flex: 0 1 33%;
					font-family: $graphik;
					font-weight: 200;
					line-height: 1;
					font-size: 22px;
					span {
						font-family: $graphik;
						font-size: 14px;
						line-height: 1;
						font-weight: 500;
						display: block;
						margin-bottom: 6px;
					}
				}
			}
			p {
				margin-bottom: 0;
			}
		}
		&__full-dimensions {
			.accordion {
				&__body {
					padding-top: 0;
				}
				&__group {
					flex-direction: column;
					padding: 15px 0;
				}
				&__aside {
					flex: 0 0 100%;
					h3 {
						font-size: 18px;
						font-stretch: normal;
						margin-right: 0;
						margin-bottom: 0;
					}
				}
				&__content {
					flex: 0 1 auto;
					.list-dimensions {
						padding: 0;
						margin: 0;
						letter-spacing: -.03em;
						list-style: none;
						li {
							color: #231f20;
							strong {
								color: #231f20;
							}
						}
					}
				}
			}
			@media (max-width: $width-sm-max) {
				.accordion__section {
					padding-bottom: 0;
				}
			}
		}
	}
}