/* ------------------------------------------------------------ *\
Callout
\* ------------------------------------------------------------ */

.callout {
	padding: 44px 38px 45px;
	font-family: $fenice;
	font-size: 32px;
	line-height: 1;
	color: #fff;
	background: $gold-dark;
	i {
		margin-bottom: 34px;
	}
	h2, p {
		margin-bottom: 28px;
		font-style: normal;
	}
	a{
		&.btn-white{
			.new-homes_series &{
				text-decoration: none;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.callout {
		padding: 37px 15px 52px;
		margin: 0 -15px;
		font-size: 25px;
		i {
			margin-bottom: 24px;
		}
		p {
			margin-bottom: 18px;
		}
	}
}


/* ------------------------------------------------------------ *\
	Callout Secondary
\* ------------------------------------------------------------ */
body.house-and-land-landing-page #special-offers-section {
	margin-top: 50px;
}
.callout-secondary {
	position: relative;
	min-height: 423px;
	padding: 8px 0;
	/*margin-bottom: 50px;*/
	border-left: 8px solid $gold-dark;
	border-right: 8px solid #fff;
	background-size: cover;
	background-position: center center;

	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}
	h3 {
		font-family: $graphik;
		font-size: 34px;
		line-height: 1;
		color: #fff;
		margin-bottom: 25px;
		span {
			font-weight: 500;
			color: #000;
		}
	}
	img {
		position: absolute;
		left: 25px;
		bottom: 26px;
	}

	&__btn {
		display: inline-block;
		height: 38px;
		padding: 0 14px;
		border-radius: 3px;
		line-height: 38px;
		letter-spacing: -0.03em;
		color: #fff;
		background: $gold-dark;
		&:hover {
			color: #fff;
			background: #e20609;
		}
	}
	&__inner {
		padding: 31px 27px;
	}

	&:before, &:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 8px;
		left: 0;
		background: rgb(255,62,64);
		background: -moz-linear-gradient(left, rgba(255,62,64,1) 0%, rgba(255,62,64,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left, rgba(255,62,64,1) 0%,rgba(255,62,64,1) 50%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right, rgba(255,62,64,1) 0%,rgba(255,62,64,1) 50%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
	}
}

@media(max-width: $width-sm-max){
	.callout-secondary {
		margin-bottom: 25px;
	}
}

@media(max-width: $width-xs-max){
	.callout-secondary {
		min-height: 211px;
		padding: 4px 0;
		margin: 0 -15px 20px;
		border-left-width: 4px;
		border-right-width: 4px;
		h3 {
			font-size: 17px;
			margin-bottom: 10px;
		}
		&__inner {
			padding: 15px 12px;
		}
		&__btn {
			height: 22px;
			padding: 0 8px;
			font-size: 12px;
			line-height: 22px;
		}
		img {
			max-width: 63px;
			height: auto;
			bottom: 14px;
			left: 12px;
		}
		&:before, &:after {
			height: 4px;
		}
	}
}
