/* ------------------------------------------------------------ *\
	List Links Tertiary
\* ------------------------------------------------------------ */

@media(max-width: $width-sm-max){
	.list-links-tertiary {
		display: flex;
		padding: 0;
		margin: 0;
		list-style-type: none;
		li {
			+ li {
				margin-left: 12px;
			}
		}
	}
}