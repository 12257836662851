/* ------------------------------------------------------------ *\
	Design
\* ------------------------------------------------------------ */

.design {
	transform: translateZ(0);
	overflow: hidden;

	h5 {
		font-family: $graphik;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.02em;
		color: #fff;
	    line-height: 1.3em;
	}
	
	.floorplan-details{
		
		h5{
			color: #333;
		}
	}

	&__select {
		// float: left;
		margin: 4px 0 0 0px;
		font-family: $helvetica;
		letter-spacing: 0.3em;
		list-style: none;

		display: flex;
		flex-wrap: wrap;

		span, a {
			font-family: $graphik;
			letter-spacing: normal;
		}

		body.new-homes_series &, body.house-and-land &, body.readybuilt & {
			float: none;
			margin-left: 0;
			margin-right: 0;
			padding-left: 0;
		}

		@media(max-width: $width-xs-max){
			margin: 0px;
			padding-left: 0px;
			width: 100%;
		}

		&-design {
			margin-bottom: 20px;
			h2 {
				float: left;
				font-size: 14px;
				.new-homes_series &{
					@media(max-width: $width-xs-max){
						margin-bottom: 20px;
					}
				}
			}

			body.new-homes_series &, body.house-and-land &, body.readybuilt & {
				margin-bottom: 0;
				border-bottom: none !important;
				height: auto !important;
				background: transparent;
				padding: 0;
				h2 {
					float: none;
					display: block;
				}
			}
		}
		li {
			margin-bottom: 3px;
			flex: 0 0 33%;
			text-align: center;

			a {
				font-weight: 500;
				line-height: 40px;
				border: 2px solid $sage;
				height: 40px;
				display: block;
				padding: 0 5px;
				color: $sage;
				background: #f2f2f2;
				margin-right: 5px;
				transition: border-color .3s, background .3s;
				font-size: 18px;

				&:hover, &.active {
					text-decoration: none;

					border-color: $sage;
					background: $sage!important;
					color: #fff;
					transition: color .3s, background .3s;

					&.grey{
						background-color: #b2b1b1;
						color:#fff;
					}
				}

				@media (max-width: $width-xs-max) {
					flex: 1 1 20%;
				}

				&.grey{
					border-color: #b2b1b1;
					color: #b2b1b1;
				}
			}
		}
	}

	&__inner {
		display: flex;
		background: #fff;
		/*contain: content;*/
		body.readybuilt & {
			margin-bottom: 57px;
		}

		html.ie11 & {
			min-height: 700px;
			position: relative;
		}
	}
	&__inner-actions {
		display: flex;
		flex-flow: row wrap;
		flex: 0 0 93px;
		padding: 29px;

		ul {
			flex: 0 0 100%;
			padding: 0;
			margin: 0;
			list-style-type: none;

			+ ul {
				align-self: flex-end;
			}
		}
		li {

			+ li {
				margin-top: 13px;
			}
		}
		a {
			display: inline-block;
			transition: opacity .3s;

			&:hover {
				opacity: .7;
				text-decoration: none;
			}

			span {
				display: block;
				font-size: 10px;
				text-align: left;
				line-height: 1.2;
				margin-top: 5px;
				color: $charcoal;
			}
		}

		html.ie11 & {
			position: absolute;
			bottom: 0;
			left: 33%;
			width: 67% !important;

			ul {
				flex: none !important;
				li {
					flex: none !important;
					display: inline-block;
				}
			}
		}
	}
	&__image {
		flex: 0 1 100%;
		padding: 47px 15px 32px 0;
		visibility: hidden;
		img {
			max-width: 100%;
			height: auto;
		}
		.floorplan {
			display: none;
			height: 100%;
			&.active {
				display: block;
				/*visibility: visible;*/
			}
			object {
				width: 100%;
				min-height: 570px;
				height: 570px;
				@media(max-width: $width-xs-max){
					min-height: 1px;
					height: 60vh;
				}
			}
			svg text {
			    -webkit-user-select: none;
			       -moz-user-select: none;
			        -ms-user-select: none;
			            user-select: none;
			}
			svg text::selection {
			    background: none;
			}
		}
	}
	&__group {
		.house-and-land &, .readybuilt &, .display-for-sale_detail &{
			display: flex;

			@media(max-width: $width-xs-max){
				display: block;
			}
		}
		@media(max-width: $width-xs-max){
			.readybuilt &{
				margin-bottom: 0px;
			}
		}
		margin-bottom: 57px;
		// display: flex;
		padding: 28px 67px 27px 29px;
		background: $main-brown;

		body.readybuilt & {
			margin-bottom: 0;
			background: #fff;
		}
	}
	&__aside {
		flex: 0 0 166px;
		padding-top: 2px;
		padding-right: 15px;
	}
	&__content {
		flex: 0 1 100%;
		a{
			color: $sage;
		}
		@media(max-width: $width-xs-max){
			a.view_dimensions {
				padding-left: 150px;
				.readybuilt &{
					padding-left: 0px;
				}
			}
			a.download_brochure{
				    position: absolute;
				    /* top: 15px; */
				    padding-top: 13px;
				    font-weight: 500;
			}
		}
	}
	.choices_and_add_ons {
		padding-bottom: 10px;
		p {
			margin-bottom: 16px;
			a:not(.standard-link) {
				position: relative;
				display: inline-block;
				font-size: 14px;
				letter-spacing: -0.02em;
				font-weight: 400;
				padding-left: 30px;
				max-width: 95%;
				color: #000;
				&:before {
					content: '';
					position: absolute;
					width: 20px;
					height: 20px;
					top: 0px;
					left: 0px;
					background: #fff;
					border: 4px solid #fff;
					border-radius: 50%;
				}
				&.selected {
					&:before {
						background: $main-brown;
					}
				}
			}
		}
		.accordion__col6 {
			width: 50%;
			float: left;
		}
	}
}

.standard-link{
	height: auto;
    padding: 0px;
    margin-bottom: 14px;
    border: none;
    border-radius: 0px;
    line-height: 1.42857;
    font-weight: 300;
    text-decoration: underline;
    color: #333!important;
    background: #fff;
    display: inline;
    
    @media (max-width: $width-sm-max) {
	     padding: 0px!important;
	    
	}    
    
}


body.new-homes_series, body.house-and-land, body.readybuilt {
	.design {
		&__select {
			margin-top: 0;
			margin-bottom: 15px;

			li {
				margin-bottom: 5px;
				a {
					background: transparent;
					/*
					&.active, &:hover {
						background: $main-brown;
					}
					*/
				}
			}
		}
		&__inner {
			display: flex;
			flex-direction: row;
			padding-top: 20px;

			@media (max-width: $width-sm-max) {
				flex-direction: column;

				html.ios10.ipad & {
					height: 120vh;
				}
			}

			&-sidebar {
				flex: 1 1 33.33%;
				padding: 20px;
				padding-top: 0;
				min-width: 0;

				@media (max-width: $width-sm-max) {
					flex: 1 1 100%;
				}
			}
			&-inner {
				flex: 1 1 67%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@media (max-width: $width-sm-max) {
					flex: 1 1 100%;

					html.ios10.ipad & {
						flex: 0 0 100%;
					}
				}
			}
			&-actions {
				flex: 0 0 60px;
				justify-content: flex-start;
				flex-flow: row-reverse;
				align-content: flex-start;
				width: 100%;
				padding-top: 0;
				padding-left: 0;
				padding-bottom: 10px;

				ul {
					flex-direction: row;
					flex: 0 1 120px;
					display: flex;
					height: 100%;

					li {
						margin: 0;
						flex: 0 1 6rem;
						display: flex;
						text-align: center;
						a {
							span {
								text-align: center;
							}
						}
					}
				}

				.ico-download {
					height: 27px;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
				}
			}
		}
		&__image {
			flex: 1 1 100%;
			width: 100%;
			height: 100%;
			max-height: 595px;
			padding-top: 0;
			padding-bottom: 20px;

			@media (max-width: $width-sm-max) {
				padding-left: 15px;
			}

			.floorplan.active {
				object {
					transform: translateZ(0);
				}
			}
		}
		.this-floorplan {
			.accordion__section {
				padding-bottom: 10px;
				&.accordion__section-expanded {
					.accordion__body {
						padding-top: 12px;
					}
				}
			}

			.from-price {
				padding-bottom: 20px;
				border-bottom: 1px solid #c8c8c8;

				h3 {
					margin-right: 0;
					font-style: normal;
					font-size: 38px;

					small {
						font-size: 20px;
						color: $footer-grey;
						display: block;
					}
				}
				
				h3.movein{
					margin-top: 20px;
				}

				h4 {
					&.strike {
						position: relative;
						display: inline-block;

						svg {
							width: 100%;
							height: 60%;
							position: absolute;
							top: 4px;
							left: 0;
							line {
								stroke: $strikethrough-color;
								stroke-width: 2;
							}
						}
					}
				}

				span.toggle-diclaimer {
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}

				p {
					margin-bottom: 0;
				}
			}

			&__dimensions {
				padding-top: 20px;

				h5 {
					font-size: 14px;
					margin-bottom: 0px;
					color: inherit;
				}
				h4 {
					font-size: 22px;
					margin-bottom: 16px;
				}

				p {
					margin-bottom: 0;
				}

				ul {
					list-style: none;
					display: flex;
					justify-content: flex-start;
					align-content: flex-start;
					padding-left: 0;
					padding-top: 4px;
					margin-bottom: 22px;

					li {
						flex: 0 1 33%;
						font-family: $graphik;
						font-weight: 200;
						line-height: 1;
						font-size: 22px;

						span {
							font-family: $graphik;
							font-size: 14px;
							line-height: 1;
							font-weight: 500;
							display: block;
							margin-bottom: 6px;
						}
					}
				}
			}

			&__full-dimensions {
				.accordion {
					&__body {
						padding-top: 0 !important;
						.accordion__group {
							flex-direction: column;
							padding: 15px 0;
						}
					}
					&__aside {
						flex: 0 0 100%;
						h3 {
							font-size: 18px;
							font-style: normal;
							margin-right: 0;
						}
					}
					&__content {
						flex: 0 1 auto;

						html.ie11 & {
							margin-top: 24px;
						}

						ul.list-dimensions {
							color: inherit;
							strong {
								color: inherit;
							}
						}
					}
				}
				.section-design h3 {
					margin-right: 0;
				}

				@media (max-width: $width-sm-max) {
					.list-dimensions {
						column-count: 2;
					}
				}
				@media (max-width: $width-xs-max) {
					.list-dimensions {
						strong {
							width: 20vw;
						}
					}
				}
			}

			&__promotional-banners {
				max-width: 100%;
				height: auto;
				@media (max-width: $width-sm-max) {
					text-align: center;
				}
			}

			.list-info-secondary {
				display: flex;
				justify-content: space-between;
				border-top: 1px solid #c8c8c8;
				border-bottom: 1px solid #c8c8c8;
				padding: 10px 0;
				margin-bottom: 20px;

				li {
					flex: 0 1 auto;
					font-size: 22px;
					+ li {
						margin-left: 0px;
					}

					@media (min-width: $width-sm-max) and (max-width: $width-md-max) {
						margin-right: 5px;
						text-align: center;
						&:last-child {
							margin-right: 0;
						}
					}
				}

				@media (max-width: $width-xs-max) {
					justify-content: space-evenly;

					li {
						margin-bottom: 0;
						text-align: center;
					}
				}
			}

			.list-features {
				display: block;
				li {
					display: block;
					border: none;

					@media (max-width: $width-sm-max) {
						display: inline-block;

						&:first-child {
							font-weight: 500;
						}
					}
				}
			}

			.choices_and_add_ons {
				p a {
					&:before {
						background: #dcdcdc;
						border: 4px solid #dcdcdc;
					}
					&.selected {
						&:before {
							background: $charcoal;
						}
					}
				}
			}
		}
	}
	.section-design__body {
		.design {
			h2.floorplan-title {
				display: none;
			}
			&--secondary-title {
				padding: 20px 20px 0 20px;
				display: block;
				background: #fff;
				font-size: 24px;
				font-weight: 300;
				letter-spacing: 4.2px;

				@media (max-width: $width-xs-max) {
					font-size: 15px;
					letter-spacing: 3.2px;
				}
			}
			&-step__2, &-step__3,  {
				.design--secondary-title {
					background: transparent;
					padding-left: 0;
				}
			}

			.pl-0 {
				padding-left: 0;
			}
			.pr-0 {
				padding-right: 0;
			}

			@media (max-width: $width-xs-max) {
				&.mr-xs-0 {
					margin-right: 0;
				}
			}
		}
	}
	.section__home-design-selection {
		.design--secondary-title {
			padding-left: 0;
		}
	}

	.section-home__sticky-bar {
		background: #e8e8e8;
		height: 90px;
		transition: background-color .4s linear;

		@media (max-width: $width-sm-max) {
			height: auto;
		}

		> .container {
			height: 100%;
		}

		.widget-enquiry {
			box-shadow: none;
			border-radius: 0;
			display: flex;
			transition: all .4s linear;
			height: 100%;
			max-height: 90px;
			align-items: center;

			&__head {
				flex: 0 1 30%;
				background: transparent;
				/*transition: all .4s linear;*/
				padding: 0;
				align-self: center;

				@media (max-width: $width-ipad-max) {
					flex: 0 1 30%;
				}
			}
			&__favourite {
				.series_home_price {
					h3, h4 {
						line-height: 1.1;
						color: #231f20;
						margin-bottom: 0;

						&.has_strike {
							font-weight: 500;
						}

						span.strike {
							position: relative;
							font-size: 75%;
							margin-right: 5px;
							font-weight: 300;

							svg {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								line {
									stroke: $strikethrough-color;
									stroke-width: 2px;
								}
							}

							@media (max-width: $width-sm-max) {
								display: inline-block;
								height: 16px;
							}
						}
					}
				}
				&.selected {
					body.new-homes_series & {
						display: flex;
						align-items: center;
					}

					.series_home_price {
						flex: 0 1 100%;
						color: #000;

						span.strike {
							svg {
								line {
									stroke: $strikethrough-color;
								}
							}
						}
					}
				}
			}
			&__body {
				flex: 0 1 70%;
				display: flex;
				flex-flow: row-reverse;
				justify-content: space-between;
				padding: 0px 0 0 18px;

				@media (max-width: $width-ipad-max) {
					flex: 0 1 70%;
				}

				&-column-1 {
					flex: 0 1 33%;
					display: flex;
				}
				&-column-2 {
					flex: 0 1 50%;
					align-self: center;

					p {
						text-align: left;
						&:first-child {
							margin-bottom: 6px;
						}
					}
				}
			}

			&__btn {
				margin-bottom: 0;
				height: 50px;
				line-height: 50px;
				width: 100%;
				align-self: center;

				@media (max-width: $width-sm-max) {
					height: 40px;
					line-height: 24px;
				}
			}
		}

		&.sticky {
			position: fixed;
			top: 0;
			z-index: 500;
			width: 100%;
			background: #f2f2f2;

			@media (max-width: $width-ipad-max) {
				top: 65px;
			}

			+ .section-sticky-limit {
				margin-top: 90px;

				@media (max-width: $width-ipad-max) {
					margin-top: 65px;
				}

				@media (max-width: $width-sm-max) {
					margin-top: 0;
				}
			}

			@media (min-width: $width-sm-max + 1) {
				.widget-enquiry {
					&__head {
						h3, h4, span {
							color: $charcoal;
						}
					}
					&__favourite.selected {
						.series_home_price {
							color: $charcoal;
						}
					}
					&__btn {
						border-color: $charcoal;
					}
					p {
						color: $charcoal;
						a {
							color: $charcoal;
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

body.new-homes_series {
	.section-home__sticky-bar {
		height: 120px;

		@media (max-width: $width-sm-max) {
			height: 0;
		}

		&.sticky + .section-sticky-limit {
			margin-top: 120px;
		}

		.widget-enquiry {
			max-height: 120px;

			&__head {
				flex: 0 1 50%;

				p.price-selected-options {
					text-align: left;
					display: none;

					&.active {
						display: block;
					}
				}
			}

			&__body {
				flex: 0 1 50%;

				&-column-1 {
					flex: 0 1 100%;
				}
			}
			&__btn {
				margin-left: 10px;
				margin-right: 10px;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}

				.ico-download {
					background-image: url('../images/ico-download-white.png');
				}

				&:hover {
					.ico-download {
						background-image: url('../images/ico-download-teal.png');
					}
				}
			}

			@media (max-width: $width-sm-max) {
				max-height: 90px;

				&__head {
					span.toggle-diclaimer {
						display: block;
					}
				}

				&__header {
					flex: 0 1 30%;
				}

				&__body {
					flex: 0 1 70%;
				}

				&__btn {
					font-size: 16px;
					font-weight: 400;
					line-height: 1.4;
					text-align: center;

					i {
						display: none !important;
					}
				}
			}
			@media (max-width: $width-sm-max) {
				box-shadow: 0 -5px 70px 0px rgba(60, 60, 60, 0.25);
			}
			@media (max-width: $width-xs-max) {
				max-height: 100px;
				flex-direction: row-reverse;
				&__btn {
					line-height: 1.6;
				}
			}
		}
	}
	.design__inner {
		&-inner {
			padding-bottom: 20px;
		}
		&-actions {
			justify-content: center;

			ul {
				li {
					margin-left: 10px;
					margin-right: 10px;
					a {
						width: 75px;
						height: 55px;
						border: 1px solid $sage;
						position: relative;

						i {
							position: absolute;
							top: 33%;
							left: 50%;
							transform: translate(-50%, -50%);
							background-size: cover;
							width: 26px;
							height: 26px;

							&.ico-360 {
								background-size: contain;
								background-position: center center;
								background-repeat: no-repeat;
							}
						}

						span {
							position: absolute;
							bottom: 5px;
							width: 100%;
							font-size: 12px;
						}

						&.flipFloorplan {
							width: 95px;
							transition: background .3s, color .3s;
							i.ico-rotate {
								width: 32px;
							}

							&.active {
								background: $sage;
								i.ico-rotate {
									background-image: url('../images/ico-rotate_white.svg');
								}
								span {
									color: #fff;
								}
							}
						}
						&.downloadPDF {
							width: 115px;
						}
					}
				}
			}
		}
	}
}

/* house and land overrides */
body.house-and-land, body.readybuilt {
	.section-home__sticky-bar {
		.widget-enquiry {
			&__head {
				padding-top: 0;
				padding-bottom: 0;
				display: flex;
				flex-direction: row;
				align-self: center;
			}
			&__favourite {
				flex: 0 1 100%;
				color: #231f20;

				h4 {
					margin-bottom: 0;
				}
			}
		}
		&.sticky {
			.widget-enquiry__favourite {
				color: #fff;
			}
		}
	}
	.design__inner {
		&-actions {
			ul {
				flex: 0 1 5rem;
				li {
					flex: 0 1 100%;
				}
			}
			ul + ul {
				flex: 0 1 10rem;
			}
		}
	}

	.section-design__body {
		.design--secondary-title {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.bg-grey {
		.container:first-child {
			margin-bottom: 57px;
		}
	}
}

body.readybuilt {
	.design__inner {
		&-actions {
			ul:empty {
				display: none;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.design {
		margin: 0 -15px;

		h5 {
			font-size: 12px;
		    max-width: 50%;
			display: inline-block;
		}

		&__inner-actions {
			flex: 0 0 59px;
			padding: 20px 9px 17px 15px;
			li {
				+ li {
					margin-top: 10px;
				}
			}
		}
		&__image {
			padding: 22px 15px 17px 0;
		}
		&__group {
			position: relative;
			// display: block;
			padding: 18px 15px 15px;
		}
		&__aside {
			padding-bottom: 47px;
		}
	}
	.new-homes_series .accordion__section.choices_and_add_ons.accordion__section-expanded { padding-bottom: 30px; }
	.choices_and_add_ons.accordion__section .accordion__col6 { width: 100%; }
}

.design-step {
	&__next-step {
		text-align: center;
		margin-top: 30px;

		.selectRow & {
			margin-top: 0;
		}

		button {
			display: block;
			width: 100%;
			padding-top: 10px;
			padding-bottom: 10px;
			border-radius: 0;

			@media (min-width: $width-xs-min) {
				width: auto;
				margin: 0 auto;
				padding-left: 5rem;
				padding-right: 5rem;
			}

			&.select-facade {
				span {
					&.unselected {
						display: inline-block;
					}
					&.selected {
						display: none;
					}
				}
				&.active {
					background: #fff;
					color: $sage;

					span.unselected {
						display: none;
					}
					span.selected {
						display: inline-block;
					}
				}
			}
		}

		@media (max-width: $width-xs-max) {
			a.btn {
				height: 46px;
				line-height: 22.8571px;
				display: block;
				padding: 12px 15px;
			}
		}
	}

	&__1 {
		margin-bottom: 60px;
		.design-step__next-step {
			padding: 0 20px;
		}
	}
	&__2, &__2-h-and-l, &__3 {
		padding: 60px 0px;
		position: relative;

		&:before {
			content: "";
			z-index: -1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: -50vw;
			right: calc(-50vw + 578px);
			background: #fff;

			@media (max-width: ($width-md-max - 1)) {
				right: calc(-50vw + 470px);
			}
			@media (max-width: ($width-sm-max - 1)) {
				right: calc(-50vw + 360px);
			}

			@media (max-width: ($width-xs-max - 1)) {
				right: 0;
			}
		}

		@media (max-width: $width-sm-max) {
			padding-top: 30px;
			padding-bottom: 30px;
			/* margin-bottom: 30px; */
		}
	}
	&__3 {
		padding-bottom: 0;
		.section__group {
			border-bottom: 0;
		}

		&::before {
			background: inherit;
		}
		.section__head {
			@media (max-width: $width-xs-max) {
				.design--secondary-title {
					padding-left: 15px !important;
				}
			}

			+ .section__body {
				margin-top: 18px;
				padding: 0 15px;

				@media (max-width: $width-sm-max) {
					padding: 0;
				}

				@media (max-width: $width-xs-max) {
					&.pl-xs-1 {
						padding-left: 15px !important;
					}
					&.pr-xs-1 {
						padding-right: 15px !important;
					}
				}
			}
		}
		&-padding-bottom {
			padding-top: 0;
			padding-bottom: 60px;

			@media (max-width: $width-sm-max) {
				padding: 0 15px 30px;
			}
		}
	}
	&__final {
		display: none;
		position: relative;
		padding: 60px 0px;
		@media (max-width: $width-sm-max) {
			padding: 30px 15px;
		}
		.home-design-selection {
			margin: 20px 0;
			padding: 20px 0;
			border-top: 1px solid $body-text-color;
			border-bottom: 1px solid $body-text-color;
			display: flex;

			> div {
				flex: 1 1 auto;
				p.small {
					margin-bottom: 4px;
				}
				p.value {
					font-size: 133%;
					margin-bottom: 0;
				}
			}
			&__buttons {
				flex: 1 1 20%;
				text-align: right;

				a.btn {
					margin-right: 10px;
					height: 46px;
					padding: 12px 15px;

					&:last-child {
						margin-right: 0;
					}

					&.widget-enquiry__btn {
						margin-bottom: 0;
						line-height: 22.8571px;
						display: inline-block;
					}

					&:hover {
						background: $grey-background-light;
					}
				}
			}

			@media (max-width: $width-sm-max) {
				flex-direction: row;
				flex-wrap: wrap;

				> div {
					flex: 0 0 33%;
				}

				&__buttons {
					flex: 1 1 auto !important;
					text-align: center;
					margin-top: 20px;
				}
			}

			@media (max-width: $width-xs-max) {
				> div {
					flex: 0 0 100%;
					margin-bottom: 20px;
				}

				&__buttons {
					margin-top: 0;
				}

				&__download-pdf {
					margin-bottom: 10px;
					margin-right: 0 !important;
					line-height: 22.8571px;
					padding: 0 15px;
					display: block;
				}

				a.btn.widget-enquiry__btn {
					display: block;
				}
			}
		}
	}
}
#facadesSelection {
	.slick-slider {
		width: 100vw;

		@media (max-width: $width-xs-max) {
			margin-right: 0;

			.slider-images {
				margin-bottom: 0;
			}
		}

		.slick-slide {
			width: 100vw;
			max-width: 1140px;
			position: relative;

			.slide_bg {
				position: relative;
				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 10;
					background-color: rgba(60,60,60,.89);

					@media (max-width: $width-sm-max) {
						display: none;
					}
				}
			}

			&.slick-current {
				.slide_bg {
					&:before {
						display: none;
					}
				}
			}

			.slide-bottom {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background: linear-gradient(transparent, rgba(60, 60, 60, 0.6) 50%);
				padding: 60px 15px 10px;
				color: #fff;

				p {
					color: #fff;
					line-height: 1.4;
				}

				@media (max-width: $width-xs-max) {
					padding-top: 15px;
					padding-bottom: 5px;
				}
			}
		}
	}
	
	&.letterbox{
		
		.slide_bg{
			max-height: 510px!important;
		}
	}
}

.new-homes__select-steps {
	padding-top: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid black;

	ul {
		text-align: center;
		margin-bottom: 0;
		padding-left: 0;

		li {
			display: inline-block;
			color: $sage;
			list-style: none;
			padding: 0 50px;
			font-size: 20px;
			opacity: 0.5;

			@media (max-width: $width-lg-max) {
				padding: 0 25px;
			}

			&.active {
				opacity: 1;
			}

			i {
				margin-right: 15px;
				vertical-align: middle;
			}

			span {
				vertical-align: middle;
			}

			@media (max-width: $width-sm-max) {
				font-size: 15px;
				padding: 0 15px;
			}

			@media (max-width: $width-xs-max) {
				text-align: left;
				padding: 0;
				display: block;
				margin-bottom: 20px;
				font-size: 20px;

				&:last-child {
					margin-right: 0;
				}

				i {
					width: 75px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
		}
	}
}

/* eligible for widget */
.eligible-for {
	&__panel {
		margin-top: 10px; 
		border: 1px solid #666; 
		padding: 15px; 
		background: #efefef; 
		h4 {
			font-weight: 500; 
			margin-bottom: 15px; 
		}
		ul {
			list-style: none; 
			padding-left: 0; 
			li {
				display: flex; 
				flex-wrap: nowrap; 
				justify-content: space-between; 
				label {
					font-weight: 300; 
				}
				input[type=checkbox] {
					margin-top: 0; 
					appearance: none; 
					width: 16px; 
					height: 16px; 
					border: 1px solid #000; 
					border-radius: 0; 
					position: relative; 
					background: #fff; 
					&:checked {
						&:after {
							display: block; 
							content: '✓'; 
							position: absolute; 
							width: 16px; 
							height: 16px; 
							top: 0; 
							left: 0; 
							color: green; 
							font-weight: 500; 
							font-size: 14px; 
							text-indent: 2px; 
							line-height: 1.2; 
						}
					}
				}
			}
		}
	}
	&__price {
		display: none; 
		text-transform: uppercase; 
		font-size: 18px; 
		font-weight: 300; 
		span#eligible-for__after-grants { 
			display: block; 
			font-size: 48px; 
			font-weight: 200; 
			letter-spacing: -.05em; 
			line-height: 1; 
		}
	}
}

/* q collection additions */
main.q-collection {
	.widget-enquiry__btn {
		background-color: $q-collection-color; 
		&:hover {
			background-color: #fff; 
			color: $q-collection-color !important;
			body.new-homes_series &, body.house-and-land & {
				.ico-download {
					background-image: url(../images/ico-download-teal.png); 
				}
				.ico-envelope {
					background-image: url(../images/ico-envelope-teal.png); 
				}
			}
		}
	}
}