/* ------------------------------------------------------------ *\
	LIST RADIOS TERTIARY
\* ------------------------------------------------------------ */

.list-radios-tertiary {
	margin-bottom: 40px;
	padding-left: 0;

	@media (max-width: $width-xs-max) {
		margin-bottom: 20px;
	}
	li {
		display: inline-block;
		overflow: hidden;
		margin-right: 44px;
		@media (max-width: $width-xs-max) {
			display: block;
			margin-right: 0;
			width: 100%;
		}
	}
	label {
		padding-left: 33px;
		letter-spacing: -0.02em;
	}
	label:before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		overflow: hidden;
		background: #fff;
		border: 4px solid  #fff;
		position: absolute;
		left: 0;
		top: -1px;
	}
	input:checked ~ label:before { background: $gold-dark; }
}

.list-radios--quinary {
	margin-bottom: 12px;
}

