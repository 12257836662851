.gallery{
	&__heading {
		margin-bottom: 3rem;
		padding-bottom: 3rem;
		border-bottom: 1px solid #333;
		font-size: 36px;

		@media (max-width: $width-xs-min) {
			margin-bottom: 1rem;
		}
	}

	fieldset > .col-md-12 {
	    padding: 0px;
	}
	select{
		cursor: pointer;

			option{

				display: none;
				&.on{
					display: block;
				}
				&:first-child{
					display: block;
				}
			}

	}
	.houses-filter__sidebar{
		.overlay{

		}
		.filters{
			form{
				fieldset{
					.label-radio{
						label{
							padding-left: 8px !important;
							@media(max-width: $width-sm-max){
							    padding: 8px 17px !important;
							}
							&.disabled{
								color: #b2b1b1;
							}
						}
					}

				}
			}
		}
	}
	.grid{
		min-height: 650px;
		margin-left: 0px;margin-right: -30px;
		 @media(max-width: $width-xs-max){
			    margin-left: -15px;
				margin-right: -15px;
		 }
		 .gutter-sizer{
			 width: 30px;
			 @media(max-width: $width-xs-max){
				 width: 5px;
			 }
		 }
		.grid-item{
			float: left;
			cursor: pointer;
			transition: opacity .3s;
	/* 		margin-bottom: 30px; */
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			.pinterest_button{
		    font-size: 25px;
		    height: 25px;
		    width: 20px;
		    position: absolute;
		    opacity: 0;

		    top: 10px;
		    left: 10px;
		    cursor: pointer;
		    transition: opacity .3s;
			    i{
				    top: 0px;
					position: absolute;
			    }
		   		@media(max-width: $width-xs-max){
					    opacity: 1;
				}
			}
			&:hover{
				.pinterest_button{
					opacity: 1;
				}
			}
			&.big {
				width: calc(66.666666% - 30px);
			    /* padding-bottom: 43.305%; */
			    display: inline-block;
				height: 380px;


			}

			&.portrait{
			    width: calc(33.3333333% - 30px);

				display: inline-block;
			    height: 380px;

			    position: relative;


		    }
		    &.small{
			    width: calc(33.3333333% - 30px);
				/* padding-bottom: 43.305%; */
				display: inline-block;
			    height: 175px;

			    position: relative;
			    &.no-count{
				    height: 1px;
			    }


		    }
		    @media(max-width: $width-md-max){
			    &.portrait, &.big{
				 	height: 424px;
				 }
				 &.small{
				 height: 197px;
				 }
		    }
		    @media(max-width: $width-sm-max){
				 &.portrait, &.big{
				  	height: 324px;
				  }
				   &.small{
					 height: 147px;
					 }
			}
		    @media(max-width: $width-xs-max){

			    &.portrait{
				    height: auto;
				    width: calc(50% - 2.5px);
				    &:before{
					 	padding-bottom: calc(134.1176% + 5px);
					 	content: '';
					 	width: 100%;
					 	display: block;
				 	}
			    }
			    &.small{
				 	width: calc(50% - 2.5px);
				 	height: auto;
				 	&:before{
					 	padding-bottom: 67.0588%;
					 	content: '';
					 	width: 100%;
					 	display: block;
				 	}

				 }
				 &.big{
				 	width: 100%;
				 	height: auto;
				 	  &:before{
					 	padding-bottom: 67.0588%;
					 	content: '';
					 	width: 100%;
					 	display: block;
				 	}
				 }
		    }

		}
		.play-vimeo{
		    left: 50%;
		    position: absolute;
		    top: 50%;
		    transform: translate(-50%,-50%);
		    width: 60px;
		    height: 60px;

		}
		iframe{
			width: 100%;
			position: absolute;
		    height: 100%;
		    top: 0;
			display: none;
			opacity: 0;
			transition: opacity .3s;

			&.active{
				display: block;
				opacity: 1;
				background: #fff;
			}
		}
		h3{
			font-family: $graphik;
			padding: 0px 15px;
			@media(max-width: $width-xs-max){
				br{display: none;}
			}
			span{
				color: $main-brown;
				cursor: pointer;
			}
		}
		&.hover-grid-item{
			.grid-item{
				opacity: 0.5;

				&:hover{
					opacity: 1;
				}
			}
		}

	}
	.load-more{
	    display: block;
	    margin: auto;
	    margin-top: 50px;
	}
  #modal-gallery{
	  .modal-close{
		  @media(max-width: $width-sm-max){
			      top: 10px;
				  right: 10px;
		  }
		   	.ico-cross{
		      width: 17px;
			  height: 17px;
	  		}
	  }

	  .form-enquiry-secondary{
		  padding: 90px 0px;
		      background: hsla(0,0%,96%,1);
		    @media (max-width: 1280px) {
			    padding: 40px 0px;
			}
		  @media(max-width: $width-xs-max){
			    height: calc(70vh - 20px);
			    padding: 60px 0px 0px;

		   }
		    @media(max-width: $width-sm-max){
				.container{
			    	width: auto;
		    	}
		    }
	  }
	  .slider-arrow-styles{
		  position: absolute;
		      width: 100%;
		      height: 70vh;
/*
		  width: 100%;
		  padding: 0px;
*/
		  .slick-arrow {
				position: absolute;
				width: 30px;
				height: 30px;
				top: 50%;
				transform: translateY(-50%);
				transition: opacity .3s;
				z-index: 10;
				font-size: 0;
				line-height: 0;
				text-indent: -4004px;
				&:hover {
					opacity: .7;
				}
				@media(max-width: $width-sm-max){
					display: none;
				}
				&:before{
					content: "";
				}

			}
			.slick-prev {
				left: 20px;
				background: url(../images/arrow-left-gold.png) no-repeat 0 0;
				background-size: 100% 100%;

				@media(max-width: $width-sm-max){
					left: 20px;
				}
			}
			.slick-next {
				right: 20px;
				background: url(../images/arrow-right-gold.png) no-repeat 0 0;
				background-size: 100% 100%;

				@media(max-width: $width-sm-max){
					right: 20px;
				}
			}


	  }
	  .slider-counter-styles{
		   position: relative;
		   .slider-images__counter{
				bottom: 50px;
				font-size: 14px;
				right:15px;
			}
	  }

	  .large_image{
		 // min-height: 500px;
		     position: relative;
		     .l_image_container{
			     position: relative;
			     .pinterest_button{
				   font-size: 35px;
				    height: 35px;
				    width: 30px;
				    position: absolute;
				    opacity: 0;
				    top: 10px;
				    left: 10px;
				    cursor: pointer;
				    transition: opacity .3s;
				    i{
					    top: 0px;
					    left: 0;
						position: absolute;
				    }
			   		@media(max-width: $width-xs-max){
						    opacity: 1;
					}
				}
				&:hover{
					.pinterest_button{
						opacity: 1;
					}
				}
		     }

			 .play-vimeo{
			    left: 50%;
			    position: absolute;
			    top: calc(50% - 50px);
			    transform: translate(-50%,-50%);
			    width: auto;
			    height: auto;
			    z-index: 1;
			    min-height: initial !important;
			     z-index: 1;
			}
			.iframe_container{
				width: 100%;
				position: absolute;
			    height: 100%;
			    top: 0;
				display: none;
				opacity: 0;
				transition: opacity .3s;
				left:50%;
				z-index: 1;
				transform: translateX(-50%);
				z-index: 1;
				background: #f5f5f5;
				&.active{
					display: block;
					opacity: 1;
				}
				iframe{
					width: 100%;
					position: absolute;
				    height: 100%;
				    top: 0;
				    left: 0px;
				}
			}
		  .image_wrapper{
				display: none;
				position: relative;
				    text-align: center;
				&.active{
					display: block;
				}
				.l_image_container{
					display: inline-block;
					min-height: 40vh;
					img{
						    max-width: 100%;
						    max-height: 70vh;
						    /* margin: auto; */
						    display: inline-block;

					}
				}

		  	}
	  }
	  .text_container{
		  	text-align: left;
			padding-top: 20px;
			font-size: 14px;
			min-height: 79px;
// 		    margin-left: 20px;
		    @media(max-width: 1400px){
			     margin-left: 0px;
		    }
			.tags{
				color: #8c8c8c;
				a {
			        padding: 5px;
					padding-top: 6px;
				    border: 2px solid #8c8c8c;
				    margin-right: 5px;
				    border-radius: 0px;
				    display: inline-block;
					margin-bottom: 3px;
					line-height: 1em;
					color: #8c8c8c;
					&:hover{
						text-decoration: none;
					}
				}
			}
			@media(max-width: $width-xs-max){
					text-align: center;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 12px;
				}
		}
		  .slider-images-secondary{
			margin-bottom: 0px;
			@media(max-width: $width-xs-max){
				top: 50%;
				transform: translateY(-50%);
			}
			.slick-prev {
				left: 20px;
				background-image: url(/resources/images/arrow-left-gold.png);

			}
			.slick-next {
				right: 20px;
				background-image: url(/resources/images/arrow-right-gold.png);
			}
			.slider-images__counter{
				right: 10px;
				bottom: -20px;
				    color: #333;
			}

			.slick-slide{
				&:not(.slick-current){
					.slider_bg_overlay{
//						background-color: rgba(0,0,0,.6);
						background-color:rgba(255,255,255,0.75);
					}
				}

				height:200px;
				width: 200px;
				@media(max-width: $width-sm-max){
					height: 150px;
					width: 150px;
				}
				@media(max-width: $width-xs-max){
					height: auto;
					width: auto;

				}


				.img-container{
					height: 100% !important;
					width: 100%;
					position: relative;
					@media(max-width: $width-xs-max){
							height: auto!important;
						    width: 100vw !important;
						    padding: 0px 20px;
					}
					.image{
						height: 100% !important;
						width: 100%;
						background-position: center center;
						background-size: cover;
						background-repeat: no-repeat;
						@media(max-width: $width-xs-max){
							height: auto!important;
						    width: 100% !important;

						    &.portrait{
						        max-height: 50vh;
							    width: auto !important;
							    max-width: 100%;
							    margin: auto;

						    }

						}
						@media(max-width: $width-xs-max) and (orientation:landscape) {
							width: auto !important;
						    max-width: 100%;
						    margin: auto;
						    max-height: 80vh;
						    &.portrait{
							    max-height: 80vh;
						    }
						}
					}


				}

			}


			.slick-arrow{
				top: 50%;
			    margin-top: 0px;
			    @media(max-width: $width-sm-max){
					width: 30px;
					height: 30px;
				}
			    &:before{
				    content: '';
			    }
			}
		}
		.mobile-text-slider{
			    position: absolute;
			       width: 100%;
			        height: calc(30vh - 20px);
			    background: hsla(0,0%,96%,.95);
				    overflow: scroll;
			.text_container{
				display: none;
				&.active{
					display: block;
				}
			}
		}
		@media(max-width: $width-xs-max) and (orientation:landscape) {

			.mobile-text-slider{display: none !important;}
			.form-enquiry-secondary {padding: 0px;    height: calc(100vh - 40px);}
			.modal-close{z-index: 10;}
		}

  	}

}