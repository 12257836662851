/* ------------------------------------------------------------ *\
Section
\* ------------------------------------------------------------ */

.section {
	padding: 99px 0 40px;
	h2 {
		font-size: 32px;
		letter-spacing: 0.03em;
		font-style: italic;
		margin-right: 93px;
	}

	&__head {
		display: flex;
		align-items: center;
	}
	&__group {
		margin-bottom: 19px;
		border-bottom: 1px solid #c9c9c9;

	}
	&__entry {
		padding: 33px 0 23px;
		font-family: $graphik;
		font-size: 32px;
		    line-height: 36px;
		letter-spacing: 0.02em;

		a {
			font-style: italic;
		}
	}
}

.section-quinary {
	padding-bottom: 73px;
}

@media(max-width: $width-sm-max){
	.section {
		padding-top: 50px;
	}
}

@media(max-width: $width-xs-max){
	.section {
		padding: 48px 0 20px;
		h3 {
			font-size: 25px;
			margin: 0 0 10px;
		}

		&__head {
			display: block;
		}
		&__entry {
			padding-bottom: 8px;
			font-size: 25px;
		}
	}
}




/* ------------------------------------------------------------ *\
	Section Red
\* ------------------------------------------------------------ */

.section-red {
	padding: 46px 0;
	background: $gold-dark;
	h3 {
		font-size: 32px;
		line-height: 1;
		em {
			display: block;
			color: $main-brown;
		}
	}
	&__head {
		margin-bottom: 31px;
	}
	&__body {
		display: flex;
		justify-content: space-between;
	}
	&__content {
		display: flex;
		padding-right: 53px;
		color: #fff;
	}
	&__inner {
		& + & {
			margin-left: 53px;
		}
		p {
			margin-bottom: 16px;
		}
		.btn {
			min-width: 0;
			padding: 0 13px;
			border-width: 1px;
			line-height: 35px;
			font-weight: 400;
		}
	}
	&__aside {
		flex: 0 0 390px;
		color: #fff;
	}
}

@media(max-width: $width-sm-max){
	.section-red {
		h3 {
			font-size: 24px;
		}
		&__aside {
			flex: 0 0 300px;
		}
		&__inner {
			font-size: 12px;
			& + & {
				margin-left: 20px;
			}
		}
		&__content {
			padding-right: 0;
		}
	}
}



.additional-inclusion{
	margin-top: 90px;
	padding-top: 15px;
	border-top: 2px solid #c9c9c9;
	border-bottom: 0px;
	@media(max-width: $width-sm-max){
		margin: 30px 15px;
		.new-homes_series &{
			margin: 30px 0px;
		}
	}
	.section__head{
		display: block;
		h2{
			display: block;
			margin-bottom: 28px;
		}
		.lightgrey{
			color: #C0C0C0;
		}
		h3.title-tag{
			font-size: 23px;
			font-style: normal;
			color: $sage;
			padding: 19px 0px;
			border-bottom: 1px solid $sage;
			border-top: 1px solid $sage;margin: 0px;
			margin-bottom: 37px;
		}

	}
	.section__body{
		> div{
			padding: 0px;
		}
		.button-gold{
			width: 275px;
			display: block;
			text-align: center;
			margin-top: 10px;
		}
		p{
			padding-bottom: 12px;
			padding-right: 40px;
		}
	}


}

/* ------------------------------------------------------------ *\
	New Homes Select Facade section
\* ------------------------------------------------------------ */
.section__select-facade {
	border-bottom: none;

	.slider-images {
		margin-bottom: 20px;
		.slide {
			&_bg {
				background-position: center center;
				background-size: cover;
			}
		}
	}

	&-continue {
		text-align: center;
		display: none;

		.slick-slide.slick-current & {
			display: block;
		}

		@media (max-width: $width-xs-max) {
			margin-top: 10px;

			button {
				display: inline-block;
				width: 80%;
			}
		}
	}

	@media (max-width: $width-xs-max) {
		margin-left: 15px;
		margin-right: 15px;
	}
}
.section__home-design-selection {
	border-bottom: none;
	&:before {
		content: "";
		z-index: -1;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -50vw;
		right: calc(-50vw + 578px);
		background: #fff;

		@media (max-width: ($width-md-max - 1)) {
			right: calc(-50vw + 470px);
		}
		@media (max-width: ($width-sm-max - 1)) {
			right: calc(-50vw + 360px);
		}

		@media (max-width: ($width-xs-max - 1)) {
			right: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	New Homes 360 degree view iframes
\* ------------------------------------------------------------ */
.section__group#virtual-tours {
	margin-top: 28px; 
	padding-top: 28px; 
	border-bottom: none; 
	border-top: 1px solid #c9c9c9;

	body.readybuilt_lot {
		margin-top: 0; 
		padding-top: 0; 
	}

	.section__head {
		margin-bottom: 20px; 
	}

	.section__body {
		&.virtual-tours {
			position: relative; 
			padding-top: 67%; 
			margin-bottom: 50px; 
			background: $charcoal; 

			&:last-child {
				margin-bottom: none; 
			}

			h2 {
				padding-top: 0; 
			}
			h3 {
				margin-top: 12px; 
				margin-bottom: 0; 
				font-size: 16px; 
				font-weight: bold; 
				font-style: normal; 
			}
			.iframe-container {
				padding-bottom: 0; 
				position: absolute; 
				top: 0; 
				right: 0; 
				bottom: 0; 
				left: 0; 
				margin: 0; 
				background: rgb(20, 20, 20);
			}

			.virtual-tour {
				&__load {
					background-position: center center; 
					background-repeat: no-repeat; 
					background-size: cover; 
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					background: rgb(20, 20, 20);
					opacity: 1; 
					visibility: visible; 
					transition: opacity .4s ease-in-out, visibility .4s ease-in-out;
					z-index: 3; 
					&:after {
						content: ''; 
						display: block; 
						position: absolute; 
						z-index: 2; 
						top: 0; 
						right: 0; 
						bottom: 0; 
						left: 0; 
						background-color: rgba(255, 255, 255, 0.75); 
						box-shadow: inset 0 0 120px 20px rgba(0, 0, 0, 0.25);
					}
					&:hover {
						text-decoration: none; 
					}
					&.fadeHide {
						opacity: 0; 
						visibility: hidden; 
					}
					
					h3 {
						margin-right: 0; 
						margin-top: 0; 
						font-size: 22px; 
						color: $charcoal; 
						margin-bottom: 20px; 
						z-index: 5; 
					}
					span {
						z-index: 5; 
					}

					body.readybuilt_lot & {
						padding: 1em; 
						@media (max-width: $width-xs-max) {
							h3 {
								text-align: center; 
							}
						}
					}
				}
			}
		}
	}
}