.image-grid{
	.img-container{
		    text-align: center;
		img{
			max-width: 100%;
			max-height: 80px;
		}
	}

	.clearboth{
		clear: both;
		    margin-bottom: 40px;
	}

	.transform-left-with-widget {
		img {
			max-width: 100%;
		}
	}
	
	.container{
			
		&.no-pad{
			padding-left:0;
			padding-right:0;
		}
	}

	
}