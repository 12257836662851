/* ------------------------------------------------------------ *\
	Sticky Limit for product index page
\* ------------------------------------------------------------ */
.section-sticky-limit {
	position: relative;
	article.article {
		&:first-of-type {
			padding-top: 0;
		}
	}
	.slider-images-secondary {
		margin-bottom: 60px;
		body.general & {
			margin-bottom: 0;
		}
	}
}
