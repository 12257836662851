/* ------------------------------------------------------------ *\
	INTRO BG
\* ------------------------------------------------------------ */

.intro-bg {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(35, 31, 32, 0.3);
	}
	&--small {
		/*height: 515px;*/
		.intro-bg__content {
			h1 {
				margin-bottom: 12px;
				text-transform: uppercase;
			}
			p {
				font-weight: 500;
			}
		}
		@media (max-width: $width-xs-max) {
			height: 300px;
			min-height: inherit;
			max-height: inherit;
			br {
				display: none;
			}
		}
	}

	&__content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		transform: translateY(-50%);
		color: #fff;
		font-size: 14px;
		h1 {
			margin-bottom: 20px;
		}
	}

	&__actions {
		position: absolute;
		bottom: 22px;
		left: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		letter-spacing: -0.019em;
		a {
			color: #fff;
/*

			text-shadow: 0 1px 3px rgba(0, 0, 0, .6);
			font-weight: 500;
*/
			&:hover {
				text-decoration: none;
				color: $sage;
			}
		}
		.separator {
			padding: 0 6px;
		}
		@media (max-width: $width-xs-max) {
			bottom: 0;
			.separator {
				display: none;
			}
			a {
				.display-centre-single &{
					display: inline-block;
					margin-left: 5px;
					&:first-child{
						margin-left: 0px;
					}

				}
				display: block;
				text-align: center;
			}

		}
	}
	.intro_tint {
		position: absolute;
	    width: 100%;
	    height: 100%;
	    background-color: rgba(0,0,0,.3);
	}

	.section-icons {
		position: absolute;
		width: 100%;
		text-align: center;
		bottom: 40px;
		height: 30px;
		background: transparent;

		ul.header-icons {
			padding: 10px 20px;
			background: rgba(51,51,51,.7);
			border-radius: 5px;
			margin: 0 auto;
			text-align: center;
			width: auto;
			display: inline-block;

			li {
				display: inline-block;
				margin: 0 12px;

				.threesixty {
					display: inline-block;
					width: 30px;
					height: 30px;
					background: url(/resources/images/360view.svg) no-repeat;
					background-size: contain;
					text-indent: -999em;
				}
				.floorplan {
					display: inline-block;
					width: 30px;
					height: 30px;
					background: url(/resources/images/Floorplan.svg) no-repeat;
					background-size: contain;
					text-indent: -999em;
				}
				.gallery {
					display: inline-block;
					width: 30px;
					height: 30px;
					background: url(/resources/images/Image.svg) no-repeat;
					background-size: contain;
					text-indent: -999em;
				}
				.video {
					display: inline-block;
					width: 30px;
					height: 30px;
					background: url(/resources/images/Video.svg) no-repeat;
					background-size: contain;
					text-indent: -999em;
				}
			}
		}
	}
}