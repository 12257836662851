/* ========================================
            color vars
========================================== */

$main-brown : #4a0e2b;/* old $main-brown; #30251d */
$light-brown :  #6e6761;
$maroon : #4a0e2b;
$charcoal : #595959;
$menu-expand-color : #000;
$button-shadow-color : $main-brown;
$grey-background-light : rgb(242, 242, 242);
$grey-background-dark : rgb(213,213,213);
$grey-dark-hover:  #e9e9e9;
$grey-dark : #b4b4b4;
$header-grey: #646464;
$gold-dark : #ad9554;
$footer-grey: #6b6d67;
$accordion-grey:#9c928e;
$footer-grey-divider: rgba(#6a6c66, 0.21);
$body-text-color:#646464;
$map-popup-grey:#e6e6e6;
$feature-grey: $map-popup-grey;
$sage:#497E66;
$white: #fff;
$red:#cc0000;

$property-slider-background: #cfcfcf;
$strikethrough-color: #cc0000;

$q-collection-color: #89b3a2;
$q-collection-banner-color: #93c0ae;
$teal-dark: $q-collection-color;

/* ========================================
            width vars
========================================== */
$width-lg-max: 1400px;
$width-md-max: 1200px;
$width-ipad-max: 1024px;
$width-sm-max: 992px;
$width-xs-max: 767px;
$width-xxs-max: 380px;
$width-md-min: 1201px;
$width-sm-min: 993px;
$width-xs-min: 768px;

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

$fontRoot: 'https://plantationhomes.com.au/resources/fonts';
$akkurat: "Akkurat", sans-serif;
$graphik: "Graphik-Regular", sans-serif;
$chronicle: "Chronicle Display A", "Chronicle Display B";
$helvetica: 'Helvetica Neue', Arial, sans-serif;
$fenice: "ITC Fenice W01";

/* ------------------------------------------------------------ *\
	Intro heights
\* ------------------------------------------------------------ */
$intro-vh-height: 35vh;
$intro-sm-height: 15vh;
$intro-mobile-height: 50vh;
$intro-min-height: 210px;
$intro-max-height: 320px;
$md-intro-min-height: 98px;
$md-intro-max-height: 225px;
$xs-intro-min-height: 50px;
$xs-intro-max-height: 98px;

/* ------------------------------------------------------------ *\
	Inclusion slider width
\* ------------------------------------------------------------ */
$inclusion-slider-xs-max: 460px;

