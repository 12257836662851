/* ------------------------------------------------------------ *\
List Stats
\* ------------------------------------------------------------ */

.list-stats {
	/*display: flex;
	flex: 0 1 auto;
	align-items: center;*/
	display: block;
	padding: 0;
	margin: 0 0 12px 0;
	list-style-type: none;
	li {
		display: inline-block;
		+ li {
			margin-left: 52px;
		}
		img {
			width: 90px;
			@media (max-width: $width-sm-max) {
				width: 70px;
			}
			@media (max-width: $width-xs-max) {
				width: 50px;
			}
		}
	}
}

@media(max-width: 1280px){
	.list-stats {
		li {
			+ li {
				margin-left: 30px;
			}
		}
	}
}

@media(max-width: $width-sm-max){
	.list-stats {
		justify-content: flex-end;
		text-align: center;
		img {
			width: 100%;
			height: auto;
		}
		li {
			flex: 0 0 50px;
		}
	}
}

@media(max-width: $width-xs-max){
	.list-stats, #modal-contact-form .list-stats .form-enquiry-secondary, #modal-enquiry-form .list-stats .form-enquiry-secondary {
		justify-content: center;
		flex-flow: row wrap;
		margin-left: -5px;
		margin-right: -5px;
		li {
			margin: 0 5px 30px !important;
			+ li {
				margin: 0 5px 30px !important;
			}
		}
	}
}