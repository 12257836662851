/* ------------------------------------------------------------ *\
	Form Enquiry
\* ------------------------------------------------------------ */

.form-enquiry {
	position: relative;
	display: block;
	margin-bottom: 18px;

	i {
		position: absolute;
		top: 50%;
		right: 18px;
		transform: translateY(-50%);
	}

	&__field {
		display: block;
		width: 100%;
		height: 52px;
		padding: 0 62px 0 19px;
		border: 1px solid #cacaca;
		font-size: 16px;
		letter-spacing: -0.02em;
		color: $charcoal;

/*
		input, select, textarea{
			font-size: 16px;
		}
*/

		&::-webkit-input-placeholder { color: inherit !important; opacity: 1 !important; }
		&::-moz-placeholder { color: inherit !important; opacity: 1 !important; }
		&:-moz-placeholder { color: inherit !important; opacity: 1 !important; }
		&:-ms-input-placeholder { color: inherit !important; opacity: 1 !important; }
	}
}

/* ------------------------------------------------------------ *\
	Section Design
\* ------------------------------------------------------------ */

.section-design {
	padding: 57px 0 100px;

	.readybuilt &, .new-homes_series &{
		padding-bottom: 0px;
	}
	#productLayout &{
		padding-top: 0px;
		padding-bottom: 0 !important;

		.bg-grey{
			padding-top: 57px;
			background-color: $grey-background-light;
		}
	}

	h3 {
		font-size: 32px;
		font-style: italic;
		color: #231f20;
		margin-right: 109px;
	}

	&__head {
		display: flex;
		align-items: center;
		padding-bottom: 21px;
	}
}

@media(max-width: $width-sm-max){
	.section-design {
		padding-bottom: 70px;
	}
}

@media(max-width: $width-xs-max){
	.section-design {
		padding: 30px 0 0;

		h3 {
			font-size: 25px;
			margin: 0 0 12px;
		}

		&__head {
			display: block;
		}
	}
}

