/* ------------------------------------------------------------ *\
	Intro Banner Options
\* ------------------------------------------------------------ */
.intro-full {
	$theHeight: calc(100vh - 183px);
	height: $theHeight;
	max-height: $theHeight;
	@media (max-width: $width-sm-max) {
		$theHeight: calc(100vh - 65px);
		height: $theHeight;
		max-height: $theHeight;
	}
	@media (max-width: $width-xs-max) {
		$theHeight: calc(100vh - 47px);
		height: $theHeight;
		max-height: $theHeight;
	}
}