/* ------------------------------------------------------------ *\
	Townhomes
\* ------------------------------------------------------------ */

.townhomes {
	&-list {

	}
	&-listing {
		display: block;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		> a {
			flex: 0 0 100%;
			display: flex;
			color: #231f20;
			text-decoration: none;

			@media (max-width: $width-xs-max) {
				flex-direction: column;
			}
		}
		&__thumb {
			flex: 0 1 50%;

			@media (max-width: $width-xs-max) {
				flex: 0 0 100%;
			}

			.thumbnail_image {
				width: 100%;
				height: 100%;
				min-height: 300px;
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
		&__info {
			flex: 0 1 50%;
			padding: 20px;
			background-color: $grey-dark-hover;
			transition: .4s background-color;

			@media (max-width: $width-xs-max) {
				flex: 0 0 100%;
			}

			.townhomes-listing a:hover & {
				background-color: darken($grey-dark-hover, 5%);
			}

			h3 {
				font-size: 30px;
			}
			h4 {
				font-size: 24px;
			}

			@media (max-width: $width-xs-max) {
				h3 {
					font-size: 24px;
				}
				h4 {
					font-size: 18px;
				}
			}
		}
		&__specs {
			margin-top: 35px;
			margin-bottom: 35px;

			@media (max-width: $width-xs-max) {
				margin-top: 10px;
				margin-bottom: 10px;
			}

			.property-item__icons {
				margin-bottom: 0;
				span {
					font-size: 18px;
					font-family: $graphik;
					&:first-child {
						padding-left: 0;
					}
					&:first-child {
						padding-right: 0;
					}

					@media (max-width: $width-xs-max) {
						img {
							width: 26px;
						}
					}
				}
			}
		}
		p {
			font-size: 18px;
			font-family: $graphik;
			strong {
				font-weight: 700;
			}

			span.btn {
				margin-left: 3em;
				min-width: 120px;
				background: #000;
				color: #fff;
				border: 1px #000 solid;

				&:hover {
					color: #000 !important;
					background: #fff;
					
				}
			}

			@media (max-width: $width-xs-max) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				span.btn {
					margin-left: 0;
				}
			}
		}
	}
}

body.readybuilt_townhomes {
	.intro-bg {
		h4 {
			margin-top: 20px;
			color: #fff;
			font-size: 26px;
		}
	}
	.section-home {
		background: $grey-background-dark;

		&__content {
			@media (max-width: $width-xs-max) {
				padding-top: 0;
			}
		}
		&__body {
			@media (max-width: $width-xxs-max) {
				padding-top: 30px;
			}
		}

		&__buttons {
			margin-top: 0;
			margin-bottom: 30px;

			@media (max-width: 440px) {
				justify-content: center;
			}
			@media (max-width: $width-xxs-max) {
				padding-top: 0;
			}

			.btn {
				margin-bottom: 0;
				line-height: 1.42857; // from bootstrap!
				background: #000;
				color: #fff;

				&:hover {
					background: transparent;
					color: #000;
				}

				&.widget-enquiry__btn {
					color: #000;
					height: auto;
					line-height: 1.42857;
					padding-top: 6px;
					padding-bottom: 6px;
					&:hover {
						opacity: 1;
						color: #fff;
					}
				}
				@media (max-width: $width-xs-max) {
					height: 35px;
					margin-bottom: 5px;
					margin-top: 0;
					width: auto;
					flex: 0 1 18%;
					justify-content: space-between;
				}
				@media (max-width: 440px) {
					flex: 0 1 25%;
					margin-left: 5px;
					margin-right: 5px;
				}
				@media (max-width: $width-xxs-max) {

				}
			}
		}
	}
	.section-townhomes {
		&__content {
			/* max-width: 920px; */
			margin: 0 auto 30px auto;
		}
		&__tabbed {
			margin-top: 30px;
			padding-right: 45px;
		}
		&__tab-anchors {
			display: flex;
			padding-left: 0;
			margin-left: 0;
			flex-wrap: wrap;
			justify-content: flex-start;
			li {
				margin-left: 3em;
				margin-right: 3em;
				list-style: none;

				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
				}
				a {
					color: lighten(#231f20, 30%);
					text-decoration: none;
					font-size: 18px;
					font-family: $graphik;
				}
				&.selected a, a:hover {
					color: #231f20;
				}
			}
			@media (max-width: $width-md-max - 1) {
				justify-content: center;
			}
			@media (max-width: $width-xs-max) {
				justify-content: space-around;
				li {
					margin-left: 0;
					margin-right: 0;
				}
			}
			@media (max-width: 440px) {
				li {
					margin-left: 20px;
					margin-right: 20px;
					flex: 1 1 33%;
					text-align: center;
					&:first-child, &:last-child {
						margin-left: 20px;
						margin-right: 20px;
					}
				}
			}
		}
		&__tabs {
		}
		&__tab {
			display: none;
			&.selected {
				display: block;
			}
			ol, ul {
				padding-left: 1.2em;
			}
		}
		&__map {
			margin-top: 30px;
			background: #fff;
			padding-left: 0;
			padding-right: 0;
			&-directions {
				padding: 10px 20px;
				background: $grey-dark;
				a {
					color: #fff;
					font-size: 20px;
				}
			}
		}
		&-listings {
			background: #fff;

			@media (max-width: $width-sm-max) {
				.container {
					width: 100%;
				}
			}
			&__map {
				img {
					display: block;
					margin: 0 auto;
					width: auto;
					max-width: 100%;
				}
			}
			&__list {
				margin-top: 30px;
				padding-left: 0;
				width: 100%;
				display: flex;
				flex-direction: flex-column;
				flex-wrap: wrap;
				li {
					list-style: none;
					flex: 0 0 100%;
					display: flex;
					flex-wrap: wrap;
					border-bottom: 1px solid $grey-dark-hover;
					margin-bottom: 0px;
					margin-top: 10px;
					padding-top: 10px;
					padding-bottom: 10px;
					align-items: center;
					> p {
						flex: 1 1 10%;
						margin-bottom: 0;
						font-size: 20px;

						@media (max-width: $width-xs-max) {
							font-size: 16px;
						}

						@media (max-width: 500px) {
							text-align: center;
						}
					}
					&:last-child {
						border-bottom: 0;
					}
					&:hover {
						background: $grey-background-light;
					}
				}
				&-price {
					color: $sage;
				}
				&-specs {
					flex: 3 0 auto !important;
					display: flex;
					justify-content: space-evenly;

					@media (max-width: 500px) {
						flex: 1 1 100% !important;
					}
				}
				&-enquire, &-view {
					flex: 1 0 10% !important;
					text-align: center;

					@media (max-width: $width-xs-max) {
						flex: 1 0 5% !important;
					}
					a {
						display: block;
						color: #000;
						&.btn-black {
							background: transparent !important;
							border: 1px solid #000;
							color: #000 !important;
							font-size: 20px;
							min-width: 120px;
							margin-bottom: 0;

							@media (max-width: $width-xs-max) {
								font-size: 16px;
								min-width: 0;
								height: 35px;
								margin-top: 4px;
								padding: 7px 10px;
								line-height: 1.42857;
							}

							&:hover {
								background: #000 !important;
								color: #fff !important;
								opacity: 1;
							}
							&.sold {
								background: $sage !important;
								border-color: $sage;
								color: #fff !important;
								cursor: default;
							}

							&.townhomes-enquire {
								line-height: 1.42857;
								padding: 6px 15px;
								height: 42px;

								@media (max-width: $width-xs-max) {
									height: 35px;
									padding: 7px 10px;
								}
							}
						}
					}
				}
				&-enquire {
					margin-right: 5px;
				}
				&-view {
					margin-left: 5px;
				}
			}
			&__expand {
				max-height: 0;
				opacity: 0;
				visibility: hidden;
				flex: 0 0 100%;
				overflow: hidden;
				display: flex;
				flex-wrap: wrap;
				background: #fff;
				padding-top: 10px;
				margin-bottom: -10px;
				transition: .4s max-height ease-in-out, .4s opacity ease-in-out, .4s visibility ease-in-out;
				&.expanded {
					opacity: 1;
					visibility: visible;
					max-height: 700px;
					margin-top: 10px;
					transition: .4s max-height ease-in-out, .4s opacity ease-in-out, .4s visibility ease-in-out;

					@media (max-width: $width-xs-max) {
						max-height: none;
					}
				}
				&-heading {
					margin-bottom: 10px;
					flex: 0 0 100%;
				}
				&-subheading {
					margin-bottom: 10px;
					flex: 0 0 100%;
				}
			}
			&__floorplan {
				flex: 0 0 70%;
				position: relative;
				margin-bottom: 30px;

				@media (max-width: $width-sm-max) {
					flex: 0 1 50%;
				}
				@media (max-width: 500px) {
					flex: 0 0 100%;
				}

				.loader-panel {
					.loader {
						top: 50% !important;
					}
				}

				object.floorplan__svg {
					width: 100%;
					height: 600px;

					@media (max-width: $width-xs-max) {
						height: 450px;
					}
				}
			}
			&__content {
				flex: 0 0 30%;
				margin-bottom: 30px;

				@media (max-width: $width-sm-max) {
					flex: 0 1 50%;
					padding-left: 20px;
				}
				@media (max-width: 500px) {
					flex: 0 0 100%;
					padding-left: 0;
				}

				hr {
					margin-top: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}
	.section__group#virtual-tours {
		margin-top: 0;
		padding-top: 0;
		border-top: none;
	}
	.slider-images-secondary {
		margin-bottom: 0;
		&__slides {
			button {
				&::before {
					display: none; // removing errant arrows in gallery
				}
			}
		}
		&__slide {
			position: relative;
			&-content {
				position: absolute;
				bottom: 0;
				z-index: 9;
				background: rgba(0, 0, 0, 0.5);
				padding: 10px;
				line-height: 1;
				color: #fff;
				width: 100%;
				left: 50%;
				transform: translateX(-50%);
				@media (min-width: $width-xs-min) and (max-width: $width-ipad-max) {
					width: 100vw;
				}
				@media (max-width: $width-sm-max) {
					width: 100%;
					p {
						margin-top: 0;
						max-width: 99vw;
					}
				}
			}
		}
		.slider_bg_overlay {
			z-index: 10 !important;
		}
	}
}