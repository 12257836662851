/* ==========================================================================
	Products range page
   ========================================================================== */
.intro-bg {
	height: $intro-vh-height;
	max-height: $intro-max-height;
	min-height: $intro-min-height;
	text-align: center;
	background-size: cover;
	background-position: center center;

	@media (max-width: $width-md-max) {
		min-height: $md-intro-min-height;
		max-height: $md-intro-max-height;
	}
	@media (max-width: $width-xs-max) {
		min-height: $xs-intro-min-height;
		max-height: $xs-intro-max-height;
	}
	h1 {
		color: #fff;
		font-size: 60px;
		text-transform: uppercase;
		@media (max-width: $width-xs-max) {
			font-size: 35px;
		}
	}

	&__content--secondary {
		padding-bottom: 18px;
	}
}

.list-menu {
	text-align: center;
	background: $grey-background-light;
	padding: 35px 0;
	ul {
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			margin: 0 15px;
			a {
				font-size: 24px;
				color: #9C9A9B;
				font-weight: 200;
				@media (max-width: $width-xs-max) {
					font-size: 15px;
				}
			}
			&.on {
				a { color: #231F20; }
			}
		}
	}
}

@media (max-width: $width-xs-max){
	.list-menu {
		padding: 24px 0 22px;
		letter-spacing: -0.03em;
		ul {
			li {
				margin: 0 6px;
				a {
					font-size: 17px;
					@media (max-width: 374px){
						font-size: 12px;
					}
				}
			}
		}
	}
}

.text-intro {
	max-width: 750px;
	margin: 50px auto 150px;
	.new-homes .collections &{
		margin: 50px auto;
	    margin-top: 0px;
	    /* border-top: none; */
	    border-top: 2px solid #ad9554;
	}
	border-top: 1px solid #000;
	padding-top: 35px;
	@media (max-width: $width-xs-max) {
		margin: 25px auto 60px;
	}

	h2 {
		font-size: 32px;
		margin-bottom: 30px;
		.collections_featured &{
			font-family: $graphik;
		}
		@media (max-width: $width-xs-max) {
			font-size: 26px;
		}
	}
	&-legend {
		padding-left: 0;
		text-align: center;
		li {
			list-style: none;
			margin-left: 0;
			display: inline-block;
			margin-right: 20px;
			color: #fff;
			font-weight: 500;
		    padding: 3px 5px;
		    font-size: 12px;
			@media (max-width: $width-xs-max) {
				display: block;
				width: 100%;
				text-align: left;
				margin-bottom: 3px;
			}
			img.text-intro-legend__arrow-icon {
				display: inline-block;
				margin-right: 5px;
			}
			&:last-child {
				margin-right: 0;
			}
			&.grey {
			    background-color: #b4b4b4;
			}
			&.gold{
				background-color: $gold-dark;
			}
			&.brown{
				background-color: $main-brown;
			}
		}
	}
}

.newhomes {
	margin-bottom: 65px;
	@media (max-width: $width-xs-max) {
		margin-bottom: 30px;
		margin-left: 15px;
		margin-right: 15px;
	}
	.logo {
		text-align: center;
		vertical-align: middle;
		margin-bottom: 30px;
		padding: 15px 0px;

		&.grey{
			background-color: $grey-dark;
		}
		&.gold{
			background-color: $gold-dark;
		}
		&.brown{
			background-color: $main-brown;
		}
		@media (max-width: $width-xs-max) {
			margin-bottom: 15px;
		}
		img {
			height: 44px;
			@media (max-width: $width-xs-max) {
				height: 30px;
// 				width: 77px;
				max-width: 100%
			}
		}
		span {
			font-size: 46px;
			color: #c7c8ca;
			font-family: $fenice;
			display: inline-block;
			vertical-align: middle;
			font-style: italic;
			@media (max-width: $width-xs-max) {
				font-size: 25px;
			}
		}
	}

}
.new-homes_collections{

	.logo{
		width: 245px;
		margin: auto;
		margin-bottom: 30px;
	}
	h4{
		color: $grey-dark;
	}
}
.property-item{
	position: relative;
	width: 100%;

	body.readybuilt-landing & {
		@media (max-width: $width-md-max - 1) {
			min-height: 480px; 
		}
		@media (max-width: $width-sm-max - 1) {
			min-height: 518px; 
		}
		@media (max-width: $width-xs-max) {
			min-height: 0; 
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		/*background: rgba(220, 221, 222, 0.5);*/
		background: rgba(245, 245, 245, 0.8);
		.button-gold {
			margin: 0 25px;
			display: inline-block;
			width: calc(100% - 50px);
			position: absolute;
			top: 220px;
			background-image: url('../images/icon-circle-right-arrow.svg');
			background-repeat: no-repeat;
			background-position: calc(100% - 15px) center;
			background-size: 16px;

			@media (max-width: $width-xs-max) {
				top: 50%;
				height: 30px;
				margin-top: -15px;
				line-height: 20px;
				background-position: 95% center;
				background-size: 10px;
			}
			&:hover {
				background-image: url('../images/icon-circle-right-arrow-gold.svg');

			}
		}
	}
	&--newhomes{
		padding: 24px;
		position: relative;
		@media (max-width: $width-sm-max) {
			height: 540px;
		}
		@media (max-width: $width-xs-max) {
			margin: 0 -15px 20px;
			padding: 15px;
			height: 350px;
			position: relative;
		}
		.property-item__up{
			height: 200px;

			@media (max-width: $width-sm-max) {
				padding-right: 50px;
			}
			@media (max-width: $width-xs-max) {
				/*float:left;
				height: 167px;
				padding-right: 20px;
				width: 50%;*/
				position: absolute;
				top: 15px;
				padding-right: 25px;
			}
			h3{
				font-size: 24px;
				font-family: $graphik;
				@media (max-width: $width-xs-max) {
					font-size: 18px;
				}
			}
		}
		.property-item__down{
			padding-top: 50px; /*65px;*/
			position: absolute;
			bottom: 24px;
			width: 100%;
		    left: 0px;
			padding-left: 24px;
			@media (max-width: $width-md-max) {
				padding-top: 29px;
			}

			@media (max-width: $width-xs-max) {
				/*float:right;
				padding-top: 0;
				width: 50%;
				*/
				padding-top: 0;
				position: absolute;
				bottom: 15px;
				padding-left: 15px;
		    }
		    h5{
				font-weight: 300;
				padding-bottom: 3px;
				font-family: $graphik;
				@media (max-width: $width-xs-max) {
					font-size: 12px;
				}
			}
			p{
				font-family: $graphik;
			}
			a {
				font-weight: bold;
			}
		}
		&--grey{
			background-color: $grey-dark;
			color:#fff;
			a{
				color:#231F20;
			}
		}
		&--gold{
			background-color: $gold-dark;
			color:#fff;
			a{
				color:#231F20;
			}
		}
		&--brown{
			padding:0;
			background-color: $main-brown;
			@media (max-width: $width-xs-max) {
				padding: 15px;
			}
			.property-item__up {
				background-color: $main-brown;
				padding: 24px;
				padding-right: 50px;
				@media (max-width: $width-xs-max) {
					/*
					float:left;
					padding: 15px;
					width: 50%;
					*/
					padding: 0px;
					position: absolute;
					top: 15px;
					padding-right: 25px;
				}
			}
			.property-item__down{

				background-size: cover;
				padding: 36px 24px;
				bottom: 0px;
				@media (max-width: $width-md-max) {
					padding: 24px;

				}
				@media (max-width: $width-sm-max) {
					padding-top: 152px;
					padding-right: 50px;
					position: absolute;
					bottom: 0;
					width: 100%;
				}
				@media (max-width: $width-xs-max) {
					padding: 15px;
				}
			}
			color:#fff;
			a{
				color: $gold-dark;
			}
		}
	}
	&__panel {
		@media (max-width: $width-xs-max) {
			height: 350px;
		}
		.all_homes &, .new-homes_series &, .house-and-land-landing-page &, .estate &, .house-and-land &, .readybuilt-landing &, .readybuilt &, .display-for-sale &, .display-for-sale_detail & {
			@media (max-width: $width-xs-max) {
				height: auto;
			}
		}
	}
	&__mobile-house-count {
		position: absolute;
		top: 23px;
		padding-right: 0 !important;
		right: 10px;
	}
}
.newhomes {

		.property{
			&:nth-child(even){
				/*padding: 0px;*/
				.property-item {
					margin: 0px;
					margin-bottom: 30px;
				}
			}
			.property-item {
				@media (max-width: $width-xs-max) {
					padding-left: 0;
					margin: 0 -15px 20px;
					&__body, &__tail {
						display: none;
					}
					&__photo {
						width: auto;
						height: 100%;
						object-fit: cover;
					}
					&__photo-clickable {
						    position: absolute;
						    display: block;
						    max-height: 100%;
						    height: 100%;
						    width: 100%;
					}
				}
			}
		}

}

.house-and-land-landing-page, .new-homes_series, .estate, .house-and-land, .readybuilt-landing, .readybuilt, .display-for-sale, .display-for-sale_detail{
	.property-item {
		&__tail {
			max-height: 40px;
			@media (max-width: $width-xs-max){
				display: none;
			}

		}
		&__body{
			height: 240px;
			position: relative;
			@media (max-width: $width-xs-max){
				height: auto;
			}
		}
	}
}
.new-homes_series {
	.property-item {
		&__body {
			padding-bottom: 32px;
		}
	}
}
.readybuilt-landing, .selected_rb_packages {
	.property-item {
		&__body {
			height: auto;
			padding: 15px 15px 0 15px;
			position: relative;

			&.promo{
				height: 314px!important;
			}

			@media (min-width: $width-sm-min) {
				padding-top: 80px;
			}

			@media (max-width: $width-sm-max) {
				padding-bottom: 0 !important;
			}

			> a.property-item__body-clickable {
				padding: 15px 15px 0px 15px;
				min-height: 80px;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;

				@media (max-width: $width-sm-max) {
					padding: 0;
					position: relative;
					min-height: 0;
					text-align: center;
				}

				.property-item__icons {
					margin-bottom: 0;
				}
			}
			p{
				&.disclaimer{
					margin: 10px 0px;
				}
			}
		}
		&__tail {
			display: none;
		}
		
		
	}
}
.selected_rb_packages .property-item__tail {
	display: block;
}

#seo-textarea-section{
	background-color: #fff;
	padding: 55px 0;
	@media (max-width: $width-sm-max) {
		padding: 30px 0;
	}
	h3{
		font-size: 32px;
		font-family: $graphik;
		@media (max-width: $width-sm-max) {
			padding-bottom: 30px;
			text-align: center;
		}
		@media (max-width: $width-xs-max) {
			padding-bottom: 15px;

			font-size: 25px;

		}
	}
	p, span {
		letter-spacing: -0.1px;
		a {
			font-family: $graphik;
			font-weight: 500;
		}
		&.seo-textarea-section__text-expandable {
			display: none;
		}
	}
}

.houses-filter {
	position: relative;
	margin-bottom: 100px;

	body.new-homes & {
		@media (min-width: $width-md-max) {
			padding-top: 200px;
		}
	}

	&__sidepanel {
		@media (max-width: $width-sm-max) {
			margin-bottom: 20px;
		}

		.houses-filter__sidebar {
			body.new-homes & {
				@media (min-width: $width-md-max) {
					min-height: 200px; 
					position: absolute; 
					top: -200px; 
					background: #fff; 
					max-width: 1140px; 
					z-index: 110; 
				}
			}
		}
		&.sticky {
			.houses-filter__sidebar {
				/*top: 176px;*/
				top: 25px;
				position: fixed;

				body.new-homes & {
					@media (min-width: $width-md-max) {
						top: 0; 
						position: fixed; 
						padding-top: 25px; 
						z-index: 105; 
						background-color: #fff; 
					}
				}
			}
			&.sticky-bottom {
				.houses-filter__sidebar {
					position: absolute !important;
				}
			}
		}
	}
	.load_more_ajax{
		@media(max-width: $width-xs-max){
			min-height: auto !important;
		}
	}

	&__sidebar{
		.filter-prevent-overlay{
			width: 100%; height: 100%; position: absolute; top: 0px; left: 0; background-color: #f5f5f5; z-index: 10000; opacity: 0; display: none; cursor: progress;
			&.on{
				display: block;
			}
		}
		h3 {
			margin-bottom: 25px;
			@media (max-width: $width-md-max) and (min-width:$width-sm-max) {
				cursor: pointer;
			}
			span{
				font-size: 14px;
				font-weight: 500;
				color: $body-text-color;
				position: relative;
				display: inline-block;
				vertical-align: middle;
				font-family: $graphik;
				font-weight: 400;
			}
			img{
				display: inline-block;
				vertical-align: middle;
				margin-left: 25px;
			}
		}
		.search-form-estate{
			position: relative;
			margin-bottom: 15px;

				width: 100%;
				label{
					color: #b2b1b1;
					font-size: 14px;
					font-weight: 400;
				}
				.custom-search{
					width: 100%;

					height: 30px;
					position: relative;
					input[type="text"]{
						color: $body-text-color;
						height: 30px;
						width: 100%;
						background: $grey-background-light;
						line-height: 30px;
						border: none;
						padding: 0 10px;
						font-size: 14px;
						&:focus{
							outline: none;
							& + input[type="submit"]{
								transform: rotate(-90deg) translate3d(0,0,0);
							}
						}
						@media (max-width: $width-sm-max){
							background: #fff;
						}
					}
					a.submit{
						cursor: pointer;
						width: 20px;
						height: 20px;
						background: url(../images/icon-search.svg);
						-webkit-appearance: none;
						text-indent: -99999999px;
						border: none;
						position: absolute;
						top: 5px;
						right: 10px;
						transition: all 300ms ease;
						overflow: hidden;
						&:focus{
							outline: none;
						}
					}

				}

		}
		&.open{
			@media (max-width: $width-md-max) and (min-width:$width-sm-max) {
				.filters {
					max-height: 1500px;
					overflow: auto;

					form{
						margin-bottom: 30px;
					}
				}
				.hidden-group-filters-advanced{
					display: block !important;
				}
				.filters-advanced-search__advanced-search{
					display: none;
				}
			}
		}
		.filters {
			@media (max-width: $width-md-max) and (min-width:$width-sm-max) {
				max-height: 0px;
				transition: max-height 0.5s ease-in;
				overflow: hidden;
			}
			&__links {
				padding-bottom: 20px;
				a {
					display: inline-block;
					font-weight: 500;
					color: #231f20;
					+ a {
						margin-left: 32px;
					}
				}
			}
			&__btn {
				display: inline-block;
				height: 36px;
				padding: 0 8px 0 12px;
				border: 1px solid #fd5455;
				border-radius: 3px;
				line-height: 36px;
				color: #231f20;
				i {
					float: right;
					margin-top: 7px;
				}
				&:hover {
					opacity: .7;
					text-decoration: none;
				}
			}
			form {
				fieldset {
					padding: 0;
					margin: 0;
					margin-bottom: 10px;
					border: none;
					&:last-child {
						margin-bottom: 0px;
					}
					label{
						color: #b2b1b1;
						font-size: 14px;
						font-weight: 400;
						margin-bottom: 0;

					}

					.label-radio{
						label{
							font-size: 14px;
							font-weight: 300;
							color: $body-text-color;
							border: 1px solid transparent;
							padding: 8px;
							line-height: 1;
							cursor: pointer;
							text-align: center;
							body.house-and-land-landing-page &.col-md-12{
								@media (min-width: $width-sm-max) {
									width: 100%;
									margin-left: 0px !important;
									margin-right: 0px !important;
								}
							}
							&.col-md-6, &.col-sm-6, &.col-xs-6, &.full-width-radio {
								float: left;
								border: 1px solid $grey-background-light;
								@media (max-width: $width-sm-max){
									border: 1px solid #b2b1b1;
									margin-bottom: 0px;
								}
								width: calc(50% - 5px);
								&:nth-of-type(odd){
									margin-left: 5px;
									margin-right: 0px;

									body.gallery & {
										margin-left: 0;
										margin-right: 5px;
									}
								}
								&:nth-of-type(even){
									margin-right: 5px;
									margin-left: 0px;

									body.gallery & {
										margin-right: 0;
										margin-left: 5px;
									}
								}
								&:nth-of-type(2) , &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
									margin-bottom: 10px;
								}
							}
							&.full-width-radio {
								width: 100%;
								float: none;
								margin-left: 0 !important;
								margin-bottom: 10px !important;
								text-align: center;
							}
							body.gallery & {
								&.col-md-12 {
									@media (min-width: $width-sm-max) {
										width: 100%;
										text-align: left;
										margin: 0px !important;
										border: 1px solid transparent;
									}
								}
							}
						}
						input{
							display: none;
							&:checked{
								& + label{
									border-color: $grey-background-dark !important;
								}
							}
						}
						.col-xs-6 {
							width: auto;
							float: none;
						}
						> .col-xs-6 {
							display: inline-block;

							&:nth-child(odd) {
								padding-right: 0px;
							}
							&:nth-child(even) {
								padding-left: 0;
							}
						}
					}

					.gallery &{
						&.filters-categories, &.filters-collections{
							.label-radio{
								label{


									@media (max-width: $width-sm-max) {
										margin-bottom: 10px;
										&.col-xs-6, &.col-sm-6{
											    margin-right: 0;
											    width: 46.8%;
											    float: none;
											    display: inline-block;
											    vertical-align: text-top;
											    color: #333;
											&.disabled {
											    color: #b2b1b1;
											}
											&:nth-of-type(even){
												margin-left: 4%;
											}
										}
									}
								}
							}
						}
					}
					.custom-select{
						select{
							-webkit-appearance: none;
							width: 100%;
							height: 30px;
							line-height: 30px;
							border: 1px solid $grey-background-dark;
							border-radius: 0;
							padding: 0 10px;
							background: transparent;
							font-size: 14px;
							background: url('../images/icon-arrow-down-dark.svg') no-repeat;
							background-position: calc(100% - 10px) 50%;
							background-size: 12px;
							.ios.tablet &{
								background-position: right 10px top 10px;
							}
							html.ie &{
								background-size: 30%;
								background-position: right center;
							}

						}

					}
					.col-md-12{
						.custom-select{
							select{
								html.ie &{
									background-position-x: 110%;
								}
							}
						}
					}
				}
				.row{
					&--ten{
						margin-left: -5px;
						margin-right: -5px;
						& > div{
							padding: 0 5px;
						}
					}
				}
				.hidden-group-filters-advanced {
					display: none;
					fieldset:last-child {
						margin-bottom: 10px;
					}
				}
				body.new-homes & {
					@media (min-width: $width-md-max) {
						display: flex; 
						flex-wrap: wrap; 

						.filter-block {
							flex: 1 1 auto; 
						}
						.filters-storeys {
							margin-right: 10px; 
						}
						.filters-bedrooms {
							margin-right: 10px; 
						}
						.filters-lot_width-lot_depth {
							margin-right: 10px; 
							select {
								width: auto; 
								min-width: 110px; 
								display: block; 
							}
						}
						.filters-home-size-series { 
							margin-right: 10px; 
						}
						.filters-pricing {
							margin-right: 10px; 
							display: inline-block; 
						}
						.filters-collections {
							select {
								width: auto; 
								min-width: 160px; 
								display: block; 
							}
						}
						.filters-collection {
							.custom-checkbox {
								padding-top: 5px; 
							}
						}
						.filters-advanced {
							flex: 1 1 auto; 
						}
						> p.small {
							align-self: flex-end; 
						}
						.hidden-group-filters-advanced {
							display: none; 
							padding-bottom: 10px; 

							fieldset {
								margin-bottom: 0; 
							}
							.filters-pricing:last-child {
								flex: 1 0 auto; 
								.custom-checkbox {
									padding-top: 5px; 
									.group-checkbox {
										float: left; 
										margin-right: 15px; 
										margin-bottom: 0; 
										display: inline-block; 
										width: auto; 
										label {
											vertical-align: super; 
											margin-left: 8px; 
										}
									}
								}
							}
						}
					}
				}
			}
			
			&-advanced-search {
				a {
					//color: #000;
					color: $body-text-color;
					font-weight: 400;
					display: inline-block;
				}
				&__reset {
					color: $grey-dark !important;
					float: right;
				}
				&__advanced-search {
					padding-right: 20px;
					margin-right: 20px;
					background: url('../images/icon-arrow-down-dark.svg') no-repeat;
					background-position: right center;
					background-size: 12px;
					html.ie &{
						background-size: 40%;
						padding-right: 40px;
					}
					&.open {
						background-image: url(../images/icon-arrow-up-dark.svg);
					}
				}
				&__reset {

				}
			}
			&-download-selection {
				a {
					width: 100%;
					display: inline-block;
					line-height: 20px;
					height: 40px;
					background: url('../images/document_download.svg') no-repeat;
					background-position: 95% 50%;
					background-size: 14px;
					@media (max-width: $width-xs-max) {
						line-height: 30px;
					}
				}
			}
		}
	}
	@media (max-width: $width-sm-max) {
		&__sidebar-mobile {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1001;
			background: $grey-background-light;
			padding: 15px;
			display: none;
			&.open {
				display: block;
				overflow: auto;
			}
		}
		&__heading {
			text-align: center;
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid #000;
			.close {
				position: absolute;
				display: block;
				top: 15px;
				left: 15px;
				width: 15px;
				height: 15px;
				background: url('../images/close-popup-black.svg') center center no-repeat;
				background-size: cover;
				opacity: 1;
				text-shadow: none;
			}
			& + h3 {
				text-align: center;
				span {
					font-weight: normal;
					font-size: 12px;
				}
				img {
					width: 16px;
					height: 16px;
					margin-left: 12px;
				}
			}
		}
		.filters form {
			margin-bottom: 60px;
			fieldset {
				.col-md-6:first-of-type {
					margin-bottom: 10px;
				}
				&.filters-home-size-series{
					.col-md-6{
						margin-bottom: 0px;
						@media(max-width:$width-sm-max){
							&:first-of-type {
								margin-bottom: 10px;
							}
						}
					}
				}
				&.filters-region{
						.label-radio{
							label{
								padding: 8px 9px;
							}
						}
				}
				.label-radio {
					label {
						padding: 8px 17px;
						text-align: center;
						border: 1px solid #b2b1b1;
						color: #b2b1b1;
						margin-right: 10px;
						&.col-xs-6 {

						}
					}
					input:checked + label {
						border-color: #000;
						color: #000;
					}
					&:last {
						margin-right: 0;
					}
					.col-xs-6 {
						width: 50%;
						float: left;
						label.col-xs-6 {
							margin-bottom: 12px;
							width: 100%;
							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
					> .col-xs-6:nth-child(odd) {
						padding-right: 7.5px;
					}
					> .col-xs-6:nth-child(even) {
						padding-left: 7.5px;
					}
					@media(max-width: $width-xxs-max){
						.col-xs-6 {
							width: 100%;
							float: left;
							padding-right: 15px;
							padding-left: 15px;
							margin-bottom: 12px;
						}
						> .col-xs-6:nth-child(odd) {
							padding-right: 15px;
						}
						> .col-xs-6:nth-child(even) {
							padding-left: 15px;
						}
					}


				}
				&.filters-collections {
/*
					.col-xs-6 {
						padding-right: 7.5px;
					}
					.col-xs-6:nth-child(2n) {
						padding-right: 15px;
						padding-left: 7.5px;
					}
					.label-radio label {
						margin-bottom: 12px;
						width: 100%;
					}
					@media(max-width: $width-xxs-max){
						.col-xs-6 {
							padding-right: 15px;
						}
						.col-xs-6:nth-child(2n) {
							padding-left: 15px;
						}
					}
*/
				}
			}
		}
		&__footer-sticky {
			position: fixed;
			width: 100%;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 1001;
			background: $grey-background-light;
			height: 64px;
			text-align: center;
			border-top: 1px solid shade($grey-background-light, 20%);
			&-submit {
				padding: 0 15px;
				position: relative;
				display: none;
				.open &{
					display: block;
				}
				/*
				display: none !important;
				&.open {
					display: block !important;
				}
				*/
			}
			&.open{
					> a{

					display: none;
					}

				}

		}
		&__footer-mobile, &__button-reset, &__button-search {
			    color: #fff;
			border: 1px solid $sage;
			display: inline-block;
			width: auto;
			margin-top: 12px;
			padding: 8px 40px;
			border-radius: 4px;
			background: $sage;
			font-weight: normal;
			img {
				margin-left: 15px;
			}
			&:focus, &:active {
				color: $sage;
				text-decoration: none;
			}
		}
		&__button-reset, &__button-search {
			width: 100%;
			display: block;
			border-radius: 2px;
			line-height: 23px;
			height: 39px;
		}
		&__button-search {
			background: $sage;
			color: #fff;
			border-color: $sage;
		}
	}
	&__top-panel {
		margin-bottom: 20px;
		p {
			line-height: 33px;
			margin-bottom: 0;
		}
		img {
			margin-bottom: 20px;
		}
		a {
			font-weight: 500;

			&.promo-tile {
				margin-bottom: 20px;
				display: block;

				img {
					margin-bottom: 0;
				}
			}
		}
	}
	&__panel-switch {
		padding-left: 0;
		margin-bottom: 0;

		/*
		body.readybuilt-landing & {
			display: none;
		}
		*/
		li {
			display: inline-block;
			list-style: none;
			margin-right: 15px;

			a {
				display: inline-block;
				height: 34px;
				line-height: 34px;
				padding: 0 40px 0 10px;
				font-weight: normal;
				background-position: 90% 50%;
				background-repeat: no-repeat;
				background-size: 18px 18px;
				border-radius: 3px;
				color: $sage;
				text-decoration: none;
				border: 1px solid #fff;
				&.active {
					border-color: $sage;
					color: #fff;
					background-color: $sage;
				}
				&:hover {
					text-decoration: none;
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__feed-switch {
		background-image: url('../images/icon-feed-gold.svg');
		&.active {
			background-image: url('../images/icon-feed-white.svg');
		}
	}
	&__map-switch {
		background-image: url('../images/icon-location-gold.svg');
		&.active {
			background-image: url('../images/icon-location-white.svg');
		}
	}
	&__top-panel-2 {
		margin-bottom: 20px;
		p {
			margin-bottom: 0;
			line-height: 30px;
		}
		.button-links{
			display: inline-block;
			a{
				font-weight: 300;
				line-height: 30px;
				color: $sage;
			}
		}
		.num_results{
			color: $body-text-color;
			    font-weight: 300;
		}
		.custom-select{
			select{
				-webkit-appearance: none;
				width: auto;
				height: 30px;
				line-height: 30px;
				color: $body-text-color;
				border: 1px solid $grey-background-dark;
				border-radius: 2px;
// 			    width: 90px;
    			font-weight: 300;
			    padding-left: 10px;
			        padding-right: 30px;
			    margin-left: 10px;
				font-size: 14px;
				background: url('/resources/images/arrow-down-header-black.svg') no-repeat;
				background-position: 95% 50%;
				background-size: 12px;
				@media (max-width: $width-sm-max) {
					width: 100%;
				}

			}
			@media (max-width: $width-sm-max) {
				label{
					display: none;
				}
			}
		}
	}
	#special-offers-section {
		padding-top: 0;
		padding-bottom: 0;
		.carousel-container {
			margin-bottom: 0;
			.slick-prev, .slick-next {
				display: none !important;
			}
		}
	}
}


body.new-homes{
	
	.hidden-group-filters-advanced {
					display: flex!important;	
					@media (max-width: $width-sm-max) {
						display: block!important;	
					}	
					
					fieldset:last-child {
						margin-bottom: 10px;
					}
				}
	
	
}