/* ------------------------------------------------------------ *\
	Visualizer
\* ------------------------------------------------------------ */

.visualizer {
	margin-top: 20px;
	&__block {
		height: 370px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		position: relative;
		margin-bottom: 30px !important;
		a {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			text-align: center;
			background: rgba(35,31,32,.2);
			text-decoration: none;
			/* transition: background-color 400ms ease; */
			&:hover, &.on {
				/* background: rgba(35,31,32,0.6); */
			}
			&:before {
				content: '';
				display: inline-block;
				width: 1px;
				height: 100%;
				vertical-align: middle;
			}
			span {
				color: #fff;
				vertical-align: middle;
				font-family: $graphik;
				font-weight: 400;
				font-size: 32px;
				display: inline-block;
				width: calc(100% - 1px);
 				
			}
		}
	}
	&__article {
		&-heading {
			position: relative;
		}
	}
	&__description {
		h2, h3 {
			height: 40px;
			z-index: 15;
			position: relative;
			background: #fff;

			@media (max-width: $width-xs-max) {
				height: auto;
				min-height: 40px;
			}
		}
	}
	&__buttons {
		position: absolute;
		top: 26px; /*44px;*/
		right: 15px;

		@media (max-width: $width-xs-max) {
			right: 20px;
		}

		&-back:hover {
			background: #e0e0e0;
		}
	}

	&__initial {
		> div {
			z-index: 20;
		}
		.visualizer__block {
			margin-bottom: 0 !important;

			@media (max-width: $width-xs-max) {
				margin-bottom: 30px !important;
			}

			span {
				visibility: visible;
			}
		}
	}
	&__category, &__subcategory {
		display: none;
		& .open {
			display: block;
		}
		hr {
			display: none;
		}
		h2, h3 {
			width: calc(100% - 75px);
			padding-left: 15px;
			padding-right: 15px;
		}
		h3 {
			font-family: PublicoRoman;
			font-weight: 400;
			letter-spacing: -.5px;
			line-height: 1.1;
		}
	}

	/*
	@media (max-width: $width-xs-max) {
		&__initial, &__category, &__subcategory {
			div.col-xs-12:last-child {
				.visualizer__block {
					margin-bottom: 0 !important;
				}
			}
		}
	}
	*/

	&__subcategory {
		padding-top: 50px;
	}
}
.visualizer__select-subcategory {
	.visualizer__block {
		a.on {
			background: none;
		}
	}
}
.col-md-3 .visualizer__block {
	height: 262px;
}


/* TV version */
.visualiser_tv {
	padding-bottom: 0;
	header {
		.container {
			width: 100%;
			.clearfix {
				text-align: center;
				a {
					display: inline-block;
				}
			}
		}
		&.header {
			height: 90px !important;
			.header__logo {
				width: 240px;
				display: inline-block;
			}
		}
	}
	.header {
		&__back {
			display: block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
			font-weight: 500;
			font-size: 20px;
			padding: 5px 10px;
			border: 1px solid #fff;
			border-radius: 10px;
			color: #fff;
			background: transparent;
			&:hover {
				text-decoration: none;
			}
		}
	}
	iframe {
		width: 100%;
		height: calc(100vh - 95px);
		margin-top: 90px;
		border: none;
	}
}
.visualiser_iframe {
	&--logo {
		width: 800px;
		height: auto;
		background: url('/resources/images/visualiser_logo.png') center center no-repeat;
		background-size: contain;
		margin: 40px auto 0 auto;
		img {
			width: 100%;
			height: auto;
			display: block;
			visibility: hidden;
		}
	}
	.container {
		width: 100vw;
		position: relative;
	}
	.container--xs {
		width: 100vw;
		max-width: 100vw;
	}
	.article:first-of-type {
		padding-top: 0;
		padding-bottom: 0;
		hr {
			display: none;
		}
		h2, h3 {
			/*margin-bottom: 0;*/
			margin-top: 20px;
			/*margin-left: 16.66667%;*/
		}
		.container {
			border-top: none;
			padding-top: 0;
		}
		.container--xs.center-block {
			max-width: 100%;
		}
	}
	.visualizer {
		margin-top: 30px;
		&__block {
			height: calc(100vh - 85px);
			/*
			&.no-overlay {
				a {
					background: transparent;
					&:hover, &.on {
						background: transparent;
					}
				}
			}
			*/
		}
		&__buttons{
		    z-index: 10;
			top: 30px;
			right: 30px;
			display: none;
		}
		&__initial {
			margin-top: 0;
			> div {
				margin-left: 0 !important;
				width: 50% !important
			}
		}
		&__select-subcategory {
			padding-top: 10vh;
			.visualizer__block {
				/*height: calc(100vh - 100px);*/
				height: 32vw;
				margin-bottom: 0 !important;
			}
		}
		&__subcategory, .rowed {
			.visualizer__block {
				height: auto;
				img {
					visibility: hidden;
					opacity: 0;
				}
			}
		}
		&__subcategory {
			padding-top: 80px;
		}
		&__description {
			display: block;
			background: #fff;
			z-index: 100;
			position: relative;
			height: 50px;
			> * {
				display: none;
			}
			h2, h3 {
				margin-top: 0 !important;
				padding-top: 10px !important;
				/*display: block;*/
				opacity: 0;
				visibility: hidden;
				margin-bottom: 0;
			}
		}
	}
}

/* visualiser standalone */
body.visualiser_standalone {
	article.article {
		padding-bottom: 0;
		.container {
			hr:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.visualizer__article {
		padding-top: 33px; /* taking into account hr visibility hidden below */
		padding-bottom: 110px !important;

		@media (max-width: $width-xs-max) {
			padding-bottom: 0px !important;
		}

		hr {
			visibility: hidden;
		}
		.container {
			hr:last-of-type {
				margin-bottom: 20px;
			}
		}
	}
	.visualizer__description {
		> *  {
			display: none;
		}
		h2, h3 {
			display: block;
			padding-top: 0;
			margin-bottom: 0;
		}
	}
}

/* visualizer modal */
.visualiser-modal {
	.inner-box {
		max-height: 800px !important;
		max-width: 1600px;
		width: 100% !important;
		// min-height: 600px !important;
		// height: 100%;
		transform: translateX(-50%) translateY(-50%) !important;
		top: 50% !important;
		overflow-y: auto;

		@media (max-width: $width-xs-max) {
			max-height: 80vh !important;
			overflow-y: scroll !important;
			h4 {
				margin-top: 0 !important;
			}
			p {
				font-size: 10px !important;
				margin-bottom: 16px !important;
			}
		}
	}
}

/* visualiser embedded in general template tweaks */
body.general {
	.visualizer__description {
		h2, h3 {
			padding-top: 0;
		}
		&.hidden {
			+ .visualizer__buttons {
				bottom: -33px;
			}
		}
	}
	.visualizer__buttons {
		top: auto;
		bottom: 0;
	}
}