/* ==========================================================================
INTRO SECTION
========================================================================== */

#intro-section{
	background-image: url('../images/intro.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: calc(100vh - 177px);
	position: relative;
	margin-top: 177px;
	overflow: hidden;
	&.intro-popup {
		/*background-image: url('../images/intro-image-5a.jpg');*/
	}
	@media (max-width: $width-sm-max) , (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
		margin-top: 65px;
		height: calc(100vh - 65px);
	}
	@media (max-width: $width-xs-max) {
		margin-top: 57px;
		height: calc(100vh - 57px);
	}

	.intro-section--slider{
		position: absolute;
		height: 100%;
		width: 100%;

		.slick-prev, .slick-next{
			content: "";
			position: absolute;
			width: 60px;
			height: 60px;
			top: calc(50% );
			transform: translateY(-50%);
			background-size: 100% 100%;
			transition: opacity .3s;
			z-index: 8;
			@media (max-width: $width-xs-max) {
				height: 20px;
				width: 20px;
			}
			&:before{
				display: none;
			}
		}
		.slick-prev{
			background-image: url(/resources/images/home-slider-left.png);
			left: 10px;
		}
		.slick-next{
			background-image: url(/resources/images/home-slider-right.png);
			right: 10px;
		}

		.slick-dots {
			position: absolute;
			bottom: 32px;
			list-style: none;
			display: block;
			text-align: center;
			padding: 0;
			margin: 0;
			width: 100%;
			z-index: 30;
			@media (max-width: $width-xs-max) {
				bottom: 35px;
			}
			li {
				position: relative;
				display: inline-block;
				height: 12px;
				width: 12px;
				margin: 0 5px;
				border-radius: 100%;
				padding: 0;
				cursor: pointer;
				border: 1px solid #fff;

				&:hover, &.slick-active{
					&:before{
						background-color: #fff;
					}
				}
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 100%;
				}

				button {
					display: none;
				}

			}
		}

		&_slide{
			height: calc(100vh - 146px);
			width: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50%;
			position: relative;

			.slide__link{
				position: absolute;
				height: 100%;
				left: 0;
				top: 0;
				width: 100%;
				z-index: 20;
			}
			@media (max-width: $width-sm-max) , (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
				height: calc(100vh - 65px);
			}
			@media (max-width: $width-xs-max) {
				height: calc(100vh - 45px);
			}
			
			@media only screen 
				and (device-width : 375px) 
				and (device-height : 667px) 
				and (-webkit-device-pixel-ratio : 2) { 
					
					height: calc(100vh - 15px);
					
				}
			
			
			
			
		}
		&_vimeo {
			&_shadow {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba(0, 0, 0, 0.3);
				z-index:  5;

				@media (max-width: $width-sm-max) {
					display: none;
				}
			}
			&_loop {
				position: relative;
				width: 100%;
				height: 100%;
				min-height: 56.25%;
				overflow: hidden;
				z-index: -1;
				background: #000;
				iframe {
					position: absolute;
					border: none;
					width: 100vw;
					height: 56.25vw;
					min-width: 177.77vh;
					min-height: 100vh;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: #000 !important;
				}
				@media (max-width: $width-sm-max) {
					display: none;
				}
			}
			&_mobile {
				display: none;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-repeat: no-repeat;
				background-size: cover;
				z-index: 6;
				@media (max-width: $width-sm-max) {
					display: block;
				}
			}
		}
	}
}

.hero_block{
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	margin:0;
	margin-top: -10px; /* vertical center correction */
	@media (max-height: $width-xxs-max) {
		margin: -30px 0 0 0!important;
	}
	@media (max-width: $width-xs-max) {
		position: absolute;
		top: 55%;
		left: 50%;
		transform: translate(-50%, 0%);
		padding:0;
		margin: -70px 0 0 0;
		.home &{
			top: 50%;
		}
	}
	@media (max-width: 410px) {
		.home &{
			top: 25%;
		}
	}
	&__title{
		font-size: 45px;
		letter-spacing: 0.5px;
		margin-bottom: 30px;
		text-transform: uppercase;
		span{
			max-width: 59%;
			display: inline-block;

			html.firefox & {
				br {
					line-height: 0;
				}
			}

		}
		@media (max-width: $width-xs-max) {
			font-size: 27px;
			line-height: 27px;
			span{
				max-width: none;
				display: block;
			}
			.line{
				display: none;
			}
		}
		i{
			width: 40%;

		}

	}
	&__button{
		font-size: 14px;
		padding: 10px 21px;
		margin: 5px;

		border: 0 none;
		overflow: hidden;
		position: relative;
		font-weight: normal;
		letter-spacing: -0.12px;
		width: 190px;

		@media (max-width: $width-xs-max) {
			font-size: 12px;
			padding: 8px 12px;
			width: auto;
			display: inline-block;
		}
	}
	&__divider {
		display: block;
		margin: 25px auto;
		margin-bottom: 35px;
		@media (max-width: $width-xs-max) {
			margin: 15px auto;
			margin-bottom: 25px;
		}
	}

	&--popup-rb {
		background: #fff;
		width: 100%;
		max-width: 30vw;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 30px;
		border: 2px solid $main-brown;
		box-shadow: 0 0 100px -20px $main-brown;
		z-index: 100;
		display: none;

		&.open {
			display: block;
		}

		@media (max-width: $width-lg-max) {
			max-width: 50vw;
		}
		@media (max-width: $width-ipad-max) {
			max-width: 60vw;
		}
		@media (max-width: $width-xs-max) {
			max-width: 80vw;
		}

		&-close {
			display: block;
			position: absolute;
			top: 10px;
			right: 10px;
			width: 16px;
			height: 16px;
			z-index: 2;
			text-indent: -9999px;
			text-align: center;
			background: transparent url('../images/close-popup-black.svg') center center no-repeat;
			background-size: cover;
		}

		&-content {
			margin-bottom: 30px;
			p {
				font-size: 36px;
				font-family: $fenice;
				text-align: center;
				margin-bottom: 0;
				padding-bottom: 0;
				line-height: 1.1;
			}
			@media (max-width: $width-xs-max) {
				text-align: center;
				p {
					font-size: 24px;
					display: inline;
				}
			}
		}
		&-suburbs {
			li {
				list-style: none;
				display: block;
				a {
					font-size: 26px;
				}
			}
			.col-sm-6 {
				margin-bottom: 0;
				+ .col-sm-6 {
					li {
						text-align: right;
					}
				}

				li {
					@media (max-width: $width-xs-max) {
						text-align: center !important;
					}
				}
				&.col-sm-offset-3 {
					li {
						text-align: center;
					}
				}
			}
		}
	}
}
@media (max-width: $width-xs-max) {
	.intro-popup {
		.hero_block {
			max-width: 90%;
			width: 100%;
			&__title {
				em { display: block; }
				br {
					display: none;
				}
			}
		}
	}
}

.intro_bottom{
	text-align: left;
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translate(-50%, 0);
	font-family: $helvetica;
	text-transform: uppercase;
	letter-spacing: 0.3em;
	font-weight: 600;
	&__arrow-icon{
		width: 27px;
		height: 27px;
		cursor: pointer;
		margin-right: 10px;
		&:hover{
			opacity:0.7;
		}
		@media (max-width: $width-xs-max) {
			display: block;
			margin: 0 auto 10px auto;
		}
	}
	&__text{
		color: white;
		font-size: 14px;
		margin-top: 12px;
		@media (max-width: $width-xs-max) {
			text-align: center;
		}
	}
	&__divider {
		display: block;
		margin: 25px auto;
	}

	&_sticker {
		position: absolute;
		bottom: 0;
		right: 90px;
		width: 326px;
		text-transform: none;
		border-radius: 5px;
		letter-spacing: 0;
		display: none;

		@media (max-width: $width-sm-max) {
			&.hidden-sm {
				display: none !important;
			}
		}
		&--top {
			padding: 15px 30px 0 15px;
			background: $gold-dark;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
			h3 {
				font-family: $fenice;
				font-size: 21px;
				color: #fff;
				text-transform: uppercase;
			}
			p {
				font-family: $graphik;
				font-size: 16px;
				font-weight: normal;
				color: #fff;
				margin-bottom: 0;
				padding-bottom: 10px;
			}
		}
		&--bottom {
			text-align: center;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
			position: relative;
			a {
				padding-left: 65px;
				padding-right: 65px;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 15px;
				font-family: $graphik;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
				visibility: hidden;
				opacity: 0;
				@media (max-width: $width-ipad-max) {
					max-height: 180px;
				}
			}
		}
		&--close {
			display: block;
			width: 20px;
			height: 20px;
			top: 7px;
			right: 7px;
			position: absolute;
			text-indent: -9999px;
			border: 1px solid #fff;
			border-radius: 50%;
			background: url('../images/close-popup.svg') center center no-repeat;
			background-size: 60% 60%;
		}
	}

	@media (max-width: $width-xs-max) {
		bottom: 5vw;
		width: 90%;
		left: 5%;
		transform: none;
		.home & {
			bottom: 15vw;
		}
	}
}

.text-muted{
	color:#9C9A9B;
}

.hero-popup-shell {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.6);
	display: none;
	&.on {
		display: block;
	}
}
.hero-popup{
	max-width: 1170px;
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	z-index: 99;
	@media (max-width: $width-xs-max) {
		padding: 0 20px;
		width: 100%;
		top:75px;
		transform:translate(-50%, 0%);
	}
	.subtitle-top{

		color:#fff;

		@media (max-width: $width-sm-max) {
			padding-top: 15px;
		}
		@media (max-width: $width-xs-max) {
			padding-top: 15px;
		}
		.tab-menu{
			padding: 0;
			margin-bottom: 0;
			font-family: $graphik;
			text-transform: none;
			letter-spacing: normal;
			li{
				display: inline-block;
				list-style: none;
				margin-right: 12px;
				a{
					color:#fff;
					font-size: 14px;
					display: block;
					padding: 5px;
					border-radius: 2px;
					font-weight: 500;
					@media (max-width: $width-xs-max) {
						font-size: 12px;
					}
					&.active{
						border: 1px solid #fff;
					}
				}
			}
		}
	}
	.offers-info-column{
		position: relative;
		&--heightsm{
			min-height: 85px;

		}
		&__body{
			font-family: $graphik;
			> a{
				display: inline-block;
				position: relative;
				cursor: pointer;
			}
			h2 {
				color:#fff;
			}
			p{
				color: #fff;
				font-size: 14px;
				margin-bottom: 0px;
				font-style: normal;

			}
			span{
				font-size: 14px;
				color: $gold-dark;
				line-height: 10px;
				display: block;
			}
			.button-icons{
				.arrow-circle{
					height: 30px;
					width: 30px;
					position: absolute;
					right: -40px;
					top: 45px;
					@media (max-width: $width-xs-max) {
						display: none;
					}
				}
			}
			@media (max-width: $width-xs-max) {
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid $grey-background-dark;

			}
		}
	}
	@media (max-width: $width-xs-max) {
		.col-xs-12{
			&:last-of-type{
				.offers-info-column{
					border-bottom: none;
				}
			}
		}
	}
	a{
		text-decoration: none;
		font-size: 14px;
		color:#fff;
		@media (max-width: $width-xs-max) {
			font-size: 12px;
		}
		img{
			margin-left: 5px;
		}
	}
	.close-popup{
		padding-top: 5px;
		font-family: $graphik;
		text-transform: none;
		letter-spacing: normal;
		@media (max-width: $width-xs-max) {
			padding-top: 5px;
		}
		span{
			@media (max-width: $width-xs-max) {
				display: none;
			}
		}
		img{
			@media (max-width: $width-xs-max) {
				width: 14px;
				height: 14px;
			}
		}
	}
	.btn-right{
		position: absolute;
		display: none;
		bottom: 0;
		right: 0;
		@media (max-width: $width-xs-max) {
			display: block;
			top: 43px;
			bottom: auto;
		}
	}
}

.tab{
	display: none;
	&--open{
		display: block;;
	}
}

#tab1{
	width: 1170px;
	@media (max-width: $width-md-max) {
		width: 942px;
	}
	@media (max-width: $width-sm-max) {
		width: 720px;
	}
	@media (max-width: $width-xs-max) {
		width: 100%;
	}

}
#tab2{
	width: 755px;
	@media (max-width: $width-md-max) {
		width: 720px;
	}
	@media (max-width: $width-xs-max) {
		width: 100%;
	}
	.offers-info-column{
		&--heightsm{
			@media (max-width: $width-md-max) {
				min-height: 160px;
			}
			@media (max-width: $width-xs-max) {
				min-height: 0;
			}
		}
	}
}

.popup-summer-sale {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	max-width: 900px;
	text-align: center;
	padding: 0 36px 75px 36px;
	box-shadow: 0 0 100px -20px $main-brown;
	z-index: 1100;
	display: none;

	.intro-section--slider_slide.popup-slide & {
		position: absolute;
		display: block;

		@media (min-width: $width-md-min) {
			padding-bottom: 0;
			top: 0;
			transform: translate(-50%, 0);
		}

		@media (max-width: 1024px) and (orientation: landscape) {
			padding-bottom: 0;
		}
	}

	@media (max-width: $width-ipad-max) {
		width: 95vw;
	}

	@media (max-width: $width-xs-max) {
		padding-left: 18px;
		padding-right: 18px;
		padding-bottom: 0;

		.intro-section--slider_slide.popup-slide & {
			position: absolute;
			top: 1%;
			transform: translate(-50%, 0);

			@media (min-height: 767px) {
				top: 25%;
				transform: translate(-50%, -25%);
			}
		}
	}

	&.open {
		display: block;
	}

	&__logo {
		max-width: 464px;
		margin: 0 auto 20px auto;
		text-align: center;

		img {
			width: 100%;
			display: inline-block;
		}

		@media (max-width: 1024px) and (orientation: landscape) {
			height: 200px;
			img {
				width: auto;
				height: 200px;
			}

			.intro-section--slider_slide.popup-slide & {
				height: 100px;
				img {
					height: 100px;
				}
			}
		}

		@media (max-width: $width-xs-max) {
			height: 100px;

			img {
				width: auto;
				height: 100px;
			}
		}
	}

	h2 {
		font: 38px/43px $helvetica;
		letter-spacing: 2px;
		margin-bottom: 26px;

		@media (max-width: $width-sm-max) {
			br {
				display: none;
			}
		}

		@media (max-width: $width-xs-max) {
			font-size: 16px;
			letter-spacing: 0px !important;
			line-height: 1.2;
			font-weight: 700;
		}

		@media (max-width: 1024px) and (orientation: landscape) {
			.intro-section--slider_slide.popup-slide & {
				line-height: 40px;
			}
		}
	}

	p {
		font: 18px/30px $helvetica;
		letter-spacing: 2px;

		@media (max-width: $width-xs-max) {
			line-height: 1.2;
			font-size: 12px;
			letter-spacing: 0;
		}

		@media (max-width: 1024px) and (orientation: landscape) {
			.intro-section--slider_slide.popup-slide & {
				line-height: 24px;
			}
		}
	}

	&__suburbs {
		padding-top: 16px;
		ul {
			li {
				list-style: none;
				border-bottom: 1px solid $main-brown;
				text-align: left;
				padding-bottom: 3px;
				margin-bottom: 12px;

				a {
					position: relative;
					display: block;
					font: 18px  $helvetica;
					line-height: 1;
					letter-spacing: 2px;
					color: $main-brown;

					&:hover {
						text-decoration: none;
					}

					&:after {
						content: '>';
						display: block;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						color: #ff5100;
					}

					@media (max-width: $width-xs-max) {
						font-size: 14px;
					}
				}
			}
		}
	}

	&__terms {
		font: 10px/11px $graphik !important;
		letter-spacing: 0 !important;

		@media (max-width: $width-xs-max) {
			line-height: 1.2 !important;
			font-size: 8px !important;
		}
	}

	&__inspection {
		height: 65px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: $main-brown;
		font: 18px $helvetica;
		font-weight: 700 !important;
		line-height: 65px !important;
		color: #fff;
		margin-bottom: 0;

		br {
			display: none;
		}

		@media (min-width: $width-md-min) {
			display: none;
		}

		@media (max-width: $width-xs-max) {
			line-height: 1.2 !important;
			font-size: 14px;
			height: auto;
			position: relative;
			padding: 6px 18px;
			margin-bottom: 18px;

			br {
				display: inline-block;
			}

			.intro-section--slider_slide.popup-slide & {
				display: none;
			}
		}

		@media (max-width: 1024px) and (orientation: landscape) {
			.intro-section--slider_slide.popup-slide & {
				display: none;
			}
		}
	}

	&__close {
		display: block;
		position: absolute;
		top: 10px;
		right: 10px;
		width: 16px;
		height: 16px;
		z-index: 2;
		text-indent: -9999px;
		text-align: center;
		background: transparent url('../images/close-popup-black.svg') center center no-repeat;
		background-size: cover;

		.intro-section--slider_slide.popup-slide & {
			display: none;
		}
	}

	@media (min-height: 600px) and (max-height: 1050px) and (min-width: 1025px) {
		&__logo {
			img {
				width: 75%;
			}
		}
	}
	@media (min-height: 600px) and (max-height: 950px) and (min-width: 1025px) {
		&__logo {
			img {
				width: 50%;
			}
		}
		h2 {
			font-size: 20px;
			line-height: 22px;
		}
		p {
			font-size: 16px;
			line-height: 20px;
		}
	}

	@media only screen and (min-device-width: 375px) and (max-device-width: 736px) {
		/*  iphone 6, 7, and 8 plus and non plus in portrait and landscape */
		&__logo {
			margin-bottom: 10px;
			height: 80px;
			img {
				height: 80px;
			}
		}
		h2 {
			margin-bottom: 12px;
		}
		&__suburbs {
			padding-top: 0;
		}
	}

	@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
		/* iphone 6, 7 and 8 NOT PLUS, in portrait and landscape */
		h2 {
			margin-bottom: 6px;
		}
		&__suburbs {
			ul {
				margin-bottom: 6px;
				li {
					margin-bottom: 6px;
				}
			}
		}
		&__terms {
			font-size: 7px !important;
		}
	}
}

.summer-sale-panel {
	margin-bottom: 50px;

	@media (max-width: $width-xs-max) {
		margin-bottom: 25px;
	}

	a{
		color: #000!important;
	}

	&__shell {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: relative;

		.promotion & {
			margin-bottom: 20px;
		}
	}

	&__logo {
		margin-left: -15px;

		img {
			width: 100%;
			height: auto;
		}

		@media (max-width: $width-sm-max) {
			height: 100%;
			position: absolute;
			margin-left: 0;

			img {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}
		}

		@media (max-width: $width-xs-max) {
			margin-left: 0;
			position: relative;

			img {
				position: relative;
				top: 0;
				transform: none;
			}
		}

		.promotion & {
			height: 100%;
			margin-left: 0;
			position: absolute;

			img {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
			}

			@media (max-width: $width-xs-max) {
				position: relative;

				img {
					position: relative;
					top: 0;
					transform: none;
				}
			}
		}
	}
	&__content {
		padding: 30px 30px 30px 15px;

		.promotion & {
			margin-left: 41.66666666667%;
			padding-left: 30px;

			@media (max-width: 1199px) {
				margin-left: 33.333333%;
			}

			@media (max-width: $width-xs-max) {
				margin-left: 0;
			}
		}

		@media (max-width: $width-xs-max) {
			padding-left: 30px;
		}

		h2 {
			font: 38px/43px $helvetica;
			letter-spacing: 2px;
			margin-bottom: 12px;
			br {
				display: none;
			}

			@media (max-width: $width-xs-max) {
				text-align: center;
				font-size: 22px;
				line-height: 26px;
			}

			@media (min-width: 1190px) {
				padding-right: 40px;
			}
		}
	}
	&__terms {
		font: 10px/11px $graphik !important;
		letter-spacing: 0 !important;

		@media (max-width: $width-xs-max) {
			line-height: 1.2 !important;
			font-size: 8px !important;
		}
	}
}
.intro-search-form {
	background: #fff;
	min-height: 50px;
	padding: 40px 0;

	h4 {
		margin-bottom: 30px;
		font-size: 30px;
	}

	&__left-side {
		padding: 25px;
		background: $grey-background-light;

		@media (max-width: $width-md-max) {
			margin-right: 0;
			padding-left: 25px;
			padding-right: 25px;
			margin-bottom: 15px;

			> .row {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}

	.custom-select {
		height: 59px;
		padding-right: 0;

		label {
			font-size: 14px;
			font-weight: 500;
		}

		select {
			-webkit-appearance: none;
			width: 100%;
			height: 34px;
			line-height: 34px;
			border: 1px solid #b2b1b1;
			border-radius: 0;
			padding: 0 7px;
			background: transparent;
			font-size: 14px;
			background: url('../images/icon-arrow-down-dark.svg') no-repeat;
			background-position: calc(100% - 7px) 50%;
			background-size: 12px;
			outline-color: $grey-background-dark;
			html.ie &{
				background-size: 30%;
				background-position: right center;
			}
			@media (max-width: $width-sm-max) {
				font-size: 16px;
			}
		}
		@media (max-width: $width-sm-max) {
			padding-left: 0;
			margin-bottom: 1em;
			&__lot-width {
				padding-right: 7.5px;
			}

			&__lot-depth {
				padding-left: 7.5px;
			}
		}
		@media (max-width: $width-xs-max) {
			&__lot-width {
				padding-right: 0;
			}
			&__lot-depth {
				padding-left: 0;
			}
		}

		.ico {
			&-bed {
				width: 31px;
			}
			&-bath {
				width: 32px;
			}
			&-sofa {
				width: 34px;
			}
			&-bed, &-bath, &-sofa {
				height: 34px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}

	.button-gold {
		line-height: 15px;
		@media (max-width: $width-xs-max) {
			line-height: 25px !important;
		}
	}

	&__build-house {
		@media (max-width: $width-md-max) {
			margin-left: -15px;
		}

		.storey-select {
			padding-right: 0;

			> label {
				display: block;
				font-size: 14px;
				font-weight: 500;
			}

			.btn-group {
				display: flex;
				label.btn {
					height: 34px;
					font-size: 14px;
					color: inherit;
					flex: 1 1 33.33%;
					border: 1px solid #b2b1b1;
					border-radius: 0;
					outline-color: $grey-dark;
					background: transparent;
					&.active {
						background: $sage;
						border-color: $sage;
						color: #fff;
						font-weight: 500;
					}
				}
			}

			@media (max-width: $width-sm-max) {
				padding-left: 0;
				margin-bottom: 1em;
			}
		}
		.buttons {
			display: flex;
			height: 59px;
			align-items: flex-end;

			.button-gold {
				display: inline-block;
				height: 34px;
				line-height: 15px;
				font-weight: 500;

				@media (max-width: $width-sm-max) {
					font-size: 16px;
				}
				@media (max-width: $width-xs-max) {
					line-height: 25px !important;
				}
			}
			.build-house__go {
				width: 100%;
				text-align: center;

				@media (max-width: $width-sm-max) {
					margin-top: 1em;
					width: 30vw;
				}
				@media (max-width: $width-xs-max) {
					width: 50vw;
				}
			}

			@media (max-width: $width-sm-max) {
				padding-left: 0;
				padding-right: 0;
				justify-content: center;
			}

			@media (max-width: $width-xs-max) {
				height: 50px;
				clear: both;
			}
		}

		.button-gold {
			display: inline-block;
			height: 34px;
			line-height: 15px;

			@media (max-width: $width-md-max) {
				width: 120px;
			}
		}
	}
}