/* ------------ product & range ------------- */
.houses-filter__sidepanel .houses-filter__sidebar{
	overflow-y: auto;
	overflow-x: hidden;
}
.houses-filter__sidepanel:not(.sticky) .houses-filter__sidebar, .houses-filter__sidepanel.sticky-bottom .houses-filter__sidebar{
	max-height: inherit !important;
}


.property__view-more .top .row:last-child p{
			position: absolute;
			bottom: 0px;
		}

/* -----------misc------------ */
body.no-scroll{    position: absolute; width: 100vw; height: 100vw; overflow: hidden;}
.header__nav a.inactive_link, .header-nav--mobile a.inactive_link{ pointer-events: none; cursor: default;}
/* -----------widget enquiry------------ */
@media (max-width: 992px){
	.section-home__aside .widget-enquiry.sticky {

    	width: 100%;
    	top: inherit;
	}
}
/* -----------header------------ */
.header__nav li.li-contactus {  float: right;

	@media (max-width: $width-md-max) {
		    padding-right: 4px;
	}

}

/* -----------series------------ */
.section-design .section-design__body h2 {display: inline-block;/*  margin-bottom: 30px; */ font-family: $helvetica;letter-spacing: 0.3em;    padding-top: 10px;    font-size: 14px;text-transform: uppercase;}
body.readybuilt .section-design .section-design__body h2 { display: block; float: none; }
body.readybuilt .design{
	&__select {
		float: none; margin-top: 12px; margin-left: 0; padding-left: 0;
		li {
			a {
				border-color: transparent;
				background: transparent;
				&.active, &:hover {
					border-color: $main-brown;
					background: transparent;
					color: $main-brown;
				}
			}
		}
	}
}


.change_on_series_home_click{display: none;}
.change_on_series_home_click.active{
	display: block;
	@media (max-width: $width-xs-max) {
		width: 100%;
	}
}
	.change_on_series_home_click.flex.active{display: flex;}

.new-homes_series .widget-enquiry__head a{
	width: 100%;
	@media (max-width: $width-xs-max) {
		display: flex; flex: 0 0 100%;
	}
}
.widget-enquiry__head .series_home_price h3{display: inline-block; width: 90%;}
@media (min-width: $width-sm-max) {
	.widget-enquiry__head .series_home_price h3{ display: block; }
}
.widget-enquiry__head h4{font-family: $graphik, sans-serif;margin-bottom: 4px;}
.widget-enquiry__head span.toggle-diclaimer{
	font-size: 12px; color: $sage; font-weight: 500; cursor: pointer;
	@media (max-width: $width-xs-max) {
		margin-top: 3px; display: block; color: #333; padding-bottom: 0;
	}
}
.widget-enquiry__head .series_home_price .ico-heart {vertical-align: text-bottom;}
.new-homes_series .widget-enquiry__head a:hover { text-decoration: none;}

.design_selectors{display: inline-block;margin-left: 80px;}
.design_selectors h4 { display: inline-block; color: $sage;  padding: 4px; line-height: 1; padding-top: 6px; cursor: pointer;}
	.design_selectors h4.active {border: 2px solid $sage;}

@media(max-width: $width-xs-max){
	.new-homes_series .list-links-secondary li {
	    min-width: 50%;
	}
}

.new-homes_series .section__group .section__body, body.readybuilt_lot .section__group .section__body {
	&.video, &.video2, &.video3, &.virtual-tours {
		.iframe-container{
			padding-bottom: 67.0588%;
			margin: 18px 0 45px;

			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
			}
		}
	}
}

.new-homes_series .slider-images-secondary--small {
    margin-top: 92px;
    margin-bottom: 0px;
    padding-top: 0px;
}
.new-homes_series .slider-images-secondary__slides .slick-slide:not(.slick-current) .slider-images-secondary__slide-content{
	opacity: 0;
	visibility: hidden;
}

.new-homes_series .slider-images-secondary--small .slider-images-secondary__slides {
    height: 570px;
}

.estate, .display-centre-single{
	#gallerySection{
		border-bottom: none;
		 	p{
			    min-height: 20px;
			    margin-top: 12px;
			}

	}
}

.new-homes_series, .house-and-land, .readybuilt, .display-for-sale_detail {
	#gallerySection{
		margin-top: 57px;
		border-bottom: none;

		body.readybuilt & {
			padding-top: 60px;
		}

		header{
			margin-bottom: 20px;
			h2{
				margin-right: 20px;
				}
		}
		.section__body{
			opacity: 0;
			visibility: hidden;
			position: fixed;
			-webkit-transition: opacity 1.5s ease-in;
		    -moz-transition: opacity 1.5s ease-in;
		    transition: opacity 1.5s ease-in;
		    max-width: 1140px;
		    &.active{
			    opacity: 1; visibility: visible;position: relative; max-width: unset;
		    }
		    p {
			    min-height: 20px;
			    margin-top: 12px;
			    color: $body-text-color;
			}
		}
		.slider-images{
			margin-bottom: 0px;
			 color: $body-text-color;
		}
		@media(max-width: $width-xs-max){
			.nav-secondary{
			padding-top: 30px;

			}
			> p{
				padding: 0px 15px;
			}
		}
	}
}

.new-homes_series #gallerySection .section__body img{width: 850px;height: 570px;}




.new-homes_series .filters select option.hide{display: none;}

.new-homes_series .new-homes__CTA{
		color: #fff;
		margin: 125px 0 25px;
		padding: 0 !important;
		position: relative;
	}
.gold-absolute-left-block{
	    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    background: #ad9554;
}
.new-homes_series .new-homes__CTA h2  {color: inherit;}
.new-homes_series .new-homes__CTA a{color: inherit; text-decoration: underline;}
.new-homes_series .new-homes__CTA .btn-white:hover{color: $sage; }

.new-homes_series, .house-and-land {
	.section__body {
		&-cta {
			width: 100%;
			height: auto;
			.collection--cta {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				position: relative;
				width: 100%;
				height: auto;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
				}
				img {
					width: 100%;
					height: auto;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
	}
}
#collectionsCTA {
	margin-top: 60px;
	border-bottom: none;
}

/*
.accordion__section.choices_and_add_ons{padding-bottom: 10px;}
@media(max-width: $width-xs-max){
.new-homes_series .accordion__section.choices_and_add_ons.accordion__section-expanded{padding-bottom: 30px;}
}
.accordion__section.choices_and_add_ons p{position: relative;font-size: 14px; letter-spacing: -0.02em; font-weight: 500; color: #231f20; padding-left: 15px;}
.accordion__section.choices_and_add_ons p:before{
	content: '';
    position: absolute;
    width: 9px;
    height: 7px;
    top: 9px;
    left: 0px;
    margin-top: -4px;
    background-image: url(../images/check.png);
    background-size: 100% 100%;
    }

.choices_and_add_ons.accordion__section .accordion__col6{width: 50%; float: left;}
@media(max-width: $width-xs-max){
	.choices_and_add_ons.accordion__section .accordion__col6{width: 100%;}
}
*/
@media(max-width: $width-xs-max){
	.new-homes_series .design__group.flex.active {

	    display: block;

	}
}

.new-homes__modal {
	position: fixed;
	visibility: hidden;
	opacity: 0;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: rgba(179, 179, 179, 0.8);
    z-index: 10000;

}
	.new-homes__modal .close__zoom{
	    position: absolute;
	    right: 20px;
		top: 20px;
	    cursor: pointer;
	    width: 24px;
		height: 23px;
		z-index: 100;
	}
/* disclaimer modal */
.disclaimer-modal.new-homes__modal .inner-box{
    width: 600px;
    min-height: 400px;
    background-color: rgba(245, 245, 245, 0.85);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 177px;
    padding: 55px;
    @media(max-width: $width-xs-max){
	    left: 50%;
	    transform: translate(-50%,-50%);
	    top: 50%;

	    max-width: 90%;
	    max-height: 90%;
	    overflow: auto;
		padding:50px 20px 20px;
    }

}
	.disclaimer-modal.new-homes__modal .inner-box h4{
		margin-bottom: 30px;
		margin-top: 30px;
		font-family: $graphik, sans-serif;
	    font-size: 24px;
	    letter-spacing: -0.03em;
	}
	.disclaimer-modal.new-homes__modal .inner-box p{
	    margin-bottom: 21px;
		line-height: 1.6;
		font-size: 14px;
	    letter-spacing: -0.01em;
	    color: #231f20;
	}
/* zoom in modal */
.zoom-in-modal .wrapper {
    width: 96%;
    height: 96%;
    left: 2%;
    top: 2%;
    position: absolute;
    background-color: #fff;
}

 .zoom-in-modal .wrapper .center{
	width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
	left: 50%;
	  -webkit-transform: translate(-50%, -50%);
	  -ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
  }
  .zoom-in-modal .wrapper .center .info-container{ height: 100%;}

  	.zoom-in-modal .wrapper .center .block{position: absolute;height: 100%;}

  		.zoom-in-modal .wrapper .center .block.left{ width: calc(35% - 50px); overflow: auto;}

  			.zoom-in-modal .wrapper .center .block.left .float{float: left; margin-right: 20px; }
	  		.zoom-in-modal .wrapper .center .block.left h2{margin-bottom: 25px;font-family: $graphik;}
	  		.zoom-in-modal .wrapper .center .block.left h2.title{margin-bottom: 45px;}

	  		.zoom-in-modal .wrapper .center .block.left h4{	font-family: $graphik; font-weight: 900;margin-bottom: 15px;}
	  		.zoom-in-modal .wrapper .center .block.left .list-features{display: block;}

	  			.zoom-in-modal .wrapper .center .block.left .list-info-secondary li{font-size: inherit;color: $body-text-color;font-family: $graphik;}
	  		.zoom-in-modal .wrapper .center .block.left a{font-size: 18px; display: inline-block; }
	  		.zoom-in-modal .wrapper .center .block.left .accordion__body a{font-size: inherit; max-width: 80%; display: block;}

	  		.zoom-in-modal .wrapper .center .block.left .accordion__aside { flex: 0 1 60%; }
	  		.zoom-in-modal .wrapper .center .block.left .accordion__content { flex: 0 1 100%; }


	  		@media (max-width: 1350px){
		  		.zoom-in-modal .wrapper .center .block.left .accordion__body li{font-size: 14px;}
		  		.zoom-in-modal .wrapper .center .block.left .accordion__body h3{font-size: 15px;}
	  		}

	  		@media (max-width: 1200px){
		  		.zoom-in-modal .wrapper .center .block.left .accordion__body li{font-size: 12px;}
		  		.zoom-in-modal .wrapper .center .block.left .accordion__body h3{font-size: 13px;}

		  		.zoom-in-modal .wrapper .center .block.left .accordion__content {flex: 0 0 185px;}
		  		.zoom-in-modal .wrapper .center .block.left .list-dimensions strong{    width: 110px;}
	  		}
	  		@media (max-width: 850px){
		  		.zoom-in-modal .wrapper .center .block.left .accordion__content {flex: 0 0 164px;}
		  		.zoom-in-modal .wrapper .center .block.left .list-dimensions strong{    width: 90px;}
	  		}

	  		.zoom-in-modal .wrapper .center .block.left .accordion__group{border-bottom: none; padding: 10px 0px;}
	  		.zoom-in-modal .wrapper .center .block.left .controls{margin-bottom: 50px;}
		  		.zoom-in-modal .wrapper .center .block.left .controls select{
		  		 	    -webkit-appearance: none;
					    height: 40px;
					    line-height: 40px;
					    border: 1px solid #b2b1b1;
					    border-radius: 0;
					    padding: 0px 10px;
					    background: transparent;
					    font-size: 16px;
					    background: url(../images/icon-arrow-down-dark.svg) no-repeat;
					    background-position: 95% 50%;
					    background-size: 12px;
					    min-width: 150px;
					    margin-right: 20px;
						@media(max-width: $width-sm-max){
							min-width: 100%;
							margin-bottom: 10px;
						}
						@media (max-width: $width-ipad-max) {
							width: 98%;
							margin-bottom: 10px;
						}
		  		}

		  		.zoom-in-modal .wrapper .center .block.left .controls a{margin-right: 15px;}

		  		@media(max-width: $width-sm-max){
			  		.zoom-in-modal .wrapper .center .block.left{
			  			display: none;
				  		.list-info-secondary li + li {
						    margin-left: 15px;
						}
			  		}

		  		}

		  		@media (max-width: $width-ipad-max) {
		  			.zoom-in-modal .wrapper .center .block.left {
		  				overflow-x: hidden;
		  				h2.title { margin-bottom: 20px; }
		  				.controls { margin-bottom: 20px; }
		  				.float h2 { margin-bottom: 0; }
		  				.list-info-secondary:nth-of-type(2) { margin-bottom: 20px; }
		  				.accordion__group { display: block; }
		  				.accordion__section { padding-bottom: 20px; }
		  				.zoom_choices_and_add_ons {
		  					.accordion__col6 {
		  						width: 100%;
		  						a {
		  							font-size: 16px;
		  						}
		  					}
		  				}
		  			}
		  		}

		.zoom-in-modal {
			.zoom_choices_and_add_ons {
				.accordion__col6 {
					width: 50%;
					p {
						a {
							padding-left: 30px;
							max-width: 90%;
							position: relative;
							color: #231f20;
							text-decoration: none;
							&:before {
								content: '';
								position: absolute;
								width: 20px;
								height: 20px;
								top: 0;
								left: 0;
								background: $grey-background-dark;
								border: 4px solid $grey-background-dark;
								border-radius: 50%;
							}
							&.selected {
								&:before {
									background: $main-brown;
								}
							}
						}
					}
				}
			}
		}

  		.zoom-in-modal .wrapper .center .block.right{ width: 65%; right: 0px; height: 100%; visibility: hidden; }
  			.zoom-in-modal .wrapper .center .block.right .floorplan { height: 100%; display: none; }
  			.zoom-in-modal .wrapper .center .block.right .floorplan.active { display: block; }
  		 	.zoom-in-modal .wrapper .center .block.right object{ width: 100%; height: 100%;}

  		 	@media (max-width: $width-sm-max) {
  		 		.zoom-in-modal .wrapper .center .block.right {
  		 			width: 100%;
  		 		}
  		 	}

  		.zoom-in-modal {
  			&__pdf {
  				position: absolute;
  				right: 0;
  				bottom: 0;
				a {
					font-size: 18px;
					font-weight: 500;
					background: #fff;
					display: inline-block;
					padding: 10px;
				}
  			}
  		}

	  		  @media(max-width: $width-xs-max){
		  		.zoom-in-modal .wrapper .center .block.left{
			  		display: none;
		  		}
		  		.zoom-in-modal .wrapper .center .block.right{
			  		width: 100% ;
		  		}
	  		}
/* -----------news------------ */
.section-news__group-image {
	min-height: 500px;
	@media(max-width: $width-sm-max){
		min-height: 0px;
	}
}


.news-post-sidebar .section-news__aside , .section-news .section-news__aside{position: relative;}
.section-news img{max-width: 100%;}

.article--news .section-secondary__media{
	    margin-bottom: 49px;
}


/* ------------slider------------ */
.slider-images-secondary__slide-image {
   height: 700px;
   width: 100%;
}

.general .slider-images-secondary .img-container{
	height: 700px !important;
	@media(max-width: $width-sm-max){
	    height: 65vw !important;
	    img{
		    height:100% !important;
	    }
	    .slider_bg_overlay {
		    max-height: none !important;
	    }
	}
}

.general .slider-images-secondary .slick-arrow:before {
    content: '';
}
	.slider-images-secondary--small .slider-images-secondary__slide-image {
	   height: 570px;

	}
/* ------------ new homes ------------ */
	.new-homes .dont_show_page{position: fixed;visibility: hidden;opacity: 0;z-index: -10;}
/* ------------property------------ */
.new-homes, .new-homes_series, .display-centre-single, .house-and-land-landing-page, .readybuilt-landing, .estate, .house-and-land, .readybuilt, .search-results, .display-for-sale, , .display-for-sale_detail {
	.property{
		a.property-item__photo-clickable { position: relative; display: block;max-height: 100%;}
		p.disclaimer { font-size: 11px; }
		img.property-item__photo {opacity: 0;visibility: hidden; display: none; }
		.thumbnail_image {width: 100%; height: 100%; /*position: absolute;*/ background-size: cover; background-position: center top; background-repeat: no-repeat; padding-bottom: 75%; }
	}
}
/* ------------property-view more------------ */
.property__view-more .top p span.line.small.grey { width: 30px; visibility: hidden;  }
.property__view-more .top p span.newhome_button { font-size: inherit; color: $sage; padding: 4px; margin-right: 0px; cursor: pointer; border: 2px solid transparent; width: 80px; text-align: center; }
.property__view-more .top p span.newhome_button.grey {color: #b2b1b1; border-color: #b2b1b1;}
.property__view-more .top p span.newhome_button.active { border: 2px solid $sage;}
.property__view-more .top p span.newhome_button.active.grey {color: #b2b1b1; border-color: #b2b1b1;}
.property__view-more .top.q-collection {
	p span.newhome_button { color: $q-collection-color; }
	p span.newhome_button.active { border-color: $q-collection-color; }
	.row .view-home.button-gold { 
		background-color: $q-collection-color; 
		&:hover {
			background-color: #fff; 
			color: $q-collection-color;
			background-image: url(../images/icon-circle-right-arrow-teal.svg); 
		}
	}
}
.property__view-more .top .home_info{display: none;}
.property__view-more .top .home_info.active{display: block;}
.property__view-more .top .home_info p{bottom: 0px; position: absolute;}
.property__view-more .top p span.newhome_button:first-child {margin-left: 20px;}

 .property__view-more object{ max-height: 100%; width: 100%; display: block; }
/*
.property__view-more .bottom.floorplan{display: none;}
.property__view-more .bottom.floorplan.active{display: block;}
*/
/* ------------collections------------ */
.new-homes .collections .property{display: block;padding: 0px 15px;}

.new-homes .collections .row{ position: relative;}


/* ------------general content page------------ */
	.slider-images-secondary .slick-arrow {top: 50%;}
	.slider-images-secondary__slides .slick-slide:not(.slick-current) .slider_bg_overlay{ width: 100%; height: 100%;background-color: rgba(60, 60, 60, 0.89);z-index: 1;position: absolute;}

	.slider-images-secondary--small .slider-images-secondary__slides{height: 570px;}

	.section-secondary__media iframe.auto-height{
		width: 100%;
		    border: none;
	}

	.pagination-widget li.previous {
	    position: relative;
	    top: -1px;
	    margin-right: 5px;
	}

	.pagination-widget li.on a:before{
		content: "( ";
	}

	.pagination-widget li.on a:after{
		content: " )";
	}

	.general .features .feature {margin-bottom: 0px;}
	.general .features > div{margin-bottom: 26px;}

	.general .tabs {margin-bottom: 0px;}
	/* spacing on general page */
	.general main > .article, .general main > section , .general main > .slider-images-secondary, .general main > .container{
		padding: 0px;
		margin-top: 0px;
		margin-bottom: 125px;
	}
	/*
	 .general main > .intro-primary, .general main > :last-child{
		margin-bottom: 70px;
	}
	*/
	.general main > .intro-primary {
		margin-bottom: 70px;
	}
	.general main > .section-sticky-limit {
		margin-bottom: 0;
	}
		.general main > :last-child.section-contact{
			margin-bottom: 0px;
		}
		.general main .section-secondary__media, .general main .features, .general main .accordion-secondary {
			margin-bottom: 0px;
		}


	.general .article .container :last-child{margin-bottom: 0px;}

	.general .accordion-secondary__body{max-height: none;    padding: 0px;}
	.general .article.accordion-secondary__body h3{ margin: 39px 0px;}
	.general .article.accordion-secondary__body h3:first-child{margin-top: 0px;}
	.general .article.accordion-secondary__body h4 {margin-bottom: 10px;}
	.general .article.accordion-secondary__body ul {margin-bottom: 20px;}
	.general .accordion-secondary h3{font-size: 22px; line-height: 1;}

	.general .feature img{width: 100%;}

	.general .article ul { margin-bottom: 21px;}
	.general .article li { margin-bottom: 5px;}

	.general form.cta-signup, .general form.ctaform-signup{max-width: 100%; width: 750px; margin: auto; text-align: left;}
	.general form.cta-signup .form-contact__field-container, .general form.ctaform-signup .form-contact__field-container{-webkit-box-flex: 0; -webkit-flex: 0 1 48%; -ms-flex: 0 1 48%; flex: 0 1 48%;}

	.general form.cta-signup input[type=submit] , .general form.ctaform-signup input[type=submit] {
		position: relative; /* margin-top: 26px; */ height: 40px; width: 48%;
		@media(max-width: $width-sm-max) {
// 			width: 100%;
		}
	}
// 	.general .article h4{ margin-bottom: 21px;}


/*
	.general .tabs h2{font-size: 32px; line-height: 1; letter-spacing: 0.02em; margin-bottom: 40px;}
	.general .tabs h3 {font-family: $graphik, sans-serif; font-size: 32px; line-height: 1; letter-spacing: -0.03em; margin-bottom: 39px;}
	.general .tabs h4 {font-family: $graphik, sans-serif; font-size: 24px; letter-spacing: -0.03em; margin-bottom: 21px;}
	.general .tabs p { margin-bottom: 21px; line-height: 1.6;}
*/

	.general .display-centre-single.container {
		margin-top: 30px;
		margin-bottom: 0;
	}

/* ------------display centers------------ */
	.display-centre-single .section-info__group-homes .col-md-3:nth-child(4n+1){
		clear: none;
	}
	.display-centre-single .property { padding: 0px 15px;}

	.display-centre-single .slider-images-secondary__slide-image{
		    width: 750px;
		    height: 500px;

		    background-position: center center;
		    background-repeat: no-repeat;
		    background-size: cover;
	}

	.article-display__content { padding: 0px; padding-bottom: 40px;}

/* -----------house-and-land------------ */
.house-and-land, .estate, .readybuilt, .display-for-sale_detail, .new-homes_series {
	.property { padding: 0px 15px;}
	.load-more{
		//margin-left: 15px;
	}
}

/* -----------where we build------------ */
.where-we-build{
	.download-map{
		float: right;

	}
	.text-below-map{
		display: inline-block;
		width: 50%;

	}
	@media(max-width: $width-xs-max) {
		.download-map{
			float: left;
			margin-bottom: 20px;
		}
		.text-below-map{
			width: 100%;
		}
	}
}