$testimonial-lg-max: 1530px;
.testimonial{
	//min-height: 420px;
	position: relative;
	&__grey{
		background-color: $charcoal;
		overflow: hidden;
		border-top: 20px solid $sage;
		.testimonial-text{
			padding: 90px 0px 90px 150px;
			@media (min-width:$testimonial-lg-max){
				padding: 115px 100px 115px 115px;
			}
			@media (max-width:$width-md-max){
				padding: 60px 40px 60px 80px;
			}
			@media (max-width:$width-xs-max){
				padding: 30px;
			}
			p{
				color: #fff;
				font-size: 24px;
				padding-left: 10%;
				line-height: 1.285;
				    margin-bottom: 23px;
				@media (min-width:$testimonial-lg-max){
					font-size: 28px;
				}
				@media (max-width:$width-xs-max){
					font-size: 18px;
					 margin-bottom: 15px;
				}
			}
			h3{
				font-size: 24px;
				color:$sage;
				padding-left: 10%;
				font-family:$graphik;
			}
			h6{
				color: #fff;
				font-size: 18px;
				padding-left: 10%;
				line-height: 1.4;
				font-family: $graphik;
				@media (min-width:$testimonial-lg-max){
					font-size: 20px;
				}
				@media (max-width:$width-xs-max){
					font-size: 13px;
				}
			}
			.bg-icon{
				content: "";
				width: 100%;
				background-size: contain;
				z-index: 2;
				@media (min-width:$testimonial-lg-max){
					width: 100%;
				}
				@media (max-width:$width-xs-max){
					width: 100%;
				}
			}
			&__content{
				z-index: 4;
				position: relative;
				.series{
					font-size:14px;	
					
					margin-bottom: 15px;
				}
				h3{
					font-size: 24px;
					
					margin-bottom: 15px;
					font-family:$graphik;
				}


			}
		}
		.testimonial-image{
			position: absolute;
		    width: 50%;
		    height: 100%;
		    right: 0;
		    background-position: center;
		    background-size: cover;
		    background-repeat: no-repeat;
		}
	}
	&__white{
		background-color: #fff;
		overflow: hidden;
		border-top: 20px solid #ad9652;
		.testimonial-text{
			padding: 115px 100px 115px 115px;
			border-right: 1px solid #fff;
			@media (min-width:$testimonial-lg-max){
				padding: 115px 0 115px 215px;
			}
			@media (max-width:$width-md-max){
				padding: 60px 40px 60px 80px;
			}
			@media (max-width:$width-xs-max){
				padding: 30px;
			}
			p{
				color: #77787b;
				font-size: 24px;
				padding-left: 10%;
				line-height: 1.285;
				    margin-bottom: 23px;
				@media (min-width:$testimonial-lg-max){
					font-size: 28px;
				}
				@media (max-width:$width-xs-max){
					font-size: 18px;
					 margin-bottom: 15px;
				}
			}
			h3{
				font-size: 24px;
				color:$sage;
				padding-left: 10%;
				font-family:$graphik;
			}
			h6{
				color: #77787b;
				font-size: 18px;
				padding-left: 10%;
				line-height: 1.4;
				font-family: $graphik;
				@media (min-width:$testimonial-lg-max){
					font-size: 20px;
				}
				@media (max-width:$width-xs-max){
					font-size: 13px;
				}
			}
			.bg-icon{
				content: "";
				width: 100%;
				background-size: contain;
				z-index: 2;
				@media (min-width:$testimonial-lg-max){
					width: 100%;
				}
				@media (max-width:$width-xs-max){
					width: 100%;
				}
			}
			&__content{
				z-index: 4;
				position: relative;
				.series{
					font-size:14px;	
					
					margin-bottom: 15px;
				}
				h3{
					font-size: 24px;
					
					margin-bottom: 15px;
					font-family:$graphik;
				}
			}
			
		}
		.testimonial-image{
			position: absolute;
		    width: 50%;
		    height: 100%;
		    right: 0;
		    background-position: center;
		    background-size: cover;
		    background-repeat: no-repeat;
		}
	}


	&.container{
		width: 100%;
		margin-bottom: 0 !important;
	}
	
}

// page
$testimonial-grey: #999999;
.testimonials{
	.article--description{
		background:#fff;
	}
	&-content{
		    position: relative;
		background-color:#f2f2f2;
		padding:30px;
		.series{
			font-size:14px;	
			color:$testimonial-grey;
			margin-bottom: 15px;
		}
		h3{
			font-size: 24px;
			color:$sage;
			margin-bottom: 15px;
			font-family:$graphik;
		}
		.short_quote{
			font-size: 14px;
			margin-bottom: 0px;
		}
		hr{
			margin: 30px 0;
			border-top-color: #fff;
		}
		.description{
			p{
				font-size: 28px;
				//font-family:$publico;
				font-style: italic;
				line-height:1.2;
			}
		}
	}
	&-play-button{
		position:absolute;
		height:100px;
		width:87px;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
		cursor:pointer;
	}
	iframe{
		background-color:#000;
		position:absolute;
		width:100%;
		height:100%;
		top: 0;
    	left: 0;
    	border:none;
	}
	&--grid{
		margin-top:-30px;
		padding-bottom: 70px;

		&-item, &-more{
			margin-top:30px;
			position:relative;
			&:before{
				content:'';
				background-color: #fff;
				position: absolute;
				width:calc(100% - 30px);
				height:100%;
				top:0;
				left:15px;
				
			}
			&-image{
				position: relative;
				padding-top:76.33587786%;
				width:100%;
				background-size:cover;
				background-position:center;
				background-repeat:no-repeat;
				background-color:$testimonial-grey;
				
			}
			.testimonials-content{
				text-align:center;
				.description{
					min-height: 170px;
					margin-bottom:30px;
					@media (max-width:$width-xs-max){
						min-height: 120px;
					}
					
					p{
						font-size: 28px;
					}
				}
			}
			&-2col{
				.testimonials-content{
					min-height:100%;	
				}
				&_brown{
					.testimonials-content {
						background-color:#f2f2f2;
						color:#333;
						.series, h3, .short_quote{
							color:#333;
						}
					}
				}
			}
			&-see-more{
				cursor: pointer;
				@media (max-width: $width-xs-max) {
					cursor:initial;
				}
			}
		}
		&-item{
			&:before{
				content:"";
				position:absolute;
				width:calc(100% - 30px);
				height:100%;
				background-color: #f2f2f2;
				left:15px;
				top:0;
			}
			.testimonials-play-button{
				width:55px;
				height: 63px;
			}
		}
		&-more{
			@media (max-width: $width-xs-max) {
				display:none !important;
			}
			.no-padding{padding:0px;}
			&-image{
				position: relative;
				padding-top:56.25%;
				width:100%;
				background-size:cover;
				background-position:center;
				background-repeat:no-repeat;
				min-height:100%;
			}
			.close-more{
				cursor:pointer;
				position: absolute;
			    top: 20px;
			    right: 35px;
			    z-index: 1;
			    height: 20px;
    			width: 20px;
			}
		}
	}

	&--slider{
		padding-top: 70px;
		&-1-slide{
			padding-bottom:80px;
		}
		&__slide{
			position:relative;
			.no-padding{padding:0px;}
			&-image{
				position: relative;
				padding-top:56.25%;
				width:100%;
				background-size:cover;
				background-position:center;
				background-repeat:no-repeat;
			}
			.testimonials-content{
				@media(max-width: $width-sm-max){
					height:auto !important;
				}
			}
			&:not(:first-child){
				.startup_loading &{
					position: absolute;
					opacity: 0;
				}
			}

		}
		.slick-initialized .slick-slide {
		    float: none;
		    display: inline-block;
		    vertical-align: middle;
		}

		.slick-dots{
			position: relative;
    		padding: 30px 0;
    		    bottom: 0;
			li{
				height:15px;
				width: 15px;
				border-radius:100%;
				border:1px solid $sage;
				&.slick-active{
					background-color:$sage;
				}
				button{
					display:none;
				}
			}
		}
	}
}