.inclusions{
	.list-menu{
		&.breeze{
			background-color: #b4b4b4;
			ul li a {color: #646464;}
			.active{
				color: #fff;
			}
		}
		&.plantation{
			background-color: $charcoal;
			ul li a {color: #97928e;}
			.active{
				color: #fff;
			}
		}
		&.reserve{
			background-color: $sage;
			ul li a {color:#d6caa9;}
			.active{
				color: #fff;
			}
		}
	}

	h2.name{
		text-align: center;
		font-size: 50px;
		padding-bottom: 74px;
		@media(max-width: $width-sm-max){
			padding-bottom: 15px;
			    font-size: 30px;
		}
	}
	.intro-text{
		@media(max-width: $width-xs-max){
			padding-bottom: 10px;
		}
		.article{

			.container {
				margin: auto !important;
				h2{
					&:first-child{
						color: #333;
					}
					    font-family: $graphik;
					margin-bottom: 0px;
					color: #C0C0C0;
				}
			}
		}
	}
	.list-menu{
		 background-color: #efefef;
		 .active{
		     color: #231f20;
		 }
	}
	@media(max-width: $width-sm-max){
		&.modal {
		    padding-left: 0px !important;
			padding-right: 0px !important;
		}
		.hotspot{
			.container{
				padding: 0px;
			}
		}
	}
	.form-enquiry-secondary {
		@media(max-width: $width-sm-max){
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	#hotspot_image{

		.image_wrapper{
			display: none;
			position: relative;
			&.active{
				display: block;
			}
			p.caption {
			       letter-spacing: -0.02em;
			    color: #918f90;
			    margin-top: 10px;
			    font-style: italic;
				font-size: 12px;
				@media(max-width: $width-xs-max){
					    max-width: 90%;
					    margin-left: auto;
					    margin-right: auto;
				}
			}
		}
	}


	.slider-images-secondary{
		margin-bottom: 0px;
		.slick-slide{
			&:not(.slick-current){
				.slider_bg_overlay{
					//background-color: rgba(0,0,0,.6);
					background-color:rgba(255,255,255,0.75);
				}
			}

			height: 120px;
			@media(max-width: $width-sm-max){
				width: auto;

			}
			@media(max-width: $width-xs-max){
				width: 33vw !important;
				height: 60px !important;

			}


			p{
				    position: absolute;
				    bottom: 0px;
				    color: $body-text-color;
				    left: 50%;
				    transform: translate(-50%);
				    font-size: 16px;
				     z-index: 10;
			         width: 80%;
			          text-align: center;
			          @media(max-width: $width-xs-max){
						color: $sage;

					}
			}
			&.slick-active{
				p{
					color: #fff;
					@media(max-width: $width-xs-max){
						color: $body-text-color;
						display: inline-block;
						width: auto;
						&:after{
							content: " ";
						    position: absolute;
						    height: 2px;
						    width: 100%;
						    bottom: 0px;
						    background-color: $grey-background-dark;
						    left: 0;
						}
					}
				}
			}
			.img-container{
				height: 100% !important;
				.slider_bg_overlay{
					@media(max-width: $width-xs-max){
						opacity: 0px;
						visibility: hidden;
					}
				}
				img{
					@media(max-width: $width-xs-max){
						width: auto!important;
					    height: 100%!important;
					    opacity: 0px;
					    visibility: hidden;
					    outline: none;
					    min-height:  0px;
					}
				}
			}

		}
		.slick-next {
			background-image: url(/resources/images/arrow-right-gold.png);
			@media(max-width: $width-xs-max){
			    right: 35px;
			}
		}
		.slick-prev {
			background-image: url(/resources/images/arrow-left-gold.png);
			@media(max-width: $width-xs-max){
			    left: 35px;
			}
		}



		.slick-arrow{
			top: 50%;
		    margin-top: 0px;
			@media(max-width: $width-xs-max){
				top: 0;
				transform: none;
			}
		    &:before{
			    content: '';
		    }
		}
	}
	.accordion-secondary{
		display: none !important;
		&.active{
			display: block !important;
		}
		.accordion-secondary__head{
			h3{
				margin-bottom: 0px;
				@media(max-width: $width-xs-max){
					font-size: 18px;
				}
			}
		}
		    margin-bottom: 0px;
	}
	&.modal {
		.intro-text{
			.article {
				padding:0px 0px 74px;
				}
		}
			.article {

				@media(max-width: $width-xs-max){
					padding-bottom: 15px;

				}

			}
		}
	.article {
		padding:74px 0px;

		@media(max-width: $width-xs-max){
			padding-bottom: 15px;
			padding-top: 25px;
			.col-sm-12{
				&:first-child{
					margin-bottom: 0px;
				}
			}
			h2{
				    font-size: 18px;
			}

		}
		&.accordion-secondary__body{
			padding-bottom: 0px;
			padding-top: 0px;
		}
		.image_caption{
			display: none;
			    margin-top: 20px;
			margin-bottom: 40px;
		    padding-right: 40px;
		    @media(max-width: $width-xs-max){
			     padding-right: 0px;
			     margin-bottom: 0px;
			     margin-top: 0px;
		    }

			p{
				font-size: 32px;
				line-height: 1.1;
				@media(max-width: $width-xs-max){
					font-size: 22px;
				}
			}
			h2, p{
				font-family: $graphik;
				&:first-child{
					margin-bottom: 20px;
					font-family: $helvetica;
					letter-spacing: 0.3em;
					text-transform: uppercase;
					font-size: 14px;
				}

				em{
					color: #333;
				}
			}
			&.active{
				display: block;
			}
		}
		.button-gold{
			width: 300px;

		    display: inline-block;
		    .ios &, html.safari &{
					    margin-bottom: 5px;
				    }
		    i{
			    float: right;
			    margin-top: 2px;
		    }
		    @media(max-width: $width-xs-max){
			    max-width: 100%;
		    }
		}

	}

	.section-secondary{
		&.collection_links{
			.container{

			    margin-bottom: 100px;
			    @media(max-width: $width-sm-max){
				    padding: 0px;
				    margin-bottom: 0px;
				    .col-sm-12{
					    padding: 0px;
				    }
			    }
			}
			.top{
				height: 150px;
				position: relative;
				span{
					position: absolute;
					bottom: 0px;
					font-size: 20px;
					padding: 0px 20px 10px;
					width: 70%;
					font-weight: 200;
				}
				&.grey{
					background-color: #dcddde;
					color: #333;
					svg{
						path, circle{
							stroke:#333;
						}
					}
				}
				&.gold{
					background-color: $sage;
					color: #fff;
					svg{
						path, circle{
							stroke:#333;
						}
					}
				}
				&.brown{
					background-color: $main-brown;
					color: #fff;
				}
				svg{width: 25px; height: 26px; position: absolute;bottom: 10px;right: 40px;}

			}
			.image{
			    height: 320px;
				background-position: center center;
				background-size: cover;
				&:hover{
					opacity: 0.8;
				}
			}
		}
	}
}

#inclusionsSection {
    border-bottom: none;

    .list-images{
	    padding-bottom: 0px;
    }
    .readybuilt &{
	    border-bottom: none;
	    padding-top: 0;
    }

    body.new-homes_series & {
    	border-bottom: 0;
    }

     @media(max-width: $width-xs-max){
		margin: 30px 15px;
		padding-top: 0;
		.section__entry {
			padding-bottom: 0px;
			padding-top: 0px;
		}
		+ .section-info{
			padding-top: 0;
		}
	}
	.section__body{
		a {
			color: $sage;
			text-decoration: none;
		}
    }
}

#modal-inclusions{
	.container{
	    padding-left: 15px;
		padding-right: 15px;
		max-width: 100%;
		.article{
			padding-bottom: 0px;
		    .container{
			    padding: 0px;
			    margin: 0px;
			    .col-sm-12{
				    padding: 0px;
			    }
		    }
			@media(max-width: $width-xs-max){
				padding-bottom: 0px;

			}
		}
		@media(max-width: $width-xs-max){
			.download-button {
			    line-height: 30px;

			}
		}
	}
	.form-enquiry-secondary__foot{
		margin-top: 0px;
		padding-top: 0px;
	    border-top: none;
		@media(max-width: $width-xs-max){
			margin-top:0px;
			padding-top: 40px;
		}

	}
}

.section__inclusions {
	&#inclusionsChooserSection {
		border-bottom: 0;
		@media (max-width: $width-xs-max) {
			margin: 0 15px;
		}
	}
	article {
		position: relative;
		margin-top: 12px;
		.tier-selector {
			display: flex;
			top: 0px;
			z-index: 10;
			padding: 0;
			margin: 0;

			&.row {
				margin-left: -10px;
				margin-right: -10px;
			}

			li {
				list-style: none;
				flex: 1;
				text-align: left;
				background: #f2f2f2;
				padding: 20px;
				margin-left: 10px;
				margin-right: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;

				span.strike {
					position: relative;
					margin-right: 5px;
					font-weight: 300;

					svg {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						line {
							stroke: $strikethrough-color;
							stroke-width: 2px;
						}
					}

					@media (max-width: $width-sm-max) {
						display: inline-block;
						height: 16px;
					}
				}
				span.design-price {
					font-weight: 500;
				}

				span.content {
					color: #888;
					font-size: 20px;
					flex: 2 1 auto;

					strong {
						display: block;
					}
				}

				button {
					width: auto;
					border: 1px solid #888;
					padding: 0 10px;
					background: transparent;
					font-size: 14px;
					height: 50px;
					max-height: 50px;
					color: #888;
					flex: 0 1 135px;
					@media (max-width: $width-sm-max) {
						padding: 0px 15px;	
					}	

					span {
						display: block;
						margin-top: 1px;
						line-height: 50px;
						text-transform: uppercase;
						font-weight: 500;
						font-size: 16px;
						position: relative;
						
						@media (max-width: $width-sm-max) {
							position: absolute;
							top: 20px;
							right: 20px;	
							
						
						}	
						
						
						
					}
				}

				.inclusions_content {
					flex: 0 0 100%;
					margin-top: 20px;
					a{
						color: $charcoal;
					}
					&--button {
						display: block;
						margin: 20px 0;
						text-align: center;

						a {
							width: auto;
							border: 1px solid #888;
							padding: 0 34px;
							background: transparent;
							font-size: 14px;
							height: 50px;
							max-height: 50px;
							line-height: 50px;
							background: #888;
							color: #fff;
							transition: color .3s, background .3s;

							&:hover {
								background: #fff;
								color: #888;
							}
						}
					}
				}

				&.active {
					span.content {
						color: inherit;
					}
					button {
						color: $sage;
						border-color: $sage;

						span {
							&:before {
								content: '\2713';
								display: block;
								position: absolute;
								top: 50%;
								transform: translateY(-50%);
							}
						}
					}
					.inclusions_content {
						&--button {
							a {
								color: #fff;
								border-color: $sage;
								background: $sage;

								&:hover {
									background: #fff;
									color: $sage;
								}
							}
						}
					}
					
					&.essence-collections{
						
						button {
							color: $maroon;
							border-color: $maroon;
	
							span {
								&:before {
									content: '\2713';
									display: block;
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}
						.inclusions_content {
							a{
								color: $maroon;
							}
							&--button {
								a {
									color: #fff;
									border-color: $maroon;
									background: $maroon;
	
									&:hover {
										background: #fff;
										color: $maroon;
									}
								}
							}
							
						}

					}
					
					&.plantation-collection-102{
						
						
						button {
							color: $gold-dark;
							border-color: $gold-dark;
	
							span {
								&:before {
									content: '\2713';
									display: block;
									position: absolute;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}
						.inclusions_content {
							a{
								color: $gold-dark;
							}
							&--button {
								a {
									color: #fff;
									border-color: $gold-dark;
									background: $gold-dark;
	
									&:hover {
										background: #fff;
										color: $gold-dark;
									}
								}
							}
							
							
							
						}

					}
					
					
					
					
					
				}
			}
			@media (max-width: $width-sm-max) {
				
				ul{
					flex-direction: column;
				}
				li {
					flex-direction: column;
					span.content {
						text-align: left;
						margin-bottom: 20px;
					}
					button {
						flex: 0 0;
						width: 150px;
						position: relative;
					}
					&.active {
						button span:before {
							transform: translateY(-50%);
						}
					}
				}
			}

			@media (max-width: 560px) {
				
				flex-direction: column;
				li {
					flex-direction: row;
					flex: 1 1 50%;
					min-width: 0;
					margin-bottom: 30px;
					span.content {
						flex: none;
						width: 100%;
						text-align: left;
						font-size: 16px;
						margin-bottom: 0;
						color: inherit;
					}
					button {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 100%;
						max-height: 100%;
						border: none;
						span {
							border: 1px solid;
							padding: 0px 20px 0px 25px;
							//text-indent: -9999px;
							&:before {
								text-indent: 0;
							}
							
						}
					}
					&.active {
						background: #F2F2F0;
						span.content {
							color: inherit;
						}
						.inclusions_content{
							color: inherit;
						}
						button span:before {
							top: -10px;
							transform: none;
							left: 5px;
							color: inherit;
						}
					}
				}
			}
		}
	}
	table {
		width: 100%;
		font-family: $graphik;
		border-collapse: collapse;
		table-layout: fixed;
		margin-top: 20px;

		tr.grey-bg {
			td {
				background: #f2f2f2;
				&.inclusion-type-name {
					border-bottom: 1px solid $sage;
					a.table-expand {
						display: block;
						position: relative;
						line-height: 1;

						&:after {
							content: '+';
							display: block;
							position: absolute;
							top: 50%;
							right: 0;
							transform: translateY(-50%);
							font-size: 20px;
						}
					}

					@media (max-width: $width-xs-max) {
						display: block;
					}
				}
			}
			&.expanded {
				td.inclusion-type-name {
					border-bottom: 1px solid transparent;
					a.table-expand {
						&:after {
							content: '-';
						}
					}
				}
			}
		}

		tbody:last-child {
			td.inclusion-type-name {
				border-bottom: 1px solid transparent;
			}
			tr.collapsible.expanded {
				border-bottom: 1px solid $sage;
			}
		}

		tr.collapsible {
			&.collapsed {
				display: none;
			}
			&.expanded {
				display: table-row;
			}
		}

		td {
			/*border: 1px solid $body-text-color;*/
			padding: 10px;
			empty-cells: show;
			vertical-align: top;

			p {
				margin-bottom: 10px;
			}

			p:last-child {
				margin-bottom: 0;
			}

			ul {
				display: block;
				border-left: none;
				margin-left: 10px;
				padding-left: 0;

				li {
					display: list-item;
					line-height: 1.4;
					margin-bottom: 5px;
					list-style: none;
					padding-left: calc(1em + 14px);

					&::before {
						content: '\2014';
						margin-right: 1em;
						color: $sage;
						margin-left: calc((1em + 14px) * -1);
					}
				}
			}
			ol {
				display: block;
				border-left: none;
				margin-left: 10px;

				li {
					display: list-item;
					list-style: decimal;
					line-height: 1.4;
					margin-bottom: 5px;
				}
			}

			body.inclusions & {
				a.select-inclusion-tier {
					color: inherit;
					text-decoration: none;
					cursor: default;
				}
			}

			&.inclusion-column {
				opacity: 0.6;
				&.active {
					opacity: 1;
				}

				@media (max-width: $width-xs-max) {
					display: none;
					&.active {
						display: table-cell !important;
					}
				}
			}
		}
	}

	&-copy {
		margin-top: 1rem;
	}

	&-link {
		text-align: right;
		
		

		.btn-outline {
			border-width: 1px;
			line-height: 46px;
			height: 46px;
			padding-top: 0;
			padding-bottom: 0;
			background-color: transparent;
			&.button-gold {
				border: 1px solid $sage;
				color: $sage;
				&:hover {
					background: $sage;
					color: #fff;
				}
			}
		}
		
		&.single-collection{
			text-align: left;
			
			.btn-outline {
				border-width: 1px;
				line-height: 46px;
				height: 46px;
				padding-top: 0;
				padding-bottom: 0;
				background-color: $sage;
				color: #fff!important;
				&.button-gold {
					border: 1px solid $sage;
					color: #fff!important;
					&:hover {
						background: #fff;
						color: $sage!important;
					}
				}
				
				&.essence{
					color: #fff!important;
					border-color: #4a0e2b!important;
					background: #4a0e2b!important;
					&:hover {
						background: #fff!important;
						color: #4a0e2b!important;
					}
					
				}
				&.luxury{
					color: #fff!important;
					border-color: #ad9554!important;
					background: #ad9554!important;
					&:hover {
						background: #fff!important;
						color: #ad9554!important;
					}
					
				}
				
			}
			
			
		}
	}
}