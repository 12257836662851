/* ==========================================================================
	SPECIAL OFFERS SECTION
   ========================================================================== */

#special-offers-section{
	background-color: #fff;
	padding:100px 0px;
	padding-bottom: 105px;
	position: relative;
	@media (max-width: $width-sm-max) {
		padding: 90px 0px;

	}
	.row-relative {
		margin-bottom: 80px;
	    @media (max-width: $width-sm-max) {
		    margin-bottom: 40px;
	    }
		@media (min-width: $width-xs-min) {
			position: relative !important;
			.col-static {
				position: static !important;
				@media (min-width: $width-xs-min) and (max-width: $width-sm-max) {
					position: relative !important;
				}
			}
		}
		@media (min-width: $width-sm-min) {
			.col-md-4 {
				float: right;
			}
		}
	}
	.carousel-container{
		margin-bottom: 30px;
		@media (max-width: $width-xs-max) {

			margin: 0 -15px;
			margin-bottom: 18px;
		}
		@media (max-width: $width-xs-max) {
			button {
				display: none !important;
			}
		}
		.carousel-slide{
			position: relative;
			&__video {
				width: 100%;
				position: relative;
				padding-bottom: 8.2%;
				/* padding-bottom: 11.3%; /* recommendation by Tomo. This better work, Tomo. */
				iframe, .vimeoEmbed {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				.vimeoSlide {
					display: none;

					@media (max-width: $width-ipad-max) {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 54px;
						left: 0;
						z-index: 100;
						display: block;
					}
				}
			}
			.link-tablet{
				display: none;
				@media (max-width: $width-md-max) {
					display: block;
					position: absolute;
					width: 100%;
					height:100%;
					z-index: 100;
				}
				@media (max-width: $width-sm-max) {
					display: none;
				}

			}
			&__slider-link {
				display: block;
			}
			.button-gold {
				margin-left: 5px;
				position: absolute;
				bottom: 20px;
				right: 20px;
				@media (max-width: $width-md-max) {
					display: none;
				}
				@media (max-width: $width-sm-max) {
					display: inline-block;
				}
			}
			&__inner {
				position: absolute;
				top:30px;
				left:20px;
				width: 55%;
				@media (max-width: $width-xs-max) {
					top: 15px;
					left: 15px;
				}
				&-text-medium {
					font-size: 35px;
					font-weight: 500;
					line-height: 1em;
					@media (max-width: $width-sm-max) {
						font-size: 28px;
					}
					@media (max-width: $width-xs-max) {
						font-size: 14px;
					}
				}
				&-text-regular {
					font-size: 35px;
					color: white;
					line-height: 1em;
					margin-bottom: 20px;
					margin-top: -10px;
					@media (max-width: $width-sm-max) {
						font-size: 30px;
					}
					@media (max-width: $width-xs-max) {
						font-size: 14px;
						margin-top: 5px;
					}
				}
			}
		}
		.slick-dots {
			bottom: 25px;
			@media (max-width: $width-xs-max) {
				/*bottom: 15px;*/
				display: none !important;
			}
			li {
				position: relative;
				width: 13px;
				height: 13px;
				margin: 0 9px 0 1px;
				&.slick-active button:before {
					background-color: $main-brown;
				}
				button {
					width: 13px;
					height: 13px;
					&:before {
						content: "";
						border-radius: 100%;
						padding: 5px;
						border:2px solid white;
						width: 13px;
						height: 13px;
						background: #fff;
					}
				}
			}
		}
		.slick-prev {
			left: 20px;
			background-image: url(../images/arrow-left.png);
		}
		.slick-next {
			right: 20px;
			background-image: url(../images/arrow-right.png);
		}

		.slick-prev, .slick-next {
			content: '';
			position: absolute;
			width: 30px;
			height: 30px;
			margin-top: -7px;
			top: calc(50% - 15px);
			transform: translate(0, 0);
			background-size: 100% 100%;
			transition: opacity .3s;
			z-index: 8;

			&:before {
				display: none;
			}
			&:hover {
				opacity: .7;
			}
		}
	}
}
.special-offers-aside{
	@media (max-width: $width-sm-max) {
		padding-left: 0;
		margin-bottom: 15px;
	}
	&__title{
		font-size: 32px;
		font-family: $graphik;
		margin-top:0;
		@media (max-width: $width-xs-max) {
			font-size: 25px;
			border: none;
			padding-top: 0;
		}
	}
	&__links {
		list-style: none;
		font-size: 14px;
		line-height:1.8;
		padding: 26px 0 0 0;
		@media (max-width: $width-sm-max) {
			display: none;
		}
		a {
			&.current {
				font-weight: bold;
			}
		}
	}
	&__cta, &__mobile-cta {
		@media (min-width: $width-sm-min) {
			position: absolute;
			bottom: 30px;
		}
		@media (max-width: $width-sm-max) {
			margin-bottom: 18px;
			text-align: center;
		}
		a.button-gold {
			display: none;
			letter-spacing: -0.5px;
			font-size: 14px;
			&.current {
				display: inline-block;
			}
		}
	}
}
.arrow-mobile{
	position: absolute;
	right: 20px;
	top: 0;
	a {
		display: inline-block;
	}
	&__left {
		margin-right: 12px;
	}
}

.offers-info-column{
	padding-bottom: 37px;
	min-height: 270px;
	position: relative;
	margin-bottom: 30px;
	@media (max-width: $width-md-max) {
		.button-gold, .button-white {
			font-size: 12px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	@media (max-width: $width-xs-max) {
		min-height: 330px;
		.button-gold, .button-white {
			width: 180px;
			display: block;
			margin: 15px 0;
		}
	}
	@media (max-width: $width-sm-max) {
		min-height: 0;
 		// padding-bottom: 55px;
		margin-bottom: 90px;
		@media (max-width: $width-xs-max) {
			margin-bottom: 40px;
			padding-bottom: 0px;
		}
	}
	&__title{
		font-family: $graphik;
		text-transform: uppercase;
		letter-spacing: 0.3em;
		font-size: 14px;
		line-height: 1em;
		font-weight: 500;
		padding: 10px 0 35px;
		@media (max-width: $width-xs-max) {
			font-size: 12px;
			padding: 10px 0 22px;
		}
	}
	&__body{
		font-size: 28px;
		line-height: 1em;
		font-family: $graphik;
		h3 {
			font-size: 32px;
			font-family: inherit;

			@media (max-width: $width-sm-max) {
				font-size: 25px;
			}
		}
		p{
 			// font-style: italic;
			font-family: inherit;
			@media (max-width: $width-md-max) {
				br {
					display: none;
				}
			}
			@media (max-width: $width-xs-max) {
				br {
					/*display: block;*/
				}
			}
		}
		img {
			max-width: 100%;
			display: block;
			margin-bottom: 10px;
		}
		@media (max-width: $width-xs-max) {
			font-size: 25px;
		}
		.red {
			line-height: 1.2em;
		}
	}
	&__tail {
		font-size: 14px;
		display: block;
		position: absolute;
		bottom: 5px;
		left: 0;
		width: 100%;

		@media (max-width: $width-sm-max) {
			position: relative;
			bottom: 0px;
			right: 0;
			margin-top: 40px;
		}
		.button-gold {
			letter-spacing: -0.5px;
			@media (max-width: $width-md-max) {
				font-size: 14px;
				padding: 10px 15px;
				display: inline-block;
			}
			@media (max-width: $width-sm-max) {
				padding-top: 8px;
				padding-bottom: 8px;
				text-align: center;
			}

		}
		.button-white {
			letter-spacing: -0.5px;
			position: relative;
			left: 5px;
			@media (max-width: $width-xs-max) {
				left:0;
				padding-top: 8px;
				padding-bottom: 8px;
			}
		}
		.btn-map {
			@media (max-width: $width-sm-max) {
				position: absolute;
				bottom: 5px;
				right: 0;
				width: 28px;
			}
		}
		}
		&--heightsm {
			min-height: 200px;
			@media (max-width: $width-xs-max) {
				min-height: 0px;
				.button-gold{
					width: 100px;
				}
			}
		}
		&.last {
			padding-bottom: 0;
			.offers-info-column__body {
				padding-right: 80px;
			}
			@media (max-width: $width-xxs-max) {
				margin-bottom: 90px;
			}
		}
		&--heightsm.last {
			.offers-info-column__body {
				padding-right: 0;
			}
			@media (max-width: $width-xs-max) {
				min-height: 0;
				padding-bottom: 0px;
				margin-bottom: 0px;
			}
		}
		&--heightsm {
		.button-gold {
			/*width: 50%;*/
			text-align: center;
			padding:10px 21px;
			@media (max-width: $width-md-max) {
				font-size: 14px;
				padding: 10px 15px;
				display: inline-block;
			}
			@media (max-width: $width-xs-max) {
				width: 50%;
			}
		}
	}
}