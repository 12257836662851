/* ------------------------------------------------------------ *\
Loader
\* ------------------------------------------------------------ */

.ico-arrow-grey {
	display: inline-block;
	width: 80px;
	height: 75px;
	vertical-align: middle;
	background: url(../images/arrow-grey-up.svg) no-repeat 0 0;
	background-size: 100% 100%;
}
.ico-arrow-red {
	display: inline-block;
	width: 80px;
	height: 75px;
	vertical-align: middle;
	background: url(../images/arrow-red-up.svg) no-repeat 0 0;
	background-size: 100% 100%;
}

.loader {
	position: fixed;
	width: 100px;
	height: 100px;
	top: 50%;
	left: 50%;
	/*transform: translate(-50%,-50%);*/
	margin-left: -50px;
	margin-top: -50px;
	animation: rotate 3s infinite;
	z-index: 150;
    border-radius: 50%;
    border-top: 10px solid transparent;
    border-right: 10px solid $sage;
    border-bottom: 10px solid $sage;
    border-left: 10px solid $sage;

    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
	@media(max-width: $width-sm-max){
			 top: 50% !important;
			 left: 50% !important;
			 transform: translate(-50%,-50%);
		 }
	&-panel {
		position: absolute;
		/*z-index: 100;*/
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.8);
		display: none;
		z-index: -1;
		opacity: 0;
		visibility: visible;

		body.compare & {
			display: block;
		}
		 @media(max-width: $width-sm-max){
			 position: fixed !important;
		 }
	}

	i {
		position: absolute;
		width: 50%;
		height: 46.88%;

		&:nth-child(1) {
			top: 0;
			left: 50%;
			margin-left: -25%;
			transform: rotate(180deg);
			animation: expandFirst 3s infinite;
		}
		&:nth-child(2) {
			top: 12.8%;
			right: 0;
			transform: rotate(240deg);
			animation: expandSecond 3s infinite;
		}
		&:nth-child(3) {
			bottom: 12.8%;
			right: 0;
			transform: rotate(300deg);
			animation: expandThird 3s infinite;
		}
		&:nth-child(4) {
			bottom: 0;
			left: 50%;
			margin-left: -25%;
			animation: expandForth 3s infinite;
		}
		&:nth-child(5) {
			bottom: 12.8%;
			left: 0;
			transform: rotate(60deg);
			animation: expandFifth 3s infinite;
		}
		&:nth-child(6) {
			top: 12.8%;
			left: 0;
			transform: rotate(120deg);
			animation: expandSixth 3s infinite;
		}
	}

	&.stopped {
		animation-name: none;
		i {
			animation-name: none;
		}
	}
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
