/* ------------------------------------------------------------ *\
	Gmap
\* ------------------------------------------------------------ */

.gmap-container {
	position: relative;
	margin-bottom: 30px;
	overflow: hidden;
	div {
		[style*="cluster"] {
			font-weight: 400 !important;
		}
	}

	&__checkbox {
		position: absolute;
		top: 8px;
		left: 20px;
		font-size: 14px;
		z-index: 3;
		input {
			display: none;
			&:checked ~ label span {
				&:after {
					content: '';
					position: absolute;
					width: 17px;
					height: 11px;
					top: 4px;
					left: 1px;
					background-image: url(../images/check.png);
					background-size: 100% 100%;
				}
			}
		}

		& &-label {
			padding: 3px 0 0 33px;
			span {
				position: absolute;
				width: 21px;
				height: 21px;
				top: 0;
				left: 0;
				border: 1px solid #fd585a;
				background: #fff;
			}
		}
	}
	.gmap-content {
		position: absolute;
		width: 375px;
		height: 100%;
		top: 0;
		right: -375px;
		overflow: hidden;
		background: #fff;
		transition: right .3s;
		
		
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 50px;
			bottom: 0;
			left: 0;
			background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		}
		&.container-shown {
			right: 0;
		}
	}
	.infobox-head {
		padding: 14px 15px;
		color: #fff;
		background: $sage;
		strong {
			text-decoration: underline;
		}
	}
	.infobox-body {
		background-color: $grey-background-light;
		position: absolute;
		width: 100%;
		top: 50px;
		bottom: 0;
		padding: 0 12px 50px;
		overflow-x: hidden;
		overflow-y: auto;
		.property{
			border-bottom: 1px solid #fff;
		}
	}
}

.gmap {
	position: relative;
	padding-top: 72.5%;
	.contact &{
		padding-top: 53%;
	}
	> div {
		top: 0;
		left: 0;
	}
}