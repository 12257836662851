/* ------------------------------------------------------------ *\
	Section Links
\* ------------------------------------------------------------ */

.section-links {
	padding: 12px 0 75px;
	.new-homes_series &{
		padding: 25px 0;
	}
	.house-and-land &, .readybuilt &, .display-for-sale_detail &{

	}
	&.reason-to-build{
		.display-centre-single &, .estate &{
			text-align: center;
			.list-links-secondary{
				justify-content: center;
			}

		}
		.estate &{
			padding: 75px 0 ;
		}
	}

	h2 {
		font-family: $graphik;
		font-size: 32px;
		color: #231f20;
		margin-bottom: 29px;
	}
}

.section-links-secondary {
	padding-bottom: 110px;
}

@media(max-width: $width-sm-max){
	.section-links {
		padding-bottom: 60px;
	}
}

@media(max-width: $width-xs-max){
	.section-links {
		padding: 0 0 70px;

		h3 {
			font-size: 25px;
			margin-bottom: 19px;
		}
	}
}