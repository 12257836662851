/* ------------------------------------------------------------ *\
	Section Home
\* ------------------------------------------------------------ */

.section-home {
	padding-bottom: 89px;

	.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
		padding-bottom: 20px;
	}
	h1 {
		font-size: 60px;
		color: $header-grey;
		#productLayout &{
			text-transform: uppercase;
		}
	}
	h2 {
		font-size: 32px;
		line-height: 1;
		color: $header-grey;
		a {
			font-family: $graphik;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -0.02em;
			color: $sage;
		}
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			 margin-bottom: 20px; line-height: 1.1;
			 font-family: $graphik;
		}
	}
	&__awards{
		@media (max-width: $width-sm-max) {
			padding-top: 10px;
		}
	}

	&__content {
		flex: 0 1 100%;
		padding-top: 24px;
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			padding-top: 25px;
		}
		/*
		html.ie &{
			max-width: calc(100% - 293px);
			@media (max-width: $width-sm-max) {
				max-width: 100%;
			}
		}
		*/
	}
	&__aside {
		flex: 0 0 263px;
		margin: -78px 0 0 30px;
		.house-and-land &, .readybuilt &, .display-for-sale_detail &{
			margin-top: -100px;
			@media (max-width: $width-sm-max) {
				margin-top: -10px;
			}
		}
		html.ie10 &{
			width: 263px;
			position: absolute;
			top:1px;
			right: 0px;
			display: block;
		}
		.widget-enquiry {
			&.sticky {
				position: fixed;
				width: 263px;
				// top: 173px;
				top: 25px;
				&.sticky-bottom {
					position: absolute;
					bottom: 100px;
					top: auto;
				}
			}
		}
	}
	&__head {
		padding-bottom: 69px;
		border-bottom: 1px solid #939192;
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			padding-bottom: 25px;
			@media(max-width: $width-xs-max){
				padding-bottom: 15px;
			}

			p {
				margin-bottom: 0;
				font-family: $graphik;
				font-size: 14px;
				font-weight: 300;
				letter-spacing: -0.02em;
				line-height: 20px;
				text-transform: none;
				display: inline-block;
			}
		}
	}
	&__collection {
		a {
			color: $grey-dark;
			font-weight: 500;
			margin-left: 5px;
		}
	}
	&__body {
		padding-top: 24px;
		.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
			a{
				font-weight: 500;
				color: $sage;
			}
		}
	}
	&__buttons {
		display: flex; 
		margin-top: 50px; 
		justify-content: center; 
		flex-wrap: wrap; 
		.btn {
			border: 2px solid $grey-background-dark;
			color: $grey-background-dark;
			border-radius: 0px; 
			padding-left: 18px; 
			padding-right: 18px; 
			font-weight: 500; 
			font-size: 18px; 
			margin-right: 15px; 
			transition: color .3s ease, background-color .3s ease;
			box-shadow: none !important; 
			margin-bottom: 10px; 
			&:last-child {
				margin-right: 0; 
			}
			&:hover {
				color: #f5f5f5;
				background: $grey-background-dark;
			}
		}
		@media (max-width: $width-xs-max) {
			justify-content: space-evenly; 
			.btn {
				font-size: 14px; 
				margin-right: 0; 
				flex-grow: 0; 
				flex-shrink: 0; 
				width: 48%; 
			}
		}
		@media (max-width: 320px) {
			.btn {
				font-size: 12px; 
				padding-left: 0; 
				padding-right: 0; 
			}
		}
	}
	&__expandable {
		display: none;
	}
	&__inner {
		padding-bottom: 24px;
		font-size: 14px;
		letter-spacing: -0.02em;
		color: #231f20;
		.house-and-land & {
			padding-bottom: 36px;
		}
		.title {
			margin-bottom: 20px;
			.new-homes_series &, .house-and-land &, .readybuilt &, .display-for-sale_detail &{
				font-family: $helvetica;
				letter-spacing: .3em;
				span, a, i{
					font-family: $graphik;
					letter-spacing: normal;
				}
			}
			.line{
				margin-left: 10px;
			}
		}
		> .col-md-6, > .col-sm-6{
			padding: 0px;
		}
		a {
			font-weight: 500;
		}
		.ico-pointer {
			position: relative;
			top: -2px;
			margin-right: 3px;
		}
		.ico-triangle {
			position: relative;
			top: -2px;
			margin-right: 3px;
		}
		.tag {
			margin-left: 10px;
			padding: 5px;

			a{
				color: #fff;
				font-weight: 500;
			}
			&.gold{
				background-color: $sage;
			}
			&.brown {
				background-color: $main-brown;
			}
			&.grey{
				background-color: $grey-dark;
			}
			&.black{
				background-color: #000;
			}
		}

	}
	.container {
		display: flex;
		justify-content: space-between;

		&:before,
		&:after {
			display: none;
		}

		html.ie10 &{
			display: block;
			position: relative;
		}
	}
}

@media(max-width: $width-sm-max){
	.section-home {
		padding-bottom: 40px;

		.house-and-land &, .new-homes_series &, .readybuilt & {
			padding-bottom: 10px;
			+ .section-design {
				padding-top: 0;
			}
		}

		h4 {
			br {
				display: none;
			}
		}
		&__head {
			padding-bottom: 15px;
		}
		&__inner {
			padding-bottom: 20px;
			font-size: 13px;
			display: block;
			p {
				+ p {
					margin-left: auto;
				}
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.section-home {
		padding-bottom: 10px;
		h1 {
			font-size: 35px;
		}
		h2 {
			font-size: 25px;
			/*font-size: 35px;*/
			br {
				display: none;
			}
		}
		h4 {
			font-size: 25px;
		}
		p {
			margin-bottom: 16px;
		}
		.container {
			display: block;
		}
		&__inner {
			display: block;
			padding-bottom: 13px;
			font-size: 12px;
			strong {
				margin-right: 4px;
			}
			.ico-pointer {
				display: none;
			}
		}
		&__content {
			padding-top: 21px;
		}
		&__aside {
			margin: 20px 0 0;
		}
		&__body {
			padding-top: 21px;
		}
	}
}