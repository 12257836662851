#quarantees-section {
	/*background: url("../images/guarantee-img.jpg")no-repeat center center;
	background-size: cover;*/
	&.css-bg{
		background: url(/resources/images/our-guarantees_plantation-homes.jpg) ;
		@media (max-width: $width-xs-max) {
			background: url(/resources/images/our-guarantees_plantation-homes_sm.jpg) ;

		}

	}
	&.white{
		color:#fff;
		h3{
			border-top-color: #fff;
		}

		svg path{
			fill:#fff;
		}
	}
	min-height: 350px;
	padding: 90px 0px;
	margin-bottom: 0px;
	@media (max-width: $width-sm-max) {
		/*height: 280px;*/
		padding-bottom: 30px;
	}
	h3{
		font-size: 14px;
		padding-bottom: 30px;
		font-family: $helvetica;
		letter-spacing: .3em;
		text-transform: uppercase;


		@media (max-width: $width-md-max) {
			padding-bottom: 18px;

		}
		@media (max-width: $width-sm-max) {
			font-size: 25px;
			padding-bottom: 12px;
		}
		img{
			margin-left: 7px;
		}
	}
	p{
		font-size: 32px;
		font-family: $graphik;
		line-height: 1.1em;
		margin-bottom: 30px;

			br {
				display: none;
			}

		@media (max-width: $width-sm-max) {
			font-size: 16px;
		}
	}
	@media (max-width: $width-md-max) {
		.button-gold {
			display: inline-block;
		}
	}
	@media (max-width: $width-sm-max) {
		.row > div:first-child {
			margin-bottom: 40px;
		}
	}
	@media (max-width: $width-xs-max) {
		.button-gold {
			line-height: 1.8;
			height: 39px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	ul{
		margin-bottom: 35px;
		text-align: center;
		    padding-left: 0px;
    display: table;
    width: 100%;
		@media (max-width: $width-sm-max) {
			margin-left: 0;
			padding-left: 0;
		}
		@media (max-width: $width-xs-max) {
			text-align: center;
		}
		li{
			display: inline-block;
			list-style: none;
			width: 20%;
			/*margin-right: 65px;*/
			margin-right: 45px;
			@media (max-width: 1024px) {
				margin-right: 0px;
			}
			    margin: 0;
				float: left;
			/*
			@media (max-width: $width-sm-max) {
				margin-right: 45px;
			}
			*/
			@media (max-width: $width-xs-max) {
				margin-right: 0px;
			}
			&:last-child {
				margin-right: 0;
			}
			img{
				width: 135px;
				html.ie &{
					height: 140px;
				}
				@media (max-width: $width-md-max) {
					width: 100%;
				}
				@media (max-width: $width-xs-max) {
					width: 17vw;
					height: auto;
				}

			}
			&:nth-child(2) {
				img {
					padding-left: 0px;
					padding-right: 0px;
					margin-top: -2px;
					width: 140px;
					@media (max-width: $width-md-max) {
						width: 100%;
						padding-top: 2px;
						margin-top: 0px;
					}
					@media (max-width: $width-xs-max) {
						padding-left: 0px;
						padding-right: 0px;
						padding-top: 1px;
						margin-top: 0px;
						width: 17vw;
					}
				}
			}
		}
	}
}
