/* ==========================================================================
	Search results page
   ========================================================================== */

body.search .main-part {
	min-height: 709px;
	.main-part__left {
		margin-bottom: 70px;
	}
}


.tabs-selector {
	margin: 70px 0 40px;
	padding: 0;
	@media (max-width: $width-xs-max) {
		margin: 40px 0 20px;
	}
	li {
		display: inline-block;
		&.on {
			a {
				font-weight: 500;
				border-color:$sage;
			}
		}
		a {
			padding: 8px 18px;
			display: block;
			color: $sage;
			border: 2px solid transparent;
			border-radius: 4px;
			@media (max-width: $width-xs-max) {
				padding: 5px 10px;
			}
		}
	}
}

.search-results {
	/*max-width: 750px;*/
	border-top: 2px solid #dbdbdb;
	padding-top: 45px;
	min-height: 300px;
	position: relative;
	@media (max-width: $width-xs-max) {
		padding-top: 20px;
	}
	.item {
		border-bottom: 2px solid #dbdbdb;
		padding-bottom: 45px;
		margin-bottom: 45px;
		em{
			color: $sage;
		}
		@media (max-width: $width-xs-max) {
			padding-bottom: 15px;
			margin-bottom: 20px;
		}
		h3 {
			font-family: $graphik;
			font-size: 24px;
			margin-bottom: 20px;
			span { font-style: italic; color: #A7A5A6; }
		}
		p {
			max-width: 680px;
		}
	}
	&--shell {
		position: relative;
	}
	.tab {
		display: none;
		&.current {
			display: block;
		}
		.property {
			padding: 0 15px;
		}
	}
	.hl-search-result.property {
		p {
			margin-bottom: 10px;
		}
		.property-item__body {
			position: relative;
		}
	}
	.property-item {
		&__body {
			min-height: 242px;
			
			@media (max-width: $width-xs-max) {
				min-height: 195px;
				&-clickable {
					p:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		&__panel {
			@media (max-width: $width-xs-max) {
				height: auto;
			}
		}
	}
}

.pagination-widget {
	padding: 0;
	@media (max-width: $width-xs-max) {
		margin-bottom: 40px;
	}
	li {
		display:  inline-block;
		a {
			font-size: 18px;
			@media (max-width: $width-xs-max) {
				font-size: 13px;
			}
		}
		&.on {
			a { font-weight: 500; }
		}
		span {
			color: $charcoal;
			font-size: 18px;
			padding: 0 8px;
			@media (max-width: $width-xs-max) {
				padding: 0 15px;
				font-size: 13px;
			}
		}
		&.next {
			position: relative;
			top: -1px;
			margin-left: 5px;
			@media (max-width: $width-xs-max){
				margin-left: 14px;
			}
		}
	}
}

.properties-list {
	border-top: 2px solid #dbdbdb;
	padding-top: 45px;
	margin-bottom: 60px;
	@media (max-width: $width-xs-max) {
		padding-top: 20px;
	}
}

#tab-homes{
	.row{
		position: relative;
	}
}