/* ------------------------------------------------------------ *\
	Actions
\* ------------------------------------------------------------ */

.actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 23px;
	margin-bottom: 23px;
	border-bottom: 1px solid #484545;
	li {
		margin-bottom: 0;
	}
}

@media(max-width: $width-md-max - 1px){
	.actions {
		padding-top: 50px;
	}
}


/* ------------------------------------------------------------ *\
Actions Secondary
\* ------------------------------------------------------------ */

.actions-secondary {
	padding: 15px 0 30px;
	font-size: 12px;
	font-weight: 500;
	text-align: center;

	&__btn {
		display: block;
		max-width: 160px;
		height: 31px;
		padding: 0;
		margin: 0 auto 21px;
		line-height: 31px;
		color: #fff;
		background: $gold-dark;
	}
}
