/* ------------------------------------------------------------ *\
	LATEST NEWS HEAD
\* ------------------------------------------------------------ */

.latest-news-head {
	&.no-border {
		margin-top: 34px;
		border-top: 0;
		padding-top: 10px;

		@media (max-width: $width-xs-max) {
			padding-top: 0;
			margin-bottom: 32px;
		}

	}
	&__link {
		padding-top: 12px;

		@media (max-width: $width-xs-max) {
			font-size: 11px;
			padding-top: 6px;
		}
	}
	@media (max-width: $width-xs-max) {
		&__title {
			font-size: 20px;
		}
	}
}

/* ------------------------------------------------------------ *\
	LATEST NEWS ITEM
\* ------------------------------------------------------------ */

.latest-news-item {
	&__title {
		margin: 24px 0 17px;
		letter-spacing: -0.02em;

		@media (max-width: $width-xs-max) {
			font-size: 18px;
			margin: 26px 0 6px;
		}
	}

	&--small {
		margin-bottom: 51px;
	}
	img {
		width: 100%;
	}
}