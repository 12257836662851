/* ------------------------------------------------------------ *\
	Section Fluid
\* ------------------------------------------------------------ */

.section-fluid {
	position: relative;
	color: $body-text-color;
	background: $grey-background-light;
	h3{
		font-size: 14px;
		font-family: $helvetica;
		text-transform: uppercase;
		letter-spacing: 0.3em;
	}
	h2 {
		font-size: 40px;
		line-height: 1;
		color:$body-text-color;
		padding-top: 20px;
		/*margin-bottom: 128px;*/
		margin-bottom: 20px;
		text-transform: uppercase;
		
	}
	img {
		display: block;
		margin-bottom: 20px;
	}
	h4 {
		font-family: $graphik;
		font-weight: 200;
		font-size: 24px;
		line-height: 1.21;
		color: $body-text-color;
		margin-bottom: 20px;
	}
	&__image {
		position: absolute;
		width: 50vw;
		height: 100%;
		top: 0;
		right: 0;
		background-size: cover;
		background-position: center center;
	}
	&__content {
		width: 50%;
		padding: 93px 113px 84px 0;
	}
}

@media(max-width: $width-sm-max){
	.section-fluid {
		h3 {
			margin-bottom: 50px;
		}
		&__content {
			padding: 20px 20px 20px 0;
		}
	}
}

@media(max-width: $width-xs-max){
	.section-fluid {
		h3 {
			font-size: 27px;
			padding-top: 11px;
			margin-bottom: 74px;
		}
		h4 {
			font-size: 19px;
			margin-bottom: 11px;
		}

		&__image {
			position: static;
			width: 100%;
			padding-top: 100%;
		}
		&__content {
			width: 100%;
			padding: 26px 0 31px;
		}
	}
}

@media (max-width: $width-xs-max) and (orientation:landscape){
	.display-centres{
		.section-fluid{
			
			&.section-secondary{
				    white-space: nowrap;
				.container, .section-fluid__image{
					width: 50%;
				    display: inline-block;
				    white-space: normal;
				    
				}
				.section-fluid__image{
				    padding-top: 62%;
				}
			}
		}
	}
}