/* ------------------------------------------------------------ *\
Popup
\* ------------------------------------------------------------ */

/* CSS */

.fixed-rebrand, .fixed-compare {
	position: fixed;
	bottom: 40px;
	right: 40px;
	width: 280px;
	height: 58px;
	z-index: 999;

	@media (max-width: $width-xs-max) {
		right: 20px;
	}
	.rebrand-info {
		display: block;
		height: 50px;
		width: 210px;
		background: #efefef;
		border-radius: 5px 5px 5px 5px;
		-moz-border-radius: 5px 5px 5px 5px;
		-webkit-border-radius: 5px 5px 5px 5px;
		line-height: 50px;
		text-align: center;
		position: absolute;
		bottom: 0;
	    box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
	    -webkit-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
	    -moz-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);

		&:before {content:''; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left:10px solid #b2b1b1; position: absolute;
    right: -12px;
    top: 19px;}

		&:after {content:''; width: 0; height: 0; border-top: 10px solid transparent; border-bottom: 10px solid transparent; border-left:10px solid #efefef; position: absolute;
    right: -10px;
    top: 16px;}

			a {color: #000; font-weight: 500; text-decoration: underline;}

		}

	.compare-tooltip {
		display: block;
		height: 50px;
		width: 170px;
		background: #fff;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		text-align: left;
		position: absolute;
		padding: 10px;
		bottom: 0;
		-webkit-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
		box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);

		&:before {
			content: '';
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left:10px solid #b2b1b1;
			position: absolute;
			right: -12px;
		    top: 19px;
		}
		&:after {
			content:'';
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left:10px solid #efefef;
			position: absolute;
			right: -10px;
			top: 16px;
		}
		a { color: #000; font-weight: 500; text-decoration: underline; }
	}

	.close-rebrand, .close-compare, .compare-count {display: block; width: 20px; height: 20px; border-radius: 20px;	-moz-border-radius: 20px; -webkit-border-radius: 20px; background: #dcdcdc; color: #b2b1b1; text-align: center; line-height: 20px; font-weight: 500; position: absolute; right: 62px; z-index: 999;}
	.compare-count {
		right: 0;
		background: #000;
		color: #fff;
	}
	.activate-popup {
		display: block; width: 50px; height: 50px; border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
	background: $sage;
	position: absolute; right: 0; bottom: 0;
	line-height: 49px; text-align: center;
	 box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
	    -webkit-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
	    -moz-box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.2);
	    @media (max-width: $width-xs-max) {

		    bottom: 25px;
	    }
	}
}
.fixed-compare {
	display: none;
	width: 240px !important;
}

.popup {position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    padding: 40px;
//     background: rgba(0,0,0,0.8);
    text-align: left;

    .popup-container {
	    position: absolute;
	    top: 50%;
	    left: 50%;
	    -webkit-transform: translate(-50%, -50%);
	    transform: translate(-50%, -50%);
	    background: rgba(245,245,245,0.95);
	    width: 450px;
	    height: 550px;
	    padding: 35px;

	    @media (max-width: $width-xs-max) {
		    height: calc(100% - 100px);
		    width: calc(100% - 40px);
		    padding: 15px;
		    -webkit-transform: translate(0,0);
		    transform: translate(0,0);
		    left: 20px;
		    top: 60px;
		}

	    hr {border-top: 1px solid #000; margin-top: 50px;}

	    .column {width: 50%; float: left;
		    h2 {font-size: 28px; margin: 47px 0px;
			    @media (max-width: $width-xs-max) {font-size: 19px;}
			    span {font-style: italic;}
		    }
		    h4 {font-family: $graphik; font-size: 14px; font-weight: 500; margin-bottom: 25px;
			    @media (max-width: $width-xs-max) {font-size: 11px;}
		    }
		    .popup-logo {display: block;
			    @media (max-width: $width-xs-max) {width: 72px;}
		    }
		    .cosmopolitan {margin-bottom: 10px;}
		    .clendon-vale {margin-bottom: 40px;}

		    img:not(.popup-logo) {width: 11px; margin-top: 6px; margin-left: -3px;
		    }
	    }

	    p {display: block; width: 100%; clear: both; font-size: 24px; line-height: 1.2;
		    @media (max-width: $width-xs-max) {
			    font-size: 17px;
		    }
	    }

	    	a.close-btn {
		    	width: 7%;
		    	position: absolute;
		    	top: 2%;
		    	right: 3%;
		    	z-index: 999;

		    img {width: 100%; height: 100%; cursor: pointer;}
		    }

	    }

    }