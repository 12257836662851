/* ------------------------------------------------------------ *\
	ARTICLE DISPLAY
\* ------------------------------------------------------------ */

.article-display {
	padding: 64px 0 92px;
	background: #f5f5f5;

	body.general & {
		padding-bottom: 64px;
	}

	.display-centre-single & , .estate &{
		background: inherit;
		padding-bottom: 67px;
		padding-top: 100px;
		h3 {
			font-family: $graphik;
		}

		@media (max-width: $width-sm-max) {
			padding-bottom: 0;
			padding-top: 30px;
		}
	}

	@media (max-width: $width-sm-max) {
		padding-bottom: 60px;
	}

	@media (max-width: $width-xs-max) {
		padding: 32px 0;
	}
	h3 {
		font-size: 24px;
		font-family: $graphik;
		letter-spacing: -0.018em;
		line-height: 1.18;
		margin-bottom: 16px;
	}
	&__logo {
		position: absolute;
		bottom: 10px;
		left: 15px;

		@media (max-width: $width-sm-max) {
			position: static;
			margin-bottom: 30px;
		}
	}
	&__content {
		padding-bottom: 40px;

		@media (max-width: $width-xs-max) {
			padding-bottom: 20px;
		}
	}
	.slider-images-secondary--small {
		padding-top: 0;
		margin-bottom: 0;
	}
	.row {
		position: relative;
	}
	.col-md-4 {
		position: static;
	}
	.article{
		padding-top: 0px;
	}
}