/*  Custom Select Tertiary */

.custom--select-tertiary {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 12px;
		height: 10px;
		right: 9px;
		top: 50%;
		margin-top: -5px;
		background: url(../images/select-arrow-down-secondary.png) no-repeat 0 0;
		background-size: 100% 100%;
		pointer-events: none;
	}

	select {
		display: block;
		width: 100%;
		height: 40px;
		padding: 0 32px 0 16px;
		border: 0;
		border-radius: 2px;
		/*color: #c0c0c0;*/
		color: #a7a5a6;
		background-color: #fff;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		font-size: 16px;
		&.error {
			color: $gold-dark;
		}
	}


}

@media(max-width: $width-xs-max){
	/*  Custom Select  */
	.custom--select {
		width: 100%;
		min-width: 0;
	}
}
