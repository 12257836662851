/* ==========================================================================
Contact
========================================================================== */
#contact-boxes-section{
	padding-top: 50px;
	padding-bottom: 100px;
	.title{
		font-size: 32px;
		color: #231F20;
		@media (max-width: $width-xs-max) {
			font-size: 24px;
		}
		&--italic{
			font-style: italic;
		}
		@media (max-width: $width-xs-max) {
			margin-bottom: 20px;
		}
	}
	@media (max-width: $width-xs-max) {
		padding-bottom: 50px;
		.row > div {
			margin-right: -15px;
			margin-left: 0;
			width: calc(50% + 7px);
		}
		.row > div:nth-child(2n) {
			margin-left: 0;
			margin-right: -15px;
		}
	}
}

.contact-box-wrapper{
	padding: 20px;
	height: 300px;
	margin-top: 35px;
	border: 1px solid #A7A5A6;
	@media (max-width: $width-xs-max) {
		padding: 10px;
		height: 150px;
		margin-top: 15px;
	}
}
.contact-box{
	position: relative;
	height: 100%;
	display: block;
	width: 100%;
	text-align: center;
	color:#fff;
	font-size: 24px;
	h3 {
		font-family: $graphik;
		font-weight: 200;
		z-index: 1;
	    top: 50%;
	    width: 100%;
	    position: absolute;
	    left: 50%;
	    transform: translate(-50%,-50%);
		@media (max-width: $width-xs-max) {
			font-size: 16px;
			padding: 0 10px;
		}
	}
	&:hover { color: #fff; }
	.heading { display: none; }
}

#contact-our-headquarters {
	margin-bottom: 60px;
	h2{
		margin-bottom: 30px;
		@media (max-width: $width-xs-max) {
			font-size: 24px;
		}
	}
	h3.title {
		margin-bottom: 35px;
		font-size: 32px;
	}
	.gmap-container {
		margin-bottom: 50px;

	}
	.list-radios {
		display: block;
	}
	.our-headquarters {
		&__get-directions {
			display: inline-block;
			margin-bottom: 32px;
			@media (max-width: $width-xs-max) {
				margin-bottom: 0px;
			}

		}
	}
	.row{
		@media (max-width: $width-md-max) and (orientation: landscape) {
			.col-xs-12, .col-xs-6{
				width: 33.3%;
			}
		}
	}
	@media (max-width: $width-xs-max) {
		margin-bottom: 0;
	}
}
.contact-intro {
	@media (max-width: $width-xs-max) {
		height: 300px;
	}
	h1 {
		margin-bottom: 36px;
		@media (max-width: $width-xs-max) {
			margin-bottom: 22px;
		}
	}
	h4 {
		font-family: $graphik;
		font-weight: 200;
		font-size: 24px;
		font-weight: 300;
		letter-spacing: -0.03em;
		color: #fff;
		@media (max-width: $width-xs-max) {
			font-size: 20px;
		}
	}
	p {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		@media (max-width: $width-xs-max) {
			line-height: 1.4;
		}
	}
}


/* ------------------------------------------------------------ *\
Section Contact
\* ------------------------------------------------------------ */
.general.cosham .section-sticky-limit > section.section-contact{
	margin-bottom: 0;
    padding: 70px 0;
    @media (max-width: $width-xs-max) {
	        padding: 20px 0 40px;
    }
}

.section-contact, .general .section-contact {
	padding: 90px 0 81px;
// 	margin-bottom: 0px;

	background: $grey-background-light;
	position: relative;

	&.dark {
		background: $charcoal;
	}

	h2 {
		font-size: 36px;
		margin-bottom: 27px;
	}
	.text{
		p,h1,h2,h3,h4{
			font-size: 32px;
		}

	}

	&.newsletter{
		padding: 50px 0px;

		.form-contact__field{
			border: 1px solid #231f20;

			@media(max-width: $width-sm-max){

				margin-bottom: 20px;

			}

			&.error{
				border: 1px solid #c33;
			}
		}

		.btn-white-transparent{
			border: 1px solid #231f20!important;
			color: #231f20!important;
		}


		&.dark{
			background: $charcoal;
			color: #fff;

			.form-contact__field{
				border: 1px solid $charcoal;


				&.error{
					border: 1px solid #c33;
				}
			}

			.btn-white-transparent{
				border: 1px solid #fff!important;
				color: #fff!important;
			}


		}

	}

	&__head {
		margin-bottom: 117px;
		&.button_only{
			margin-bottom: 0px;
		}

		text-align: center;
		@media (max-width: $width-sm-max) {
			margin-bottom: 0px;
		}

		i {
			margin-bottom: 35px;
		}

		.btn{
			padding: 0 20px;
			margin-top: 27px;
		}
	}

	&__body {
		position: relative;
	}

	.form-contact__row.error {
		input {
			color: $sage;
			&::-webkit-input-placeholder { opacity: 1 !important; color: $sage !important; }
			&::-moz-placeholder { opacity: 1 !important; color: $sage !important; }
			&:-moz-placeholder { opacity: 1 !important; color: $sage !important; }
			&:-ms-input-placeholder { opacity: 1 !important; color: $sage !important; }
		}
	}

	.form-contact__row input.error {
		color: $sage;
		&::-webkit-input-placeholder { opacity: 1 !important; color: $sage !important; }
		&::-moz-placeholder { opacity: 1 !important; color: $sage !important; }
		&:-moz-placeholder { opacity: 1 !important; color: $sage !important; }
		&:-ms-input-placeholder { opacity: 1 !important; color: $sage !important; }
	}

	.form-contact__thank-you {
		background: $grey-background-light;
		position: absolute;
		top: -5px;
		right: 0;
		bottom: 0;
		left: 0;

		h2 {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			font-size: 48px;
			width: 100%;
		}
	}
}

@media(max-width: $width-sm-max){
	.section-contact {
		&__head {
			margin-bottom: 50px;
		}
	}
}

@media(max-width: $width-xs-max){
	.section-contact {
		padding: 50px 0;
		h2 {
			font-size: 28px;
			margin-bottom: 15px;
			margin-right: 0 !important;
		}
		&__head {
			margin-bottom: 30px;
			i {
				margin-bottom: 15px;
			}
		}
		.container {
			padding: 0 20px;
		}
	}
}

.section-contact--light {
	background-color: #fff;
	color: #333;
	.form-contact {
		&__aside {
			border-top: 1px solid #666;
			border-bottom: 1px solid #666;
			.ico-envelope-big {
				background-image: url(../images/ico-envelope-grey.png);
			}
		}
		&__field {
			border: 1px solid #666;
		}
		.custom--select-tertiary {
			select {
				border: 1px solid #666;
			}
			&:after {
				background: url(../images/select-arrow-down-black.png) no-repeat 0 0;
				background-size: 100% 100%;
			}
		}
		.btn-white-transparent {
			border: 2px solid #000;
			background: transparent;
			color: #000;
			transition: .4s color ease-in-out, .4s background ease-in-out;
			&:hover {
				color: #fff;
				background: #000;
			}
		}
	}
}

.section-contact.dark {
	.form-contact {
		&__aside {
			h1, p {
				color: $white;
			}
			.ico-envelope-big {
				background-image: url(../images/ico-envelope-big-gold.png) !important;
			}
		}
		&__content {
			.form-contact__row {
				color: $white;
			}
		}
		&__actions {
			p {
				color: $white;
			}
		}
		&__thank-you {
			background-color: $charcoal;

			h2 {
				color: $white;
				font-style: italic;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	CONTACTS
\* ------------------------------------------------------------ */

.contacts {
	p { display: inline-block; width: 46%; }
	p ~ p {
		margin-left: 26px;
		.display-centre-single &{
			@media(max-width: $width-sm-max){
				margin-left: 0px;
			}
		}
	}
	strong { display: block;  }
	a:not(.button-gold){ color: inherit; }

	&.display-centres{
		p:nth-child(2) { display: inline-block; width: 34%; }

		@media(max-width: $width-xs-max){
			p:nth-child(2) { display: inline-block; width: 58%; }
		}


		p:nth-child(3) { display: inline-block; width: 58%; }

	}




}

@media(max-width: $width-xs-max){
	.contacts {
		p {
			width: 40%;
			display: block;
			margin-left: 0 !important;
			float: none !important;
			/*
			width: 45%;
			~ p {
				margin-left: 12px;
			}
			*/
		}
	}
	.section-info__group-contacts .button-gold-transparent.contacts__get-directions {
		font-weight: 400;
		font-size: 14px;
		color: $main-brown;
		padding: 12px 15px;
		margin-top: 0;
		width: auto;
		height: auto;
		line-height: 1;
	}
}

#modal-contact-form, #modal-enquiry-form {
	max-width: 1400px;
	margin: 0 auto;
	@media (max-width: $width-xs-max) {
		padding: 15px !important;
	}

	&.small-modal{
		max-width: 800px;
	}

	.form-enquiry-secondary {
		&__aside {
			position: relative;
			display: inline-block;
			vertical-align: top;
			max-width: 388px;
			width: 100%;
			h3 {
				font-family: $graphik;
			}
			@media (max-width: $width-md-max) {
				max-width: 300px;
				text-align: left;
				margin-bottom: 30px;
				h3 {
					font-size: 40px;
				}
				br {
					display: block;
				}
			}
			@media (max-width: $width-sm-max) {
				max-width: 100%;
				br {
					content: ' ';
					display: inline-block;
					width: 10px;
				}
				h3 {
					font-size: 22px;
				}
			}
		}
		&__content {
			display: inline-block;
			/*flex: 0 1 418px;*/
		}
		&__row {
			display: block;
			@media (max-width: $width-sm-max) {
				margin-top: 0;
			}
		}
		&__field-container {
			display: block;
			flex: none;
			@media (max-width: $width-sm-max) {
				margin-top: 0;
				margin-bottom: 18px;
			}
		}
		&__actions {
			display: block;
			@media (max-width: $width-xs-max) {
				padding-top: 0;
			}
		}
		@media (max-width: $width-sm-max) {
			&__body {
				padding-bottom: 0;
				border-bottom: 0;
			}
			&__foot {
				margin-top: 30px;
				border-top: 1px solid #565354;
			}
			.list-stats li {
				margin: 0 8px;
				flex: 0 0 30px;
			}
		}
	}
}