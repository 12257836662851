/* ------------------------------------------------------------ *\
Section Properties
\* ------------------------------------------------------------ */

.section-properties {
	padding-bottom: 65px;
	&__title {
		font-size: 32px;
		a {
			display: inline-block;
			margin-left: 10px;
			font-family: $graphik;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: -0.03em;
		}
	}

	&__head {
		margin-bottom: 25px;
	}
}

@media(max-width: $width-sm-max){
	.section-properties {
		padding-bottom: 20px;
	}
}

@media(max-width: $width-xs-max){
	.section-properties {
		&__title {
			font-size: 25px;
			a {
				display: block;
				margin: 10px 0 0;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Property Item
\* ------------------------------------------------------------ */

.property-item__body {
	padding: 32px 15px 0 15px;
	position: relative; 

	body.new-homes & {
		padding-top: 55px !important; 
	}

	&.promo{
		height:220px!important;
	}

	span.strike {
		position: relative;
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			line {
				stroke: $strikethrough-color;
				stroke-width: 2px;
			}
		}
	}
	strong {
		font-weight: 500;
		font-size: 120%;
	}

	@media (max-width: $width-xs-max) {
		p {
			display: block;
		}
	}

	body.new-homes & {
		strong {
			font-weight: 500; 
			font-size: 120%; 
		}
		span.strike {
			font-size: 100%;
		}
	}
}

.property-item__dots {
	display: block !important; 
	position: absolute; 
	top: 0; 
	right: 0; 
	left: 0; 
	padding-top: 6px !important; 
	padding-left: 10px !important; 
	padding-right: 10px !important; 
	text-align: center; 
	li {
		display: inline-block; 
		margin-right: -3px; 
		margin-bottom: 3px; 
		a {
			font-weight: 500; 
			height: 20px; 
			display: block; 
			padding: 0 5px; 
			border-radius: 3px; 
			border: 2px solid $sage; 
			background: transparent; 
			transition: color .3s, background .3s;
			text-decoration: none; 
			font-size: 12px; 
			color: $sage;
			&.active {
				background: $sage; 
				color: #fff; 
			}
		}
		&:last-child {
			margin-right: 0; 
		}
	}
}

.property-item__dimensions {
	display: flex; 
	text-align: left; 
	padding-left: 15px; 
	padding-right: 15px; 
	justify-content: space-around; 
	margin-bottom: 15px; 

	> div {
		h5 {
			font-size: 10px; 
			font-family: $graphik; 
			font-weight: 500; 
		}
		h4 {
			font-size: 16px; 
		}
		body.new-homes & {
			h5, h4 {
				text-align: center; 
			}
			h4 {
				font-weight: 300; 
				line-height: 19px; 
				font-size: 13px; 
				letter-spacing: -.1px;
			}
		}
	}
}

.property-item__pricing {
	font-weight: bold; 
	body.new-homes & {
		font-weight: 300;
	}
}