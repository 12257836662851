/* ------------------------------------------------------------ *\
	SECTION NEWS
\* ------------------------------------------------------------ */

.section-news {
	
	.news-post, .news-post-sidebar {
		position: static;
	}
	.news-post-shell {
		position: relative;
	}
	&__content {
		max-width: 751px;
	}
	&__aside {
		padding-top: 50px;
		position: absolute; /* fixed; */
		width: 262px;
		.news-post &{
			padding-top: 180px;
			@media (max-width: $width-xs-max) {
				padding-top: 0px;
			}
		}
		@media (max-width: $width-xs-max) {
			padding-top: 0;
			
		}
		/*
		@media (max-width: $width-sm-max) {
			position: relative;
			top: auto;
			width: inherit;
		}
		*/
		@media (min-width: $width-sm-max) and (max-width: $width-md-max) {
			width: 212px;
		}
		@media (min-width: $width-md-max) and (max-width: 1200px) {
			width: 242px;
		}
		@media (max-width: $width-sm-max) {
			position: relative;
			width: 100%;
			bottom: 0;
			.news &{
				    padding-top: 40px;
				    border-top:1px solid #545151;
			}
		}
		&.sticky {
			position: fixed;
			top: 50px;
			padding-top: 0;
			@media (max-width: $width-sm-max) {
				position: relative;
				top: 0;
			}
		}
		&.sticky-bottom {
			position: absolute;
			bottom: 116px;
			top: auto;
			@media (max-width: $width-sm-max) {
				position: relative;
				bottom: 0;
			}
		}
		&--secondary {
			padding-top: 0;
			h4 {
				margin-bottom: 20px;
			}
			&.sticky {
				top: 25px !important;
			}
			&.sticky-bottom {
				top: auto !important;
			}
		}
	}
	&__head {
		padding: 94px 0 77px;
		@media (max-width: $width-sm-max) {
			padding-bottom: 70px;
		}
		@media (max-width: $width-xs-max) {
			.container { padding-left: 20px; padding-right: 20px; }
		}
		h1 {
			text-transform: uppercase;
			font-size: 60px;
			color: #231f20;
		}
	}

	&__inner {
		padding-top: 20px;
		html.ie10 &{
				margin-top: 40px;
			}
		h2 {
			a {
				color: $charcoal;
				text-decoration: none;
			}
		}
	}
	&__group {
		display: flex;
		margin-bottom: 130px;
		font-size: 14px;
		letter-spacing: -0.03em;
		color: #fff;
		&-content {
			position: relative;
			display: flex;
			flex: 0 0 263px;
			align-items: center;
			padding: 15px 25px;
			line-height: 1.5;
			background: #f5f5f5;
			color: $charcoal!important;
			html.ie10 &{
				width: 20%;
				float: right;
				min-height: 608px;
				display: block;
				margin-bottom: 50px;
			}
			h2 {
				font-size: 32px;
				line-height: 1;
				margin-bottom: 19px;
			}
			span {
				position: absolute;
				top: 28px;
				left: 25px;
				right: 25px;
				color: $sage;
				font-family: $helvetica;letter-spacing: 0.3em;font-size: 12px;text-transform: uppercase;
				font-weight: 500;
			}

			.section-news__btn {
				position: absolute;
				max-width: 200px;
				min-width: 0;
				bottom: 25px;
				left: 25px;
				padding: 5px 10px;
				font-size: 14px;
				font-weight: 400;
				letter-spacing: -0.02em;
				line-height: 20px;
				border: 1px solid $charcoal!important;
				color: $charcoal!important;
				
			}
		}
		&-image {
			flex: 0 1 100%;
			
			html.ie10 &{
					width: 80%;
					float: left;
				}
			img {
				
				width: 100%;
				height: auto;
				
				
			}
			@media (min-width: $width-sm-max) and (max-width: $width-md-max) and (orientation: landscape) {
				position: relative;
				img{
					width: auto;
					height: 100%;
					position: absolute;
					max-width: none;
				}
			}
		}
	}

	&__list {
		position: relative;
		&-sidebar {
			position: static;
			.sticky {
				position: fixed;
				top: 170px;
				width: 262.5px;
				@media (max-width: $width-sm-max) {
					position: relative;
					top: auto;
					width: inherit;
				}
				@media (min-width: $width-sm-max) and (max-width: $width-md-max) {
					width: 212px;
				}
				@media (min-width: $width-md-max) and (max-width: 1200px) {
					width: 242px;
				}
				&-bottom {
					position: absolute;
					top: auto;
					bottom: 0;
				}
			}
		}
		.button-gold{
			padding: 10px 15px;
		}
		.col-md-9.ajax {
			@media (max-width: $width-sm-max) {
				    padding-bottom: 40px;
			}
		}
	}
	.slider-images-secondary{
		margin-bottom: 0px;
	}
}

.section-news-secondary {
	padding-bottom: 78px;
}

@media(max-width: $width-sm-max){
	.section-news {
		&__group {
			display: block;
			span {
				display: block;
				margin-bottom: 15px;
			}
			p {
				margin-bottom: 21px;
			}

			&-content {
				display: block;
			}
			.section-news__btn, span {
				position: static;
			}
		}
	}
}

@media(max-width: $width-xs-max){
	.section-news {
		&__head {
			padding: 25px 0 43px;
			h1 {
				font-size: 35px;
			}
		}
		.container {
			padding: 0 20px;
		}
		&__group {
			margin-bottom: 38px;
			font-size: 12px;
			h3 {
				margin-bottom: 8px;
				font-size: 25px;
			}
			span {
				padding-top: 6px;
				margin-bottom: 28px;
				font-size: 12px;
			}
			p {
				margin-bottom: 10px;
			}
			&-content {
				padding: 14px 13px 17px;
			}
			.section-news__btn {
				max-width: 100%;
// 				min-width: 157px;
				height: 33px;
				font-size: 12px;
				line-height: 29px;
			}
		}
		&__inner {
			/*padding-right: 90px;*/
			padding-right: 60px;
		}
		&__aside--secondary {
			padding-top: 40px;
		}
	}
}