/* ------------------------------------------------------------ *\
	FORM BOOKING
\* ------------------------------------------------------------ */

.form-booking {
	padding: 14px 0;

	label {
		letter-spacing: -0.019em;
		.display-centre-single &, .estate &{
			font-weight: 300;
		}
	}
	input, textarea {
		background: #fff;
		border: 0;
		border-radius: 0;
		-webkit-appearnace: none;
		padding: 2px 15px;
		box-shadow: none;
		outline: none;
		&:focus {
			box-shadow: none;
		}
	}
	input {
		height: 37px;
	}
	textarea {
		resize: none;
		height: 112px;
		padding-top: 10px;
	}
	.text-right {
		font-size: 14px;
		letter-spacing: -0.02em;
		color: #999;
		font-weight: 500; /* 600 */
		margin-top: 16px;
		margin-bottom: 0;
	}
	.custom-select {
		position: relative;
		select{
			-webkit-appearance: none;
			width: 100%;
			height: 36px;
			line-height: 38px;
			border: 0;
			border-radius: 0;
			padding: 0 10px;
			font-size: 14px;
			background: #fff;
			color: #555;
			position: relative;
			box-shadow: none;
		}

		&:after {
			content: '';
			background: #fff url('../images/icon-arrow-down-dark.svg') no-repeat center center;
			z-index: 5;
			position: absolute;
			right: 0;
			bottom: 0;
			height: 36px;
			width: 43px;
			background-size: 18px;
			pointer-events: none;
		}

		&.disabled {
			opacity: 0.5; 
		}
	}
	button {
		padding: 10px 34px;
		margin-top: -20px;
	}

	div.row {
		.form-group {
			label {
				display: none;
			}
			input::placeholder, textarea::placeholder {
				color: #555;
			}

			&.error {
				input::placeholder, textarea::placeholder {
					color: $red;
				}
				select {
					color: $red;
				}
			}
		}
	}

	&__thank-you {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #f2f2f2;
		z-index: 10;

		.container {
			position: relative;
			height: 100%; 
		}

		h2 {
			position: absolute;
			left: 0;
			top: 50%;
			font-size: 48px;
			font-style: italic;
			transform: translateY(-50%);
		}
		
		&.dual_occupancy_warning{
			
			h2{
				font-size: 36px;
			}
			
		}
	}
}

#d365o-search {
	button.button-gold {
		margin-top: -3px; 
	}
}

#d365o-booking-list {
	display: none; 
	&.active {
		display: block; 
	}
}

#d365o-appointment-edit {
	.submit-row {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;
	}
}

#mandarinForm{
	
	textarea {
		resize: none;
		height: 140px;
		padding-top: 10px;
	}
	
}