/* ------------------------------------------------------------ *\
	News Items
\* ------------------------------------------------------------ */

.news-items {
	padding: 0 100px 0 0;
	margin: 0;
	list-style-type: none;
}

.news-item {
	display: flex;
	padding: 54px 0;
	border-top: 1px solid #d5d5d5;
	@media (max-width: $width-sm-max) {
		padding: 70px 0;
	}
	h3 {
		font-size: 24px;
		line-height: 1.17;
		margin-bottom: 16px;
		a {
			display: block;
			color: inherit;
		}
	}
	p {
		margin-bottom: 20px;
		letter-spacing: -0.03em;
		a {
			font-weight: 500;
		}
	}

	&__image {
		flex: 0 0 267px;
		margin-right: 25px;
		html.ie10 &{
			width: 267px;
			float: left;
			
			
		}
	}
	&__content {
		flex: 0 1 100%;
		
	}
	&__inner {
		display: flex;
		align-items: center;
		p {
			margin: 0;
			color: #b2b1b1;
			margin-right: 17px;
		}
	}
}

@media(max-width: 1279px){
	.news-items {
		padding-right: 0;
	}
}

@media(max-width: $width-xs-max){
	.news-item {
		display: block;
		padding: 40px 0;
		h3 {
			font-size: 19px;
			margin-bottom: 5px;
		}
		p {
			margin-bottom: 14px;
		}
		img {
			width: 100%;
			height: auto;
		}

		&__image {
			margin: 0 0 19px;
		}
		&__inner {
			display: block;

			p {
				margin-bottom: 10px;
			}
		}
	}
}

