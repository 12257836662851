/* ------------------------------------------------------------ *\
	Section Center
\* ------------------------------------------------------------ */
.section-center {
	background: #f5f5f5;
	padding-top: 54px;
	.display-centre-single &, .estate & {
		background: inherit;
		padding-top: 0px;
	}

	@media (max-width: $width-sm-max) {
		padding-top: 30px;
	}

	@media (max-width: $width-xs-max) {
		padding-top: 0;
	}

	&__group-amenities {
		.no-border {
			margin-bottom: 31px;
		}
		p {
			font-size: 14px;
		}
		.latest-news-item__title {
			font-weight: 200;
		}
		@media (max-width: $width-xs-max) {
			.latest-news-head__title {
				font-size: 30px;
			}
			.latest-news-item__title {
				margin-top: 0;
				font-size: 14px;
				font-weight: 500;
			}
			.latest-news-item__text {
				line-height: 1.2;
			}
			.latest-news-item--small {
				margin-bottom: 12px;
				.row > div:nth-child(2) {
					padding-left: 0;
				}
			}
		}
	}

	&__group-team {
		border-bottom: 2px solid  #6e6b6c;
		padding: 26px 0 97px;
		margin-bottom: 34px;
		img {
			max-width: 100%;
			height: auto;
		}
		@media (max-width: $width-sm-max) {
			padding-bottom: 60px;
		}
		h2 {
			/*letter-spacing: 0.05em;*/
			margin-bottom: 18px;
		}
		.button-gold {
			position: absolute;
			bottom: 0;
			left: 15px;
			padding: 14px 26px;
			font-size: 16px;
			font-weight: 500;
		}
		&__content {
			max-width: 300px;
			@media (max-width: $width-sm-max) {
				max-width: 100%;
				margin-bottom: 46px;
				.button-gold {
					position: relative;
					display: inline-block;
					left: auto;
					bottom: auto;
					padding: 10px 15px;
					font-size: 12px;
					margin-top: 20px;
				}
			}
			@media (max-width: $width-xs-max) {
				margin-bottom: 30px;
			}
		}
		&-column-copy {
			.section-center__group-team__content {
				.button-gold {
					position: relative;
				}
			}
		}
		.col-md-4 {
			position: static;
		}
		.row {
			position: relative;
		}
	}

	&__group-features {
		padding: 0 0 119px;
		@media (max-width: $width-sm-max) {
			padding-bottom: 60px;
		}
		h2 {
			margin-bottom: 30px;
			/*letter-spacing: 0.05em;*/
		}
		a {
			padding: 16px 23px;
			border-width: 2px;
			margin-right: 6px;
			&:hover {
				background: #c9c8c8;
				color: #fff;
				text-decoration: none;
			}

			@media (max-width: $width-sm-max) {
				margin-bottom: 13px;
			}

			@media (max-width: $width-xs-max) {
				padding: 10px 15px;
			}
		}
	}
}

